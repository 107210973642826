import React, { useState, useEffect } from "react";
import "./createRfi.css";
import TextInput from "../Inputs/TextInput";
import SingleSelect from "../Common/SingleSelect";
import { Editor } from "primereact/editor";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { DragAndDrop } from "../Inputs/DragAndDrop";
import {
  addCategory,
  addSubmittalCategory,
  createRfi,
  getAllCategory,
  getCompanyByProjectId,
  getRFICount,
  getUserByProjectId,
  getUsersByCompanyId,
  uploadDocs,
} from "../../Api/Admin/AllAPIs";
import {
  convertHourlySpentStringIntoObject,
  getActionsPermission,
  getProjectCode,
  getProjectName,
  getUserId,
  getUserName,
  toast,
} from "../../utils";
import Loader from "../Common/Loader";
import moment from "moment";
import AddSets from "../Common/AddSets";
import { MultiSelect } from "primereact/multiselect";
import AutoCompleteSelect from "../Common/AutoCompleteSelect";
import TimeInput from "../Inputs/TimeSelector";
import { FormHeader } from "../Common/FormHeader";

export const CreateRfi = () => {

  const isApproverAccess = getActionsPermission("Rfi Approver");
  const isReviewerAccess = getActionsPermission("Rfi Reviewer");
  const [fileUpload, setFileUpload] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFile, setPreviousFile] = useState([]);

  const [subject, setSubject] = useState("");
  const [rfi, setRfi] = useState("");
  const [responsibleContractor, setResponsibleContractor] = useState([]);
  const [responsiblePerson, setResponsiblePerson] = useState([]);
  const [membersResponsible, setMembersResponsible] = useState([]);
  const [initiatedDate, setInitiatedDate] = useState(moment().format("DD/MM/YYYY h:mm:ss a"))
  const [dueDate, setDueDate] = useState("");
  const [location, setLoation] = useState("");
  const [questionReceived, setQuestionReceived] = useState([]);
  const [reference, setReference] = useState('');
  const [drawingNumber, setDrawingNumber] = useState("");
  const [category, setCategory] = useState(null);
  const [addCategoryy, setAddCategoryy] = useState()
  const [scheduledImpact, setScheduledImpact] = useState(null);
  const [costImpact, setCostImpact] = useState(null);
  const [question, setQuestion] = useState("");
  const [costCode, setCostCode] = useState("");
  const [rfiManager, setRfiManager] = useState([]);
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState(null);
  const projectId = localStorage.getItem("projectId");
  const projectData = JSON.parse(localStorage.getItem("projectData"));
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [allUsersOfProject, setAllUsersOfProject] = useState([]);
  const [approver, setApprover] = useState(null);
  const [reviewer, setReviewer] = useState(null);
  const [visible, setVisible] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [companyId, setCompanyId] = useState()
  const [users, setUsers] = useState([])
  const [categories, setCategories] = useState([])
  const [companies, setCompanies] = useState([])
  const [hourSpent, setHourSpent] = useState("")

  const [Validation, setValidation] = useState({
    subject: false,
    rfiNo: false,
    initialDate: false,
    dueDate: false,
    category: false,
    status: false,
    approvalStatus: false,
    rfiManager: false,
    questionReceived: false,
  });


  const statusOptions = [
    { label: "Not Started", value: "Not Started" },
    { label: "In Progress", value: "In Progress" },
    { label: "Completed", value: "Completed" },
    { label: "Ready for Review", value: "Ready for Review" },
    { label: "Incomplete", value: "Incomplete" }
  ]

  const generateNextRFI = async () => {
    try {
      let res = await getRFICount(projectId)
      setRfi(res.data.data);
    } catch (Err) {
      console(Err)
    }
  };


  useEffect(() => {
    generateNextRFI();
  }, []);



  const checkValidation = () => {
    if (!subject.trim()) {
      setValidation((prevState) => ({
        ...prevState,
        subject: true,
      }));
    }
    if (!category) {
      setValidation((prevState) => ({
        ...prevState,
        category: true,
      }));
    }
    if (!status) {
      setValidation((prevState) => ({
        ...prevState,
        status: true,
      }));
    }
    if (!dueDate) {
      setValidation((prevState) => ({
        ...prevState,
        dueDate: true,
      }));
    }
    if (!rfi) {
      setValidation((prevState) => ({
        ...prevState,
        rfiNo: true,
      }));
    }

    if (rfiManager.length == 0) {
      setValidation((prevState) => ({
        ...prevState,
        rfiManager: true,
      }));
    }
    if (questionReceived.length == 0) {
      setValidation((prevState) => ({
        ...prevState,
        questionReceived: true,
      }));
    }


    if (!initiatedDate) {
      setValidation((prevState) => ({
        ...prevState,
        initialDate: true,
      }));
    }



    if (
      !category ||
      !initiatedDate ||
      !membersResponsible ||
      !responsibleContractor ||
      !rfi ||
      !subject ||
      !dueDate || rfiManager?.length == 0 || questionReceived.length == 0
    ) {
      toast.info("Please Fill all the Fields Marked with * are Mandatory");
      return true;
    }
    return false;
  };
  const domain = window.location.origin;

  const handleFileSubmit = (event) => {
    setFileUpload(event);
  };

  const handleRfi = async () => {
    if (fileUpload.length > 10) {
      toast.info("You can add only 10 attachments");
      return;
    }

    if (checkValidation()) {

      return console.log(Validation);
    }

    let uploadResponse = {};
    if (fileUpload.length !== 0) {
      const formData = new FormData();
      for (const file of fileUpload) {
        formData.append("upload", file);
      }
      uploadResponse = await uploadDocs(formData);
    }


    let emailData = [];

    if (isReviewerAccess) {
      let findEmail = allUsersOfProject?.find(res => res._id == reviewer)
      emailData.push({
        name: findEmail?.first_name,
        to: findEmail?.email_address,
        tool_number: rfi,
        tool: "Rfi",
        tool_name: subject,
        subject: `Action Required: Appointed as the Reviewer to  Rfi #${rfi} ${subject}`,
        project_name: getProjectName(),
        project_id: getProjectCode(),
        template_type: "createdToolEmailToReviewer",
        created_by: getUserName(),
        url: `${domain}/projectManagement/openrfis`,
      });
    }

    let manager = allUsersOfProject?.filter(res => rfiManager.includes(res?._id))
    let assignees = allUsersOfProject?.filter(res => responsiblePerson.includes(res?._id))
    manager?.forEach(res => {
      emailData.push({
        name: res?.first_name,
        to: res?.email_address,
        tool_number: rfi,
        subject: `FYI: Task #${rfi} ${subject} Created in ${getProjectName()}`,
        tool: "Rfi",
        tool_name: subject,
        project_name: getProjectName(),
        project_id: getProjectCode(),
        template_type: "emailToAllUserWhileUpdatingToolData",
        url: `${domain}/projectManagement/openrfis`,
        updated_by: getUserName()
      });
    })
    assignees?.forEach(res => {
      emailData.push({
        name: res?.first_name,
        to: res?.email_address,
        tool_number: rfi,
        subject: `FYI: Task #${rfi} ${subject} Created in ${getProjectName()}`,
        tool: "Rfi",
        tool_name: subject,
        project_name: getProjectName(),
        project_id: getProjectCode(),
        template_type: "emailToAllUserWhileUpdatingToolData",
        url: `${domain}/projectManagement/openrfis`,
        updated_by: getUserName()
      });
    })

    const payload = {
      subject: subject,
      rfi: rfi,
      responsibleContractor: responsibleContractor,
      membersResponsible: membersResponsible,
      status: reviewer !== null && approver !== null ? 'inreview' : 'draft',
      initiatedDate: moment().format('DD/MM/YYYY h:mm:ss a'),
      dueDate: moment(dueDate).format("DD/MM/YYYY h:mm:ss a"),
      location: location,
      questionReceived: questionReceived,
      reference: reference,
      drawingNumber: drawingNumber,
      category: category,
      scheduledImpact: scheduledImpact,
      costImpact: costImpact,
      question: question,
      attachFile: uploadResponse.data,
      rfi_manager: rfiManager,
      assignees: responsiblePerson,
      costCode: costCode,
      project_id: projectId,
      reviewer_id: reviewer,
      approver_id: approver,
      email_data: emailData,
      hourSpent: hourSpent,
      workflow_status: status,
      creator_email: getUserId()
    };
    console.log(payload);
    // return
    setLoader(true);
    try {
      await createRfi(payload);
      toast.success("RFI Created Successfully!");
      navigate("/projectManagement/openrfis");
      setSubject("");
      setRfi("");
      setResponsibleContractor("");
      setMembersResponsible("");
      setInitiatedDate(moment().format("DD/MM/YYYY h:mm:ss a"));
      setDueDate(moment().format("DD/MM/YYYY h:mm:ss a"));
      setResponsiblePerson(null);
      setRfiManager(null);
      setCostCode("");
      setLoation("");
      setQuestionReceived("");
      setReference("");
      setDrawingNumber("");
      setCategory("");
      setScheduledImpact("");
      setCostImpact("");
      setQuestion("");
      setFileUpload("");
      setStatus("");
    } catch (err) {
      console.error(err);
      if (err?.response?.data?.message === "Rfi ID Already Exist") {
        toast.error("RFI Number Already Exit");
      } else {
        toast.error("Something Wents Wrong, Please Try Again!");
      }
    }
    setIsCreating(false)
    setLoader(false);
  };




  const navigate = useNavigate();



  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId);
      let allUsers = res?.data?.data?.[0]?.combined_users || [];
      // setAllUsersOfProject(allUsers?.filter(item => item?._id !== getUserId()));
      setAllUsersOfProject(allUsers);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllUsersOfProject();
  }, []);

  const getrfiCategory = async (projectId) => {
    try {
      const res = await getAllCategory(projectId)
      const categoryy = res?.data?.data
      setCategories(categoryy)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getrfiCategory(projectId)
  }, [])

  const submitRfiCategory = async () => {

    try {
      const payload = {
        name: addCategoryy,
        project_id: projectId
      }
      await addSubmittalCategory(payload);
      setAddCategoryy('')
      getrfiCategory(projectId)
      setVisible(false)
    } catch (error) {
      console.log(error)
    }
  }

  const getcompany = async () => {
    try {
      const res = await getCompanyByProjectId(projectId);
      if (res && res?.data) {
        const companyNames = res?.data?.data[0]?.companynames;
        const formattedCompanies = companyNames.map((company) => ({
          label: company.company_name,
          value: company._id

        }));
        setCompanies(formattedCompanies);
      } else {
        console.log("Response data is not in the expected format");
      }
    } catch (error) {
      console.log("Error fetching company data:", error);
    }
  };
  useEffect(() => {
    getcompany()
  }, [])

  const handleCompanyChange = async (e) => {
    const selectedCompanyIds = e.value; // e.value should be an array of selected company IDs
    setResponsibleContractor(selectedCompanyIds);

    // Fetch users for all selected companies
    if (selectedCompanyIds.length > 0) {
      const usersFromAllCompanies = [];

      for (const companyId of selectedCompanyIds) {
        try {
          const res = await getUsersByCompanyId(projectId, { company_id: [companyId] });
          console.log(res)
          if (res && res?.data?.data) {
            const usersFromCompany = res?.data?.data?.map(user => ({
              label: user.name,
              value: user._id
            }));
            usersFromAllCompanies.push(...usersFromCompany);
          } else {
            console.error("Response data is not in the expected format");
          }
        } catch (error) {
          console.log("Error fetching company data:", error);
        }
      }

      // Remove duplicate users
      const uniqueUsers = Array.from(new Set(usersFromAllCompanies.map(user => user.value)))
        .map(id => {
          return usersFromAllCompanies.find(user => user.value === id);
        });

      setUsers(uniqueUsers);
    } else {
      setUsers([]);
    }
  };



  const companyUser = async (companyId) => {
    try {
      const res = await getUsersByCompanyId(projectId, companyId)

      if (res) {
        const userOptions = res?.data?.data?.map(user => ({
          label: user.name,
          value: user._id
        }));
        setUsers(userOptions);
      } else {
        console.error("Response data is not in the expected format");
      }
    }
    catch (error) {
      console.log("Error fetching company data:", error);
    }
  }

  useEffect(() => {
    if (companyId)
      companyUser(companyId)
  }, [companyId])

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (/^[0-9]*\.?[0-9]*$/.test(inputValue)) {
      setCostImpact(inputValue);
    }
  }

  const handleCancel = () => {
    setIsCreating(false);
    navigate("/projectManagement/openrfis");
  }

  return (
    <>
      <div className="main-container">
        <div>

          <FormHeader
            to={"/projectManagement/openrfis"}
            formHeader={"RFI"}
            breadcrumItem={["RFIs", `Create RFIs`]} />

          {/* Top Create New Rfi End */}
          {loader ? (
            <Loader />
          ) : (
            <div className="row m-0 p-0 ">
              <div className="row  m-0 p-0">
                <div className="col-md-6 col-12 d-flex align-items-end">
                  <div className="w-100">
                    <label htmlFor="" className="py-2 labelCss">
                      Subject: <span className="text-danger">*</span>
                    </label>
                    <TextInput
                      placeholder="Subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      className={Validation.subject ? "Validation" : ""}
                      onClick={() =>
                        setValidation({ ...Validation, subject: false })
                      }
                    >
                      {" "}
                    </TextInput>
                  </div>
                </div>
                <div className="col-md-6 col-12 d-flex align-items-end">
                  <div className="w-100">
                    <label className="labels mb-2">Category:</label>{" "}
                    <span className="text-danger">*</span>
                    <div className="card flex justify-content-center">


                      <AutoCompleteSelect
                        options={categories?.map((item) => ({
                          label: item?.name,
                          value: item?._id,
                        }))}
                        value={category}
                        optionLabel="category_name"
                        onChange={(value) => {
                          setCategory(value)
                          setValidation({ ...Validation, category: false })
                        }}
                        className={`p-0 ${Validation.category ? "Validation" : ""} text-capitalize`}
                        onClick={() =>
                          setValidation({ ...Validation, category: false })
                        }
                        placeholder="Select Category"

                      />

                    </div>
                  </div>
                  <div className="mt-4 d-flex align-items-end ">
                    <MdAdd
                      className="h2"
                      style={{ cursor: "pointer" }}
                      onClick={() => setVisible(true)}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Workflow Status: <span className="text-danger">*</span>
                  </label>
                  <SingleSelect
                    value={status}
                    options={statusOptions}
                    placeholder="Status"
                    onChange={(e) => setStatus(e.target.value)}
                    className={Validation.status ? "Validation p-0" : "p-0"}
                    onClick={() =>
                      setValidation({ ...Validation, status: false })
                    }

                  />
                </div>

                <div className="col-md-6 col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Created Date: <span className="text-danger">*</span>
                  </label>

                  <input
                    type="date"
                    style={{ height: "50px" }}
                    value={moment(initiatedDate, "DD/MM/YYYY h:mm:ss a")?.format('yyyy-MM-DD')}
                    className={` ${Validation.initialDate ? "Validation" : "form-control"
                      }`}
                    onClick={() =>
                      setValidation({ ...Validation, initialDate: false })
                    }
                    placeholder="dd-mm-yy"
                    selected={initiatedDate}
                    onChange={(e) => setInitiatedDate(e.target.value)}
                    readOnlyInput={false}
                    disabled
                  />

                </div>
              </div>

              {/*  calender  section start */}
              <div className="row  m-0 p-0">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    RFI Number: <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex w-100 ">
                    <input
                      type="text"
                      placeholder="Rfi Number"
                      value={rfi}
                      onChange={(e) => setRfi(e.target.value)}
                      style={{ width: "100%" }}
                      className={`${Validation.rfiNo ? "Validation" : ""
                        } form-control common_input`}
                      onClick={() =>
                        setValidation({ ...Validation, rfiNo: false })
                      }

                    />
                  </div>

                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Due Date: <span className="text-danger">*</span>
                  </label>

                  <input
                    type="date"
                    style={{ height: "50px" }}
                    placeholder="Due Date"
                    onChange={(e) => setDueDate(e.target.value)}
                    className={`${Validation.dueDate ? "Validation" : ""
                      } form-control`}
                    onClick={() =>
                      setValidation({ ...Validation, dueDate: false })
                    }
                  />
                </div>
              </div>

              {/*  calender section start */}
              <div className="row  gap-0 m-0 p-0">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Responsible Contractor Company (s ):
                  </label>

                  <MultiSelect
                    value={responsibleContractor}
                    onChange={handleCompanyChange}
                    options={companies}
                    optionLabel="label"
                    filter placeholder="Company"
                    // maxSelectedLabels={3} 
                    style={{ width: "100%" }}
                    className={`p-0 `}


                  />

                </div>


                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Responsible Member (s):
                  </label>
                  <div>
                    <MultiSelect
                      value={membersResponsible}
                      onChange={(e) => setMembersResponsible(e.value)}
                      options={users}
                      optionLabel="label"
                      filter placeholder="Select Member"
                      // maxSelectedLabels={3} 
                      style={{ width: "100%" }}
                      className={`p-0 `}

                    />
                  </div>


                </div>

              </div>

              <div className="row  gap-0 m-0 p-0">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    RFI Manager (s): <span className="text-danger">*</span>
                  </label>
                  <MultiSelect
                    options={allUsersOfProject.map((item) => ({
                      label: item?.first_name,
                      value: item?._id,
                    }))}
                    value={rfiManager}
                    optionLabel="label"
                    onChange={(e) => {
                      setRfiManager(e.value)
                      setValidation({ ...Validation, rfiManager: false })
                    }}
                    className={`p-0 ${Validation.rfiManager ? "Validation" : ""}`}
                    style={{ width: "100%" }}
                    placeholder="RFI Manager"
                    filter={true}

                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Location:
                  </label>
                  <TextInput
                    placeholder="Location"
                    value={location}
                    onChange={(e) => {
                      setLoation(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="row  m-0 p-0">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Question Received from: <span className="text-danger">*</span>
                  </label>

                  <MultiSelect
                    options={allUsersOfProject.map((item) => ({
                      label: item?.first_name,
                      value: item?._id,
                    }))}
                    value={questionReceived}
                    optionLabel="label"
                    onChange={(e) => {
                      setQuestionReceived(e.value)
                      setValidation({ ...Validation, questionReceived: false })
                    }}
                    className={`p-0 ${Validation.questionReceived ? "Validation" : ""}`}
                    style={{ width: "100%" }}

                    placeholder="Question"

                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Reference:
                  </label>

                  <TextInput
                    placeholder="Reference"
                    value={reference}
                    onChange={(e) => {
                      setReference(e.target.value);
                    }}


                  />

                </div>
              </div>

              <div className="row m-0 p-0">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Drawing Number:
                  </label>
                  <div className="d-flex w-100 ">
                    <input
                      type="number"
                      placeholder="Enter Drawing Number"
                      inputId="integeronly"
                      value={drawingNumber}
                      onChange={(e) => setDrawingNumber(e.target.value)}
                      className="form-control common_input w-100"
                    />
                  </div>
                  {/* <TextInput className="" placeholder="Enter Drawing Number" value={drawingNumber} onChange={(e) => { setDrawingNumber(e.target.value) }} /> */}
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Hours Spent:
                  </label>
                  <TimeInput
                    value={convertHourlySpentStringIntoObject(hourSpent)}
                    onChange={(newValue) =>
                      setHourSpent(`${newValue.hours}-${newValue.minutes}`)
                    }
                  />

                </div>

              </div>
              <div className="row m-0 p-0">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Responsible Person (s):
                  </label>
                  <MultiSelect
                    value={responsiblePerson}
                    onChange={(e) => setResponsiblePerson(e.value)}
                    options={allUsersOfProject.map((item) => ({
                      label: item?.first_name,
                      value: item?._id,
                    }))}

                    optionLabel="label"
                    filter placeholder="Select Person"
                    // maxSelectedLabels={3} 
                    style={{ width: "100%" }}
                    className={`p-0 ${Validation.contractor ? "Validation" : ""}`}

                  />




                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Cost Code:
                  </label>
                  <TextInput
                    placeholder="Enter Cost Code"
                    value={costCode}
                    onChange={(e) => {
                      setCostCode(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="row m-0 p-0">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Scheduled Impact:
                  </label>
                  <TextInput
                    placeholder="Schedule Impact"
                    value={scheduledImpact}
                    onChange={(e) => {
                      setScheduledImpact(e.target.value);
                    }}
                  />

                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Cost Impact:
                  </label>
                  <TextInput
                    placeholder=" Cost"
                    value={costImpact}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row m-0 p-0">
                {isReviewerAccess && (
                  <div className="col-md-6 col-12">
                    <label htmlFor="" className="py-2 labelCss">
                      Reviewer:
                    </label>
                    <AutoCompleteSelect
                      options={allUsersOfProject?.map((item) => {
                        // if (item?._id !== getUserId()) {
                          return {
                            label: item?.first_name,
                            value: item?._id,
                          }
                        // }
                      })}
                      value={reviewer}
                      optionLabel="label"
                      onChange={(value) => {
                        setReviewer(value);
                      }}
                      className={`p-0 ${Validation.reviewer ? "Validation" : ""}`}
                      onClick={() =>
                        setValidation({ ...Validation, reviewer: false })

                      }

                      disabled={reviewer === getUserId()}
                      placeholder="Select Reviewer"

                    />

                  </div>
                )}

                {isApproverAccess && (
                  <>
                    <div className="col-md-6 col-12">
                      <label htmlFor="" className="py-2 labelCss">
                        Approver:
                      </label>
                      <AutoCompleteSelect
                        options={allUsersOfProject?.map((item) => {
                          // if (item?._id !== getUserId()) {
                            return {
                              label: item?.first_name,
                              value: item?._id,
                            }
                          // }
                        })}
                        value={approver}
                        optionLabel="label"
                        onChange={(value) => {
                          setApprover(value);
                        }}
                        className={`p-0 ${Validation.approver ? "Validation" : ""}`}
                        onClick={() =>
                          setValidation({ ...Validation, approver: false })

                        }

                        disabled={approver === getUserId()}
                        placeholder="Select Approver"

                      />
                    </div>
                  </>

                )}

              </div>

              <div className="row m-0 p-0">
                <div className="col-md-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Question:
                  </label>
                  <div className="card">

                    <Editor
                      value={question}
                      onTextChange={(e) => setQuestion(e.htmlValue)}
                      style={{ height: "200px" }}
                    />
                  </div>
                </div>
              </div>

              <div className="row m-0 p-0 css_com">
                <div className="col-md-12 mt-2">
                  <DragAndDrop
                    setRemovePhoto={setRemovePhoto}
                    value={fileName}
                    setFiles={setFileUpload}
                    onChange={handleFileSubmit}
                    setValue={setFileName}
                    setPreviousFiles={setPreviousFile}
                  />
                </div>
              </div>

              <div className="btn_main mt-5 ">
                <span
                  className="cancelBtn  px-2 py-2"
                  onClick={handleCancel}
                >
                  {" "}
                  Cancel
                </span>

                <span className="CreateRfi px-2 py-2" onClick={handleRfi}>
                  Create RFI
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <br />

      <AddSets
        visible={visible}
        setVisible={setVisible}
        CategoryName={"RFI Category"}
        label={"Category"}
        reqFor={"create"}
        value={addCategoryy}
        setValue={setAddCategoryy}
        onSubmit={submitRfiCategory}
      />

    </>
  );
};

import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getProjectCode, getProjectId, getProjectName, getUserId, getUserName } from '../../utils'
import TextInput from '../../Components/Inputs/TextInput'
import { getTimeZoneOptions } from '../../Components/Common/timeZone'
import CustomDragAndDrop from '../../Components/Inputs/CustomDragAndDrop'
import { addMeeting, getMeetingById, getUserByProjectId, updateMeeting, uploadMultipleDocs } from '../../Api/Admin/AllAPIs'
import MultiSelects from '../../Components/Common/MultiSelects'
import AutoCompleteSelect from '../../Components/Common/AutoCompleteSelect'
import { Editor } from 'primereact/editor'
import { MultiSelect } from 'primereact/multiselect'
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton'
import { TagsInput } from 'react-tag-input-component'
import { FormHeader } from '../../Components/Common/FormHeader'

export const CreateUpdateMeeting = () => {

    let { id } = useParams()
    let navigate = useNavigate()
    let [deletedFiles, setDeletedFiles] = useState([])
    let [btnLoader, setBtnLoader] = useState(false)
    let [allUsers, setAllUsers] = useState([])
    let [meetingData, setMeetingData] = useState({})

    const { control, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
        defaultValues: {
            meeting_name: "",
            videoConferencingLink: "",
            meetingLocation: "",
            meetingDate: "",
            timeZone: "",
            startTime: "",
            endTime: "",
            description: "",
            attachment: [],
            meeting_attendees: [],
            project_id: getProjectId(),
            meeting_guest: [],
            creator_email: getUserId(),
        },
    });

    useEffect(() => {
        getAllUsers()
        if (id) {
            getMeetingData(id)
        }
    }, [])

    async function getMeetingData(id) {
        try {
            let res = await getMeetingById(id)
            console.log(res.data.data[0])
            setMeetingData(res.data.data[0])

            let { meeting_name, videoConferencingLink, meetingLocation, meetingDate, timeZone, startTime, endTime, description, meeting_attendees, meeting_guest, attachment } = res?.data?.data?.[0]

            meeting_attendees = meeting_attendees.map(e => e._id)

            reset({
                meeting_name,
                videoConferencingLink,
                meetingLocation,
                meetingDate,
                timeZone,
                startTime,
                endTime,
                description,
                attachment,
                meeting_attendees,
                meeting_guest,
            })

        }
        catch (err) {
            console.log(err)
        }
    }


    const getAllUsers = async () => {
        try {
            let res = await getUserByProjectId(getProjectId())
            setAllUsers(res?.data?.data?.[0].combined_users || [])
        }
        catch (err) {
            console.log(err)
        }
    }

    async function formSubmit(data) {
        try {
            setBtnLoader(true)
            let payload = { ...data, email_data: [] }
            // store all already uploaded filed mainly form edit mode
            let files = data.attachment.filter(at => !at.file)

            let newFiles = data.attachment.filter(at => at.file).map(e => e.file)
            // if (newFiles.length > 0) {
            //     console.log(newFiles)
            //     let re = await uploadMultipleDocs(newFiles);
            //     console.log(re.data.data, "ffffffffffffffff")
            //     files = [...files, ...(re?.data?.data || [])];
            // }

            payload.attachment = files
            // console.log(payload)

            payload.deleted_files = deletedFiles;
            if (id) {
                payload.tool_name = "meeting"
                payload.user_name = getUserName()
                payload.project_id = getProjectId()
                await updateMeeting(id, payload);
            }
            else {

                payload.meeting_guest.forEach((res) => {
                    payload.email_data.push({
                        name: "Guest",
                        to: res,
                        tool: "Meeting",
                        tool_name: payload.meeting_name,
                        project_name: getProjectName(),
                        project_id: getProjectCode(),
                        created_by: getUserName(),
                        meeting_link: payload.videoConferencingLink,
                        subject: `FYI: New Meeting created in ${getProjectName()}`
                    })
                })

                payload.meeting_attendees.forEach((d) => {
                    let userData = allUsers.find(user => user._id == d)
                    payload.email_data.push({
                        name: `${userData.first_name} ${userData.last_name}`,
                        tool: "Meeting",
                        to: userData.email_address,
                        tool_name: payload.meeting_name,
                        project_name: getProjectName(),
                        project_id: getProjectCode(),
                        created_by: getUserName(),
                        meeting_link: payload.videoConferencingLink,
                        subject: `FYI: New Meeting created in ${getProjectName()}`
                    })
                })

                console.log(payload)




                let res = await addMeeting(payload);
            }
            navigate("/projectManagement/meetings")
        }



        catch (err) {
            console.log(err)
        }
        finally {
            setBtnLoader(false)
        }
    }

    function setEmailData() {

    }



    return (
        <div>

            <FormHeader
                to={"/projectManagement/meetings"}
                formHeader={"Meeting"}
                breadcrumItem={["Meeting", `${id ? "Edit" : "Create"} Meeting`]} />

            <form onSubmit={handleSubmit(formSubmit)}>

                <div className="row">

                    <div className="col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Name:<span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="meeting_name"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <TextInput
                                        placeholder="Enter Meeting Name"
                                        value={value}
                                        onChange={onChange}
                                        className={errors.meeting_name ? "Validation" : ""}
                                    />
                                );
                            }}
                        />
                    </div>

                    <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Video Conferencing Link: <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="videoConferencingLink"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <TextInput
                                        value={value}
                                        placeholder="Conferencing Link"
                                        onChange={onChange}
                                        className={errors.videoConferencingLink ? "Validation" : ""}
                                    />
                                );
                            }}
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Meeting Location: <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="meetingLocation"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <TextInput
                                        value={value}
                                        placeholder="Meeting Location"
                                        onChange={onChange}
                                        className={errors.meetingLocation ? "Validation" : ""}
                                    />
                                );
                            }}
                        />
                    </div>


                    <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Meeting Date: <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="meetingDate"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <input
                                        type='date'
                                        value={value}
                                        placeholder="Meeting Date"
                                        onChange={onChange}
                                        className={`${errors.meetingDate ? "Validation" : ""} form-control commpn common_input`}
                                    />
                                );
                            }}
                        />
                    </div>

                    <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Timezone: <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="timeZone"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <AutoCompleteSelect
                                        className={errors.timeZone ? "Validation" : ""}
                                        options={getTimeZoneOptions()}
                                        value={value}
                                        optionLabel="label"
                                        onChange={(e) => {
                                            onChange(e)
                                        }}
                                        placeholder="Select Time Zone"

                                    />
                                );
                            }}
                        />
                    </div>


                    <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Start Time: <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="startTime"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <input
                                        type='time'
                                        value={value}
                                        placeholder="Start Time"
                                        onChange={onChange}
                                        className={`${errors.startTime ? "Validation" : ""} form-control common_input`}
                                    />
                                );
                            }}
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            End Time: <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="endTime"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <input
                                        type='time'
                                        value={value}
                                        placeholder="End Time"
                                        onChange={onChange}
                                        className={`${errors.endTime ? "Validation" : ""} form-control common_input`}
                                    />
                                );
                            }}
                        />
                    </div>
                    <div className="col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Description:
                        </label>
                        <Controller
                            name="description"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Editor
                                        value={value}
                                        onTextChange={(e) => onChange(e.htmlValue)}
                                        style={{ minHeight: "140px" }}
                                    />
                                );
                            }}
                        />
                    </div>

                    <div className="col-12">

                        <Controller
                            name="attachment"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <CustomDragAndDrop
                                        value={value}
                                        onChange={(e) => onChange(e)}
                                        setDeleted={setDeletedFiles}
                                    />
                                );
                            }}
                        />
                    </div>

                    <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Meeting Attendees:
                        </label>
                        <Controller
                            name="meeting_attendees"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <MultiSelect
                                        filter={true}
                                        optionLabel="first_name"
                                        options={allUsers}
                                        value={allUsers.filter((e) => value.includes(e._id))}
                                        onChange={(e) => {
                                            onChange(e.value.map((e) => e._id));
                                        }}
                                        placeholder="Responsible Person"
                                        className={`${errors.meeting_attendees ? "Validation" : ""
                                            } w-100`}
                                    />
                                );
                            }}
                        />
                    </div>


                    <div className="col-12 mt-3">
                        <label>Meeting Guests</label>
                        <Controller
                            name="meeting_guest"

                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <div className={errors.meeting_guest ? "Validation" : ""}>
                                        <TagsInput
                                            className={errors.meeting_guest ? "Validation" : ""}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e)
                                            }}
                                            placeHolder={`guest ${value.length + 1}`}
                                        />
                                    </div>
                                );
                            }}
                        />
                    </div>




                    <div className="btn_main mt-4 d-flex align-items-center justify-content-end gap-3">
                        <Link to={"/projectManagement/meetings"}>
                            <button className="btn-Secondary  px-2 py-2">Cancel</button>
                        </Link>
                        <PrimaryButton
                            loader={btnLoader}
                            className={"py-1"}
                            type="submit"
                            text={id ? "Update" : "Create"}
                        />
                    </div>


                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            </form>

        </div>
    )
}

import React, { useEffect, useRef, useState } from "react";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { Statuscomp } from "../../Components/Common/StatusComp";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Filter from "./Filter";
import { useNavigate } from "react-router-dom";
import { filterSpecification, getSpecificationDataByProjectId, specificatonDelete, updateSpecification } from "../../Api/Admin/AllAPIs";
import { CSVLink } from "react-csv";
import Popover from "../../Components/Common/Popover";
import { FaCheck, FaRegClock } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DeleteDialog from "../../Components/Common/DeleteDialog";
import { changeStatusUi2, getActionsPermission, getProjectCode, getProjectName, getUserId, searchDataWithKeys, showDateAndTime, toast } from "../../utils";
import { ExportPdfButton } from "../../Components/Buttons/ExportPdfButton";
import ChangeStatusModal from "../../Components/ChangeStatusModal/ChangeStatusModal";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import { DownloadPdfAndSendEmail } from "../../Components/Common/ViewPageComponents/DownloadPdfAndSendEmail";
import { HtmlIntoString, HtmlIntoText } from "../../Components/Common/HtmlIntoText";
import Pagination from "../../Components/Common/Pagination/Pagination";
import moment from "moment";
import { ChangeApprovalStatus } from "../../Components/Tools/ChangeApprovalStatus";
import { CDC_LOGO_URL } from "../../constants";

export const Specifications = () => {
  const [allRes, setAllRes] = useState([]);
  const [loader, setLoader] = useState(true);
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [originalRes, setOriginalRes] = useState([]);
  const [search, setSearch] = useState("");
  const [clearBtn, setClearBtn] = useState(true);
  const projectId = localStorage.getItem('projectId')
  const userData = localStorage.getItem("userData");
  const userObj = JSON.parse(userData);
  const userId = userObj._id;
  const userName = userObj.name;
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [specificationDelete, setSpecificationDelete] = useState(null)
  const [open, setOpen] = useState(null);
  const [dropdownvalue, setDropDownValue] = useState(null);
  const [comment, setComment] = useState("");
  const [type, setType] = useState("");
  const [itemId, setItemId] = useState("");
  const [statusRequired, setStatusRequired] = useState(false);
  const [filteredData1, setFilteredData1] = useState([]);
  const [specificationData, setSpecificationData] = useState({});
  const [page, setPage] = useState(1)
  const perPageData = 10;
  const [totalCount, setTotalCount] = useState(0);
  const [tempFilterData, setTempFilterData] = useState({})
  const [filterActive, setFilterActive] = useState(false)
  const [allData, setAllData] = useState([])
  const [filteredData, setFilteredData] = useState()

  const [filterData, setFilterData] = useState({
    workflow_status: null,
    responsible_person: []

  })


  async function changeStatus(e) {
    e.preventDefault();

    if (dropdownvalue == null) {
      setStatusRequired(true);
      return;
    }

    setOpen(false);
    setLoader(true);

    try {
      if (type == "reviewer") {
        if (dropdownvalue.code == "approved") {
          let payload = {
            specification_review_status: "approved",
            reviewer_comment: comment,
            specification_approval_status: "under_review",
            status: 'close'
          };

          await updateSpecification(itemId, payload);
        } else {
          let payload = {
            specification_review_status: "unapproved",
            reviewer_comment: comment,
            status: "reopen",
            specification_approval_status: "pending",
          };
          await updateSpecification(itemId, payload);
        }
      } else if (type == "approver") {
        if (dropdownvalue.code == "approved") {
          let payload = {
            specification_approval_status: "approved",
            approver_comment: comment,
            specification_review_status: "approved",
          };
          await updateSpecification(itemId, payload);
        } else {
          let payload = {
            specification_review_status: "under_review",
            specification_approval_status: "unapproved",
            approver_comment: comment,
          };
          await updateSpecification(itemId, payload);
        }
      } else {
        console.log("nonooo");
      }
    } catch (err) {
      console.log(err);
    } finally {
      await getSpecifications();
      setItemId("");
      setType("");
      setLoader(false);
      setDropDownValue(null);
    }
  }
  function changeToCap(str) {
    return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`
  }

  const deletespecifi = async (id) => {
    setVisibleDelete(true)
    setSpecificationDelete(id);
  };

  const confirmDelete = async () => {
    setLoader(true);
    let payload = {
      created_by: userName,
    }
    try {
      await specificatonDelete(specificationDelete, payload);
      toast.success("Specification Deleted Successfully!");
      getSpecifications();
      setLoader(false);
    } catch (err) {
      console.error(err);
    }
  };
  const handleSearchChange = (e) => {
    const searchTerm = e?.target?.value;
    console.log(searchTerm)
    setSearch(searchTerm);
    const filtered = specificationData?.filter(item =>
      item?.set[0]?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
    console.log(filtered)
    setFilteredData(filtered);
  };
  const handleCancel = () => {
    setSearch('');
    setFilteredData(specificationData); // Reset to original data
  };
  const getSpecifications = async () => {
    try {
      setSkeletonLoader(true);
      let params = {
        page,
        perPageData
      }
      let body = { ...tempFilterData }
      const response = await getSpecificationDataByProjectId(projectId, params, body);
      console.log(response?.data?.data)
      setSpecificationData(response?.data?.data || [])
      setFilteredData(response?.data?.data || [])
      setTotalCount(response?.data?.totalCount || 0)
      setOriginalRes(response?.data?.data || [])

    } catch (error) {
      console.log(error);
    }
    finally {
      setSkeletonLoader(false);
    }
  };

  useEffect(() => {
    if (projectId) {
      getSpecifications();
    }
  }, [page, tempFilterData]);


  console.log(allRes);
  const navigate = useNavigate();



  const formatHourSpent = (hours) => {

    console.log(hours, "check")
    if (!hours || hours === "--") return "N/A";


    const parts = hours.split('-').map(Number);


    if (parts.some(isNaN)) return "Invalid";

    const [min, max] = parts;


    if (parts.length === 1) {
      if (min < 1) {
        return `<1 hour`;
      } else {
        return `${min} hours`;
      }
    } else if (max) {
      if (max >= 10) {
        return `${min} hours ${max} min`;
      } else {
        return `${min} hours ${max} min`;
      }
    } else {
      return `${min} hours`;
    }
  }



  const csvData = originalRes?.map(item => ({
    "Set Name": item?.set[0]?.name,
    "Format": item?.format[0]?.name,
    "Responsible Contractor Company(s)": item?.responsible_contractor_company?.map(res => res?.company_name)?.join(', '),
    "Responsible Member(s)": item?.responsible_member?.map(res => res?.first_name)?.join(', '),
    "Spec Manager": item?.spec_manager?.map(res => res?.first_name)?.join(', '),
    "Spec Language": item?.specificationLanguage?.map(res => res?.name)?.join(', '),
    "Spec ID": item.spec_id,
    "Spec Version": item.defaultRevision,
    "Spec Issue Date": showDateAndTime(item.issuedDate),
    "Spec Received Date": showDateAndTime(item.receivedDate),
    "Hours Spent": formatHourSpent(item?.hours_spent),
    "Responsible Person(s)": item?.responsible_person?.map(res => res?.first_name)?.join(', '),
    "Workflow Status": item?.workflow_status,
    "Approval Status": item.status,
    "Reviewer": item?.reviewer?.first_name,
    "Approver": item?.approver?.first_name,
    "Description": HtmlIntoString({ html: item.description }),
    "Deleted": item.is_deleted ? 'Yes' : 'No',
    "Created At": new Date(item.created_at).toLocaleString(),
  }));


  const csvHeaders = [
    { label: 'Set Name', key: 'Set Name' },
    { label: 'Format', key: 'Format' },
    { label: 'Responsible Contractor Company(s)', key: 'Responsible Contractor Company(s)' },
    { label: 'Responsible Member(s)', key: 'Responsible Member(s)' },
    { label: 'Spec Manager', key: 'Spec Manager' },
    { label: 'Spec Language', key: 'Spec Language' },
    { label: 'Spec ID', key: 'Spec ID' },
    { label: 'Spec Version', key: 'Spec Version' },
    { label: 'Spec Issue Date', key: 'Spec Issue Date' },
    { label: 'Spec Received Date', key: 'Spec Received Date' },
    { label: 'Hours Spent', key: 'Hours Spent' },
    { label: 'Responsible Person(s)', key: 'Responsible Person(s)' },
    { label: 'Workflow Status', key: 'Workflow Status' },
    { label: 'Approval Status', key: 'Approval Status' },
    { label: 'Reviewer', key: 'Reviewer' },
    { label: 'Approver', key: 'Approver' },
    { label: 'Description', key: 'Description' },
    { label: 'Deleted', key: 'Deleted' },
    { label: 'Created At', key: 'Created At' },
  ];

  const tableRef = useRef();

  const exportToPDF = () => {

    const doc = new jsPDF();



    const imgData = CDC_LOGO_URL
    const imgW = 20;
    const imgH = 10;
    const imageX = 5;
    const imageY = 5;
    doc.addImage(imgData, 'JPEG', imageX, imageY, imgW, imgH);
    const projectName = `${getProjectName()} (Project Id: ${getProjectCode() || "-"})`;
    const textX = imageX + imgW + 6;
    const textY = imageY + 7.5;
    // Add project name text to the PDF
    doc.setFontSize(16);
    doc.text(projectName, textX, textY);



    doc.autoTable({
      html: tableRef.current,
      startY: 30,
      didParseCell: function (data) {
        data.cell.styles.fontSize = 2;
      },
      didDrawPage: function (data) {

      },
    });

    // Save the PDF
    doc.save('Specification.pdf');
  };


  function isAbletoAccess(rowId) {
    return getUserId() == rowId
  }


  function onFilter() {
    console.log(filterData)
    setTempFilterData(filterData)
    setPage(1)
    setFilterActive(true)
  }
  function clearFilter() {
    setFilterActive(false)
    setFilterData({
      workflow_status: null,
      responsible_person: null,

    })
    setTempFilterData({
      workflow_status: null,
      responsible_person: null,
    })
  }

  async function statusChange(data, id, toolId, comment) {
    try {
      let res = await updateSpecification(id, { ...data, spec_id: toolId, ...comment });
      getSpecifications();

    } catch (error) {
      console.log(error)
    }
  }



  return (
    <>
      <ChangeStatusModal
        open={open}
        setOpen={setOpen}
        value={dropdownvalue}
        setValue={setDropDownValue}
        onSubmit={changeStatus}
        setComment={setComment}
        statusRequired={statusRequired}
        setStatusRequired={setStatusRequired}
      />


      <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center">
        <h1 className="page_heading">Specification</h1>
        <div className="d-flex text-nowrap justify-content-end gap-2 mt-sm-0 mt-2">
          <div className="right d-flex gap-2 align-items-center">
            <ExportPdfButton exportToPDF={exportToPDF}
              csvBtn={<CSVLink
                className="pe-1"
                data={csvData}
                headers={csvHeaders}
                filename={"specification.csv"}
              >
                CSV
              </CSVLink>
              }
            />
          </div>
          <PrimaryButton
            className={`primararyButton`}
            addIcon
            text={"Create"}
            onClick={() => navigate("create")}
          />
        </div>
      </div>


      <div style={{ height: '0', overflow: 'hidden' }}>
        <table ref={tableRef}>
          <thead>
            <tr>
              <th>Set Name</th>
              <th>Format</th>
              <th>Responsible Contractor Company (s)</th>
              <th>Responsible Member (s)</th>
              <th>Spec Manager</th>
              <th>Spec Language</th>
              <th>Spec ID</th>
              <th>Spec version</th>
              <th>Spec Issue Date</th>
              <th>Spec Received Date</th>
              <th>Hours Spent</th>
              <th>Responsible Person (s)</th>
              <th>Workflow Status</th>
              <th>Approval Status</th>
              <th>Reviewer</th>
              <th>Approver</th>
              <th>Description</th>


            </tr>
          </thead>
          <tbody>
            {originalRes?.map((item, index) => {
              return (
                <tr key={index}>

                  <td>{item?.set[0]?.name}</td>
                  <td>{item?.format[0]?.name}</td>
                  <td>{item?.responsible_contractor_company?.map(res => res?.company_name)?.join(', ')}</td>
                  <td>{item?.responsible_member?.map(res => res?.first_name)?.join(', ')}</td>
                  <td>{item?.spec_manager?.map(res => res?.first_name)?.join(', ')}</td>
                  <td>{item?.specificationLanguage?.map(res => res?.name)}</td>
                  <td>{item.spec_id}</td>
                  <td>{item.defaultRevision}</td>
                  <td> {moment(item?.dueDate).format("DD-MM-YYYY")}</td>
                  <td> {moment(item?.receivedDate).format("DD-MM-YYYY")}</td>

                  <td>{formatHourSpent(item?.hours_spent)}</td>
                  <td>{item?.responsible_person?.map(res => res?.first_name)?.join(', ')}</td>
                  <td>{item?.workflow_status}</td>
                  <td>{item.status}</td>
                  <td>{item?.approver?.first_name}</td>
                  <td>{item?.reviewer?.first_name}</td>
                  <td>{<HtmlIntoText html={item.description} />}</td>
                  <td>{item.is_deleted ? 'Yes' : 'No'}</td>
                  <td>{item.created_at}</td>

                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center">

        <div className="searchInput">
          <SearchInput
            value={search}
            onChange={handleSearchChange}
            onCancel={handleCancel}


          />
        </div>


        <div className="d-flex text-nowrap gap-2 mt-sm-0 mt-2">
          <div className="w-100">

            <Filter
              filterData={filterData}
              setFilterData={setFilterData}
              onFilter={onFilter}
            />
          </div>

          &nbsp;
          <button
            className="filter_btn w-100"
            onClick={clearFilter}
            disabled={!filterActive}
          >
            Clear Filter
          </button>
        </div>

      </div>

      <br />
      <div>
        <div style={{ width: '100%', overflow: 'auto' }}>
          <div style={{ width: '2400px' }}>
            {
              skeletonLoader ?
                <TableSkelton
                  header={[
                    "Spec ID",
                    "Workflow Status",
                    "Set Name.",
                    "Resposible contractor Company/Name",
                    "Spec Manager",
                    "Responsible Person",
                    "Spec Recived Date",
                    "Approval Status",
                    "",
                  ]}
                />
                :
                <BorderedTable>
                  <DataTable
                    value={filteredData}
                  >
                    <Column sortable field="spec_id" header="Spec ID"></Column>
                    <Column
                      sortable
                      field="workflow_status"
                      header="Workflow Status"
                      style={{ width: "5rem" }}
                      body={(rowData) => changeStatusUi2(rowData?.workflow_status)}
                    ></Column>

                    <Column field="set" header="Set Name"

                      sortable
                      body={(rowData) => {

                        return (
                          <div >
                            {rowData?.set[0]?.name}
                          </div>
                        )
                      }}
                    >

                    </Column>
                    <Column

                      headerClassName="text-nowrap"
                      body={(rowData) => {
                        let data = rowData?.responsible_contractor_company?.map((res) => {
                          let pp = rowData?.responsible_member?.filter(
                            (item) => item?.company == res?._id
                          );
                          console.log(pp)
                          return {
                            company: res?.company_name,
                            persons:
                              pp?.length == 0
                                ? "No Person Added"
                                : pp?.map((ress) => ress?.first_name)?.join(", "),
                          };
                        });
                        // let data = rowData?.responsibleContractor?.map(res => res?.company_name)?.join(', ')
                        return (
                          <div className="d-flex flex-column">
                            {data?.map((res, index) => (
                              <div key={index}>
                                {res?.company} - {res?.persons}
                              </div>
                            ))}
                          </div>
                        );
                      }}

                      header="Responsible Contractor Company / Name"></Column>
                    {/* <Column
                     sortable
                    field="responsible_contractor_company"
                      header="Responsible Contractor Company / Name"
                      body={(rowData) => {
                        let data = rowData?.responsible_contractor_company?.map((res) => {
                          let pp = rowData?.responsible_member?.filter((res) => res?._id
                          );
                          console.log(pp)
                          return {
                            company: res?.company_name,
                            persons:
                              pp.length == 0
                                ? "No Person Added"
                                : pp?.map((ress) => ress?.first_name)?.join(", "),
                          };
                        });
                        // let data = rowData?.responsibleContractor?.map(res => res?.company_name)?.join(', ')
                        return (
                          <div className="d-flex flex-column">
                            {data?.map((res, index) => (
                              <div key={index}>
                                {res?.company} - {res?.persons}
                              </div>
                            ))}
                          </div>
                        );
                      }}
                    /> */}
                    <Column
                      field="spec_manager"
                      sortable
                      header="Spec Manager"
                      body={(rowData) => {
                        let data = rowData?.spec_manager?.map(res => res?.first_name)?.join(', ')
                        return (
                          <div title={data}>
                            {data?.slice(0, 15)}{data?.length > 15 ? '...' : ''}
                          </div>
                        )
                      }}
                    />
                    <Column
                      sortable
                      field="responsible_person"
                      header="Responsible Person"
                      body={(rowData) => {
                        let data = rowData?.responsible_person?.map(res => res?.first_name)?.join(', ')
                        return (
                          <div title={data}>
                            {data?.slice(0, 15)}{data?.length > 15 ? '...' : ''}
                          </div>
                        )
                      }}
                    ></Column>
                    <Column
                      field="receivedDate"
                      sortable
                      body={(rowData) => moment(rowData?.receivedDate).format('DD-MM-YYYY')}
                      header="Spec Recived Date"
                    ></Column>
                    {/* <Column
                      field="status"
                      header="Approval status"
                      style={{ width: "5rem" }}
                      body={(rowData) => (
                        <div className="d-flex justify-content-center">
                          {rowData?.status?.toLowerCase() === "open" ? (
                            <Statuscomp className={"open_status"} />
                          ) : rowData?.status?.toLowerCase() ===
                            "close" ? (
                            <Statuscomp className={"close_status"} />
                          ) : rowData?.status?.toLowerCase() ===
                            "reopen" ? (
                            <Statuscomp className={"reopen_status"} />
                          ) : rowData?.status?.toLowerCase() ===
                            "onhold" ? (
                            <Statuscomp className={"on_hold"} />
                          ) : null}
                        </div>
                      )}
                    ></Column> */}


                    {/* <Column
                      field="approve"
                      header="Approval Status"
                      body={(rowData, meta) => {


                        if (rowData?.status === "close") {
                          if (rowData?.specification_review_status === "under_review" || rowData?.specification_review_status === "unapproved") {
                            return (
                              <>
                                <div
                                  className={`d-flex justify-content-center align-items-center`}
                                >
                                  {rowData.specification_review_status === "approved" && (
                                    <FaCheck className={`body_green ${rowData?.specification_approval_status !== 'approved' ? 'pointer' : ''}`}
                                    // onClick={rowData?.specification_approval_status !== 'approved' ? () => {
                                    //   isAbletoAccess(rowData?.reviewer_id) && setOpen(true);
                                    //   setType("reviewer");
                                    //   setItemId(rowData._id);
                                    //   setDropDownValue({ name: changeToCap(rowData.specification_review_status), code: rowData.specification_review_status })
                                    // } : () => { }}
                                    />
                                  )}
                                  {rowData.specification_review_status === "unapproved" && (
                                    <ImCross className="body_red pointer" onClick={() => {
                                      isAbletoAccess(rowData?.reviewer_id) && setOpen(true);
                                      setType("reviewer");
                                      setItemId(rowData._id);
                                      setDropDownValue({ name: changeToCap(rowData.specification_review_status), code: rowData.specification_review_status })
                                    }} />
                                  )}
                                  {rowData.specification_review_status === "pending" && "--"}
                                  {rowData.specification_review_status === "under_review" && (
                                    <FaRegClock className="body_blue pointer" onClick={() => {
                                      isAbletoAccess(rowData?.reviewer_id) && setOpen(true);
                                      setType("reviewer");
                                      setItemId(rowData._id);
                                    }} />
                                  )}
                                </div>
                              </>
                            )
                          }
                          else if (rowData?.specification_approval_status === "under_review") {
                            return (
                              <>
                                <div
                                  className={`d-flex justify-content-center align-items-center`}
                                >
                                  {rowData.specification_approval_status === "approved" && (
                                    <FaCheck className="body_green pointer"
                                    // onClick={() => {
                                    //   isAbletoAccess(rowData?.approver_id) && setOpen(true);
                                    //   setType("approver");
                                    //   setItemId(rowData._id);
                                    //   setDropDownValue({ name: changeToCap(rowData.specification_approval_status), code: rowData.specification_approval_status })
                                    // }}
                                    />
                                  )}
                                  {rowData.specification_approval_status === "unapproved" && (
                                    <ImCross className="body_red pointer" onClick={() => {
                                      isAbletoAccess(rowData?.approver_id) && setOpen(true);
                                      setType("approver");
                                      setItemId(rowData._id);
                                      setDropDownValue({ name: changeToCap(rowData.specification_approval_status), code: rowData.specification_approval_status })
                                    }} />
                                  )}
                                  {rowData.specification_approval_status === "pending" && "--"}
                                  {rowData.specification_approval_status === "under_review" && (
                                    <FaRegClock className="body_blue pointer" onClick={() => {
                                      isAbletoAccess(rowData?.approver_id) && setOpen(true);
                                      setType("approver");
                                      setItemId(rowData._id);
                                    }} />
                                  )}
                                </div>
                              </>
                            )
                          }
                          else if (rowData?.specification_approval_status === "approved" && rowData?.specification_review_status === "approved") {
                            return (
                              <>
                                <FaCheck className="body_green pointer" />
                              </>
                            )
                          }

                        }
                        else {
                          return "---"
                        }

                      }}
                      style={{ width: "5rem" }}
                    ></Column> */}
                    <Column
                      field="status"
                      sortable
                      headerClassName="text-nowrap"
                      body={(rowData) => {

                        return (<ChangeApprovalStatus
                          status={rowData.status}
                          onSubmit={statusChange}
                          toolData={{
                            tool_id: rowData?._id,
                            tool_number: rowData?.spec_id,
                            tool_name: rowData?.set[0]?.name,
                            tool: "Specification",
                            _id: rowData?._id,
                            approver_id: rowData?.approver_id,
                            reviewer_id: rowData?.reviewer_id,
                            reviewer_name: `${rowData?.reviewer?.first_name} ${rowData?.reviewer?.last_name}`,
                            approver_name: `${rowData?.approver?.first_name} ${rowData?.approver?.last_name}`,
                            reviewer_email: `${rowData?.reviewer?.email_address}`,
                            approver_email: `${rowData?.approver?.email_address}`,
                            creator_name: rowData?.creator_email?.[0]?.first_name || rowData?.creator_email?.[0]?.name,
                            creator_email: rowData?.creator_email?.[0]?.email_address || rowData?.creator_email?.[0]?.email
                          }}
                          rowData={rowData}

                        />
                        )

                      }}
                      header="Approval Status"></Column>
                    <Column
                      header="Action"
                      style={{ width: "6rem" }}
                      body={(rowData) => {
                        return (
                          <div className="flex align_center">
                            <Popover
                              options={["Version History", "Edit", "Delete", "View",


                                <DownloadPdfAndSendEmail

                                  heading={"specification"}
                                  fileName={"Specification"}
                                  id={rowData._id}
                                  files={rowData?.attachFile?.map(e => e?.fileUrl).filter(e => e)}
                                >
                                  <ViewPagesPdfTemplate
                                    header={`#SPECIFICATION ${rowData?.set?.map(res => res?.name)}`}
                                    // header={`${rowData?.set?.map(res => res?.name)}`}
                                    description={{
                                      key: "Specification Description",
                                      value: <HtmlIntoText html={rowData?.description} />
                                    }}
                                    tableData1={[
                                      { "Set Name": rowData?.set?.[0]?.name || "--" },
                                      { "Responsible Contractor Company": rowData?.responsible_contractor_company?.map(e => e?.company_name).join(", ") || "--" },
                                      { "Spec Manager": rowData?.spec_manager?.map(e => e?.first_name).join(", ") || "--" },
                                      { "Spec ID": rowData?.spec_id || "--" },
                                      {
                                        "Hours Spent": rowData?.hours_spent
                                          ? `${rowData?.hours_spent?.split("-")[0]} hour ${rowData?.hours_spent?.split("-")[1]
                                          } Min`
                                          : "--",
                                      },
                                      // { "Hours Spent": rowData?.hourSpent || "--" },
                                      { "Approval Status": rowData?.status || "--" },
                                      { "Work Flow Status": rowData?.workflow_status || "--" },
                                      { "Spec Received Date": moment(rowData?.receivedDate).format("DD/MM/YYYY") || "--" },

                                      { "Reviewer": rowData?.reviewer?.[0]?.first_name || "--" },
                                      { "Approver Comment": rowData?.approver_comment || "--" },
                                    ]}


                                    tableData2={[
                                      { "Format": rowData?.format?.[0]?.name || "--" },
                                      { "Responsible Member": rowData?.responsible_member?.map(e => e?.first_name).join(", ") || "--" },
                                      { "Spec Language": rowData?.specificationLanguage?.[0]?.name || "--" },
                                      { "Spec version": rowData?.defaultRevision || "--" },
                                      { "Responsible Person": rowData?.responsible_person?.map(e => e?.first_name).join(", ") || "--" },

                                      { "Approver": rowData?.approver?.[0]?.first_name || "--" },
                                      { "Reviewer Comment": rowData?.reviewer_comment || "--" }
                                    ]}
                                  />
                                </DownloadPdfAndSendEmail>,
                                "Send Via Post"

                              ]}
                              onSelect={(index) => {
                                if (index === 0) {
                                  if (!getActionsPermission("Specifications version history")) {
                                    toast.error("You don't have permission of Specifications version history")
                                    return

                                  }
                                  navigate(`/projectManagement/version-history/specification/${rowData?.set}/${rowData?._id}`);
                                }
                                else if (index === 1) {

                                  if (!getActionsPermission("Edit Specifications")) {
                                    toast.error("You don't have permission of Edit specification")
                                    return

                                  }
                                  navigate(`edit/${rowData?._id}`);


                                }
                                // else if (index === 2) {

                                // }
                                else if (index === 2) {
                                  if (!getActionsPermission("Delete Specifications")) {
                                    toast.error("You don't have permission of Delete specification")
                                    return

                                  }
                                  deletespecifi(rowData?._id)
                                }
                                else if (index === 3) {
                                  if (!getActionsPermission("view Specifications")) {
                                    toast.error("You don't have permission of View specification")
                                    return

                                  }
                                  navigate(`view/specification/${rowData?.set}/${rowData?._id}`)
                                }
                                else if (index === 5) {

                                  navigate(`/projectManagement/addressListing/specification/${rowData?.set[0]?.name}/${rowData?._id}`)
                                }
                              }}
                            />
                            {/* <EditButton
                              onClick={() =>
                                navigate(`/specifications/edit/${rowData?._id}`)
                              }
                            />
                            &nbsp;&nbsp;
                            <ViewButton
                              onClick={() => {
                                setPdfUrl(
                                  rowData.attachFile.map((res) => res?.fileUrl)
                                );
                                // setVisible(true);
                              }}
                            />
                            <ViewPdf
                              setVisible={setVisible}
                              visible={visible}
                              // pdfUrls={rowData.attachFile.map(
                              //   (res) => res?.fileUrl
                              // )}
                              pdfUrls={pdfUrl}
                              title={"Specifications File"}
                            ></ViewPdf> */}
                          </div>
                        );
                      }}
                    ></Column>
                  </DataTable>
                </BorderedTable>}
          </div>
        </div>
        <br />
        <Pagination
          currentPage={page}
          totalCount={totalCount}
          pageSize={perPageData}
          onPageChange={setPage}
        />

      </div>




      <DeleteDialog
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        onCancel={() => setSpecificationDelete(null)}
        onConfirm={confirmDelete}
        header={"Specification"}
      />

    </>
  );
};

import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Sidebar } from "../Sidebar/Sidebar"
import { getUserData } from "../../utils";

export const LogingKeeper = ({ role, layer }) => {
  const login = JSON.parse(localStorage.getItem('isLoggedIn'))
  const data = getUserData();

  console.log("okookokokok")

  return (login && data) ? <>
    {/* <Sidebar layer={layer}> */}
    <Outlet></Outlet>
    {/* </Sidebar> */}
  </>
    :
    <Navigate to='/' />
};



import React, { useEffect, useRef, useState } from "react";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import {
  deleteusertask,
  getalldataby_id,
  updatetasktable,
} from "../../Api/Admin/AllAPIs";
import { changeStatusUi2, formatDateInIST, getActionsPermission, getProjectCode, getProjectId, getProjectName, toast } from "../../utils";
import Popover from "../../Components/Common/Popover";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import Pagination from "../../Components/Common/Pagination/Pagination";
import moment from "moment";
import DeleteDialog from "../../Components/Common/DeleteDialog";
import { DownloadPdfAndSendEmail } from "../../Components/Common/ViewPageComponents/DownloadPdfAndSendEmail";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import { ExportPdfButton } from "../../Components/Buttons/ExportPdfButton";
import { CSVLink } from "react-csv";
import TaskFilter from "./Taskfilter";
import jsPDF from "jspdf";
import { ChangeApprovalStatus } from "../../Components/Tools/ChangeApprovalStatus";
import { CDC_LOGO_URL } from "../../constants";
import { useSelector } from "react-redux";

function Tasktable() {

  const [loader, setLoader] = useState(true);
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState({})
  let d = useSelector(e => e.rolesAndPermissionData)
  const [taskData, setTaskData] = useState([])
  const [tableSortData, setTableSortData] = useState({
    page: 1,
    search: ""
  })
  const domain = window.location.origin;
  const [taskId, setTaskId] = useState("")
  const [visibleDelete, setVisibleDelete] = useState(false)
  const projectId = getProjectId()
  const userData = localStorage.getItem("userData");
  const userObj = JSON.parse(userData);
  const userId = userObj._id;
  const userName = userObj.name;
  const perPageData = 10;
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchTaskData();

  }, [tableSortData]);

  const fetchTaskData = async () => {
    try {
      let params = {
        page: tableSortData.page,
        perPageData,
        search: tableSortData.search
      }

      let body = {
        responsibleContractor: tableSortData?.responsibleContractor,
        responsiblePerson: tableSortData?.responsiblePerson
      }

      setSkeletonLoader(true);
      const res = await getalldataby_id(projectId, params, body);
      console.log("all data", res);
      setTotalCount(res?.data?.totalCount || 1)
      setTaskData(res?.data?.data || [])


    } catch (err) {
      console.log(err);
      toast.info("Please Try again data.");
    }
    finally {
      setSkeletonLoader(false);
    }
  };

  const confirmDelete = async () => {
    setLoader(true);
    let payload = {
      created_by: userName,
    }
    try {

      await deleteusertask(taskId, payload);
      fetchTaskData();
      toast.success("Task deleted successfully!");

      // setLoader(false)
    } catch (err) {
      console.error(err);
    }
  };
  let tableRef = useRef(null)

  const exportToPDF = () => {
    const doc = new jsPDF();

    const imgData = CDC_LOGO_URL
    const imgW = 20;
    const imgH = 10;
    const imageX = 5;
    const imageY = 5;
    doc.addImage(imgData, 'JPEG', imageX, imageY, imgW, imgH);
    const projectName = `${getProjectName()} (Project Id: ${getProjectCode() || "-"})`;
    const textX = imageX + imgW + 6;
    const textY = imageY + 7.5;
    // Add project name text to the PDF
    doc.setFontSize(16);
    doc.text(projectName, textX, textY);

    doc.autoTable({
      html: tableRef.current,
      startY: 30,
      didParseCell: function (data) {
        data.cell.styles.fontSize = 2;
      },
      didDrawPage: function (data) {

      },
    });

    // Save the PDF
    doc.save('Task.pdf');
  };

  const csvHeaders = [

    { label: 'Task ID', key: 'Task ID' },
    { label: 'Task Name', key: 'Task Name' },
    { label: 'WF Status', key: 'WF Status' },

    { label: 'Responsible Company Name', key: 'Responsible Company Name' },
    { label: 'Task Manager', key: 'Task Manager' },
    { label: 'Responsible Person', key: 'Responsible Person' },


    { label: 'Due Date', key: 'Due Date' },
    { label: 'Hours Spent', key: 'Hours Spent' },
    { label: 'Responsible Member', key: 'Responsible Member' },

  ];

  const csvData = taskData.map(item => ({
    "Task ID": item?.taskId,
    "WF Status": item?.workFlowStatus,
    "Task Name": item?.task_name,

    "Responsible Company Name": item?.responsibleContractor?.map(e => e.company_name).join(", "),
    "Task Manager": item?.taskManager?.map(e => e.first_name).join(", "),
    "Responsible Person": item?.responsiblePerson?.map(e => e.first_name).join(", "),

    "Due Date": moment(item?.dueDate).format("DD-MM-YYYY"),
    "Hours Spent": item?.hourSpent,

    "Responsible Member": item?.responsibleMembers.map(e => e.first_name),

  }));

  function filterData() {
    setTableSortData(pre => ({ ...pre, ...filteredData }));
  };

  async function statusChange(data, _id, toolId, comment, toolData) {
    let emailData = formatEmailData(toolData)
    console.log(emailData)
    try {
      if (data.status === "approved") {
        // send email to all the assigies 
      }

      let res = await updatetasktable(_id, { ...data, ...comment })
      fetchTaskData()
    } catch (error) {

    }
  }


  function formatEmailData(data) {
    let email_data = []

    // add email data of responsibl members selected 
    data?.responsibleMembers?.forEach((currentUser) => {
      email_data.push({
        name: currentUser?.first_name,
        to: currentUser?.email_address,
        // tool_number: body?.taskId,
        tool: "Task",
        // tool_name: body.task_name,
        project_name: getProjectName(),
        project_id: getProjectCode(),
        template_type: "emailToAssociatedUser",
        subject: "",
        userType: "Responsible Member", // new key
        url: `${domain}/coretools/tasktable`,
        // created_by: getUserName()
      })
    })

    // add email data of responsible Person selected                                                                         
    data?.responsiblePerson?.forEach((currentUser) => {
      email_data.push({
        name: currentUser?.first_name,
        to: currentUser?.email_address,
        // tool_number: body?.taskId,
        tool: "Task",
        // tool_name: body.task_name,
        project_name: getProjectName(),
        project_id: getProjectCode(),
        subject: "",
        template_type: "emailToAssociatedUser",
        userType: "Responsible Contractor",
        url: `${domain}/coretools/tasktable`,
        // created_by: getUserName()
      })
    })

    // add email data of Taskmanager selected     
    data?.taskManager?.forEach((currentUser) => {

      email_data.push({
        name: currentUser?.first_name,
        to: currentUser?.email_address,
        // tool_number: body?.taskId,
        tool: "Task",
        subject: "",
        // tool_name: body.task_name,
        project_name: getProjectName(),
        project_id: getProjectCode(),
        template_type: "emailToAssociatedUser",
        userType: "Task Manager", // new key,,
        url: `${domain}/coretools/tasktable`,
        // created_by: getUserName()
      })

    })
    return email_data


  }

  return (
    <>
      <div >
        <div className="parent">

          <div className="header d-flex flex-column">
            <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center">
              <div className="page_heading"> Project Tasks </div>
              <div className="d-flex text-nowrap justify-content-end gap-2 mt-sm-0 mt-2">
                <div className="right d-flex gap-2 align-items-center">
                  <ExportPdfButton exportToPDF={exportToPDF}
                    csvBtn={<CSVLink
                      className="pe-1"
                      data={csvData}
                      headers={csvHeaders}
                      filename={"Task.csv"}
                    >
                      CSV
                    </CSVLink>
                    }
                  />
                </div>
                {getActionsPermission("Create Task") && <PrimaryButton
                  className={`primararyButton`}
                  addIcon
                  text={"Create"}
                  onClick={() => {
                    navigate("create")
                  }}
                />}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-3 searchInput">
                <SearchInput value={tableSortData.search} onCancel={() => setTableSortData(pre => ({ ...pre, page: 1, search: '' }))} onChange={(e) => {
                  setTableSortData(pre => ({
                    ...pre,
                    page: 1,
                    search: e.target.value
                  }))
                }} />
              </div>
              <div className="col-md-9 d-flex justify-content-end">

                <TaskFilter
                  filterData={filteredData}
                  setFilterData={setFilteredData}
                  onFilter={filterData}
                />

                <button className={` ${Object.keys(filteredData).length === 0 && 'disabled'} filter_btn ms-2`} onClick={() => {
                  setTableSortData({ page: 1, search: "" })
                  setFilteredData({})
                }
                }
                  disabled={Object.keys(filteredData).length === 0}
                >Clear Filter</button>
              </div>
            </div>
          </div>


          <div className="logTable mt-3 w-100 d-flex flex-column">

            <div className="tools_table_scroll">
              {
                skeletonLoader ?
                  <TableSkelton
                    header={[
                      "Task ID",
                      "WF Status",
                      "Task Name",
                      "Responsible Contractor Company / Name",
                      "Task Manager",
                      "Responsible Person",
                      "Responsible Member",
                      "Due Date",
                      "Approval Status",
                      "Action",
                    ]}
                  />
                  :

                  <DataTable
                    value={taskData}

                  >
                    <Column sortable field="taskId" headerClassName="text-nowrap" header="Task ID"></Column>
                    <Column
                      field="workFlowStatus"
                      sortable
                      body={(rowData) => changeStatusUi2(rowData?.workFlowStatus)}
                      header="WF Status"></Column>
                    <Column sortable headerClassName="text-nowrap" field="task_name" header="Task Name"></Column>
                    <Column

                      headerClassName="text-nowrap"
                      body={(rowData) => {
                        let data = rowData?.responsibleContractor?.map((res) => {
                          let pp = rowData?.responsibleMembers?.filter(
                            (item) => item?.company == res?._id
                          );
                          return {
                            company: res?.company_name,
                            persons:
                              pp?.length == 0
                                ? "No Person Added"
                                : pp?.map((ress) => ress?.first_name)?.join(", "),
                          };
                        });
                        // let data = rowData?.responsibleContractor?.map(res => res?.company_name)?.join(', ')
                        return (
                          <div className="d-flex flex-column">
                            {data?.map((res, index) => (
                              <div key={index}>
                                {res?.company} - {res?.persons}
                              </div>
                            ))}
                          </div>
                        );
                      }}

                      header="Responsible Contractor Company / Name"></Column>

                    <Column
                      headerClassName="text-nowrap"
                      body={(rowData) => rowData?.taskManager?.map(e => e.first_name).join(", ")}
                      header="Task Manager"></Column>
                    <Column

                      headerClassName="text-nowrap"
                      body={(rowData) => rowData?.responsiblePerson?.map(e => e.first_name).join(", ")}
                      header="Responsible Person"></Column>


                    <Column
                      sortable
                      field="dueDate"
                      body={(rowData) => formatDateInIST(rowData.dueDate)}
                      header="Due Date"></Column>
                    <Column
                      sortable
                      field="approver.first_name"
                      body={(rowData) => rowData?.approver?.first_name}
                      header="Approver"></Column>
                    <Column
                      sortable
                      field="reviewer.first_name"
                      body={(rowData) => rowData?.reviewer?.first_name}
                      header="Reviewer"></Column>

                    <Column
                      sortable
                      field="status"
                      headerClassName="text-nowrap"
                      body={(rowData) => {

                        return (<ChangeApprovalStatus
                          status={rowData.status}
                          allData={rowData}
                          onSubmit={statusChange}
                          toolData={{
                            tool_id: rowData?._id,
                            tool_number: rowData?.taskId,
                            tool_name: rowData?.task_name,
                            tool: "Task",
                            _id: rowData?._id,
                            approver_id: rowData?.approver_id,
                            reviewer_id: rowData?.reviewer_id,
                            reviewer_name: `${rowData?.reviewer?.first_name} ${rowData?.reviewer?.last_name}`,
                            approver_name: `${rowData?.approver?.first_name} ${rowData?.approver?.last_name}`,
                            reviewer_email: `${rowData?.reviewer?.email_address}`,
                            approver_email: `${rowData?.approver?.email_address}`,
                            creator_name: rowData?.creator_email?.[0]?.first_name || rowData?.creator_email?.[0]?.name,
                            creator_email: rowData?.creator_email?.[0]?.email_address || rowData?.creator_email?.[0]?.email
                          }}
                          toolName={"Task"}
                        />
                        )

                      }}
                      header="Approval Status"></Column>
                    <Column header="Action"
                      headerClassName="text-nowrap"
                      body={(rowData) => {
                        return (
                          <Popover
                            options={[
                              "Version History",
                              "Edit",
                              "View",
                              "Send Via Post",
                              <DownloadPdfAndSendEmail
                                heading={"task"}
                                id={rowData._id}
                                fileName={"Task"}
                                files={rowData?.attachments?.map(e => e.fileUrl)}
                              >
                                <ViewPagesPdfTemplate
                                  header={`#TASK ${rowData?.task_name}`}
                                  tableData1={[
                                    { "Task ID": rowData?.taskId },
                                    { "WF Status": rowData?.workFlowStatus },
                                    { "Task Name": rowData?.task_name },
                                    { "Responsible Company Name": rowData?.responsibleContractor?.map(e => e.company_name).join(", ") },
                                  ]}

                                  tableData2={[
                                    { "Due Date": rowData?.dueDate },
                                    { "Task Manager": rowData?.taskManager?.map(e => e.first_name).join(", ") },
                                    { "Responsible Person": rowData?.responsiblePerson?.map(e => e.first_name).join(", ") },
                                    { "Hours Spent": rowData?.hourSpent },
                                    { "Responsible Member": rowData?.responsibleMembers?.map(e => e?.first_name)?.join(", ") }
                                  ]}
                                />
                              </DownloadPdfAndSendEmail>,
                              "Delete",


                            ]}
                            onSelect={(index) => {
                              if (index === 0) {
                                if (!getActionsPermission("Task version history")) {
                                  toast.error("You don't have permission of task version history")
                                  return

                                }
                                navigate(
                                  `/projectManagement/version-history/TASK/${rowData?.task_name}/${rowData?._id}`
                                );
                              }
                              else if (index === 1) {
                                if (!getActionsPermission("Edit Task")) {
                                  toast.error("You don't have permission to edit task")
                                  return

                                }
                                navigate(`edit/${rowData._id}`);
                              }
                              else if (index === 2) {
                                if (!getActionsPermission("View Task")) {
                                  toast.error("You don't have permission to view task")
                                  return
                                }
                                navigate(`/coretools/tasktable/view/TASK/${rowData?.task_name}/${rowData?._id}`);
                              }

                              else if (index === 3) {

                                navigate(`/projectManagement/addressListing/task/${rowData?.task_name}/${rowData?._id}`)
                              }
                              else if (index === 5) {
                                if (!getActionsPermission("Delete Task")) {
                                  toast.error("You don't have permission to delete task")
                                  return
                                }
                                setTaskId(rowData?._id);
                                setVisibleDelete(true)
                              }
                            }}
                          />
                        )
                      }}

                    ></Column>
                  </DataTable>
              }
            </div>
            <br />

          </div>

        </div>

      </div>

      <div style={{ height: '0', overflow: 'hidden' }}>
        <table ref={tableRef}>
          <thead>
            <tr>
              <th>Task ID</th>
              <th>WF Status</th>
              <th>Task Name</th>
              <th>Responsible Company Name</th>
              <th>Task Manager</th>
              <th>Responsible Person</th>
              <th>Due Date</th>
              <th>Responsible Member</th>
              <th>Hours Spent</th>
            </tr>
          </thead>
          <tbody>
            {taskData?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item?.taskId || "---"}</td>
                  <td>{item?.workFlowStatus || "---"}</td>
                  <td>{item?.task_name || "---"}</td>
                  <td>{item?.responsibleContractor?.map(e => e.company_name).join(", ") || "---"}</td>
                  <td>{item?.taskManager?.map(e => e.first_name).join(", ") || "---"}</td>
                  <td>{item?.responsiblePerson?.map(e => e.first_name).join(", ") || "---"}</td>
                  <td>{moment(item?.dueDate).format("DD-MM-YYYY") || "---"}</td>
                  <td>{item?.responsibleMembers.map(e => e.first_name)}</td>
                  <td>{item?.hourSpent}</td>
                </tr>

              );
            })}
          </tbody>
        </table>
      </div>
      <DeleteDialog
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        onCancel={() => setTaskId(null)}
        onConfirm={confirmDelete}
        header={"Task"}
      />
    </>
  );
}

export default Tasktable;

import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { BorderedTable } from '../../Components/Tables/BorderedTable';
import { Checkbox } from 'primereact/checkbox';
import { TableSkelton } from '../../Components/Common/TableSkelton';
import { getPermissionByRole, updatePermissionById } from '../../Api/Admin/AllAPIs';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton';
import "./rbacManagement.css"

export const ManageAccess = () => {

    let [rolesData, setRolesData] = useState([])
    let [loader, setLoader] = useState(true);
    let navigate = useNavigate()
    let { id } = useParams();

    const [ignoredModules, setIgnoredModules] = useState(["Email Management", "Picklist Management", "Role Management"])

    function moduleCheckboxChange(event, ind) {
        let checked = event.target.checked;
        setRolesData(pre => {
            pre[ind].isAcess = checked
            if (!checked) {
                pre[ind].sub_module = pre[ind].sub_module.map(ee => ({ ...ee, isAcess: false }))
            }
            return [...pre]
        })
    }
    function subModuleCheckboxChange(event, parentInd, cInd) {
        let checked = event.target.checked;
        setRolesData(pre => {
            if (checked) {
                pre[parentInd].isAcess = true
            }
            pre[parentInd].sub_module[cInd].isAcess = checked
            return [...pre]
        })
    }

    async function getRoles() {
        try {
            setLoader(true)
            let res = await getPermissionByRole(id)
            console.log(res.data.data)
            let data = res?.data?.data?.[0]?.permission_tab
            setRolesData(data)
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoader(false)
        }
    }

    async function updatePermission() {
        try {
            setLoader(true)
            let res = await updatePermissionById(id, { permission_tab: rolesData })
            navigate("/rbacManagement")
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        getRoles()
    }, [])

    return (
        <div className=" rbacManagement_container">
            <div className='heading mb-4'>Roles And Permissions Control Management</div>
            <div>
                <button className='btn btn-primary ms-2' onClick={() => setIgnoredModules([])}>ShowAll</button>

                {rolesData.map((res, ind) => {
                    if (ignoredModules.includes(res.module)) {
                        return
                    }
                    return (
                        <div key={ind}>

                            <div className='card p-md-4 p-1 mb-3'>
                                <div className="d-flex align-items-center gap-5 mb-2">
                                    <div className='parentModule_heading'>
                                        {res.module}
                                        <div className='parentModule_description'>{res.description}</div>
                                    </div>

                                    <div>
                                        <Checkbox onChange={(e) => moduleCheckboxChange(e, ind)} checked={res.isAcess} />
                                    </div>
                                </div>
                                {res.sub_module.map((res2, jj) => {
                                    return (
                                        <div className="d-flex align-items-center gap-5">
                                            <div className='childModule_heading mb-1'>{res2.module}</div>
                                            <div className='mb-1'>
                                                <Checkbox onChange={(e) => subModuleCheckboxChange(e, ind, jj)} checked={res2.isAcess} />
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    )
                })}
                <br />
                <div className="d-flex justify-content-end mb-2">
                    <div className='d-flex gap-3'>
                        <button className='btn border' onClick={() => navigate("/rbacManagement")} >Cancel</button>
                        <PrimaryButton onClick={updatePermission} text={"Update"} />
                    </div>
                </div>


            </div>
        </div >
    )
};

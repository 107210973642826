import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { getInspectionTypeByProjectId, getTradeT, getUserByProjectId } from "../../Api/Admin/AllAPIs";
import { Dropdown } from "primereact/dropdown";
import { getProjectId } from "../../utils";
import SingleSelect from "../../Components/Common/SingleSelect";
const Filter = ({ filterData, setFilterData, onFilter, className }) => {


  const [visible, setVisible] = useState(false)
  const [allUsersOfProject, setAllUsersOfProject] = useState([]);
  const projectId = localStorage.getItem("projectId");
  const [allInspectionTrades, setAllInspectionTrades] = useState([])
  const [allInspectionTypes, setAllInspectionTypes] = useState([])

  const [data, setData] = useState({ ...filterData })


  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId)
      let allUsers = res?.data?.data?.[0]?.combined_users || []
      setAllUsersOfProject(allUsers)
    }
    catch (err) {
      console.log(err)
    }
  }
  async function getAllInspectionTrades() {
    try {
      let res = await getTradeT(getProjectId())
      setAllInspectionTrades(res.data.data)
    }
    catch (err) {
      console.log(err)
    }
  }
  async function getAllInspectionTypes() {
    try {
      let res = await getInspectionTypeByProjectId(getProjectId())
      setAllInspectionTypes(res.data.data)
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllInspectionTrades()
    getAllInspectionTypes()

  }, [])

  useEffect(() => {

    if (visible && allUsersOfProject.length == 0) {
      getAllUsersOfProject()
    }
  }, [visible])

  function handleFilter() {
    onFilter(filterData)
    setVisible(false)
  };

  function inpChange(e) {
    let { name, value } = e.target
    setFilterData(pre => ({ ...pre, [name]: value }))

  }

  return (
    <div className={`card flex justify-content-center ${className}`}>
      <button
        className="filter_btn w-100"
        onClick={() => setVisible(true)}
      >
        Filter
      </button>
      <Dialog
        draggable={false}
        header="Filter Inspection"
        visible={visible}
        maximizable
        style={{ width: "50rem", color: "#101928" }}
        onHide={() => setVisible(false)}
        headerClassName="addBorder_bottom"
      >
        <div className="row mt-4">

          <div className="col-md-6 mt-3">
            <label className="labels mb-1"> Approver:</label>
            <div className="card flex justify-content-center">
              <Dropdown
                value={filterData?.approver_id}
                onChange={inpChange}
                options={allUsersOfProject.map(d => ({ label: d.first_name, value: d._id }))}
                name="approver_id"
                placeholder="Select Approver"
              />
            </div>
          </div>

          <div className="col-md-6 mt-3">
            <label className="labels mb-1"> Reviewer:</label>
            <div className="card flex justify-content-center">
              <Dropdown
                value={filterData?.reviewer_id}
                onChange={inpChange}
                options={allUsersOfProject.map(d => ({ label: d.first_name, value: d._id }))}
                name="reviewer_id"
                placeholder="Select Reviewer"
              />
            </div>
          </div>

          <div className="col-md-6 mt-3">
            <label className="labels mb-1">Type:</label>

            <div className="card flex justify-content-center ">
              <SingleSelect
                placeholder="Inspection Type"
                options={allInspectionTypes.map(e => e.name)}
                value={filterData.inspection_type}
                onChange={inpChange}
                filter={true}
                name={'inspection_type'}
                filterInputAutoFocus={true}
              />
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <label className="labels mb-1">Trade:</label>

            <div className="card flex justify-content-center ">
              <SingleSelect
                placeholder="Inspection Trade"
                options={allInspectionTrades.map(e => e.name)}
                value={filterData.trade}
                onChange={inpChange}
                filter={true}
                name={"trade"}
                filterInputAutoFocus={true}
              />
            </div>
          </div>
          <div className="col-md-12 mt-5 d-flex justify-content-end">
            <div className="d-flex align-items-center gap-3">
              <button className="filter_btn" onClick={() => setVisible(false)}>
                Cancel
              </button>
              <Button className="Apply_filter_btn" onClick={handleFilter}>Apply Filter</Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default Filter;

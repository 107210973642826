import React, { useEffect, useState } from "react";
import "./createRfi.css";
import TextInput from "../Inputs/TextInput";
import SingleSelect from "../Common/SingleSelect";
import { Editor } from "primereact/editor";
import { MdAdd } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { DragAndDrop } from "../Inputs/DragAndDrop";
import {
  addSubmittalCategory,
  getAllCategory,
  getCompanyByProjectId,
  getRfiById,
  getUserByProjectId,
  getUsersByCompanyId,
  updateRfi,
  uploadDocs,
} from "../../Api/Admin/AllAPIs";
import {
  convertHourlySpentStringIntoObject,
  getActionsPermission,
  getProjectCode,
  getProjectName,
  getUserData,
  getUserId,
  getUserName,
  toast,
} from "../../utils";
import FormSkeleton from "../Common/FormSkeleton";
import AddSets from "../Common/AddSets";
import AutoCompleteSelect from "../Common/AutoCompleteSelect";
import { MultiSelect } from "primereact/multiselect";
import moment from "moment";
import TimeInput from "../Inputs/TimeSelector";
import { FormHeader } from "../Common/FormHeader";

export const EditRfi = () => {

  const projectId = localStorage.getItem("projectId");
  const projectData = JSON.parse(localStorage.getItem("projectData"));
  const isApproverAccess = getActionsPermission("Rfi Approver");
  const isReviewerAccess = getActionsPermission("Rfi Reviewer");
  const [subject, setSubject] = useState("");
  const [rfi, setRfi] = useState("");
  const [reviewerComment, setReviewerComment] = useState('')
  const [approverComment, setApproverComment] = useState('')
  const [responsibleContractor, setResponsibleContractor] = useState([]);
  const [alldata, setAlldata] = useState({});
  const [membersResponsible, setMembersResponsible] = useState([]);
  const [initiatedDate, setInitiatedDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [location, setLoation] = useState("");
  const [questionReceived, setQuestionReceived] = useState([]);
  const [reference, setReference] = useState('');
  const [status, setStatus] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [drawingNumber, setDrawingNumber] = useState();
  const [category, setCategory] = useState("");
  const [scheduledImpact, setScheduledImpact] = useState();
  const [costImpact, setCostImpact] = useState();
  const [question, setQuestion] = useState("");
  const [fileUpload, setFileUpload] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFile, setPreviousFile] = useState([]);
  const [btnDisable, setBtnDisable] = useState(false);
  const [allUsersOfProject, setAllUsersOfProject] = useState([]);
  const [oldApproverReviewwerData, setOldApproverReviewwerData] = useState({});
  const [oldrfi_review_status, setOldrfi_review_status] = useState("");
  const [allData, setAllData] = useState({})
  const [responsiblePerson, setResponsiblePerson] = useState([]);
  const [costCode, setCostCode] = useState("");
  const [rfiManager, setRfiManager] = useState('');
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [approver, setApprover] = useState(null);
  const [reviewer, setReviewer] = useState(null);
  const [visible, setVisible] = useState(false)
  const [hourSpent, setHourSpent] = useState('')
  const [companies, setCompanies] = useState([])
  const [users, setUsers] = useState([])
  const [categories, setCategories] = useState([])
  const [addCategoryy, setAddCategoryy] = useState("")
  const skeletonData = [
    { qty: 1 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 1, height: "15rem" },
    { qty: 1, height: "7rem" },
  ];
  const statusOptions = [
    { label: "Not Started", value: "Not Started" },
    { label: "In Progress", value: "In Progress" },
    { label: "Completed", value: "Completed" },
    { label: "Ready for Review", value: "Ready for Review" },
    { label: "Incomplete", value: "Incomplete" }
  ]
  const statusOptions2 = [
    { label: "Draft", value: "draft" },
    { label: "In Review", value: "inreview" },
    { label: "Pending Approval", value: "pendingapproval" },
    { label: "Approved", value: "approved" },
  ]

  const [Validation, setValidation] = useState({
    subject: false,
    rfiNo: false,
    initialDate: false,
    dueDate: false,
    category: false,
    status: false,
    approvalStatus: false,
    rfiManager: false,
    questionReceived: false,

  });

  const { id } = useParams();
  const navigate = useNavigate();
  const userData = localStorage.getItem("userData");
  const userObj = JSON.parse(userData);
  const userId = userObj._id;
  const userName = userObj.name;




  const handleFileSubmit = (event) => {
    setFileUpload(event);
  };


  const getrfiCategory = async (projectId) => {
    try {
      const res = await getAllCategory(projectId)
      const categoryy = res?.data?.data
      setCategories(categoryy)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getrfiCategory(projectId)
  }, [])

  const handleGetRfi = async () => {
    try {
      setSkeletonLoader(true);
      const response = await getRfiById(id);

      const rfiData = response?.data?.data;
      let approverData = rfiData?.approver?.[0] || {};
      let reviewerData = rfiData?.reviewer?.[0] || {};
      setAllData(rfiData)
      setOldApproverReviewwerData({
        approver: approverData?._id || null,
        reviewer: reviewerData?._id || null,
      });

      setApprover(approverData?._id !== undefined ? approverData?._id : null);
      setReviewer(reviewerData?._id !== undefined ? reviewerData?._id : null);
      setOldrfi_review_status(rfiData?.rfi_review_status);
      setAlldata(rfiData);
      setSubject(rfiData.subject);
      setApproverComment(rfiData?.approver_comment)
      setReviewerComment(rfiData?.reviewer_comment)
      setRfi(rfiData.rfi);
      setResponsibleContractor(rfiData.responsibleContractor?.map((e) => e._id));
      setMembersResponsible(rfiData.membersResponsible?.map((e) => e._id));
      setInitiatedDate(moment(rfiData.initiatedDate, 'DD/MM/YYYY h:mm:ss a')?.format('yyyy-MM-DD'));
      setDueDate(moment(rfiData.dueDate, 'DD/MM/YYYY h:mm:ss a')?.format('yyyy-MM-DD'));
      setStatus(rfiData.workflow_status);
      setLoation(rfiData.location);
      setQuestionReceived(rfiData.questionReceived?.map((e) => e._id));
      setApprovalStatus(rfiData?.status);
      setReference(rfiData.reference);
      setDrawingNumber(rfiData.drawingNumber);
      setCategory(rfiData.category);
      setScheduledImpact(rfiData.scheduledImpact);
      setCostImpact(rfiData.costImpact);
      setQuestion(rfiData.question);
      setHourSpent(rfiData?.hourSpent)
      setRfiManager(rfiData.rfi_manager?.map((e) => e._id));
      setResponsiblePerson(rfiData?.assignees?.map((e) => e._id));
      let data = {
        value: rfiData?.responsibleContractor?.map(res => res?._id)
      }
      await handleCompanyChange(data)
      setCostCode(rfiData.costCode);
      setFileName(
        rfiData.attachFile.map((item) => ({
          name: item?.fileName,
          id: item?._id,
        }))
      );
      setPreviousFile([...rfiData?.attachFile]);
    } catch (error) {
      console.error(error);
    } finally {
      setSkeletonLoader(false);
    }
  };

  const checkValidation = () => {
    if (!subject.trim()) {
      setValidation((prevState) => ({
        ...prevState,
        subject: true,
      }));
    }
    if (!category) {
      setValidation((prevState) => ({
        ...prevState,
        category: true,
      }));
    }
    if (!status) {
      setValidation((prevState) => ({
        ...prevState,
        status: true,
      }));
    }
    if (!dueDate) {
      setValidation((prevState) => ({
        ...prevState,
        dueDate: true,
      }));
    }
    if (!rfi) {
      setValidation((prevState) => ({
        ...prevState,
        rfiNo: true,
      }));
    }
    if (rfiManager.length == 0) {
      setValidation((prevState) => ({
        ...prevState,
        rfiManager: true,
      }));
    }
    if (questionReceived.length == 0) {
      setValidation((prevState) => ({
        ...prevState,
        questionReceived: true,
      }));
    }


    if (!initiatedDate) {
      setValidation((prevState) => ({
        ...prevState,
        initialDate: true,
      }));
    }

    if (
      !category ||
      !initiatedDate ||
      !membersResponsible ||
      !responsibleContractor ||
      !rfi ||
      !subject ||
      !dueDate || rfiManager?.length == 0 || questionReceived.length == 0
    ) {
      toast.info("Please Fill all the Fields Marked with * are Mandatory");
      return true;
    }
    return false;
  };
  const domain = window.location.origin;
  const handleUpdate = async () => {
    if (fileUpload.length > 10) {
      toast.info("You can add only 10 attachments");
      return;
    }
    if (checkValidation()) {
      return;
    }

    setBtnDisable(true);


    let uploadedFiles = [];

    if (fileUpload && fileUpload.length > 0) {
      const formData = new FormData();
      for (const file of fileUpload) {
        formData.append("upload", file);
      }

      const uploadResponse = await uploadDocs(formData);

      uploadedFiles = uploadResponse.data.map((item) => ({
        fileName: item.fileName,
        fileUrl: item.fileUrl,
      }));
    }
    let emailData = [];

    if (oldApproverReviewwerData?.reviewer !== reviewer?._id && reviewer?._id !== undefined) {
      emailData.push({
        name: reviewer?.first_name,
        to: reviewer?.email_address,
        tool_number: rfi,
        tool: "Rfi",
        tool_name: subject,
        subject: `Action Required: Appointed as the Reviewer to  Rfi #${rfi} ${subject}`,
        project_name: getProjectName(),
        project_id: getProjectCode(),
        template_type: "createdToolEmailToReviewer",
        created_by: getUserName(),
        url: `${domain}/projectManagement/openrfis`,
      });
    }
    if (approvalStatus == 'inreview') {
      let findEmail = allUsersOfProject?.find(res => res._id == reviewer)
      emailData.push({
        name: findEmail?.first_name,
        to: findEmail?.email_address,
        tool_number: rfi,
        tool: "Rfi",
        tool_name: subject,
        subject: `Action Required: Project ${getProjectName()} status changed`,
        project_name: getProjectName(),
        project_id: getProjectCode(),
        template_type: "createdToolEmailToReviewer",
        created_by: getUserName(),
        url: `${domain}/projectManagement/openrfis`,
      });
    }

    const payload = {
      subject: subject,
      rfi: rfi,
      responsibleContractor: responsibleContractor,
      membersResponsible: membersResponsible,
      initiatedDate: moment(initiatedDate).format("DD/MM/YYYY h:mm:ss a"),
      dueDate: moment(dueDate).format("DD/MM/YYYY h:mm:ss a"),
      location: location,
      questionReceived: questionReceived,
      reference: reference,
      drawingNumber: drawingNumber,
      category: category,
      scheduledImpact: scheduledImpact,
      costImpact: costImpact,
      question: question,
      attachFile: [...previousFile, ...uploadedFiles],
      deleted_file: removePhoto,
      rfi_manager: rfiManager,
      assignees: responsiblePerson,
      costCode: costCode,
      userName: userName,
      user_id: userId,
      reviewer_id: reviewer,
      approver_id: approver,
      email_data: emailData,
      hourSpent: hourSpent,
      user_name:getUserName(),
      workflow_status: status,
      project_id: projectId,
    };
    if (allData?.status == 'draft') {
      payload.status = 'inreview'
    }
    console.log(payload)
    // return
    try {
      await updateRfi(id, payload);
      setBtnDisable(false);
      toast.success("RFI Updated Successfully!");
      navigate("/projectManagement/openrfis");
    } catch (err) {
      console.error(err?.response?.data?.message);
      if (err?.response?.data?.message === "Rfi ID Already Exist") {
        toast.error("RFI Number Already Exit")
      } else {
        toast.error("Something Wents Wrong, Please Try Again!");
      }
    }
  };

  useEffect(() => {
    handleGetRfi();
  }, []);

  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId);
      let allUsers = res?.data?.data?.[0]?.combined_users || [];
      setAllUsersOfProject(allUsers);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllUsersOfProject();
  }, []);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (/^[0-9]*\.?[0-9]*$/.test(inputValue)) {
      setCostImpact(inputValue);
    }
  }

  const getcompany = async () => {
    try {
      const res = await getCompanyByProjectId(projectId);
      if (res && res?.data) {
        const companyNames = res?.data?.data[0]?.companynames;
        const formattedCompanies = companyNames.map((company) => ({
          label: company.company_name,
          value: company._id
        }));

        setCompanies(formattedCompanies);
      } else {
        console.log("Response data is not in the expected format");
      }
    } catch (error) {
      console.log("Error fetching company data:", error);
    }
  };
  useEffect(() => {
    getcompany()
  }, [])

  const handleCompanyChange = async (e) => {
    const selectedCompanyIds = e.value;
    setResponsibleContractor(selectedCompanyIds);

    // Fetch users for all selected companies
    if (selectedCompanyIds.length > 0) {
      const usersFromAllCompanies = [];

      for (const companyId of selectedCompanyIds) {
        try {
          const res = await getUsersByCompanyId(projectId, { company_id: [companyId] });
          if (res && res?.data?.data) {
            const usersFromCompany = res.data.data.map(user => ({
              label: user.name,
              value: user._id
            }));
            usersFromAllCompanies.push(...usersFromCompany);
          } else {
            console.error("Response data is not in the expected format");
          }
        } catch (error) {
          console.log("Error fetching company data:", error);
        }
      }


      const uniqueUsers = Array.from(new Set(usersFromAllCompanies.map(user => user.value)))
        .map(id => {
          return usersFromAllCompanies.find(user => user.value === id);
        });

      setUsers(uniqueUsers);
      let arrU = uniqueUsers?.map(res => res?.value)
      let arr = membersResponsible
      if (arr?.length > arrU?.length) {
        let newArr = arr?.filter(res => arrU.includes(res))
        setMembersResponsible(newArr)
      }
    } else {
      setUsers([]);
      setMembersResponsible([])
    }
  };

  const submitRfiCategory = async () => {

    try {
      const payload = {
        name: addCategoryy,
        project_id: projectId
      }
      await addSubmittalCategory(payload);
      setAddCategoryy('')
      getrfiCategory(projectId)
      setVisible(false)
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <>
      <div className="main-container ">
        <div>
          <FormHeader
            to={"/projectManagement/openrfis"}
            formHeader={"RFI"}
            breadcrumItem={["RFIs", `Edit RFIs`]} />


          {/* Top Create New Rfi End */}
          {
            <div>


              {/*  New section End */}
              {skeletonLoader ? (
                <FormSkeleton data={skeletonData} />
              ) : (
                <>
                  <div className="row m-0 p-0">
                    <div className="col-md-6 col-12 d-flex align-items-end">
                      <div className="w-100 mt-3">
                        <label htmlFor="" className="py-2 labelCss">
                          Subject: <span className="text-danger">*</span>
                        </label>
                        <TextInput
                          placeholder="Subject"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          className={Validation.subject ? "Validation" : ""}
                          onClick={() =>
                            setValidation({ ...Validation, subject: false })
                          }
                        >
                          {" "}
                        </TextInput>
                      </div>
                    </div>
                    <div className="col-md-6 col-12 d-flex align-items-end">
                      <div className="w-100 mt-3">
                        <label className="labels mb-2">Category:</label>{" "}
                        <span className="text-danger">*</span>
                        <div className="card flex justify-content-center  ">
                          <AutoCompleteSelect
                            options={categories?.map((item) => ({
                              label: item?.name,
                              value: item?._id,
                            }))}
                            value={category}
                            optionLabel="category_name"
                            onChange={(value) => {
                              setCategory(value)
                              setValidation({ ...Validation, category: false })
                            }}
                            className={`p-0 ${Validation.category ? "Validation" : ""}`}

                            placeholder="Select Category"

                          />
                        </div>
                      </div>
                      <div className="mt-4 d-flex align-items-end ">
                        <MdAdd
                          className="h2"
                          style={{ cursor: "pointer" }}
                          onClick={() => setVisible(true)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <label htmlFor="" className="py-2 labelCss">
                        Workflow Status: <span className="text-danger">*</span>
                      </label>
                      <SingleSelect
                        value={status}
                        options={statusOptions}
                        placeholder="Workflow Status"
                        onChange={(e) => setStatus(e.target.value)}
                        className={Validation.status ? "Validation p-0" : "p-0"}
                        onClick={() =>
                          setValidation({ ...Validation, status: false })
                        }
                        disabled={
                          alldata.rfi_approval_status == "approved" ||
                          alldata.rfi_review_status == "approved"
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Created Date: <span className="text-danger">*</span>
                      </label>

                      <input
                        style={{ height: "50px" }}
                        type="date"
                        placeholder="Created Date"
                        value={initiatedDate}
                        onChange={(e) => setInitiatedDate(e.target.value)}
                        className={`${Validation.initialDate ? "Validation" : ""
                          } form-control`}
                        onClick={() =>
                          setValidation({ ...Validation, initialDate: false })
                        }
                        disabled

                      />
                    </div>
                  </div>

                  {/*  calender  section start */}
                  <div className="row  m-0 p-0">
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        RFI Number: <span className="text-danger">*</span>
                      </label>
                      <div className="d-flex w-100 ">
                        <input
                          type="text"
                          placeholder="Rfi Number"
                          value={rfi}
                          onChange={(e) => setRfi(e.target.value)}
                          style={{ width: "100%" }}
                          className={`${Validation.rfiNo ? "Validation" : ""
                            } form-control common_input`}
                          onClick={() =>
                            setValidation({ ...Validation, rfiNo: false })
                          }

                        />
                      </div>

                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Due Date: <span className="text-danger">*</span>
                      </label>

                      <input
                        style={{ height: "50px" }}
                        type="date"
                        placeholder="Due Date"
                        value={dueDate}
                        onChange={(e) => setDueDate(e.target.value)}
                        className={`${Validation.dueDate ? "Validation" : ""
                          } form-control`}
                        onClick={() =>
                          setValidation({ ...Validation, dueDate: false })
                        }
                      />
                    </div>
                  </div>

                  {/*  calender section start */}
                  <div className="row  gap-0 m-0 p-0">
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Responsible Contractor Company (s):
                      </label>

                      <MultiSelect
                        value={responsibleContractor}
                        onChange={handleCompanyChange}
                        options={companies}
                        optionLabel="label"
                        filter placeholder="Company"
                        style={{ width: "100%" }}
                        className={`p-0 `}


                      />

                    </div>


                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Responsible Member (s):
                      </label>
                      <MultiSelect
                        value={membersResponsible}
                        onChange={(e) => setMembersResponsible(e.value)}
                        options={users}
                        optionLabel="label"
                        filter placeholder="Select Member"
                        style={{ width: "100%" }}
                        className={`w-full md:w-20rem `}

                      />
                    </div>
                  </div>

                  <div className="row m-0 gap-0 p-0">
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        RFI Manager (s): <span className="text-danger">*</span>
                      </label>
                      <MultiSelect
                        options={allUsersOfProject.map((item) => ({
                          label: item?.first_name,
                          value: item?._id,
                        }))}
                        filter={true}
                        value={rfiManager}
                        optionLabel="label"
                        onChange={(e) => {
                          setRfiManager(e.value)
                        }}
                        className={`p-0 ${Validation.rfiManager ? "Validation" : ""}`}
                        style={{ width: "100%" }}
                        placeholder="RFI Manager"
                        onClick={() =>
                          setValidation({ ...Validation, rfiManager: false })
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Location:
                      </label>
                      <TextInput
                        placeholder="Location"
                        value={location}
                        onChange={(e) => {
                          setLoation(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="row  m-0 p-0">
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Question Received from: <span className="text-danger">*</span>
                      </label>
                      <MultiSelect
                        options={allUsersOfProject.map((item) => ({
                          label: item?.first_name,
                          value: item?._id,
                        }))}
                        value={questionReceived}
                        optionLabel="label"
                        onChange={(e) => {
                          setQuestionReceived(e.value)
                        }}
                        onClick={() =>
                          setValidation({ ...Validation, questionReceived: false })

                        }
                        className={`p-0 ${Validation.questionReceived ? "Validation" : ""}`}
                        style={{ width: "100%" }}
                        placeholder="Question"

                      />

                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Reference:
                      </label>
                      <TextInput
                        placeholder="Reference"
                        value={reference}
                        onChange={(e) => {
                          setReference(e.target.value);
                        }}
                      />

                    </div>
                  </div>

                  <div className="row  m-0 p-0">
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Drawing Number:
                      </label>
                      <div className="d-flex w-100 ">
                        <input
                          type="number"
                          placeholder="Enter Drawing Number"
                          inputId="integeronly"
                          value={drawingNumber}
                          onChange={(e) => setDrawingNumber(e.target.value)}
                          style={{ width: "100%" }}
                          className="form-control common_input"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Hours Spent:
                      </label>
                      <TimeInput
                        value={convertHourlySpentStringIntoObject(hourSpent)}
                        onChange={(newValue) =>
                          setHourSpent(`${newValue.hours}-${newValue.minutes}`)
                        } />
                    </div>
                  </div>
                  <div className="row  m-0 p-0">
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Responsible Person (s):
                      </label>
                      <MultiSelect
                        value={responsiblePerson}
                        onChange={(e) => setResponsiblePerson(e.value)}
                        options={allUsersOfProject?.map((item) => ({
                          label: item?.first_name,
                          value: item?._id,
                        }))} optionLabel="label"
                        filter placeholder="Select Person"
                        // maxSelectedLabels={3} 
                        style={{ width: "100%" }}
                        className={`p-0 `}

                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Cost Code:
                      </label>
                      <TextInput
                        placeholder="Enter Cost Code"
                        value={costCode}
                        onChange={(e) => {
                          setCostCode(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="row  m-0 p-0">
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Scheduled Impact:
                      </label>
                      <TextInput
                        placeholder="Schedule Impact"
                        value={scheduledImpact}
                        onChange={(e) => {
                          setScheduledImpact(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Cost Impact:
                      </label>
                      <TextInput
                        placeholder=" Cost"
                        value={costImpact}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row m-0 p-0">

                    {isReviewerAccess && (
                      <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                          Reviewer:
                        </label>
                        <AutoCompleteSelect
                          options={allUsersOfProject.map((item) => ({
                            label: item?.first_name,
                            value: item?._id,
                          }))}
                          value={reviewer}
                          optionLabel="label"
                          onChange={(value) => {
                            setReviewer(value)
                          }}
                          className={`p-0 `}
                          // disabled={reviewer === getUserId() || allData?.status == 'approved'}
                          placeholder="Select Reviewer"
                        />
                      </div>
                    )}

                    {isApproverAccess && (
                      <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                          Approver:
                        </label>

                        <AutoCompleteSelect
                          options={allUsersOfProject.map((item) => ({
                            label: item?.first_name,
                            value: item?._id,
                          }))}
                          value={approver}
                          optionLabel="label"
                          onChange={(value) => {
                            setApprover(value)
                          }}
                          className={`p-0 `}

                          placeholder="Select Approver"
                          // disabled={approver === getUserId() || allData?.status == 'approved'}
                        />
                      </div>
                    )}

                  </div>

                  {/* <div className="col-12">
                    <label htmlFor="" className="py-2 labelCss">
                      Approval Status:
                    </label>
                    <SingleSelect
                      value={approvalStatus}
                      options={statusOptions2}
                      placeholder="Approval Status"
                      onChange={(e) => setApprovalStatus(e.target.value)}
                      className={'p-0'}
                      disabled={true}
                    />
                  </div> */}
                  <div className="row m-0 p-0">
                    <div className="col-md-6 col-12">
                      <label htmlFor="" className="py-2 labelCss">
                        Reviewer Comment:
                      </label>
                      <TextInput
                        placeholder="Reviewer Comment"
                        value={reviewerComment}
                        // onChange={(e) => setReviewerComment(e.target.value)}
                        disabled
                      />
                    </div>
                    <div className="col-md-6 col-12">
                      <label htmlFor="" className="py-2 labelCss">
                        Approver Comment:
                      </label>
                      <TextInput
                        placeholder="Approver Comment"
                        value={approverComment}
                        // onChange={(e) => setApproverComment(e.target.value)}
                        disabled
                      />
                    </div>

                  </div>

                  <div className="row m-0 p-0">
                    <div className="col-md-12">
                      <label htmlFor="" className="py-2 labelCss">
                        Question:
                      </label>
                      <div className="card">
                        <Editor
                          value={question}
                          onTextChange={(e) => setQuestion(e.htmlValue)}
                          style={{ height: "200px" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 p-0 css_com">
                    <div className="col-md-12">
                      <DragAndDrop
                        setRemovePhoto={setRemovePhoto}
                        value={fileName}
                        setFiles={setFileUpload}
                        onChange={handleFileSubmit}
                        setValue={setFileName}
                        setPreviousFiles={setPreviousFile}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="btn_main mt-4 ">
                <span
                  className="cancelBtn  px-3 py-2"
                  onClick={() => navigate("/projectManagement/openrfis")}
                >
                  {" "}
                  Cancel
                </span>

                <span className="CreateRfi px-3 py-2" onClick={handleUpdate}>
                  Update
                </span>
              </div>
            </div>
          }
        </div>
      </div>
      <br />




      <AddSets
        visible={visible}
        setVisible={setVisible}
        CategoryName={"RFI Category"}
        label={"Category"}
        reqFor={"create"}
        value={addCategoryy}
        setValue={setAddCategoryy}
        onSubmit={submitRfiCategory}
      />
    </>
  );
};

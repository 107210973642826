import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react'
import CustomDragAndDrop from '../../Components/Inputs/CustomDragAndDrop';
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton';
import TextInput from '../../Components/Inputs/TextInput';
import SingleSelect from '../../Components/Common/SingleSelect';

import { INSPECTION_ITEM_RESULT } from '../../constants';
import { getUserName, toast } from '../../utils';
import { Editor } from 'primereact/editor';

export const AddSectionItem = ({ onSubmit, mode, data, subSectionIndex, sectionIndex }) => {

    console.log(sectionIndex, "[[[[[[[[[[[[[[[[[")
    const [visible, setVisible] = useState(false)

    const [deletedFiles, setDeletedFiles] = useState([])
    const [files, setFiles] = useState([])

    const resultOptions = [
        "pass", "fail", "n/a"
    ]

    let [sectionItemData, setSectionItemData] = useState(data || {
        subsectionHeadingData: "",
        itemResult: "",
        feedback: "",

        attachFile: [],
        responses: []
    })


    function formSubmit(e) {

        if (!Object.values(sectionItemData).every(e => e)) {
            toast.error("Please fill all fields !")
            return
        }

        onSubmit(sectionItemData, subSectionIndex?.split?.("_")?.[0] || sectionIndex, subSectionIndex?.split?.("_")?.[1] || null)
        setVisible(false)
        setSectionItemData({
            subsectionHeadingData: "",
            itemResult: "",
            feedback: "",
            attachFile: [],
            responses: []
        })
    }

    return (
        <div>
            <div
                onClick={(e) => {
                    e.stopPropagation()
                    setVisible(true)
                }}
            >{mode} Item</div>

            <Dialog
                onClick={(e) => e.stopPropagation()}
                header={`${mode} inspection item`}
                style={{ width: "60rem", minWidth: "30rem" }}
                headerClassName="addBorder_bottom"
                draggable={false}
                visible={visible}
                onHide={() => setVisible(false)}
            >

                <div className="row">
                    <div className="col-md-6">
                        <label className="labelCss">Inspection item name</label>
                        <TextInput
                            value={sectionItemData.subsectionHeadingData}
                            placeholder="Inspection item name"
                            onChange={(e) => { setSectionItemData(pre => ({ ...pre, subsectionHeadingData: e.target.value })) }}
                        />
                    </div>


                    <div className="col-md-6">
                        <label className="labelCss">Result</label>

                        <SingleSelect
                            placeholder="Result"
                            options={INSPECTION_ITEM_RESULT}
                            value={sectionItemData.itemResult}
                            onChange={(e) => { setSectionItemData(pre => ({ ...pre, itemResult: e.target.value })) }}
                            filter={true}
                            filterInputAutoFocus={true}
                        />

                    </div>

                </div>

                <div className="row">
                    <div className="col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Feedback:
                        </label>
                        <Editor
                            style={{ height: "200px" }}
                            value={sectionItemData.feedback}
                            onTextChange={(e) => {
                                console.log(e.htmlValue)
                                setSectionItemData(pre => ({ ...pre, feedback: e.htmlValue }))
                            }}
                        />
                    </div>
                </div>


                <div className="row">
                    <div className="col-12">
                        <label htmlFor="" className="py-2 labelCss">
                            Attachments:
                        </label>
                        <CustomDragAndDrop
                            value={sectionItemData.attachFile}
                            setDeleted={setDeletedFiles}
                            onChange={(e) => { setSectionItemData(pre => ({ ...pre, attachFile: e })) }}
                        />
                    </div>
                </div>

                <div className="d-flex justify-content-end mt-3">
                    <div className="d-flex">
                        <button
                            className="btn border me-3"
                            type="button"
                            onClick={() => setVisible(false)}
                        >
                            Cancel
                        </button>
                        <PrimaryButton
                            onClick={formSubmit}
                            type="button"
                            text={"Add"}

                        />
                    </div>
                </div>



            </Dialog>
        </div>
    )
};

import React, { useEffect, useRef, useState } from "react";

import TextInput from "../../Components/Inputs/TextInput";
import Calender from "../../Components/Common/Calender";
import SingleSelect from "../../Components/Common/SingleSelect";
import { Editor } from "primereact/editor";
import { useNavigate, useParams } from "react-router-dom";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import {
  addSubmittal,
  addSubmittalCategory,
  addSubmittalPackage,
  addSubmittalType,
  getCodeCost,
  getCompanyByProjectId,
  getCountForSubmitalByProjectId,
  getDrawing,
  getDrawingByProjectId,
  getProjectDirectory,
  getSubmittalById,
  getSubmittalCategory,
  getSubmittalPackage,
  getSubmittalType,
  getUserByProjectId,
  updateSubmittalById,
  uploadDocs,
  addRespoSubmittals,
  getAllRespoSubmittalsById,
} from "../../Api/Admin/AllAPIs";
import Loader from "../../Components/Common/Loader";
import { InputNumber } from "primereact/inputnumber";
import {
  getActionsPermission,
  getProjectCode,
  getProjectId,
  getProjectName,
  getUserData,
  getUserId,
  getUserName,
  toast,
} from "../../utils";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import { useSelector } from "react-redux";

import { MdAdd } from "react-icons/md";
import AddSets from "../../Components/Common/AddSets";
import AutoCompleteSelect from "../../Components/Common/AutoCompleteSelect";

import { MultiSelect } from "primereact/multiselect";
import TimeInput from "../../Components/Inputs/TimeSelector";
import moment from "moment";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { FormHeader } from "../../Components/Common/FormHeader";
export const CreateSubmittals = ({ reqFor }) => {
  const [subject, setSubject] = useState("");
  const [spec, setSpec] = useState(null);
  const [number, setNumber] = useState("");
  const [revision, setRevision] = useState("");
  const [submitedDate, setSubmitedDate] = useState(
    moment().format("DD/MM/YYYY h:mm:ss a")
  );
  const [receivedDate, setReceivedDate] = useState();
  const [issueDate, setIssueDate] = useState();
  const [fianlDueDate, setFianlDueDate] = useState();
  const [type, setType] = useState(null);
  const [SubmittalPackage, setSubmittalPackage] = useState(null);
  const [contractor, setContractor] = useState([]);
  const [manager, setManager] = useState([]);
  const [status, setStatus] = useState(null);
  const [costCode, setCostCode] = useState(null);
  const [location, setLocation] = useState("");
  const [linkedDrawing, setLinkedDrawing] = useState(null);
  const [distributionList, setDistributionList] = useState(null);
  const [discrition, setDiscription] = useState("");
  const [fileUpload, setFileUpload] = useState([]);
  const [submittalData, setSubmittalData] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [loader, setLoader] = useState(false);
  const [drawingListing, setDrawingListing] = useState([]);
  const projectId = localStorage.getItem("projectId");
  const [options, setOptions] = useState([]);
  const [allUsersOfProject, setAllUsersOfProject] = useState([]);
  const [allUsersOfProjectStatus, setAllUsersOfProjectStatus] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const userData = localStorage.getItem("userData");
  const projectData = JSON.parse(localStorage.getItem("projectData"));
  const userObj = JSON.parse(userData);
  const userId = userObj._id;

  const userName = userObj.name;
  console.log(userName)

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [PackageVisible, setPackageVisible] = useState(false);
  const [managerVisible, setManagerVisible] = useState(false);
  const [typeVisible, setTypeVisible] = useState(false);
  const [resVisible, setResVisible] = useState(false);

  const [drawingVisible, setDrawingVisible] = useState(false);

  const [receivedFrom, setReceivedFrom] = useState();
  const [approver, setApprover] = useState();
  const [reviewer, setReviewer] = useState();
  const [workFlow, setWorkFlow] = useState("");
  const [getDrawingWithProjectId, setGetDrawingWithProjectId] = useState([]);

  const [submittalCategory, setSubmittalCategory] = useState("");
  const [addRespo, setAddRespo] = useState("");
  const [getSubmittalCategorys, setGetSubmittalCategorys] = useState([]);
  const [oldApproverReviewwerData, setOldApproverReviewwerData] = useState({
    approver: {},
    reviewer: {},
  });




  const [submittalPackage, setSubmittalPackages] = useState("");

  const [submittalType, setSubmitallType] = useState("");
  const [getSubmittalpackage, setGetSubmittalPackage] = useState([]);
  const [getSubmitallType, setGetSubmitallType] = useState([]);
  const [getSubmitallRespo, setGetSubmitallRespo] = useState([]);

  const [approverStatus, setApproverStatus] = useState("");

  const [hoursSpent, setHoursSpent] = useState("");

  const [companies, setCompanies] = useState([]);
  const [getCount, setGetCount] = useState([]);
  const [allData, setAllData] = useState({});
  const [reviewerComment, setReviewerComment] = useState("");
  const [approverComment, setApproverComment] = useState("");

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setSubmitedDate(currentDate);
  }, []);
  const domain = window.location.origin;
  const [validation, setValidation] = useState({
    subject: false,
    spec: false,
    number: false,
    revision: false,
    submitedDate: false,
    receivedDate: false,
    issueDate: false,
    fianlDueDate: false,
    type: false,
    SubmittalPackage: false,

    receivedFrom: false,

    location: false,

  });
  const [skeletonLoader, setSkeletonLoader] = useState(
    reqFor === "edit" ? true : false
  );
  const skeletonData = [
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 1, height: "15rem" },
    { qty: 1, height: "7rem" },
  ];
  const isApproverAccess = getActionsPermission("Submittals Approver");
  const isReviewerAccess = getActionsPermission("Submittals Reviewer");
  let set = useSelector((d) => d.rolesAndPermissionData);
  const navigate = useNavigate();

  let { submittalId } = useParams();

  const handleFileSubmit = (event) => {
    setFileUpload(event);
  };

  const handleGetDrawing = async () => {
    try {
      const res = await getDrawingByProjectId(getProjectId());
      const getListing = res?.data?.data;
      setDrawingListing(getListing);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetDrawing();
    if (reqFor !== "edit") {
      CompanyandPerson();
      costCodeList();
    }
  }, []);

  const checkValidation = () => {
    let isValid = true;
    const updatedValidation = {};

    if (!subject.trim()) {
      updatedValidation.subject = true;
      isValid = false;
    }
    if (!spec) {
      updatedValidation.spec = true;
      isValid = false;
    }
    if (!number) {
      updatedValidation.number = true;
      isValid = false;
    }
    if (!revision.trim()) {
      updatedValidation.revision = true;
      isValid = false;
    }
    if (!submitedDate) {
      updatedValidation.submitedDate = true;
      isValid = false;
    }
    if (!receivedDate) {
      updatedValidation.receivedDate = true;
      isValid = false;
    }
    if (!issueDate) {
      updatedValidation.issueDate = true;
      isValid = false;
    }
    if (!fianlDueDate) {
      updatedValidation.fianlDueDate = true;
      isValid = false;
    }
    if (!type) {
      updatedValidation.type = true;
      isValid = false;
    }
    if (!SubmittalPackage) {
      updatedValidation.SubmittalPackage = true;
      isValid = false;
    }



    if (!receivedFrom) {
      updatedValidation.receivedFrom = true;
      isValid = false;
    }

    if (!location.trim()) {
      updatedValidation.location = true;
      isValid = false;
    }

    // Update validation state
    setValidation((prevState) => ({
      ...prevState,
      ...updatedValidation,
    }));

    if (!isValid) {
      toast.info("Please Fill all the Fields Marked with * are Mandatory");
    }

    return isValid;
  };
  const [comtractorData, setContractorData] = useState([]);
  const [personData, setPersonData] = useState([]);
  const [costCodeData, setCostCodeData] = useState([]);
  const [oldSubmittal_review_status, setOldSubmittal_review_status] =
    useState("");

  const CompanyandPerson = async (data) => {
    const res = await getProjectDirectory();
    const responseData = res.data.data;
    const subContractor = {
      company_name: "All Subcontractor",
      person: responseData.subcontractor.map((e) => {
        return { ...e, ...{ personName: e.company_name } };
      }),
    };

    const individual = {
      company_name: "All Individual",
      person: responseData.users.map((e) => {
        return { ...e, ...{ personName: e.first_name + " " + e.last_name } };
      }),
    };
    const contractor = responseData.contractor.map((s) => {
      const data = {
        _id: s?._id,
        company_name: s?.company_name,
        person: s.person.map((e) => {
          return {
            ...e,
            ...{ personName: e?.first_name + " " + e?.last_name },
          };
        }),
      };
      return data;
    });
    setContractorData([...contractor, subContractor, individual]);

    if (reqFor === "edit") {
      let userdata = [...contractor, subContractor, individual];
      let contractordata = userdata.find(
        (contractor) =>
          contractor.company_name === data?.submittal_responsibleContractor
      );

      if (contractordata) {
        setPersonData(contractordata?.person);
        setManager(
          contractordata?.person.find(
            (manager) => manager.personName === data?.submittal_submittalManager
          )
        );
      }
    }
  };
  const costCodeList = async (data) => {
    const res = await getCodeCost(getProjectId());

    const responseData = res?.data?.data;

    let code = responseData?.map((e) => {
      return { ...e, ...{ code_name: e.description + "-" + e.code } };
    });
    setCostCodeData(code);

    if (reqFor === "edit") {
      setCostCode(data?.submittal_costCode);
    }
  };

  async function handleSubmit() {
    if (!checkValidation()) {
      return;
    }

    try {
      const formData = new FormData();
      for (const file of fileUpload) {
        formData.append("upload", file);
      }

      let fileData = [];
      if (fileUpload.length !== 0) {
        const uploadResponse = await uploadDocs(formData);
        fileData = uploadResponse.data.map((item) => ({
          fileName: item.fileName,
          url: item.fileUrl,
        }));
      }

      let emailData = [];
      if (reviewer) {
        let approverData = allUsersOfProject.find((e) => e._id == reviewer);

        emailData.push({
          to: approverData?.email_address,
          name: approverData?.first_name,
          tool_number: number,
          tool_name: subject,
          project_name: getProjectName(),
          project_id: getProjectCode(),
          created_by: getUserName(),
          tool: "Submittal",
          subject: `FYI: Reviewer appointed created in ${getProjectName()}`,
          url: `${domain}/projectManagement/submittals`,
          template_type: "createdToolEmailToReviewer",
        });
      }
      if (approver) {
        let reviewerData = allUsersOfProject.find((e) => e._id == approver);
        emailData.push({
          to: reviewerData.email_address,
          name: reviewerData.first_name,
          tool_number: number,
          tool_name: subject,
          subject: `FYI: Approver appointed created in ${getProjectName()}`,
          project_name: getProjectName(),
          project_id: getProjectCode(),
          created_by: getUserName(),
          tool: "Submittal",
          url: `${domain}/projectManagement/submittals`,
          template_type: "createdToolEmailToApprover",
        });
      }
      manager?.forEach((userId) => {
        let reviewerData = allUsersOfProject.find((e) => e._id == userId);
        if (reviewerData) {
          emailData.push({
            to: reviewerData?.email_address,
            name: reviewerData?.first_name,
            tool_number: number,
            tool_name: subject,
            project_name: getProjectName(),
            project_id: getProjectCode(),
            created_by: getUserName(),
            tool: "Submittal",
            subject: `FYI:New Submittal created in ${getProjectName()}`,
            userType: "Submittal Manager",
            url: `${domain}/projectManagement/submittals`,
            template_type: "createdToolEmailToAssociatedUsers",
          });
        }
      });
      console.log(emailData);
      const payload = {
        submittal_title: subject?.trim(),
        submittal_specSection: spec,
        submittal_number: number,
        submittal_revision: revision?.trim(),
        submittal_submitBy: moment(submitedDate).format("YYYY-MM-DD"),
        submittal_receivedDate: moment(receivedDate).format("YYYY-MM-DD"),
        submittal_issueDate: moment(issueDate).format("YYYY-MM-DD "),
        submittal_finalDueDate: moment(fianlDueDate).format("YYYY-MM-DD "),
        submittal_submittalType: type,
        submittal_submittalPackage: SubmittalPackage,
        submittal_responsibleContractor: contractor,
        submittal_receivedFrom: userName,
        submittal_submittalManager: manager,
        // submittal_status: "open",
        submittal_costCode: costCode,
        submittal_location: location?.trim(),
        submittal_linkedDrawings: linkedDrawing,
        submittal_distributionList: distributionList,
        submittal_description: discrition,
        submittal_file: fileData,
        project_id: projectId,
        reviewer_id: reviewer || null,
        approver_id: approver || null,
        // email_data: emailData,
        workflow_status: workFlow,
        // approval_status: approverStatus,
        received_from: receivedFrom,
        hourSpent: hoursSpent,
        email_data: emailData,
        creator_email: getUserId(),
      };
      console.log(payload);
      // return
      if (payload.reviewer_id && payload.approver_id) {
        payload.submittal_status = "inreview";
      } else {
        if (allData.submittal_status) {
          //create

          payload.submittal_status = allData?.submittal_status;
        } else {
          payload.submittal_status = "draft";
          //edit
        }
      }

      const res = await addSubmittal(payload);
      console.log(res);
      //  setLoader(true)
      if (res?.data?.message === "Documnet number Already Exist") {
        toast.error(
          "Document number already exists. Please use a different number."
        );
      } else {
        toast.success("Submittal Created Successfully!");
        navigate(-1);
      }
      // setLoader(false)
    } catch (error) {
      if (error.response?.data?.message === "Documnet number Already Exist") {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred.");
        console.error("Error details:", error);
      }
    }
  }

  const submittals = [
    {
      id: 1,
      type: "Design Proposal",
    },
    {
      id: 2,
      type: "Engineering Report",
    },
    {
      id: 3,
      type: "Construction Drawings",
    },
    {
      id: 4,
      type: "Material Submittal",
    },
  ];

  const distributionsList = [
    {
      list_id: 1,
      list_name: "Engineering Team",
    },
    {
      list_id: 2,
      list_name: "Design Team",
    },
    {
      list_id: 3,
      list_name: "Project Management Team",
    },
    {
      list_id: 4,
      list_name: "Quality Assurance Team",
    },
  ];

  // const submittalPackages = [
  //   {
  //     package_id: 1,
  //     package_name: "Design Proposal Package",
  //   },
  //   {
  //     package_id: 2,
  //     package_name: "Material Submittal Package",
  //   },
  //   {
  //     package_id: 3,
  //     package_name: "Engineering Report Package",
  //   },
  //   {
  //     package_id: 4,
  //     package_name: "Construction Drawings Package",
  //   },
  // ];
  const specSections = [
    {
      section_id: 1,
      section_name: "Structural Specifications",
    },
    {
      section_id: 2,
      section_name: "Electrical Specifications",
    },
    {
      section_id: 3,
      section_name: "Plumbing Specifications",
    },
    {
      section_id: 4,
      section_name: "HVAC Specifications",
    },
  ];

  const workFlowOption = [
    { label: "Not Started", value: "Not Started" },
    { label: "In Progress", value: "In Progress" },
    { label: "Completed", value: "Completed" },
    { label: "Ready for Review", value: "Ready for Review" },
    { label: "Incomplete", value: "Incomplete" },
  ];

  const approval = [
    { label: "Draft", value: "Draft" },
    { label: "Reviewed", value: "Reviewed" },
    { label: "Approved", value: "Approved" },
  ];

  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  //getting edit data
  async function fetchDataById() {
    if (reqFor !== "edit") {
      return;
    }

    try {
      setSkeletonLoader(true);
      const res = await getSubmittalById(submittalId);
      const data_array = res?.data?.data?.[0] || {};

      let approverData = data_array?.approver[0]?._id || null;
      let reviewerData = data_array?.reviewer[0]?._id || null;

      setOldApproverReviewwerData({
        approver: approverData?._id || null,
        reviewer: reviewerData?._id || null,
      });
      setApproverComment(data_array?.approver_comment);
      setReviewerComment(data_array?.reviewer_comment);
      setApprover(approverData);
      setReviewer(reviewerData);
      setAllData(data_array);

      setSubmittalData(data_array);
      setOldSubmittal_review_status(data_array?.submittal_review_status);
      setSubject(data_array?.submittal_title);
      costCodeList(data_array);

      setContractor(
        data_array?.submittal_responsibleContractor?.map((res) => res?._id)
      );

      setManager(data_array.submittal_submittalManager?.map((e) => e?._id));

      setWorkFlow(data_array?.workflow_status);
      // setApproverStatus(data_array?.approval_status);

      CompanyandPerson(data_array);
      setSpec(data_array?.submittal_specSection);
      setNumber(data_array?.submittal_number);
      setRevision(data_array?.submittal_revision);
      setSubmitedDate(
        moment(data_array?.submittal_submitBy, "YYYY-MM-DD").format(
          "yyyy-MM-DD"
        )
      );
      setReceivedDate(
        moment(data_array?.submittal_receivedDate, "YYYY-MM-DD").format(
          "yyyy-MM-DD"
        )
      );
      setIssueDate(
        moment(data_array?.submittal_issueDate, "YYYY-MM-DD").format(
          "yyyy-MM-DD"
        )
      );
      setFianlDueDate(
        moment(data_array?.submittal_finalDueDate, "YYYY-MM-DD").format(
          "yyyy-MM-DD"
        )
      );
      setType(data_array?.submittal_submittalType);
      setSubmittalPackage(data_array?.submittal_submittalPackage);
      setReceivedFrom(data_array?.received_from);
      setStatus(data_array?.submittal_status);
      setLocation(data_array?.submittal_location);
      setLinkedDrawing(data_array?.submittal_linkedDrawings);
      setDistributionList(data_array?.submittal_distributionList);
      setDiscription(data_array?.submittal_description);
      setFileName(
        data_array?.submittal_file.map((item) => ({
          name: item?.fileName,
          id: item?._id,
        }))
      );
      setPreviousFiles([...data_array?.submittal_file]);

      setHoursSpent(data_array?.hourSpent);
      let statuss = data_array.submittal_status;

      setOptions([
        {
          label: "On Hold",
          value: "onhold",
          disabled: !check("onhold", statuss),
        },
        {
          label: "Reopen",
          value: "reopen",
          disabled: !check("reopen", statuss),
        },
        {
          label: "Close",
          value: "close",
          disabled: !check("close", statuss),
        },
        {
          label: "Open",
          value: "open",
          disabled: !check("open", statuss),
        },
      ]);
    } catch (error) {
      console.log(error, "error loading data");
    } finally {
      setSkeletonLoader(false);
    }
  }

  useEffect(() => {
    fetchDataById();
  }, []);

  //handle edit
  async function handleEdit() {
    if (fileUpload.length > 10) {
      toast.info("You can add only 10 attachments");
      return;
    }
    if (!checkValidation()) {
      return;
    }
    setLoader(true);
    try {
      const formData = new FormData();
      for (const file of fileUpload) {
        formData.append("upload", file);
      }

      // const uploadResponse = await uploadDocs(formData);
      // const fileData = uploadResponse.data.map((item) => ({
      //   fileName: item.fileName,
      //   url: item.fileUrl,
      // }));
      let fileData = [];
      if (fileUpload.length != 0) {
        const uploadResponse = await uploadDocs(formData);
        fileData = uploadResponse.data.map((item) => ({
          fileName: item.fileName,
          url: item.fileUrl,
        }));
      }

      let emailData = [];
      if (reviewer) {
        let approverData = allUsersOfProject.find((e) => e._id == reviewer);

        emailData.push({
          to: approverData?.email_address,
          name: approverData?.first_name,
          tool_number: number,
          tool_name: subject,
          project_name: getProjectName(),
          project_id: getProjectCode(),
          created_by: getUserName(),
          tool: "Submittal",
          subject: `FYI: Submittal updated in ${getProjectName()}`,
          url: `${domain}/projectManagement/submittals`,
          template_type: "emailToAllUserWhileUpdatingToolData",
          updated_by: getUserName(),
        });
      }
      console.log(emailData.url)
      if (approver) {
        let reviewerData = allUsersOfProject.find((e) => e._id == approver);
        emailData.push({
          to: reviewerData.email_address,
          name: reviewerData.first_name,
          tool_number: number,
          tool_name: subject,
          subject: `FYI: Submittal  updated in ${getProjectName()}`,
          project_name: getProjectName(),
          project_id: getProjectCode(),
          created_by: getUserName(),
          tool: "Submittal",
          url: `${domain}/projectManagement/submittals`,
          template_type: "emailToAllUserWhileUpdatingToolData",
          updated_by: getUserName(),
        });
      }
      manager?.forEach((userId) => {
        let reviewerData = allUsersOfProject.find((e) => e._id == userId);
        if (reviewerData) {
          emailData.push({
            to: reviewerData?.email_address,
            name: reviewerData?.first_name,
            tool_number: number,
            tool_name: subject,
            project_name: getProjectName(),
            project_id: getProjectCode(),
            created_by: getUserName(),
            tool: "Submittal",
            subject: `FYI: Submittal updated in ${getProjectName()}`,
            userType: "Submittal Manager",
            url: `${domain}/projectManagement/submittals`,
            template_type: "emailToAllUserWhileUpdatingToolData",
            updated_by: getUserName(),
          });
        }
      });
      console.log(emailData);
      // return
      const payload = {
        submittal_title: subject?.trim(),
        submittal_specSection: spec,
        submittal_number: number,
        submittal_revision: revision?.trim(),
        submittal_submitBy: moment(submitedDate).format("YYYY-MM-DD"),
        submittal_receivedDate: moment(receivedDate).format("YYYY-MM-DD"),
        submittal_issueDate: moment(issueDate).format("YYYY-MM-DD"),
        submittal_finalDueDate: moment(fianlDueDate).format("YYYY-MM-DD"),
        submittal_submittalType: type,
        submittal_submittalPackage: SubmittalPackage,
        submittal_responsibleContractor: contractor || contractor,
        submittal_receivedFrom: receivedFrom,
        submittal_submittalManager: manager,
        // submittal_status: status,
        submittal_review_status:
          oldSubmittal_review_status === "pending"
            ? status?.toLowerCase() === "close"
              ? "under_review"
              : "pending"
            : oldSubmittal_review_status,
        submittal_costCode: costCode,
        submittal_location: location?.trim(),
        submittal_linkedDrawings: linkedDrawing,
        submittal_distributionList: distributionList,
        submittal_description: discrition,
        submittal_file: [...previousFiles, ...fileData],
        model_deleted_file: removePhoto,
        userName: userName,
        userId: userId,
        user_name:getUserName() || "",
        reviewer_id: reviewer || null,
        approver_id: approver || null,
        email_data: emailData,
        creator_email: getUserId(),
        workflow_status: workFlow,
        // approval_status: approverStatus,
        received_from: receivedFrom,
        hourSpent: hoursSpent,
        project_id: projectId,
        created_by: userName,
        project_name: projectData.project_name,
        project_number: projectData.projectNumber,
      };
      // return
      if (
        payload?.reviewer_id &&
        payload?.approver_id &&
        !allData?.submittal_status
      ) {
        payload.submittal_status = "inreview";
      } else {
        if (allData?.submittal_status) {
          //create

          payload.submittal_status = allData?.submittal_status;
        } else {
          payload.submittal_status = "draft";
          //edit
        }
      }
      

      const res = await updateSubmittalById(submittalId, payload);
      setBtnLoader(true);
      if (res?.data?.message === "Documnet number Already Exist") {
        toast.error(
          "Document number already exists. Please use a different number."
        );
      } else {
        toast.success("Submittal Update Successfully!");
        setBtnLoader(false);
        navigate(-1);
      }
    } catch (error) {
      if (error.response?.data?.message === "Documnet number Already Exist") {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred.");
        console.error("Error details:", error);
      }
    }
  }

  function check(option, status) {
    switch (status) {
      case "open":
        return ["close", "onhold"].includes(option);
      case "reopen":
        return ["close", "onhold"].includes(option);
      case "onhold":
        return ["close"].includes(option);
      case "close":
        return ["close"].includes(option);
      default:
        return true;
    }
  }

  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId);
      let allUsers = res?.data?.data?.[0]?.combined_users || [];
      setAllUsersOfProject(allUsers);
      if (reqFor === "edit") {
        setAllUsersOfProjectStatus(allUsers);
      } else {
        setAllUsersOfProjectStatus(allUsers)
      }
      // console.log(allUsers, "================================================");
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllUsersOfProject();
    if (reqFor !== "edit") {
      getCountForSubmital();
    }
  }, []);

  const handleCreateAddIcon = async (type) => {
    try {
      if (type === "category" && !submittalCategory) {
        toast.info(
          "Please fill  the  Submittal Category field marked with * are mandatory"
        );
        return;
      }

      if (type === "package" && !submittalPackage) {
        toast.info(
          "Please fill  the  Submittal Package field marked with * are mandatory"
        );
        return;
      }

      if (type === "type" && !submittalType) {
        toast.info(
          "Please fill  the Submittal Type field marked with * are mandatory"
        );
        return;
      }

      if (type === "category") {
        setLoading(true);
        await addSubmittalCategory({
          name: submittalCategory,
          project_id: projectId,
        });
        setSubmittalCategory("");
        getByProjectId(projectId);
        setVisible(false);
        setLoading(false);
      } else if (type === "package") {
        setLoading(true);
        await addSubmittalPackage({
          name: submittalPackage,
          project_id: projectId,
        });
        setSubmittalPackages("");
        setPackageVisible(false);
        getByProjectId(projectId);
        setLoading(false);
      } else if (type === "responsible") {
        setLoading(true);
        await addRespoSubmittals({
          name: addRespo,
          project_id: projectId,
        });
        setAddRespo("");
        setResVisible(false);
        getByProjectId(projectId);
        setLoading(false);
      } else {
        setLoading(true);
        await addSubmittalType({ type: submittalType, project_id: projectId });
        setSubmitallType("");
        setTypeVisible(false);
        getByProjectId(projectId);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getByProjectId = async (id) => {
    try {
      const results = await Promise.allSettled([
        getSubmittalCategory(id),
        getDrawingByProjectId(id),
        getSubmittalPackage(id),
        getSubmittalType(id),
        getAllRespoSubmittalsById(id),
      ]);

      const [
        getSubmittalCategoryById,
        getDrawingProjectId,
        fatchSubmittalPackage,
        fatchSubmitallType,
        getRespoSubmiattalDataById,
      ] = results;

      if (getSubmittalCategoryById?.status === "fulfilled") {
        setGetSubmittalCategorys(getSubmittalCategoryById.value?.data?.data);
      } else {
        console.log(
          "Error in getSubmittalCategory:",
          getSubmittalCategoryById.reason
        );
      }

      if (getDrawingProjectId?.status === "fulfilled") {
        setGetDrawingWithProjectId(getDrawingProjectId.value?.data?.data);
      } else {
        console.log(
          "Error in getDrawingByProjectId:",
          getDrawingProjectId?.reason
        );
      }

      if (fatchSubmittalPackage.status === "fulfilled") {
        setGetSubmittalPackage(fatchSubmittalPackage?.value?.data?.data);
      } else {
        console.log(
          "Error in getSubmittalPackage:",
          fatchSubmittalPackage.reason
        );
      }

      if (fatchSubmitallType.status === "fulfilled") {
        setGetSubmitallType(fatchSubmitallType?.value?.data?.data);
      } else {
        console.log("Error in getSubmittalType:", fatchSubmitallType.reason);
      }

      if (getRespoSubmiattalDataById.status === "fulfilled") {
        setGetSubmitallRespo(getRespoSubmiattalDataById?.value?.data?.data);
      } else {
        console.log(
          "Error in getRespoSubmiattalDataById ",
          getRespoSubmiattalDataById?.error
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCountForSubmital = async () => {
    try {
      let res = await getCountForSubmitalByProjectId(getProjectId());
      setNumber(res?.data?.data);
    } catch (Err) {
      console(Err);
    }
  };
  // console.log(getCount, "getCount");

  useEffect(() => {
    if (projectId) {
      getByProjectId(projectId);
    }
  }, []);

  const getcompany = async () => {
    try {
      const res = await getCompanyByProjectId(projectId);

      if (res && res?.data) {
        const companyNames = res?.data?.data[0]?.companynames;
        // console.log(companyNames, "companynames-------");

        const formattedCompanies = companyNames?.map((company) => ({
          label: company?.company_name,
          value: company?._id,
        }));

        setCompanies(formattedCompanies);
      } else {
        console.log("Response data is not in the expected format");
      }
    } catch (error) {
      console.log("Error fetching company data:", error);
    }
  };
  useEffect(() => {
    getcompany();
  }, []);

  function convertHourlySpentStringIntoObject(str) {
    // console.log(str, "yyyyyyyy");
    if (typeof str === "string") {
      return {
        hours: parseInt(str?.split("-")[0]) || "00",
        minutes: parseInt(str?.split("-")[1]) || "00",
      };
    } else {
      return {
        hours: "0",
        minutes: "0",
      };
    }
  }

  const capitalizeFirstLetter = (str) => {
    let typeCap = str?.split(" ");
    let capitalizedWords = typeCap?.map((word) => {
      return word?.charAt(0)?.toUpperCase() + word?.slice(1);
    });
    let result = capitalizedWords?.join(" ");
    return result;
    console.log(result);
  };
  return (
    <>
      <div className=" main-container">
        <div>
          <FormHeader
            to={"/projectManagement/submittals"}
            formHeader={"Submittals"}
            breadcrumItem={["Submittals", `${reqFor === "edit" ? "Edit Submittal" : "Create Submittal"}`]} />

          {/* Top Create New Rfi End */}
          {loader ? (
            <Loader />
          ) : (
            <div>


              {/*  New section End */}

              {skeletonLoader ? (
                <FormSkeleton data={skeletonData} />
              ) : (
                <>
                  <div className="row m-0 p-0">
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Title: <span className="text-danger">*</span>
                      </label>
                      <TextInput
                        placeholder="Title"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        className={validation.subject ? "Validation" : ""}
                        onClick={() =>
                          setValidation({ ...validation, subject: false })
                        }
                      />
                    </div>
                    <div className="col-md-6 d-flex ">
                      <div className="maindiv_plusIcon">
                        <label htmlFor="" className="py-2 labelCss">
                          Submittal Category:{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <SingleSelect
                          options={getSubmittalCategorys?.map((item) => ({
                            label: capitalizeFirstLetter(item?.name),
                            value: item?.name,
                          }))}
                          value={spec}
                          onChange={(e) => {
                            setSpec(e?.value);
                            setValidation({ ...validation, spec: false });
                          }}
                          className={`p-0 ${validation.spec ? "Validation" : ""
                            }`}
                          onClick={() =>
                            setValidation({ ...validation, spec: false })
                          }
                          filterInputAutoFocus={true}
                          filter={true}
                          placeholder="Select  Submittal Category"
                        />
                      </div>
                      <span className="ml-3 mt-5">
                        <MdAdd
                          className="h2"
                          style={{ cursor: "pointer" }}
                          onClick={() => setVisible(true)}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="row mt-3 p-0 m-0">
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Document Number <span className="text-danger">*</span>
                      </label>

                      <input
                        type="number"
                        inputId="integeronly"
                        placeholder="Number"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        className={`${validation.number ? "Validation" : ""
                          } form-control common_input`}
                        onClick={() =>
                          setValidation({ ...validation, number: false })
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Document Version <span className="text-danger">*</span>
                      </label>
                      <TextInput
                        placeholder="Document Version"
                        value={revision}
                        onChange={(e) => setRevision(e.target.value)}
                        className={validation.revision ? "Validation" : ""}
                        onClick={() =>
                          setValidation({ ...validation, revision: false })
                        }
                      >
                        {" "}
                      </TextInput>
                    </div>
                  </div>
                  {/*  calender  section start */}

                  {/*  calender section start */}
                  <div className="row mt-3 p-0 m-0 gap-0">
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Submittal Create Date{" "}
                        <span className="text-danger">*</span>
                      </label>
                      {/* <Calender
              value={submitedDate}
              className={`p-0 ${validation.submitedDate ? "Validation" : ""
                }`}
              onClick={() =>
                setValidation({ ...validation, submitedDate: false })
              }
              placeholder="dd/mm/yy"
              selected={submitedDate}
              onChange={(e) => setSubmitedDate(e.target.value)}
              readOnlyInput={false}
            /> */}
                      <input
                        type="date"
                        style={{ height: "50px" }}
                        value={moment(submitedDate, "YYYY-MM-DD")?.format(
                          "yyyy-MM-DD"
                        )}
                        className={` ${validation.submitedDate
                          ? "Validation"
                          : "form-control"
                          }`}
                        onClick={() =>
                          setValidation({ ...validation, submitedDate: false })
                        }
                        placeholder="dd/mm/yy"
                        selected={submitedDate}
                        onChange={(e) => setSubmitedDate(e.target.value)}
                        readOnlyInput={false}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Document Receive date :{" "}
                        <span className="text-danger">*</span>
                      </label>
                      {/* <Calender
              className={`p-0 ${validation.receivedDate ? "Validation" : ""
                }`}
              onClick={() =>
                setValidation({ ...validation, receivedDate: false })
              }
              placeholder="dd/mm/yy"
              selected={receivedDate}
              readOnlyInput={false}
              value={receivedDate}
              onChange={(e) => setReceivedDate(e.target.value)}
            /> */}
                      <input
                        type="date"
                        style={{ height: "50px" }}
                        className={` ${validation.receivedDate
                          ? "Validation p-2"
                          : "form-control"
                          }`}
                        onClick={() =>
                          setValidation({ ...validation, receivedDate: false })
                        }
                        placeholder="dd/mm/yy"
                        selected={receivedDate}
                        readOnlyInput={false}
                        value={moment(receivedDate, "YYYY-MM-DD")?.format(
                          "yyyy-MM-DD"
                        )}
                        onChange={(e) => setReceivedDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row mt-3 p-0 m-0">
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Document Issue date:{" "}
                        <span className="text-danger">*</span>
                      </label>
                      {/* <Calender
              className={`p-0 ${validation.issueDate ? "Validation" : ""
                }`}
              onClick={() =>
                setValidation({ ...validation, issueDate: false })
              }
              placeholder="dd/mm/yy"
              selected={issueDate}
              value={issueDate}
              readOnlyInput={false}
              onChange={(e) => setIssueDate(e.target.value)}
            /> */}

                      <input
                        type="date"
                        style={{ height: "50px" }}
                        className={` ${validation.issueDate
                          ? "Validation p-2"
                          : "form-control"
                          }`}
                        onClick={() =>
                          setValidation({ ...validation, issueDate: false })
                        }
                        placeholder="dd/mm/yy"
                        selected={issueDate}
                        value={moment(issueDate, "YYYY-MM-DD")?.format(
                          "yyyy-MM-DD"
                        )}
                        readOnlyInput={false}
                        onChange={(e) => {
                          setIssueDate(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Final Due Date: <span className="text-danger">*</span>
                      </label>
                      {/* <Calender
              className={`p-0 ${validation.fianlDueDate ? "Validation" : ""
                }`}
              onClick={() =>
                setValidation({ ...validation, fianlDueDate: false })
              }
              value={fianlDueDate}
              placeholder="dd/mm/yy"
              selected={fianlDueDate}
              readOnlyInput={false}
              onChange={(e) => setFianlDueDate(e.target.value)}
            /> */}
                      <input
                        type="date"
                        style={{ height: "50px" }}
                        className={` ${validation.fianlDueDate
                          ? "Validation p-2"
                          : "form-control"
                          }`}
                        onClick={() =>
                          setValidation({ ...validation, fianlDueDate: false })
                        }
                        value={moment(fianlDueDate, "YYYY-MM-DD")?.format(
                          "yyyy-MM-DD"
                        )}
                        placeholder="dd/mm/yy"
                        selected={fianlDueDate}
                        readOnlyInput={false}
                        onChange={(e) => setFianlDueDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row mt-3 p-0 m-0">
                    <div className="col-md-6 d-flex">
                      <div className="maindiv_plusIcon">
                        <label htmlFor="" className="py-2 labelCss">
                          Submittal Package:{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <AutoCompleteSelect
                          placeholder="Submittal Package"
                          options={getSubmittalpackage?.map((item) => ({
                            label: capitalizeFirstLetter(item?.name),
                            value: item?.name,
                          }))}
                          optionLabel="type"
                          value={SubmittalPackage}
                          onChange={(value) => {
                            setSubmittalPackage(value);
                            setValidation({
                              ...validation,
                              SubmittalPackage: false,
                            });
                          }}
                          // filterInputAutoFocus={true}
                          // filter={true}
                          className={
                            validation.SubmittalPackage ? "Validation" : ""
                          }
                          onClick={() =>
                            setValidation({
                              ...validation,
                              SubmittalPackage: false,
                            })
                          }
                        />
                      </div>
                      <div className="ml-3 mt-5 ">
                        {" "}
                        <MdAdd
                          className="h2"
                          style={{ cursor: "pointer" }}
                          onClick={() => setPackageVisible(true)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6  d-flex  flex-column ">
                      <label htmlFor="" className="py-2 labelCss">
                        Responsible Contractor(s):{" "}
                      </label>
                      <MultiSelect
                        placeholder="Responsible Contractor"
                        options={companies}
                        value={Array.isArray(contractor) ? contractor : []}
                        onChange={(e) => setContractor(e.value)}
                        filter
                      />
                    </div>
                  </div>

                  <div className="row mt-3 p-0 m-0">
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Received From: <span className="text-danger">*</span>
                      </label>
                      <TextInput
                        placeholder="Receiver"
                        value={receivedFrom}
                        onChange={(e) => setReceivedFrom(e.target.value)}
                        className={validation.receivedFrom ? "Validation" : ""}
                        onClick={() =>
                          setValidation({ ...validation, receivedFrom: false })
                        }
                      >
                        {" "}
                      </TextInput>
                      {/* <SingleSelect
            className={`p-0 ${
              validation.receivedFrom ? "Validation" : ""
            }`}
            onClick={() =>
              setValidation({ ...validation, receivedFrom: false })
            }
            placeholder="Received From"
            options={ReceivedFrom}
            optionLabel="entity_name"
            value={receivedFrom}
            onChange={(e) => setReceivedFrom(e.target.value)}
          /> */}
                    </div>
                    <div className="col-md-6 d-flex  flex-column ">
                      <label htmlFor="" className="py-2 labelCss">
                        Submittal Manager(s):
                      </label>

                      <MultiSelect
                        value={manager}
                        onChange={(e) => setManager(e.value)}
                        options={allUsersOfProject.map((item) => ({
                          label: item?.first_name,
                          value: item?._id,
                        }))}
                        optionLabel="label"
                        filter
                        placeholder="Select Manager"
                      />
                    </div>
                  </div>

                  <div className="row mt-3 m-0 p-0">
                    {/* {reqFor === "edit" ? (
            <div className="col-md-12">
              <label htmlFor="" className="py-2 labelCss">
                Status: <span className="text-danger">*</span>
              </label>
              <SingleSelect
                placeholder="Status"
                options={options}
                optionLabel="label"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className={validation.status ? "Validation" : ""}
                disabled={
                  submittalData.submittal_review_status == "approved" ||
                  submittalData.submittal_approval_status == "approved"
                    ? true
                    : false
                }
              />
            </div>
          ) : (
            ""
          )} */}

                    <div className={"col-md-6 "}>
                      <label htmlFor="" className="py-2 labelCss">
                        Cost Code:
                      </label>
                      <AutoCompleteSelect
                        placeholder="Cost Code"
                        options={costCodeData.map((item) => ({
                          label: item?.code_name,
                          value: item?.code_name,
                        }))}
                        optionLabel="code_name"
                        value={costCode}
                        onChange={(value) => {
                          setCostCode(value);
                          // console.log(e.target.value);
                          setValidation({ ...validation, costCode: false });
                        }}
                        className={validation.costCode ? "Validation" : ""}
                        onClick={() =>
                          setValidation({ ...validation, costCode: false })
                        }
                      />
                    </div>

                    <div className="col-md-6 ">
                      <label htmlFor="" className="py-2 labelCss">
                        WorkFlow's Status:
                      </label>
                      <SingleSelect
                        placeholder="WorkFlow's Status"
                        options={workFlowOption}
                        optionLabel="label"
                        value={workFlow}
                        onChange={(e) => {
                          setWorkFlow(e.target.value);
                        }}
                      />
                    </div>

                    {/* <div className="col-12 col-md-6 mt-3">
            <label htmlFor="" className="py-2 labelCss">
              Approval Status:
            </label>
            <SingleSelect
              placeholder="Approver Status"
              options={approval.map((item) => ({
                label: item?.label,
                value: item?.value,
              }))}
              optionLabel="label"
              value={approverStatus}
              onChange={(e) => setApproverStatus(e.target.value)}
            />
          </div> */}
                  </div>

                  <div className="row mt-3 m-0 p-0">
                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Location: <span className="text-danger">*</span>
                      </label>
                      <TextInput
                        placeholder="Location"
                        // options={location_data}
                        // optionLabel="location_name"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        className={validation.location ? "Validation" : ""}
                        onClick={() =>
                          setValidation({ ...validation, location: false })
                        }
                      />
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="py-2 labelCss">
                        Linked Drawings:
                      </label>
                      <SingleSelect
                        placeholder="Linked Drawings"
                        options={getDrawingWithProjectId?.map((item) => ({
                          label: item.drawing_name,
                          value: item.drawing_name,
                        }))}
                        value={linkedDrawing}
                        onChange={(e) => setLinkedDrawing(e.target.value)}
                        className={validation.linkedDrawing ? "Validation" : ""}
                        onClick={() =>
                          setValidation({ ...validation, linkedDrawing: false })
                        }
                        filter={true}
                        filterInputAutoFocus={true}
                      />
                    </div>

                    {/* {reqFor === "edit" ? (
            <div className="row mt-3  m-0 p-0">
              <div className="col-6">
                <label htmlFor="" className="py-2 labelCss">
                  Reviewer's Comments:{" "}
                  <span className="text-danger">*</span>
                </label>
                <TextInput placeholder="Reviewer's Comments" disabled />
              </div>

              <div className="col-6">
                <label htmlFor="" className="py-2 labelCss">
                  Approver 's Comments:{" "}
                  <span className="text-danger">*</span>
                </label>
                <TextInput
                  placeholder="Approver 's Comments"
                  disabled
                />
              </div>
            </div>
          ) : (
            ""
          )} */}
                  </div>

                  <div className="row mt-3 m-0 p-0">
                    <div className="col-md-6 d-flex">
                      <div className="maindiv_plusIcon">
                        <label htmlFor="" className="py-2 labelCss">
                          Responsible Team :
                        </label>
                        {/* <SingleSelect
              placeholder="Responsible team"
              options={distributionsList}
              optionLabel="list_name"
              value={distributionList}
              onChange={(e) => setDistributionList(e.target.value)}
            /> */}
                        <SingleSelect
                          placeholder="Responsible team"
                          options={getSubmitallRespo?.map((item) => ({
                            label: capitalizeFirstLetter(item?.name),
                            value: item?.name,
                          }))}
                          value={distributionList}
                          onChange={(e) => {
                            setDistributionList(e?.value);
                          }}
                          filter={true}
                          filterInputAutoFocus={true}
                        />
                      </div>
                      <div className="ml-3 mt-5">
                        <MdAdd
                          className="h2"
                          style={{ cursor: "pointer" }}
                          onClick={() => setResVisible(true)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div className="maindiv_plusIcon">
                        <label htmlFor="" className="py-2 labelCss">
                          Submittal Type: <span className="text-danger">*</span>
                        </label>

                        <AutoCompleteSelect
                          className={`p-0 ${validation.type ? "Validation" : ""
                            }`}
                          onClick={() =>
                            setValidation({ ...validation, type: false })
                          }
                          placeholder="Submittal Type"
                          optionLabel={"type"}
                          options={getSubmitallType?.map((item) => ({
                            label: capitalizeFirstLetter(item?.type),
                            value: item?.type,
                          }))}
                          value={type}
                          onChange={(value) => {
                            setType(value);
                            setValidation({ ...validation, type: false });
                          }}
                        />
                        {console.log(getSubmitallType)}
                      </div>

                      <div className="ml-3 mt-5">
                        {" "}
                        <MdAdd
                          className="h2"
                          style={{ cursor: "pointer" }}
                          onClick={() => setTypeVisible(true)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 p-0">
                    {isReviewerAccess && (
                      <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                          Reviewer:
                        </label>
                        <AutoCompleteSelect
                          options={allUsersOfProjectStatus?.map((item) => {
                            return {
                              label: item?.first_name,
                              value: item?._id,
                            };
                          })}
                          optionLabel="label"
                          value={reviewer}
                          onChange={(value) => setReviewer(value)}
                          placeholder="Reviewer"
                          // disabled={
                          //   reviewer === getUserId() ||
                          //   allData?.submittal_status == "approved"
                          // }
                        />
                      </div>
                    )}

                    {isApproverAccess && (
                      <div className="col-md-6 col-12">
                        <label htmlFor="" className="py-2 labelCss">
                          Approver:
                        </label>
                        <AutoCompleteSelect
                          placeholder="Approver"
                          options={allUsersOfProjectStatus?.map((item) => {
                            return {
                              label: item?.first_name,
                              value: item?._id,
                            };
                          })}
                          optionLabel="label"
                          value={approver}
                          onChange={(value) => setApprover(value)}
                          // disabled={
                          //   reviewer === getUserId() ||
                          //   allData?.submittal_status == "approved"
                          // }
                        />
                      </div>
                    )}
                    {reqFor === "edit" ? (
                      <>
                        <div className="row m-0 p-0 mt-3">
                          <div className="col-md-6 col-12">
                            <label htmlFor="" className="py-2 labelCss">
                              Reviewer Comment:
                            </label>
                            <TextInput
                              placeholder="Reviewer Comment"
                              value={reviewerComment}
                              // onChange={(e) => setReviewerComment(e.target.value)}
                              disabled
                            />
                          </div>
                          <div className="col-md-6 col-12">
                            <label htmlFor="" className="py-2 labelCss">
                              Approver Comment:
                            </label>
                            <TextInput
                              placeholder="Approver Comment"
                              value={approverComment}
                              // onChange={(e) => setApproverComment(e.target.value)}
                              disabled
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="col-12 col-md-6 mt-3">
                      <label htmlFor="" className="py-2 labelCss">
                        Hours Spent:
                      </label>
                      <TimeInput
                        value={convertHourlySpentStringIntoObject(hoursSpent)}
                        onChange={(newValue) =>
                          setHoursSpent(`${newValue.hours}-${newValue.minutes}`)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-3 m-0">
                    <div className="col-md-12 p-0">
                      <label htmlFor="" className="py-2 labelCss">
                        Description :
                      </label>
                      <div className="card">
                        <Editor
                          style={{ height: "200px" }}
                          value={discrition}
                          onTextChange={(e) => setDiscription(e.htmlValue)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row m-0 mt-3">
                    <div className="col-12 p-0">
                      <DragAndDrop
                        placeholder={"Attach File(s)"}
                        // label={"Attach File(s) :"}
                        value={fileName}
                        setFiles={setFileUpload}
                        onChange={handleFileSubmit}
                        setValue={setFileName}
                        setPreviousFiles={setPreviousFiles}
                        setRemovePhoto={setRemovePhoto}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="btn_main mt-4 ">
                <span
                  className="cancelBtn  px-3 py-2"
                  onClick={() => navigate("/projectManagement/submittals")}
                >
                  {" "}
                  Cancel
                </span>
                <span
                  loader={btnLoader}
                  className="CreateRfi px-3 py-2"
                  onClick={() => {
                    reqFor === "edit" ? handleEdit() : handleSubmit();
                  }}
                >
                  {reqFor === "edit" ? "Update" : "Create"}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <br />

      <AddSets
        visible={visible}
        loading={loading}
        setLoading={setLoading}
        setVisible={setVisible}
        CategoryName={"Submittal Category "}
        label={"Category"}
        reqFor={"create"}
        value={submittalCategory}
        setValue={setSubmittalCategory}
        onSubmit={() => handleCreateAddIcon("category")}
      />

      <AddSets
        visible={PackageVisible}
        loading={loading}
        setLoading={setLoading}
        setVisible={setPackageVisible}
        CategoryName={"Submittal Package "}
        label={"Submittal Package"}
        reqFor={"create"}
        setValue={setSubmittalPackages}
        value={submittalPackage}
        onSubmit={() => handleCreateAddIcon("package")}
      />

      <AddSets
        loading={loading}
        setLoading={setLoading}
        visible={typeVisible}
        setVisible={setTypeVisible}
        CategoryName={"Submittal Type"}
        label={"Submittal Type"}
        reqFor={"create"}
        onSubmit={() => handleCreateAddIcon("type")}
        setValue={setSubmitallType}
        value={submittalType}
      />
      <AddSets
        loading={loading}
        setLoading={setLoading}
        visible={resVisible}
        setVisible={setResVisible}
        CategoryName={"Responsible Team"}
        label={"Responsible team"}
        reqFor={"create"}
        onSubmit={() => handleCreateAddIcon("responsible")}
        setValue={setAddRespo}
        value={addRespo}
      />
    </>
  );
};

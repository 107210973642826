import React, { useState, useEffect, useRef } from "react";
import "./dailyLog.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { CSVLink } from "react-csv";
import Popover from "../../Components/Common/Popover";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportPdfButton } from "../../Components/Buttons/ExportPdfButton";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { useNavigate, useNavigation } from "react-router-dom";
import { deleteDailyLogData, getAllDailyLogData, updateDailyLogData } from "../../Api/Admin/AllAPIs";
import { getActionsPermission, getProjectCode, getProjectId, getProjectName, showDateAndTime, toast } from "../../utils";
import moment from "moment/moment";
import DeleteDialog from "../../Components/Common/DeleteDialog";
import Pagination from "../../Components/Common/Pagination/Pagination";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import { DownloadPdfAndSendEmail } from "../../Components/Common/ViewPageComponents/DownloadPdfAndSendEmail";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import Filter from "./Filter.jsx"
import { DocumentViewer } from "../../Components/Common/PagesComponents/DocumentViewer.jsx";
import { ChangeApprovalStatus } from "../../Components/Tools/ChangeApprovalStatus.jsx";
import { CDC_LOGO_URL } from "../../constants.js";


export default function DailyLog() {

  const [filteredData, setFilteredData] = useState({});
  const [dailyLogData, setDailyLogData] = useState([])
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [dailyLogId, setDailyLogId] = useState(null)
  const [totalCount, setTotalCount] = useState(0)
  const [skeltonLoader, setSkeltonLoader] = useState(false)
  const projectId = localStorage.getItem('projectId')
  const userData = localStorage.getItem("userData");
  const userObj = JSON.parse(userData);
  const userId = userObj._id;
  const userName = userObj.name;
  const [tableSortData, setTableSortData] = useState({
    page: 1,
    search: ""
  })


  const perPageData = 10;

  let navigate = useNavigate();


  const csvHeaders = [
    { label: 'Visitor Name', key: 'Visitor Name' },
    { label: 'VisitID', key: 'VisitID' },
    { label: 'Hours Spent', key: 'Hours Spent' },
    { label: 'Planned Start Date/Time', key: 'Actual End Date/Time' },
    { label: 'Planned End Date/Time', key: 'Actual End Date/Time' },
    { label: 'Actual Start Date/Time', key: 'Actual Start Date/Time' },
    { label: 'Actual End Date/Time', key: 'Actual End Date/Time' },
    { label: 'Visitor Status', key: 'Visitor Status' },
    { label: 'Visit Purpose', key: 'Visit Purpose' },
    { label: 'Visitor Company Name', key: 'Visitor Company Name' },
    { label: 'Visitor Company Type', key: 'Visitor Company Type' },
    { label: 'Company Address', key: 'Company Address' },
    { label: 'Approver', key: 'Approver' },
    { label: 'Reviewer', key: 'Reviewer' },

  ];


  const tableRef = useRef();

  const exportToPDF = () => {

    const doc = new jsPDF();



    const imgData = CDC_LOGO_URL
    const imgW = 20;
    const imgH = 10;
    const imageX = 5;
    const imageY = 5;
    doc.addImage(imgData, 'JPEG', imageX, imageY, imgW, imgH);
    const projectName = `${getProjectName()} (Project Id: ${getProjectCode() || "-"})`;
    const textX = imageX + imgW + 6;
    const textY = imageY + 7.5;
    // Add project name text to the PDF
    doc.setFontSize(16);
    doc.text(projectName, textX, textY);



    doc.autoTable({
      html: tableRef.current,
      startY: 30,
      didParseCell: function (data) {
        data.cell.styles.fontSize = 2;
      },
      didDrawPage: function (data) {

      },
    });

    // Save the PDF
    doc.save('dailylog.pdf');
  };

  useEffect(() => {

    getAllDailyLog()

  }, [tableSortData])

  async function getAllDailyLog() {
    try {
      let query = {
        page: tableSortData.page,
        perPageData,
        search: tableSortData.search
      }

      let body = {
        visitorCompanyName: tableSortData?.visitorCompanyName,
        visitStatus: tableSortData?.visitStatus
      }

      setSkeltonLoader(true)
      let res = await getAllDailyLogData(getProjectId(), query, body)
      setDailyLogData(res.data.data.map(e => ({ ...e, approverName: e?.approver?.first_name, reviewerName: e?.reviewer?.first_name })))
      setTotalCount(res?.data?.totalCount || 0)
      console.log(res)
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setSkeltonLoader(false)
    }
  }

  function searchValue(e) {
    setTableSortData(pre => ({
      ...pre,
      page: 1,
      search: e.target.value
    }))
  }

  async function confirmDelete() {
    console.log(dailyLogId)
    let payload = {
      created_by: userName,
    }
    try {
      let res = await deleteDailyLogData(dailyLogId, payload)
      setDailyLogData(pre => pre.filter(e => e._id !== dailyLogId))

    }
    catch (e) {
      console.log(e)
    }
  }

  const skeletonData = [
    { qty: 1, height: "2.5rem" },
    { qty: 1, height: "2.5rem" },
    { qty: 1, height: "1.4rem" },
    { qty: 1, height: "1.4rem" }

  ];

  const csvData = dailyLogData.map(item => ({
    "VisitID": item?.dailyLogId,
    "Visitor Name": item?.visitorName,
    "Hours Spent": `${item?.hoursSpent?.split("-")[0]} Hr ${item?.hoursSpent?.split("-")[1]} Mins`,
    "Actual Start Date/Time": showDateAndTime(item?.actualStartDate),
    "Actual End Date/Time": showDateAndTime(item?.actualEndDate),
    "Planned Start Date/Time": showDateAndTime(item?.plannedStartDate),
    "Planned End Date/Time": showDateAndTime(item?.plannedEndDate),
    "Visitor Status": item.visitStatus,
    "Visit Purpose": item.visitPurpose,
    "Visitor Company Name": item.visitorCompanyName,
    "Visitor Company Type": item.companyType,
    "Company Address": item.companyAddress,
    "Approver": item.companyAddress,
    "Reviewer": item.companyAddress

  }));

  function filterData(e) {

    setTableSortData(pre => ({ ...pre, ...filteredData }))
  }


  async function statusChange(data, _id, toolId, comment) {

    try {
      let res = await updateDailyLogData(_id, { ...data, ...comment })
      getAllDailyLog()
    } catch (error) {

    }
  }

  return (
    <>
      <div className="dailyLog">
        <div className="parent">
          <div className="body d-flex flex-column">
            <div className="header d-flex flex-column">
              <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center">
                <h1 className="page_heading">DAILY LOG</h1>
                <div className="d-flex text-nowrap justify-content-end gap-2 mt-sm-0 mt-2">
                  <div className="right d-flex gap-2 align-items-center">
                    <ExportPdfButton exportToPDF={exportToPDF}
                      csvBtn={<CSVLink
                        className="pe-1"
                        data={csvData}
                        headers={csvHeaders}
                        filename={"dailylog.csv"}
                      >
                        CSV
                      </CSVLink>
                      }
                    />

                  </div>

                  {getActionsPermission("Daily log") && <PrimaryButton
                    className={`primararyButton`}
                    addIcon
                    text={"Create"}
                    onClick={() => {
                      navigate("create")
                    }}
                  />
                  }
                </div>
              </div>

              <div className="row ">
                <div className="col-md-3 searchInput">
                  <SearchInput value={tableSortData.search} onCancel={() => setTableSortData(pre => ({ ...pre, page: 1, search: '' }))} onChange={searchValue} />
                </div>
                <div className="col-md-9 d-flex justify-content-end">

                  <Filter
                    filterData={filteredData}
                    setFilterData={setFilteredData}
                    onFilter={filterData}

                  />

                  <button className={` ${Object.keys(filteredData).length === 0 && 'disabled'}  filter_btn ms-2`} onClick={() => {
                    setTableSortData({ page: 1, search: "" })
                    setFilteredData({})
                  }
                  }
                    disabled={Object.keys(filteredData).length === 0}
                  >Clear Filter</button>
                </div>
              </div>
            </div>




            <div className="logTable w-100 d-flex flex-column">
              <div>
                <h1 className="logName px-2">Visitors</h1>
              </div>

              <div className="react_table table_scroll_daily_log">

                {skeltonLoader ?
                  <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} /> :


                  <DataTable
                    value={dailyLogData}

                  >
                    <Column headerClassName="text-nowrap" field="dailyLogId" sortable header="VisitID"></Column>
                    <Column headerClassName="text-nowrap" field="visitorName" sortable header="Visitor Name"></Column>
                    <Column headerClassName="text-nowrap" field="visitorCompanyName" sortable header="Visitor Company Name"></Column>
                    <Column headerClassName="text-nowrap" field="visitPurpose" sortable header="Visit Purpose"></Column>
                    <Column headerClassName="text-nowrap" field="visitStatus" sortable header="Visit Status"></Column>
                    <Column
                      headerClassName="text-nowrap"
                      sortable
                      field="actualStartDate"
                      body={(rowData) => {
                        return `${moment(rowData.actualStartDate).format('DD-MM-YYYY')} / ${moment.utc(rowData.actualStartDate).format("HH:mm") || ""}`
                      }} header="Actual Start Date/Time"></Column>
                    <Column
                      headerClassName="text-nowrap"
                      sortable
                      field="actualEndDate"
                      body={(rowData) => {
                        return `${moment(rowData.actualEndDate).format('DD-MM-YYYY')} / ${moment.utc(rowData.actualEndDate).format("HH:mm") || ""}`
                      }} header="Actual End Date/Time"></Column>

                    <Column headerClassName="text-nowrap"
                      sortable
                      field={"approverName"}
                      header="Approver"></Column>
                    <Column
                      field={"reviewerName"}
                      headerClassName="text-nowrap" header="Reviewer"></Column>

                    <Column
                      headerClassName="text-nowrap"

                      sortable
                      field="status"
                      body={(rowData) => {

                        return (<ChangeApprovalStatus
                          status={rowData.status}
                          onSubmit={statusChange}
                          toolData={{
                            tool_id: rowData?._id,
                            tool_number: rowData?.dailyLogId,
                            tool_name: rowData?.visitorName,
                            tool: "Daily Log",
                            _id: rowData?._id,
                            approver_id: rowData?.approver_id,
                            reviewer_id: rowData?.reviewer_id,
                            reviewer_name: `${rowData?.reviewer?.first_name} ${rowData?.reviewer?.last_name}`,
                            approver_name: `${rowData?.approver?.first_name} ${rowData?.approver?.last_name}`,
                            reviewer_email: `${rowData?.reviewer?.email_address}`,
                            approver_email: `${rowData?.approver?.email_address}`,
                            creator_name: rowData?.creator_email?.[0]?.first_name || rowData?.creator_email?.[0]?.name,
                            creator_email: rowData?.creator_email?.[0]?.email_address || rowData?.creator_email?.[0]?.email
                          }}
                          rowData={rowData}

                        />
                        )

                      }}
                      header="Approval Status"></Column>

                    <Column
                      headerClassName="text-nowrap"
                      field="delete"
                      header="Action"
                      style={{ width: "4%" }}
                      body={(rowData) => (
                        <div className="d-flex justify-content-center">
                          <Popover
                            options={["Edit", "Delete", "View",
                              <DownloadPdfAndSendEmail
                                heading={"DAILYLOG"}
                                fileName={"Dailylog"}
                                id={rowData._id}
                                files={rowData?.Attachments?.map(e => e.fileUrl)}
                              >
                                <ViewPagesPdfTemplate
                                  header={`#DAILYLOG ${rowData?.visitorName}`}
                                  tableData1={[
                                    { "VisitID": rowData?.dailyLogId },
                                    { "Visitor Company Name": rowData.visitorCompanyName },
                                    { "Visitor Name": rowData?.visitorName },
                                    { "Visitor Visit Purpose": rowData.visitPurpose },
                                    { "Actual Start Date/Time": showDateAndTime(rowData?.actualStartDate) },
                                    { "Actual End Date/Time": showDateAndTime(rowData?.actualEndDate) },
                                    { "Planned Start Date": showDateAndTime(rowData?.plannedStartDate) },
                                    { "Planned End Date": showDateAndTime(rowData?.plannedEndDate) },
                                  ]}

                                  tableData2={[
                                    { "Company Address": rowData?.companyAddress },
                                    { "Hourly spent": `${rowData?.hoursSpent?.split("-")[0]} Hr ${rowData?.hoursSpent?.split("-")[1]} Min` },
                                    { "Visit Status": rowData?.visitStatus },
                                    { "Company Type": rowData?.companyType },
                                    { "Reviewer": rowData?.approver?.[0]?.first_name },
                                    { "Approver": rowData?.reviewer?.[0]?.first_name },
                                    { "Approver Comment": rowData?.approver_comment || "--" },
                                    { "Reviewer Comment": rowData?.reviewer_comment || "--" }
                                  ]}
                                />
                              </DownloadPdfAndSendEmail>,
                              "Send Via Post",
                              "Version History",
                              <DocumentViewer links={rowData?.Attachments.map(e => e.fileUrl)}>
                                Documents
                              </DocumentViewer>
                            ]}
                            onSelect={(index) => {
                              if (index === 0) {
                                if (!getActionsPermission("Edit Daily Log")) {
                                  toast.error("You don't have permission to edit Daily log")
                                  return
                                }
                              
                                navigate(`/projectManagement/dailyLog/edit/${rowData?._id}`);
                              }
                              else if (index === 1) {

                                if (!getActionsPermission("Delete Daily Log")) {
                                  toast.error("You don't have permission to delete Daily log")
                                  return
                                }

                                setDailyLogId(rowData?._id);
                                setVisibleDelete(true)
                              }
                              else if (index === 2) {
                                if (!getActionsPermission("view Daily Log")) {
                                  toast.error("You don't have permission to view Daily log")
                                  return
                                }
                                navigate(`/projectManagement/dailyLog/view/DAILYLOG/${rowData?.visitorName}/${rowData?._id}`);
                              }
                              else if (index === 4) {
                                navigate(`/projectManagement/addressListing/dailylog/${rowData?.visitorName}/${rowData?._id}`)
                              }
                              else if (index === 5) {
                                if (!getActionsPermission("Daily Log version history")) {
                                  toast.error("You don't have permission of Daily log version history")
                                  return
                                }
                                navigate(
                                  `/projectManagement/version-history/DAILYLOG/${rowData?.visitorName}/${rowData?._id}`
                                );
                              }
                            }}
                          />
                        </div>
                      )}
                    ></Column>
                  </DataTable>

                }


              </div>

              <Pagination
                currentPage={tableSortData.page}
                totalCount={totalCount}
                pageSize={perPageData}
                onPageChange={(e) => setTableSortData(pre => ({ ...pre, page: e }))} />
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: '0', overflow: 'hidden' }}>

        <table ref={tableRef}>
          <thead>
            <tr>
              <th>Visitor Id</th>
              <th>Visitor Name</th>
              <th>Hour Spent</th>
              <th>Actual Start Date/Time</th>
              <th>Actual End Date/Time</th>
              <th>Planned Start Date/Time</th>
              <th>Planned End Date/Time</th>
              <th>Visitor Status</th>
              <th>Visit Purpose</th>
              <th>Visitor Company Name</th>
              <th>Visitor Company Type</th>
              <th>Company Address</th>
              <th>Approver</th>
              <th>Reviewer</th>
            </tr>
          </thead>
          <tbody>
            {dailyLogData?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.dailyLogId || "---"}</td>
                  <td>{item?.visitorName || "---"}</td>
                  <td>{`${item?.hoursSpent?.split("-")[0]} Hr ${item?.hoursSpent?.split("-")[1]} Mins`}</td>
                  <td>{showDateAndTime(item?.actualStartDate)}</td>
                  <td>{showDateAndTime(item?.actualEndDate)}</td>
                  <td>{showDateAndTime(item?.plannedStartDate)}</td>
                  <td>{showDateAndTime(item?.plannedEndDate)}</td>
                  <td>{item.visitStatus}</td>
                  <td>{item.visitPurpose}</td>
                  <td>{item.visitorCompanyName}</td>
                  <td>{item.companyType}</td>
                  <td>{item.companyAddress}</td>
                  <td>{item.approver?.first_name}</td>
                  <td>{item.reviewer?.first_name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <DeleteDialog
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        onCancel={() => setDailyLogId(null)}
        onConfirm={confirmDelete}
        header={"Rfi"}
      />


    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { Statuscomp } from "../../Components/Common/StatusComp";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Filter from "./Filter";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  deleteObservation,
  getObservationByProjectId,
  updateObservation,
} from "../../Api/Admin/AllAPIs";
import { getActionsPermission, getProjectCode, getProjectName, getUserId, searchDataWithKeys, toast } from "../../utils";
import { CSVLink } from "react-csv";
import Popover from "../../Components/Common/Popover";
import DeleteDialog from "../../Components/Common/DeleteDialog";
import { FaCheck, FaRegClock } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { ExportPdfButton } from "../../Components/Buttons/ExportPdfButton";
import ChangeStatusModal from "../../Components/ChangeStatusModal/ChangeStatusModal";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import { DownloadPdfAndSendEmail } from "../../Components/Common/ViewPageComponents/DownloadPdfAndSendEmail";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import Pagination from "../../Components/Common/Pagination/Pagination";
import moment from "moment";
import { ViewPageDocumentName } from "../../Components/Common/ViewPageComponents/ViewPageDocumentName";
import { ViewPageTable } from "../../Components/Common/ViewPageComponents/ViewPageTable";
import { ChangeApprovalStatus } from "../../Components/Tools/ChangeApprovalStatus";
import { CDC_LOGO_URL } from "../../constants";
import { useSelector } from "react-redux";

export const Observation = () => {
let d=useSelector(e=>e.rolesAndPermissionData)
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [observationId, setObservationId] = useState(null);
  const [observationData, setObservationData] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const projectId = localStorage.getItem("projectId");
  const [open, setOpen] = useState(null);
  const [dropdownvalue, setDropDownValue] = useState(null);
  const [comment, setComment] = useState("");
  const [type, setType] = useState("");
  const [itemId, setItemId] = useState("");
  const [statusRequired, setStatusRequired] = useState(false);
  const [allData, setAllData] = useState([]);

  const [page, setPage] = useState(1);
  const perPageData = 10;
  const [totalCount, setTotalCount] = useState(0);
  const [tempFilterData, setTempFilterData] = useState({});
  const [filterActive, setFilterActive] = useState(false);
  const [tableSortData, setTableSortData] = useState({
    page: 1,
    search: "",
  });

  // const projectId = localStorage.getItem('projectId')
  const userData = localStorage.getItem("userData");
  const userObj = JSON.parse(userData);
  const userId = userObj._id;
  const userName = userObj.name;

  const [currentStatusRowData, setCurrentStatusRowData] = useState({});

  const domain = window.location.origin;

  // let csvData = [];

  const [filteredData, setFilteredData] = useState({
    issuedDate: null,
    receivedDate: null,
    numberToIgnore: null,
    observationStatus: null,
    observedBy: null,
  });

  async function changeStatus(e) {
    e.preventDefault();

    if (dropdownvalue == null) {
      setStatusRequired(true);
      return;
    }

    setOpen(false);
    setLoader(true);

    try {
      if (type == "reviewer") {
        if (dropdownvalue.code == "approved") {
          let payload = {
            observation_review_status: "approved",
            reviewer_comment: comment,
            observation_approval_status: "under_review",
            status: "close",
          };

          await updateObservation(itemId, payload);
        } else {
          let payload = {
            observation_review_status: "unapproved",
            reviewer_comment: comment,
            status: "reopen",
            observation_approval_status: "pending",
          };
          await updateObservation(itemId, payload);
        }
      } else if (type == "approver") {
        if (dropdownvalue.code == "approved") {
          let payload = {
            observation_approval_status: "approved",
            approver_comment: comment,
            observation_review_status: "approved",
          };
          await updateObservation(itemId, payload);
        } else {
          let payload = {
            observation_review_status: "under_review",
            observation_approval_status: "unapproved",
            approver_comment: comment,
          };
          await updateObservation(itemId, payload);
        }
      } else {
        console.log("nonooo");
      }
    } catch (err) {
      console.log(err);
    } finally {
      await fetchData();
      setItemId("");
      setType("");
      setLoader(false);
      setDropDownValue(null);
    }
  }
  //HANDLING FETCH DATA
  async function fetchData() {
    setLoader(true);
    try {
      let params = {
        page: tableSortData.page,
        perPageData,
        search: tableSortData.search,
      };
      let body = { ...tempFilterData };
      const res = await getObservationByProjectId(projectId, params, body);

      let reverseData = res?.data?.data
      console.log(reverseData)
      // let dataReverse = reverseData?.reverse()
      // setObservationData(res?.data?.data || []);
      // setAllData(res?.data?.data || [])
      setTotalCount(res?.data?.totalCount || 0);
      setObservationData(reverseData);
      setObservationData(reverseData?.map((item) => ({
        ...item,
        "observation_status": item?.observationStatus?.map((res) => res?.name).join(),
        "observer_by": item?.observedBy?.map((res) => res?.first_name).join()
      })));
      setAllData(res?.data?.data);
      // setTotalCount(0);
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  }

  useEffect(() => {
    if (projectId) {
      fetchData();
    }
  }, [tempFilterData, tableSortData]);

  const handleDelete = async (id) => {
    setVisibleDelete(true);
    setObservationId(id);
  };
  //HANDLING DELETE DATA
  const handleConfirmDelete = async (id) => {

    let payload = {
      created_by: userName,
    }

    try {
      const res = await deleteObservation(observationId, payload);
      console.log(res, "data deleted successfully");
      await fetchData();
      toast.success("Observation Deleted Succesfully!");
    } catch (error) {
      console.log(error, "error deleting data");
      toast.error("Something Wents Wrong, Please Try Again!");
    }
  };

  const csvData = observationData?.map((item) => ({
    "Observation Id": item?.observationId,
    "Observation Status": item?.observationStatus?.map((res) => res?.name).join(),
    "Observer By": item?.observedBy?.map((res) => res?.first_name).join(),
    Title: item?.title,
    "Observed Date": moment(item?.observedDate).format("DD/MM/YYYY"),
    "Approval Status": item?.status,
    "Type Category": item?.category?.map((res) => res?.name).join(),
    Trade: item?.trade?.map((res) => res?.name).join(),
    "Location": item?.location,
    "Reviewer": item?.reviewer?.first_name,
    "Approver": item?.approver?.first_name,
    "Workflow Status": item?.workflowStatus,
    "Hours Spent": `${item?.hoursSpent?.split("-")[0]} Hr ${item?.hoursSpent?.split("-")[1]} Min`,

  }));

  // Define CSV headers
  const csvHeaders = [
    { label: "Observation Id", key: "Observation Id" },
    { label: "Type Category", key: "Type Category" },
    { label: "Observer By", key: "Observer By" },
    { label: "Trade", key: "Trade" },
    { label: "Location", key: "Location" },
    { label: "Reviewer", key: "Reviewer" },
    { label: "Approver", key: "Approver" },
    { label: "Workflow Status", key: "Workflow Status" },
    { label: "Hours Spent:", key: "Hours Spent:" },
    { label: "Observation Status", key: "Observation Status" },
    { label: "Title", key: "Title" },
    { label: "Observed Date", key: "Observed Date" },
    { label: "Approval Status", key: "Approval Status" },

    // { label: "Trade", key: "Trade" },
    // { label: "Location", key: "Location" },
    // { label: "Created At", key: "Created At" },
    // { label: "Is Deleted", key: "Is Deleted" },
  ];

  let navigate = useNavigate();

  const tableRef = useRef();

  const exportToPDF = () => {
    const doc = new jsPDF();
    const imgData = CDC_LOGO_URL
    const pageMargin = 10;  // Adjust this value for overall page margin

    const imgW = 20;
    const imgH = 10;
    const imageX = pageMargin;
    const imageY = pageMargin;
    doc.addImage(imgData, 'JPEG', imageX, imageY, imgW, imgH);
    const projectName = `${getProjectName()} (Project Id: ${getProjectCode() || "-"})`;
    const textX = imageX + imgW + 6;
    const textY = imageY + 7.5;
    // Add project name text to the PDF
    doc.setFontSize(16);
    doc.text(projectName, textX, textY);
    doc.autoTable({
      html: tableRef.current,
      startY: imageY + imgH + 10,  // Start table below the image with some margin
      margin: { top: pageMargin, left: pageMargin, right: pageMargin },  // Table margins
      // columnStyles: { 0: { columnWidth: 50 }, 1: { columnWidth: 50 }, 2: { columnWidth: 50 }, ... },
      didParseCell: function (data) {
        // if (data.row.index === 0) {
        //   // Scale down font size for header row
        data.cell.styles.fontSize = 2;
        // }
      },
    });
    doc.save("observation.pdf");
  };

  function changeToCap(str) {
    return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;
  }
  function isAbletoAccess(rowId) {
    return getUserId() == rowId;
  }

  function onFilter() {
    setTempFilterData(filteredData);
    setPage(1);
    setFilterActive(true);
  }
  function clearFilter() {
    setFilterActive(false);
    setFilteredData({
      status: null,
      location: null,
      responsibleContractor: null,
      membersResponsible: null,
      initiatedDate: null,
      observedDate: null,
    });
    setTempFilterData({
      status: null,
      location: null,
      responsibleContractor: null,
      membersResponsible: null,
      initiatedDate: null,
      observedDate: null,
    });
  }

  // approver and reviewer

  async function changeStatus(e) {
    e.preventDefault();

    if (dropdownvalue == null) {
      setStatusRequired(true);
      return;
    }
    setOpen(false);

    try {
      if (type == "reviewer") {
        let emailData = [];
        let payload = {
          status: dropdownvalue?.code,
          reviewer_comment: comment,
          project_id: projectId,
          email_data: [],
          observationId: currentStatusRowData?.observationId

        };

        // creator_email
        if (dropdownvalue?.code == "pendingapproval") {
          emailData = [
            {
              name: currentStatusRowData?.approver?.first_name,
              to: currentStatusRowData?.approver?.email_address,
              tool_number: currentStatusRowData?.observationId,
              tool: "Observation",
              tool_name: currentStatusRowData?.title,
              project_name: getProjectName(),
              project_id: "8767",
              // type: "Approver",
              template_type: "createdToolEmailToApprover",
              url: `${domain}/projectManagement/observation`,
              created_by: userData?.name,
              // createdAt: moment().format("DD MMM YYYY  hh:mm A"),
            },
          ];
        }

        emailData.push({
          name: currentStatusRowData?.approver?.first_name,
          to: currentStatusRowData?.creator_email?.email_address,
          tool_number: currentStatusRowData?.observationId,
          tool: "Observation",
          tool_name: currentStatusRowData?.title,
          project_name: getProjectName(),
          project_id: "8767",
          template_type: "Project Creator",
          url: `${domain}/projectManagement/observation`,
          created_by: userData?.name,
          // createdAt: moment().format("DD MMM YYYY  hh:mm A"),
        });

        payload.email_data = emailData;

        await updateObservation(itemId, payload);
      } else {
        let emailData = [];
        let payload = {
          status: dropdownvalue?.code,
          approver_comment: comment,
          project_id: projectId,
          observationId: currentStatusRowData?.observationId
        };
        if (dropdownvalue?.code == "approved") {
          emailData.push({
            name: currentStatusRowData?.approver?.first_name,
            to: currentStatusRowData?.creator_email?.email_address,
            tool_number: currentStatusRowData?.observationId,
            tool: "Observation",
            tool_name: currentStatusRowData?.title,
            project_name: getProjectName(),
            project_id: "8767",
            template_type: "Project Creator",
            url: `${domain}/projectManagement/observation`,
            created_by: userData?.name,
            // createdAt: moment().format("DD MMM YYYY  hh:mm A"),
          });
        }
        payload.email_data = emailData;
        await updateObservation(itemId, payload);
      }
    } catch (err) {
      console.log(err);
    } finally {
      fetchData();
      setItemId("");
      setType("");
      // setLoader(false)
      setDropDownValue(null);
    }
  }




  function changeStatusUi(status) {
    switch (status) {
      case "draft":
        return (
          <div className="statusOuterD">
            <div className="statusDotD" />
            <div className="StatusD">Draft</div>
          </div>
        );
        break;
      case "inreview":
        return (
          <div className="statusOuterI">
            <div className="statusDotI" />
            <div className="StatusI">In Review</div>
          </div>
        );
        break;
      case "pendingapproval":
        return (
          <div className="statusOuterP">
            <div className="statusDotP" />
            <div className="StatusP">Pending Approval</div>
          </div>
        );
        break;
      case "approved":
        return (
          <div className="statusOuterA">
            <div className="statusDotA" />
            <div className="StatusA">Approved</div>
          </div>
        );
        break;

      default:
        break;
    }
  }

  async function statusChange(data, id, toolId, comment) {
    try {
      console.log({ ...data, observationId: toolId })

      let result = await updateObservation(id, { ...data, ...comment });

      fetchData()

    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>

      <ChangeStatusModal
        open={open}
        setOpen={setOpen}
        value={dropdownvalue}
        setValue={setDropDownValue}
        onSubmit={changeStatus}
        setComment={setComment}
        type={type}
        statusRequired={statusRequired}
        setStatusRequired={setStatusRequired}
      />

      <div className="d-flex flex-sm-row flex-column justify-content-between">
        <div className="page_heading">Observation</div>
        <div className="d-flex align-items-center justify-content-end mt-sm-0 mt-3 gap-3">
          <div className="right d-flex gap-1 align-items-center">
            <ExportPdfButton
              exportToPDF={exportToPDF}
              csvBtn={
                <CSVLink
                  className="pe-1"
                  data={csvData}
                  headers={csvHeaders}
                  filename={"Observation.csv"}
                >
                  CSV
                </CSVLink>
              }
            />
          </div>
          {getActionsPermission("Create Observation") && <PrimaryButton
            onClick={() => navigate("create")}
            addIcon
            text={"Create Observation"}
          />}
        </div>
      </div>

      <div style={{ height: "0", overflow: "hidden" }}>
        <table ref={tableRef}>
          <thead>
            <tr>
              <th>Observation Id</th>
              <th>Observation Status</th>
              <th>Observer By</th>
              <th>Title</th>
              <th>Observed Date</th>
              <th>Approval Status</th>
              <th>Type Category</th>
              <th>Trade</th>
              <th>Location</th>
              <th>Reviewer</th>
              <th>Approver</th>
              <th>Workflow Status</th>
              <th>"Hours Spent</th>
            </tr>
          </thead>
          <tbody>
            {observationData?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item?.observationId}</td>
                  <td>
                    {item?.observationStatus?.map((res) => res?.name).join()}
                  </td>
                  <td>
                    {item?.observedBy?.map((res) => res?.first_name).join()}
                  </td>
                  <td style={{ textTransform: "capitalize" }}>{item?.title}</td>
                  <td>{moment(item?.observedDate).format("DD/MM/YYYY")}</td>
                  <td>{item?.status}</td>
                  <td>{item?.category?.map((res) => res?.name).join()}</td>
                  <td>{item?.trade?.map((res) => res?.name).join()}</td>
                  <td>{item?.location}</td>
                  <td>{item?.reviewer?.first_name}</td>
                  <td>{item?.approver?.first_name}</td>
                  <td>{item?.workflowStatus}</td>
                  <td>{`${item?.hoursSpent?.split("-")[0]} Hr ${item?.hoursSpent?.split("-")[1]} Min`}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center">
        <div className="searchInput">
          <SearchInput
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              // setObservationData(
              //   searchDataWithKeys(["title"], allData, e.target.value)
              // );
              setTableSortData({ page: 1, search: e.target.value });
            }}
            onCancel={() => {
              setSearch("");
              setObservationData(allData);
              setTableSortData({ page: 1, search: "" });
            }}
          />
        </div>

        <div className="d-flex text-nowrap gap-2 mt-sm-0 mt-2">
          <div className="w-100">
            <Filter
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              onFilter={onFilter}
            />
          </div>
          &nbsp;
          <button
            disabled={!filterActive}
            className="w-100 filter_btn"
            onClick={clearFilter}
          >
            Clear Filter
          </button>
        </div>
      </div>

      <div className="tools_table_scroll mt-3">
        {loader ? (
          <TableSkelton
            header={[
              "Observation Id",
              "Observer Status",
              "Observer By",
              "Title",
              "Due Date",
              "Approval Status",
              "Actions",
            ]}
          />
        ) : (
          <BorderedTable>
            <DataTable value={observationData}>

              <Column sortable field="observationId" header="Observation Id"></Column>
              <Column
                sortable
                header="Observation Status"
                field="observation_status"
                body={(rowData) => (
                  <div style={{ textTransform: "capitalize" }}>{rowData?.observation_status}</div>
                )}

              ></Column>

              <Column
                sortable
                field="title"
                header="Title"
                body={(rowData) => (
                  <div
                    className=""

                    style={{ cursor: "pointer", textTransform: "capitalize" }}
                  >
                    {rowData?.title}
                  </div>
                )}
              ></Column>

              <Column
                sortable
                header="Observed By"

                field="observer_by"
              ></Column>










              <Column
                sortable
                field="observedDate"
                body={(rowData) =>
                  moment(rowData?.observedDate).format("DD/MM/YYYY")
                }
                header="Observed Date"
              ></Column>

              <Column
                sortable
                field="status"
                headerClassName="text-nowrap"
                body={(rowData) => {

                  return (<ChangeApprovalStatus
                    status={rowData.status}
                    onSubmit={statusChange}
                    toolData={{
                      tool_id: rowData?._id,
                      tool_number: rowData?.observationId,
                      tool_name: rowData?.title,
                      tool: "Observation",
                      _id: rowData?._id,
                      approver_id: rowData?.approver_id,
                      reviewer_id: rowData?.reviewer_id,
                      reviewer_name: `${rowData?.reviewer?.first_name} ${rowData?.reviewer?.last_name}`,
                      approver_name: `${rowData?.approver?.first_name} ${rowData?.approver?.last_name}`,
                      reviewer_email: `${rowData?.reviewer?.email_address}`,
                      approver_email: `${rowData?.approver?.email_address}`,
                      creator_name: rowData?.creator_email?.[0]?.first_name || rowData?.creator_email?.[0]?.name,
                      creator_email: rowData?.creator_email?.[0]?.email_address || rowData?.creator_email?.[0]?.email
                    }}
                    rowData={rowData}

                  />
                  )

                }}
                header="Approval Status"></Column>
              <Column
                style={{ width: "6rem" }}
                header="Actions"
                body={(rowData) => (
                  <div className="d-flex justify-content-center">
                    <Popover
                      options={[
                        "Version History",
                        "Edit",
                        "Delete",
                        "View",

                        <DownloadPdfAndSendEmail
                          heading={"OBSERVATION"}
                          fileName={"Observation"}
                          id={rowData._id}
                          files={rowData?.attachments?.map((e) => e.url)}
                        >

                          <div className="card p-5">
                            <h1 className="heading">{rowData?.title} </h1>
                            <div className="row mt-3">
                              <div className="col-6">
                                <ViewPageTable
                                  tableData={[
                                    { "Observation Id": rowData?.observationId },
                                    {
                                      "Observation Status":
                                        // (
                                        //   <Statuscomp
                                        //     className={`${rowData?.observationStatus?.[0]?.name}_status_obser`}
                                        //     label={rowData?.observationStatus?.[0]?.name}
                                        //   />
                                        // ) || ""
                                        <div style={{ textTransform: "capitalize" }}>{rowData?.observationStatus?.[0]?.name || "--"}</div>,
                                    },
                                    {
                                      "Observed Date":
                                        moment(rowData?.observedDate).format("MM/DD/YYYY") || "",
                                    },
                                    // { "Member Responsible": data?.MembersResponsible || "--" },
                                    {
                                      "Created By": rowData?.observedBy?.[0]?.first_name || "--",
                                    },
                                    { Title: <div style={{ textTransform: "capitalize" }}>{rowData?.title || "--"}</div> },
                                    { Category: <div style={{ textTransform: "capitalize" }}>{rowData?.category?.[0]?.name || "--"}</div> },
                                    // { "Type": data?.type || "--" },
                                  ]}
                                />
                              </div>
                              <div className="col-6">
                                <ViewPageTable
                                  tableData={[
                                    {
                                      "Observed By": rowData?.observedBy?.[0]?.first_name || "--",
                                    },
                                    // { "Location": data?.location || "--" },
                                    { Trade: <div style={{ textTransform: "capitalize" }}>{rowData?.trade?.[0]?.name || "--"}</div> },
                                    {
                                      "Hourly Spent": `${rowData?.hoursSpent?.split("-")[0]} Hr ${rowData?.hoursSpent?.split("-")[1]} Min`,
                                    },
                                    { Location: <div style={{ textTransform: "capitalize" }}>{rowData?.location || "--"}</div> },
                                    { Approver: <div style={{ textTransform: "capitalize" }}>{rowData?.approver?.[0]?.first_name || "--"}</div> },

                                    { Reviewer: <div style={{ textTransform: "capitalize" }}>{rowData?.reviewer?.[0]?.first_name || "--"}</div> },
                                    { "Approver Comment": <div style={{ textTransform: "capitalize" }}>{rowData?.approver_comment || "--"}</div> },
                                    { "Reviewer Comment": <div style={{ textTransform: "capitalize" }}>{rowData?.reviewer_comment || "--"}</div> }
                                  ]}
                                />
                              </div>
                            </div>

                            <div className="view_page_para_head mt-4 mb-2">
                              Attached Files(s):
                            </div>

                            <div className="d-flex flex-wrap gap-2">
                              {rowData?.attachments?.map((file, index) => (
                                <ViewPageDocumentName
                                  docUrl={file?.url}
                                  key={index}
                                  name={file.fileName}
                                />
                              ))}
                            </div>
                            {/* {(!usedAsComponent || includeVersionHistory) && (
              <>
                <div className="mt-3">
                  <ViewVersion isView={true} />
                </div>
              </>
            )} */}
                          </div>
                        </DownloadPdfAndSendEmail>,
                        "Send Via Post",
                      ]}
                      onSelect={(index) => {
                        if (index === 0) {
                          if (!getActionsPermission("Observation Version history")) {
                            toast.error("You don't have permission of Observation version history")
                            return

                          }
                          navigate(
                            `/projectManagement/version-history/OBSERVATION/${rowData?.title}/${rowData?._id}`
                          );
                        } else if (index === 1) {
                          if (!getActionsPermission("Edit Observation")) {
                            toast.error("You don't have permission to Edit Observation")
                            return
                          }
                          navigate(
                            `/projectManagement/observation/${rowData?._id}`
                          );
                        } else if (index === 2) {
                          if (!getActionsPermission("Delete Observation")) {
                            toast.error("You don't have permission to Delete Observation")
                            return
                          }
                          handleDelete(rowData?._id);
                        } else if (index === 3) {
                          if (!getActionsPermission("View Observation")) {
                            toast.error("You don't have permission to View Observation")
                            return
                          }
                          navigate(
                            `view/OBSERVATION/${rowData?.title}/${rowData?._id}`
                          );
                        } else if (index === 5) {
                          navigate(
                            `/projectManagement/addressListing/OBSERVATION/${rowData?.title}/${rowData?._id}`
                          );
                        }
                      }}
                    />
                  </div>
                )}
              ></Column>
            </DataTable>
          </BorderedTable>
        )}
      </div>
      <br />
      <Pagination
        currentPage={tableSortData.page}
        totalCount={totalCount}
        pageSize={perPageData}
        onPageChange={(e) => setTableSortData((pr) => ({ ...pr, page: e }))}
      />

      <DeleteDialog
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        onCancel={() => setObservationId(null)}
        onConfirm={handleConfirmDelete}
        header={"Observation"}
      />
    </>
  );
};

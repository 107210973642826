import React, { useEffect, useRef, useState } from "react";

import TextInput from "../../Components/Inputs/TextInput";
import Calender from "../../Components/Common/Calender";
import SingleSelect from "../../Components/Common/SingleSelect";
import { Editor } from "primereact/editor";
import { MdAdd, MdOutlineAttachment } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import {
  createIssue,
  addPrioritytype,
  getPrioritytype,
  getTransmittalDataByProjectId,
  addTradeT,
  getTradeT,
  addTypeT,
  getTypeT,
  getUserByProjectId,
  getUsersByCompanyId,
  uploadDocs,
  getCompanyByProjectId,
  getCountIssueId,
} from "../../Api/Admin/AllAPIs";
import {
  getActionsPermission,
  getProjectCode,
  getProjectId,
  getProjectName,
  getUserId,
  getUserName,
  toast,
} from "../../utils";
import { useSelector } from "react-redux";
import AddSets from "../../Components/Common/AddSets";
import { MultiSelect } from "primereact/multiselect";
import Loader from "../../Components/Common/Loader";
import AutoCompleteSelect from "../../Components/Common/AutoCompleteSelect";
import { TOOL_STATUS } from "../../constants";
import { FormHeader } from "../../Components/Common/FormHeader";

export const CreateIssue = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  let set = useSelector((d) => d.rolesAndPermissionData);
  const isApproverAccess = getActionsPermission("Issue Approver");
  console.log(isApproverAccess);
  const isReviewerAccess = getActionsPermission("Issue Reviewer");
  const [allUsersOfProject, setAllUsersOfProject] = useState([]);
  const [approver, setApprover] = useState(null);
  const [reviewer, setReviewer] = useState(null);
  const [priorityData, setPriorityData] = useState([]);
  console.log(priorityData);
  const [typeData, setTypeData] = useState([]);
  const [typeName, setTypeName] = useState("");
  const [tradeData, setTradeData] = useState([]);
  const [assigneeData, setAssigneeData] = useState([]);
  const [identified, setIdentified] = useState();
  console.log(assigneeData);
  // const dataType = [
  //   { name: "Building Coordination" },
  //   { name: "Clash" },
  //   { name: "Client Feedback" },
  //   { name: "Coordination" },
  // ];
  async function getAssignUser() {
    let res = await getUserByProjectId(projectId);
    setAssigneeData(res?.data?.data[0]?.combined_users);
  }
  useEffect(() => {
    getAssignUser();
  }, []);
  // const assigneeData = [
  //   { name: "Andrew" },
  //   { name: "John" },
  //   { name: "Smith" },
  //   { name: "Joseph" },
  // ];
  // const assCompanyData = [
  //   { name: "Dell Company" },
  //   { name: "Connie Group" },
  //   { name: "SB Mechanical" },
  //   { name: "Zenith Global Solution" },
  // ];

  const priorityTypeData = [{ name: "Personal" }, { name: "Others" }];
  const statusData = [{ name: "open" }, { name: "close" }];
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [status, setStatus] = useState(null);
  const [location, setLocation] = useState("");
  const [type, setType] = useState(null);
  const [assignee, setAssignee] = useState(null);
  const [assigneeCompany, setAssigneeCompany] = useState(null);
  const [dueDate, setDueDate] = useState("");
  const [approval, setApproval] = [];
  const [rfi, setRfi] = useState("");
  const [priority, setPriority] = useState(null);
  const [trade, setTrade] = useState(null);
  const [priorityType, setPriorityType] = useState(null);
  const [listingType, setListingType] = useState(null);
  const [companyId, setCompanyId] = useState();
  const [fileUpload, setFileUpload] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFile, setPreviousFile] = useState([]);
  const [visible, setVisible] = useState(false);
  const [typeVisible, setTypeVisible] = useState(false);
  const [visibleType, setVisibleType] = useState(false);
  const [approvestatus, setApproveStatus] = useState();
  // const [addPriority, setAddPriority] = useState("")
  const [responsibleContractor, setResponsibleContractor] = useState([]);
  const [responsiblePerson, setResponsiblePerson] = useState([]);
  const [membersResponsible, setMembersResponsible] = useState([]);
  const projectId = localStorage.getItem("projectId");
  const [tradeValue, setTradeValue] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [addType, setAddType] = useState(null);

  const [loader, setLoader] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [IssueID, setIssueID] = useState("");
  const [workFlowOption, setworkFlowOption] = useState([]);
  const mandatoryFields = ["title", "type", "identified", "dueDate", "trade"];
  const [Validation, setValidation] = useState({
    title: false,
    type: false,
    identified: false,
    trade: false,
    dueDate: false,
  });
  const domain = window.location.origin;

  // const toast = useRef(null);

  const handleFileSubmit = (event) => {
    setFileUpload(event);
  };

  useEffect(() => {
    getPriority(projectId);
  }, []);

  async function getPriority(id) {
    // alert("sdfljkhsfdfdfdfdfdfdfdfdfdfdfdfdfdfdfdfdfdfdfdl")
    try {
      let res = await getPrioritytype(id);

      console.log(res);
      setPriorityData(res?.data?.data || []);
    } catch (err) {
      console.log(err);
    }
  }

  const addTypeP = async () => {
    setLoading(true);

    try {
      let payload = {
        name: typeName,
        project_id: projectId,
      };
      console.log(payload);

      let t = await addPrioritytype(payload);
      console.log(t);
      getPriority(projectId);
      setLoading(false);
      setVisibleType(false);
      setTypeName("");
    } catch (err) {
      console.log(err);
    }
  };

  async function getTypeListing() {
    try {
      let res = await getTypeT(getProjectId());
      console.log(res?.data?.data, "[[][][][][]");
      setListingType(res?.data?.data || []);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getTypeListing();
  }, []);
  const addTypeType = async () => {
    setLoading(true);
    try {
      const payload = {
        name: typeValue,
        project_id: projectId,
      };
      console.log(payload);

      let t = await addTypeT(payload);
      console.log(t);

      getTypeListing(projectId);
      setTypeVisible(false);
      setTypeValue("");
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  //Trade-----------------------------------------------------------

  const addTradeP = async () => {
    setLoading(true);
    console.log("oooooooooooooo");
    try {
      let payload = {
        name: tradeValue,
        project_id: projectId,
      };
      console.log(payload);
      await addTradeT(payload);
      getTradeData();
      setVisible(false);
      setTradeValue("");
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTradeData();
  }, []);

  async function getTradeData(data) {
    try {
      let res = await getTradeT(getProjectId());
      console.log(res, "resr");
      setTradeData(res?.data?.data || []);
    } catch (err) {
      console.log(err);
    }
  }

  const approvelOptions = [
    { label: "Draft", value: "draft" },
    { label: "Reviewed", value: "reviewed" },
    { label: "Approved", value: "approved" },
  ];

  const generateNextRFI = async () => {
    try {
      let res = await getCountIssueId(projectId);
      console.log(res);
      setIssueID(res.data.data);
    } catch (Err) {
      console(Err);
    }
  };

  useEffect(() => {
    generateNextRFI();
  }, []);

  // check validation
  const checkValidation = () => {
    console.log(isApproverAccess ? !approver : false);
    console.log(isReviewerAccess ? !reviewer : false);

    if (!title) {
      setValidation((prevState) => ({
        ...prevState,
        title: true,
      }));
    }
    // if (!priorityType) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     priorityType: true,
    //   }));
    // }
    if (!type) {
      setValidation((prevState) => ({
        ...prevState,
        type: true,
      }));
    }

    if (!identified) {
      setValidation((prevState) => ({
        ...prevState,
        identified: true,
      }));
    }

    if (!dueDate) {
      setValidation((prevState) => ({
        ...prevState,
        dueDate: true,
      }));
    }
    if (!trade) {
      setValidation((prevState) => ({
        ...prevState,
        trade: true,
      }));
    }

    if (!title || !type || !trade || !identified || !dueDate) {
      toast.info("Please Fill all the Fields Marked with * are Mandatory");
      return true;
    }
    return false;
  };

  const handleCreateIssue = async () => {
    if (fileUpload.length > 10) {
      toast.info("You can add only 10 attachments");
      return;
    }
    console.log(checkValidation(), "[[[[[[[[[[[[[[[[[[[[[");
    if (checkValidation()) {
      return;
    }
    setLoader(true);

    const formData = new FormData();
    for (const file of fileUpload) {
      formData.append("upload", file);
    }
    const uploadResponse = await uploadDocs(formData);

    const fileData = uploadResponse.data.map((item) => ({
      fileName: item.fileName,
      url: item.fileUrl,
    }));


    const missingFields = [];
    mandatoryFields.forEach((field) => {
      if (!eval(field)) {
        missingFields.push(field);
      }
    });

    let emailData = [];
    if (reviewer) {
      let approverData = allUsersOfProject.find((e) => e._id == reviewer);

      emailData.push({
        to: approverData?.email_address,
        name: approverData?.first_name,
        tool_number: IssueID,
        tool_name: title,
        project_name: getProjectName(),
        project_id: getProjectCode(),
        created_by: getUserName(),
        tool: "Issue",
        subject: `Reviewer appointed created in ${getProjectName()}`,
        url: `${domain}/coretools/coordinationIssues`,
        template_type: "createdToolEmailToReviewer",
      });
    }
    if (approver) {
      let reviewerData = allUsersOfProject.find((e) => e._id == approver);
      emailData.push({
        to: reviewerData.email_address,
        name: reviewerData.first_name,
        tool_number: IssueID,
        tool_name: title,
        subject: `FYI: Approver appointed created in ${getProjectName()}`,
        project_name: getProjectName(),
        project_id: getProjectCode(),
        created_by: getUserName(),
        tool: "Issue",
        url: `${domain}/coretools/coordinationIssues`,
        template_type: "createdToolEmailToApprover",
      });
    }



    if (identified) {
      let reviewerData = allUsersOfProject.find((e) => e._id == identified);
      emailData.push({
        to: reviewerData.email_address,
        name: reviewerData.name,
        tool_number: IssueID,
        tool_name: title,
        subject: `FYI:New Issue created in ${getProjectName()}`,
        project_name: getProjectName(),
        project_id: getProjectCode(),
        created_by: getUserName(),
        tool: "Issue Identified",
        url: `${domain}/coretools/coordinationIssues`,
        template_type: "createdToolEmailToApprover",
      });
    }
    console.log(emailData);
    // let payload = {
    //   person: personResponsible,
    //   membersResponsible: membersResponsible,
    // };

    // console.log(reviewer)
    const Payload = {
      issue_title: title,
      issue_description: description,
      status: TOOL_STATUS.draft,
      issue_location: location,
      Issue_IdentifiedBy: identified,
      issue_assignee: assignee,
      issue_dueDate: dueDate.toLocaleString(),
      issue_priority: priority,
      issue_trade: trade,
      issue_priorityType: priorityType,
      issue_assigneeCompany: "",
      issue_type: type,
      issue_file: fileData,
      issue_ID: IssueID,
      project_id: projectId,
      reviewer_id: reviewer,
      approver_id: approver,
      // reviewer_id: reviewer?._id || null,
      // approver_id: approver?._id || null,
      email_data: emailData,
      responsibleContractor: responsibleContractor,
      responsiblePerson: responsiblePerson,
      responsibleMember: membersResponsible,
      creator_email: getUserId(),
    };
    console.log(Payload);
    // return
    // return
    if (Payload.reviewer_id && Payload.approver_id) {
      Payload.status = TOOL_STATUS.inreview;
    }
    try {
      let t = await createIssue(Payload);
      console.log(t);
      //  return
      toast.success("Issue Created Successfully!");
      navigate("/projectManagement/coordinationIssues");
      setTitle("");
      setDescription("");
      setFileUpload("");
      setStatus("");
      setLocation("");
      setIdentified("");
      setDueDate("");
      setPriority("");
      setTrade("");
      setPriorityType("");
      setAssigneeCompany("");
      setType("");
      setResponsibleContractor("");
      setLoader(false);
    } catch (err) {
      console.error(err);
      toast.error("Something Wents Wrong, Please Try Again!");
    }
  };

  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    // Do something with the selected files
    console.log(files);
  };

  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId);
      let allUsers = res?.data?.data?.[0]?.combined_users || [];
      setAllUsersOfProject(allUsers);
      // setIdentified(allUsers)
      console.log(allUsers, "================================================");
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllUsersOfProject();
  }, []);
  const getcompany = async () => {
    try {
      const res = await getCompanyByProjectId(projectId);
      if (res && res?.data) {
        const companyNames = res?.data?.data[0]?.companynames;
        const formattedCompanies = companyNames.map((company) => ({
          label: company.company_name,
          value: company._id,
        }));
        setCompanies(formattedCompanies);
      } else {
        console.log("Response data is not in the expected format");
      }
    } catch (error) {
      console.log("Error fetching company data:", error);
    }
  };
  useEffect(() => {
    getcompany();
  }, []);

  const handleCompanyChange = async (e) => {
    const selectedCompanyIds = e.value; // e.value should be an array of selected company IDs
    setResponsibleContractor(selectedCompanyIds);

    // Fetch users for all selected companies
    if (selectedCompanyIds.length > 0) {
      const usersFromAllCompanies = [];

      for (const companyId of selectedCompanyIds) {
        try {
          const res = await getUsersByCompanyId(projectId, {
            company_id: [companyId],
          });
          console.log(res);
          if (res && res?.data?.data) {
            const usersFromCompany = res?.data?.data?.map((user) => ({
              label: user.name,
              value: user._id,
            }));
            usersFromAllCompanies.push(...usersFromCompany);
          } else {
            console.error("Response data is not in the expected format");
          }
        } catch (error) {
          console.log("Error fetching company data:", error);
        }
      }

      // Remove duplicate users
      const uniqueUsers = Array.from(
        new Set(usersFromAllCompanies.map((user) => user.value))
      ).map((id) => {
        return usersFromAllCompanies.find((user) => user.value === id);
      });

      setUsers(uniqueUsers);
    } else {
      setUsers([]);
    }
  };
  const companyUser = async (companyId) => {
    try {
      const res = await getUsersByCompanyId(projectId, companyId);

      if (res) {
        const userOptions = res?.data?.data?.map((user) => ({
          label: user.name,
          value: user._id,
        }));
        setUsers(userOptions);
      } else {
        console.error("Response data is not in the expected format");
      }
    } catch (error) {
      console.log("Error fetching company data:", error);
    }
  };

  useEffect(() => {
    if (companyId) companyUser(companyId);
  }, [companyId]);

  return (
    <>
      <div className=" main-container">
        <div>

          <FormHeader
            to={"/projectManagement/coordinationIssues"}
            formHeader={"Issue"}
            breadcrumItem={["Issue", `Create Issue`]} />

          {loader ? (
            <Loader />
          ) : (
            <div>


              {/*  New section End */}

              <div className="row m-0 p-0">
                <div className="col-md-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Title: <span className="text-danger">*</span>
                  </label>
                  <TextInput
                    placeholder="Enter New Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className={Validation.title ? "Validation" : ""}
                    onClick={() =>
                      setValidation({ ...Validation, title: false })
                    }
                  >
                    {" "}
                  </TextInput>
                </div>
              </div>

              <div className="row m-0 p-0   ">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Location:
                  </label>
                  <TextInput
                    placeholder="Location"
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                  />
                </div>

                <div className="col-md-6 d-flex">
                  <div className="maindiv_plusIcon">
                    <label htmlFor="" className="py-2 labelCss">
                      Type: <span className="text-danger">*</span>
                    </label>
                    <AutoCompleteSelect
                      options={
                        listingType?.map((item) => ({
                          label: item?.name,
                          value: item?._id,
                        })) || []
                      }
                      value={type}
                      optionLabel="label" // Ensure this matches the property name in your options
                      onChange={(value) => {
                        setType(value);
                        setValidation({ ...Validation, type: false });
                      }}
                      className={`p-0 ${Validation.type ? "Validation" : ""
                        } text-capitalize`}
                      onClick={() =>
                        setValidation({ ...Validation, type: false })
                      }
                      placeholder="Select Issue Type"
                    />
                  </div>
                  <span className="ml-3 mt-5">
                    <MdAdd
                      className="h2"
                      style={{ cursor: "pointer" }}
                      onClick={() => setTypeVisible(true)}
                    />
                  </span>
                </div>
              </div>

              <div className="row m-0 p-0  gap-0 ">
                <div className="col-md-6 d-flex">
                  <div className="maindiv_plusIcon">
                    <label htmlFor="" className="py-2 labelCss">
                      Trade: <span className="text-danger">*</span>
                    </label>
                    <AutoCompleteSelect
                      // className="p-0"
                      placeholder="Select Issue Trade"
                      options={
                        tradeData?.map((item) => ({
                          label: item?.name,
                          value: item?._id,
                        })) || []
                      } // Add fallback for options
                      optionLabel="lebal"
                      value={trade}
                      onChange={(value) => {
                        setTrade(value);
                        setValidation({ ...Validation, trade: false });
                      }}
                      className={Validation.trade ? "Validation" : ""}
                      onClick={() =>
                        setValidation({ ...Validation, trade: false })
                      }
                    />
                  </div>
                  <span className="ml-3 mt-5">
                    <MdAdd
                      className="h2"
                      style={{ cursor: "pointer" }}
                      onClick={() => setVisible(true)}
                    />
                  </span>
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Isuue ID: <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex w-100 ">
                    <input
                      type="text"
                      placeholder="Issue ID"
                      value={IssueID}
                      onChange={(e) => setIssueID(e.target.value)}
                      style={{ width: "100%" }}
                      className={`${Validation.rfiNo ? "Validation" : ""
                        } form-control common_input`}
                      onClick={() =>
                        setValidation({ ...Validation, rfiNo: false })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row m-0 p-0  gap-0">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Due Date: <span className="text-danger">*</span>
                  </label>

                  <input
                    type="date"
                    style={{ height: "50px" }}
                    // className="p-0"
                    placeholder="Due Date"
                    value={dueDate}
                    onChange={(e) => setDueDate(e.target.value)}
                    className={
                      Validation.dueDate ? "Validation p-2" : "form-control"
                    }
                    onClick={() =>
                      setValidation({ ...Validation, dueDate: false })
                    }
                  />
                </div>

                <div className="col-md-6 d-flex">
                  <div className="maindiv_plusIcon">
                    <label htmlFor="" className="py-2 labelCss">
                      Priority:
                    </label>
                    <AutoCompleteSelect
                      // className="p-0"
                      placeholder=" Select Issue Priority"
                      options={
                        priorityData?.map((item) => ({
                          label: item?.name,
                          value: item?._id,
                        })) || []
                      } // Add fallback for options
                      optionLabel="lebal"
                      value={priority}
                      onChange={(value) => {
                        setPriority(value);
                        setValidation({ ...Validation, priority: false });
                      }}
                      className={Validation.priority ? "Validation" : ""}
                      onClick={() =>
                        setValidation({ ...Validation, priority: false })
                      }
                    />{" "}
                  </div>
                  <span className="ml-3 mt-5">
                    <MdAdd
                      className="h2"
                      style={{ cursor: "pointer" }}
                      onClick={() => setVisibleType(true)}
                    />
                  </span>
                </div>
              </div>

              <div className="row  gap-0 m-0 p-0">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Responsible Contractor Company (s ):
                  </label>

                  <MultiSelect
                    value={responsibleContractor}
                    onChange={handleCompanyChange}
                    options={companies}
                    optionLabel="label"
                    filter
                    placeholder="Company"
                    // maxSelectedLabels={3}
                    style={{ width: "100%" }}
                    className={`p-0 `}
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Responsible Member (s):
                  </label>
                  <div>
                    <MultiSelect
                      value={membersResponsible}
                      onChange={(e) => setMembersResponsible(e.value)}
                      options={users}
                      optionLabel="label"
                      filter
                      placeholder="Select Member"
                      // maxSelectedLabels={3}
                      style={{ width: "100%" }}
                      className={`p-0 `}
                    />
                  </div>
                </div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Responsible Person (s):
                  </label>
                  <MultiSelect
                    value={responsiblePerson}
                    onChange={(e) => setResponsiblePerson(e.value)}
                    options={allUsersOfProject.map((item) => ({
                      label: item?.first_name,
                      value: item?._id,
                    }))}
                    optionLabel="label"
                    filter
                    placeholder="Select Person"
                    // maxSelectedLabels={3}
                    style={{ width: "100%" }}
                    className={`p-0 ${Validation.contractor ? "Validation" : ""
                      }`}
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Issue Identified By:<span className="text-danger">*</span>
                  </label>
                  <SingleSelect
                    value={identified}
                    onChange={(e) => setIdentified(e?.value)}
                    options={allUsersOfProject?.map((item) => ({
                      label: item?.first_name,
                      value: item?._id,
                    }))}
                    optionLabel="label"
                    filter
                    placeholder="Select User"
                    style={{ width: "100%" }}
                    // className={Validation.identified ? "Validation" : ""}
                    // onClick={() =>
                    //   setValidation({...Validation, identified: false })
                    // }
                    className={Validation.identified ? "Validation" : ""}
                    onClick={() =>
                      setValidation({ ...Validation, identified: false })
                    }
                  />
                </div>
              </div>
              <div className="row m-0 p-0">
                {isReviewerAccess && (
                  <div className="col-md-6 col-12">
                    <label htmlFor="" className="py-2 labelCss">
                      Reviewer:
                    </label>
                    <AutoCompleteSelect
                      options={allUsersOfProject?.map((item) => {
                        return {
                          label: item?.first_name,
                          value: item?._id,
                        };
                      })}
                      value={reviewer}
                      optionLabel="label"
                      onChange={(value) => {
                        setReviewer(value);
                      }}
                      className={`p-0 ${Validation.reviewer ? "Validation" : ""
                        }`}
                      onClick={() =>
                        setValidation({ ...Validation, reviewer: false })
                      }
                      // disabled={reviewer === getUserId()}
                      placeholder="Select Reviewer"
                    />
                  </div>
                )}

                {isApproverAccess && (
                  <>
                    <div className="col-md-6 col-12">
                      <label htmlFor="" className="py-2 labelCss">
                        Approver:
                      </label>
                      <AutoCompleteSelect
                        options={allUsersOfProject?.map((item) => {
                          return {
                            label: item?.first_name,
                            value: item?._id,
                          };
                        })}
                        value={approver}
                        optionLabel="label"
                        onChange={(value) => {
                          setApprover(value);
                        }}
                        className={`p-0 ${Validation.approver ? "Validation" : ""
                          }`}
                        onClick={() =>
                          setValidation({ ...Validation, approver: false })
                        }
                        // disabled={approver === getUserId()}
                        placeholder="Select Approver"
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="row m-0 p-0 mt-3">
                <div className="col-md-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Description:
                  </label>
                  <Editor
                    style={{ height: "200px" }}
                    value={description}
                    onTextChange={(e) => setDescription(e.htmlValue)}
                  />
                </div>
              </div>
              <div className="row m-0 p-0 mt-3 css_com">
                <div className="col-md-12">
                  <DragAndDrop
                    setRemovePhoto={setRemovePhoto}
                    value={fileName}
                    setFiles={setFileUpload}
                    onChange={handleFileSubmit}
                    setValue={setFileName}
                    setPreviousFiles={setPreviousFile}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end  mt-3">
                <span
                  className="cancelBtn  px-3 py-2"
                  onClick={() =>
                    navigate("/projectManagement/coordinationIssues")
                  }
                >
                  {" "}
                  Cancel
                </span>
                &nbsp;&nbsp;
                <span
                  className="CreateRfi px-3 py-2"
                  onClick={handleCreateIssue}
                >
                  Create
                </span>
              </div>
            </div>
          )}
        </div>
        <br />
      </div>

      <AddSets
        loading={loading}
        setLoading={setLoading}
        visible={visibleType}
        setVisible={setVisibleType}
        CategoryName={"Priority"}
        label={"Priority"}
        reqFor={"create"}
        setValue={setTypeName}
        value={typeName}
        onSubmit={addTypeP}
      />

      <AddSets
        loading={loading}
        setLoading={setLoading}
        visible={visible}
        setVisible={setVisible}
        CategoryName={"Trade"}
        label={"Trade"}
        reqFor={"create"}
        setValue={setTradeValue}
        value={tradeValue}
        onSubmit={addTradeP}
      />
      <AddSets
        loading={loading}
        setLoading={setLoading}
        visible={typeVisible}
        setVisible={setTypeVisible}
        CategoryName={"Type"}
        label={"Type"}
        reqFor={"create"}
        setValue={setTypeValue}
        value={typeValue}
        onSubmit={addTypeType}
      />
    </>
  );
};

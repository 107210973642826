import { Dialog } from "primereact/dialog";
import React, { Children, useState } from "react";
import { GoArrowUp } from "react-icons/go";
import { BorderedTable } from "../../../Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import DeleteButton from "../../../Buttons/DeleteButton";
import { PrimaryButton } from "../../../Buttons/PrimaryButton";
import { addDivision } from "../../../../Api/Admin/AllAPIs";

export const AddDivision = ({
  project_id,
  getProjectes,
  existCode,
  setSecondTableData,
}) => {
  let [visible, setVisible] = useState(false);

  let [divisionData, setDivisionData] = useState({
    description: "",
    code: "",
    children: [{ code: "", description: "" }],
  });
  const [error, setError] = useState(false);
  const [codeError, setCodeError] = useState([]);
  const [desError, setDesError] = useState([]);

  function addCode() {
    setDivisionData((pre) => ({
      ...pre,
      children: [...pre.children, { code: "", description: "" }],
    }));
  }
  const validate = () => {
    if (divisionData.code.length === 0) {
      setError(2);
      return false;
    } else if (divisionData.description.length === 0) {
      setError(3);
      return false;
    } else if (childValidation()) {
      return false;
    } else {
      return true;
    }
  };
  const childValidation = () => {
    let code = [];
    let des = [];
    divisionData.children.forEach((child, index) => {
      if (child.code.length === 0) {
        code.push(index);
      }
      if (child.description.length === 0) {
        des.push(index);
      }
    });
    console.log(code, "code");
    console.log(des, "des");

    setCodeError(code);
    setDesError(des);
    if (code.length !== 0 || des.length !== 0) {
      return true;
    } else {
      return false;
    }
  };
  async function addDivisionSubmit() {
    let body = {
      ...divisionData,
      project_id: project_id,
    };

    console.log(body);
    if (validate()) {
      try {
        // let res = await addDivision(body);
        setSecondTableData((obj) => [...obj, divisionData]);
        setDivisionData({
          description: "",
          code: "",
          children: [{ code: "", description: "" }],
        });
        setVisible(false);
      } catch (err) {
        console.log(err);
      }
    }
  }

  function deleteCodes(ind) {

    setDivisionData(pre=>{
      let children = [...pre.children];
      children.splice(ind, 1);
      return {
       ...pre,
        children
      }
    })


  }

  return (
    <div>
      <PrimaryButton
        onClick={() => setVisible(true)}
        text=" Add Divisions" addIcon />
      <Dialog
        visible={visible}
        header="Add New Cost Division to Project"
        closable={false}
        draggable={false}
      >
        <div>
          <label>New Cost Division Code:</label>
          <input
            className="form-control"
            value={divisionData.code}
            onChange={(e) => {
              setDivisionData((pre) => {
                pre.code = e.target.value;
                return { ...pre };
              });
              if (existCode.includes(e.target.value)) {
                setError(1);
              } else {
                setError(0);
              }
            }}
          />

          {error === 1 && (
            <>
              <p className="text-danger mt-1">Division Code already Exists</p>
            </>
          )}
          {error === 2 && (
            <>
              <p className="text-danger mt-1">Please enter division code</p>
            </>
          )}
          <br />
          <label>New Division Description</label>
          <input
            className="form-control"
            value={divisionData.description}
            onChange={(e) => {
              setError(0);
              setDivisionData((pre) => {
                pre.description = e.target.value;
                return { ...pre };
              });
            }}
          />
          {error === 3 && (
            <>
              <p className="text-danger mt-1">Please enter division description</p>
            </>
          )}
          <br />
          <label>Add Division Codes</label>

          <div className="px-3">
            <table className="table bordered-table">
              <thead className="border">
                <tr>
                  <th className="border"></th>
                  <th className="border">Cost Codes</th>
                  <th className="border">Descriptions</th>
                  <th className="border"></th>
                </tr>
              </thead>

              <tbody className="border">
                {divisionData.children.map((res, ind) => {
                  return (
                    <tr>
                      <td>{ind + 1}</td>
                      <td className="border">
                        <input
                          value={res.code}
                          onChange={(e) => {
                            setCodeError(codeError.filter((s) => s !== ind));
                            setDivisionData((pre) => {
                              pre.children[ind].code = e.target.value;
                              return { ...pre };
                            });
                          }}
                          type="text"
                          className="form-control"
                        />
                        {codeError.length !== 0 && codeError.includes(ind) && (
                          <>
                            <p className="text-danger mt-1">
                              Please select code
                            </p>
                          </>
                        )}
                      </td>
                      <td className="border">
                        <input
                          type="text"
                          value={res.description}
                          onChange={(e) => {
                            setDesError(desError.filter((s) => s !== ind));
                            setDivisionData((pre) => {
                              pre.children[ind].description = e.target.value;
                              return { ...pre };
                            });
                          }}
                          className="form-control"
                        />
                        {desError.length !== 0 && desError.includes(ind) && (
                          <>
                            <p className="text-danger mt-1">
                              Please select description
                            </p>
                          </>
                        )}
                      </td>
                      <td>
                        
                        <DeleteButton onConfirm={() => deleteCodes(ind,)} type="button" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="pointer" onClick={() => addCode()}>
            Add Code
          </div>

          <br />

          <div className="d-flex justify-content-end">
            <button
              className="btn border me-3"
              onClick={() => setVisible(false)}
            >
              Cancel
            </button>
            <PrimaryButton
              text={"Add"}
              // disabled={error}
              onClick={addDivisionSubmit}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

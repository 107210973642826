import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import Calender from "../Common/Calender";
import { MultiSelect } from "primereact/multiselect";

// import { MultiSelect } from "primereact/multiselect";
import { getCompanyByProjectId, getUserByProjectId, getUsersByCompanyId } from "../../Api/Admin/AllAPIs";
import moment from "moment";
import AutoCompleteSelect from "../Common/AutoCompleteSelect";

const Filter = ({ filterData, setFilterData, onFilter, className, ...props }) => {

  const [visible, setVisible] = useState(false);
  const [allUsersOfProject, setAllUsersOfProject] = useState([]);
  const projectId = localStorage.getItem("projectId");
  const [companies, setCompanies] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [allCompanies, setAllCompanies] = useState([])


  const [responsibleContractor, setResponsibleContractor] = useState([]);
  const [membersResponsible, setMembersResponsible] = useState([]);

  const [users, setUsers] = useState([])




  const statusOptions = [
    { label: "Not Started", value: "Not Started" },
    { label: "In Progress", value: "In Progress" },
    { label: "Completed", value: "Completed" },
    { label: "Ready for Review", value: "Ready for Review" },
    { label: "Incomplete", value: "Incomplete" }
  ]

  function inpChange(event) {
    let { name, value } = event.target
    if (name == "dueDate") {
      setFilterData(pre => ({ ...pre, [name]: value }))
    }
    else {
      setFilterData(pre => ({ ...pre, [name]: value }))
    }
  };

  function handleFilter() {
    onFilter()
    setVisible(false)
  };








  const getAllUsers = async () => {
    try {
      let res = await getUserByProjectId(projectId)
      setAllUsers(res?.data?.data?.[0].combined_users || [])
    }
    catch (err) {
      console.log(err)
    }
  }

  const getAllCompany = async () => {
    try {
      let res = await getCompanyByProjectId(projectId)
      setAllCompanies(res?.data?.data?.[0]?.companynames || [])
    }
    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {

    getAllUsers()
    getAllCompany()
  }, [])



  return (
    <div className={`card flex justify-content-center ${className}`}>
      <button
        className="filter_btn me-3 w-100"
        onClick={() => setVisible(true)}
      >
        Filter
      </button>

      <Dialog
        header="Filter Rfi"
        visible={visible}
        maximizable
        style={{ width: "50rem", color: "#101928" }}
        onHide={() => setVisible(false)}
        headerClassName="addBorder_bottom"
        draggable={false}
      >
        <div className="row mt-4">
          <div className="col-md-6 mt-3">
            <label className="labels mb-1">Workflow Status:</label>

            <div className="card flex justify-content-center">
              <Dropdown
                value={filterData.workflow_status}
                onChange={inpChange}
                name="workflow_status"
                options={statusOptions}
                placeholder="Select Status"
              />
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <label className="labels mb-1">Responsible Contractor:</label>
            <div className="card flex justify-content-center">
              <MultiSelect
                filter={true}
                value={allCompanies.filter(e => filterData?.responsibleContractor?.includes(e._id))}
                placeholder="Responsible Contractor"
                optionLabel='company_name'
                onChange={(e) => {
                  setFilterData((pre) => ({ ...pre, responsibleContractor: e.value.map(e => e._id) }))
                }}
                options={allCompanies}
              />
              {/* <Dropdown
                value={filterData?.responsibleContractor}
                onChange={inpChange}
                name="responsibleContractor"
                options={allUsersOfProject.map(d => ({ label: d.first_name, value: d._id }))}
                placeholder="All Users"
              /> */}
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <label className="labels mb-1"> Responsible Person:</label>
            <div className="card flex justify-content-center">

              {/* <Dropdown
                value={filterData?.membersResponsible}
                onChange={inpChange}
                options={allUsersOfProject.map(d => ({ label: d.first_name, value: d._id }))}
                name="membersResponsible"
                placeholder="All Users"
              /> */}
              <div className="card flex justify-content-center  ">
                <MultiSelect
                  filter={true}
                  value={allUsers.filter(e => filterData?.assignees?.includes(e._id))}
                  onChange={(e) => {
                    setFilterData((pre) => ({ ...pre, assignees: e.value.map(e => e._id) }))
                  }}
                  optionLabel='first_name'
                  placeholder="Responsible Person"
                  options={allUsers}

                />
              </div>
            </div>
          </div>

          {/* <div className="col-md-6 mt-3">
            <label className="labels mb-1">Date initiated:</label>
            <div className="mb-1">
              <Calender placeholder="mm/dd/yy" value={filterData?.initiatedDate} onChange={inpChange} name="initiatedDate" />
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <label className="labels mb-1">Due Date:</label>

            <Calender placeholder="mm/dd/yy" value={filterData?.dueDate} onChange={inpChange} name="dueDate"
            />
          </div> */}
          <div className="col-md-12 mt-5 d-flex justify-content-end">
            <div className="d-flex align-items-center gap-3">
              <button className="filter_btn" onClick={() => setVisible(false)}>
                Cancel
              </button>
              <Button className="Apply_filter_btn py-2 px-2" onClick={handleFilter}>Apply Filter</Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default Filter;

import React, { useEffect, useRef, useState } from "react";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { useNavigate } from "react-router-dom";
import style from './meeting.module.css'
import { deleteMeetings, getMeetingByProjectId, } from "../../Api/Admin/AllAPIs";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportPdfButton } from "../../Components/Buttons/ExportPdfButton";
import Pagination from "../../Components/Common/Pagination/Pagination";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Popover from "../../Components/Common/Popover";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import { HtmlIntoText } from "../../Components/Common/HtmlIntoText";
import DeleteDialog from "../../Components/Common/DeleteDialog";
import { getActionsPermission, toast } from "../../utils";
import { DownloadPdfAndSendEmail } from "../../Components/Common/ViewPageComponents/DownloadPdfAndSendEmail";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import { useSelector } from "react-redux";


export const Meetings = () => {
  const [loader, setLoader] = useState(true);
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [originalRes, setOriginalRes] = useState([]);
  const [search, setSearch] = useState("");
  let dd = useSelector(e => e.rolesAndPermissionData)
  const projectId = localStorage.getItem('projectId')
  const [meetingdata, setMeetingData] = useState([])

  let [meetingId, setMeetingId] = useState("")
  let [visibleDelete, setVisibleDelete] = useState(false)


  const [page, setPage] = useState(1)
  const perPageData = 10;
  const [totalCount, setTotalCount] = useState(0);
  const [allData, setAllData] = useState([]);
  let [tableSortData, setTableSortData] = useState({
    page: 1,
    perPageData: perPageData,
    search: null
  });


  const getMeetingData = async () => {
    try {
      setSkeletonLoader(true);
      const response = await getMeetingByProjectId(projectId, tableSortData);
      const data = response?.data?.data || [];
      setTotalCount(response?.data?.totalCount || 0)
      setOriginalRes(data)
      setMeetingData(data)
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
    finally {
      setSkeletonLoader(false);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    getMeetingData();
  }, [tableSortData]);


  const csvData = originalRes.map(item => ({
    "Meeting Name": item.meeting_name,
    "Video Conferencing Link": item.videoConferencingLink,
    "Meeting Location": item.meetingLocation,
    "Meeting Date": moment(item.meetingDate).format('MM-DD-YYYY'),
    "Time Zone": item.timeZone,
    "Start Time": item.startTime,
    "End Time": item.endTime,
    "Attendees": item.meeting_attendees_data.map(e => e.first_name).join(", "),
  }));

  // Define CSV headers
  const csvHeaders = [
    { label: 'Meeting Name', key: 'Meeting Name' },
    { label: 'Video Conferencing Link', key: 'Video Conferencing Link' },
    { label: 'Meeting Location', key: 'Meeting Location' },
    { label: 'Meeting Date', key: 'Meeting Date' },
    { label: 'Time Zone', key: 'Time Zone' },
    { label: 'Start Time', key: 'Start Time' },
    { label: 'End Time', key: 'End Time' },
    { label: 'Attendees', key: 'Attendees' },
  ];


  const tableRef = useRef();

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      html: tableRef.current,
      // columnStyles: { 0: { columnWidth: 50 }, 1: { columnWidth: 50 }, 2: { columnWidth: 50 }, ... },
      didParseCell: function (data) {
        // if (data.row.index === 0) {
        //   // Scale down font size for header row
        data.cell.styles.fontSize = 2;
        // }
      },
    });
    doc.save('meetings.pdf');
  };
  async function confirmDelete() {
    try {
      let res = await deleteMeetings(meetingId)
      getMeetingData()
      toast.success("Meeting deleted successfully")
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <>

      <div className="">
        <div>
          <div className="between">
            <h1 className="page_heading">Meetings</h1>
            <div
              className="flex align_center gap-2"

            >
              <div className="right d-flex gap-2 align-items-center ">
                <ExportPdfButton exportToPDF={exportToPDF}
                  csvBtn={<CSVLink
                    className="pe-1"
                    data={csvData}
                    headers={csvHeaders}
                    filename={"meetings.csv"}
                  >
                    CSV
                  </CSVLink>
                  }
                />
              </div>
              {getActionsPermission("Create Meeting") && <div className={style.CreatMeeting}>
                <PrimaryButton
                  onClick={() => navigate("/projectManagement/meetings/create")}
                  addIcon
                  className={"text-nowrap"}
                  text={"Add Meeting"}
                />
              </div>
              }
            </div>
          </div>


          <div style={{ height: '0', overflow: 'hidden' }}>
            <table ref={tableRef}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Meeting Name</th>
                  <th>Video Conferencing Link</th>
                  <th>Meeting Location</th>
                  <th>Meeting Date</th>
                  <th>Time Zone</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Attendees</th>

                </tr>
              </thead>
              <tbody>
                {originalRes?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.meeting_name}</td>
                      <td>{item.videoConferencingLink}</td>
                      <td>{item.meetingLocation}</td>
                      <td>{moment(item.meetingDate).format('MM-DD-YYYY')}</td>
                      <td>{item.timeZone}</td>
                      <td>{item.startTime}</td>
                      <td>{item.endTime}</td>
                      <td>{item?.meeting_attendees_data?.map(e => e.first_name)?.join(", ")}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="my-4">
            <div className="searchInput">
              <SearchInput
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                  setTableSortData(pre => ({ ...pre, search: e.target.value }))
                }}
                onCancel={() => {
                  setSearch("")
                  setTableSortData(pre => ({ ...pre, search: "" }))
                }}
              />
            </div>

          </div>
          <div>
            <div className="tools_table_scroll">
              {

                skeletonLoader ?
                  <TableSkelton
                    header={[
                      "Sno.",
                      "Meeting Name",
                      "Description",
                      "Meeting Date",
                      "Time Zone",
                      "Start Time",
                      "End Time",
                      "Actions",
                    ]}
                  />
                  :
                  <DataTable
                    value={meetingdata}
                  >
                    <Column header="Sno." body={(rowData, data) => (<div>{data.rowIndex + 1}</div>)}></Column>
                    <Column field="meeting_name" header="Meeting Name "
                      body={(rowData) => (
                        <div
                          className=""
                        >
                          {rowData?.meeting_name}
                        </div>
                      )}></Column>
                    {/* <Column field="meeting_number" header="Meeting No"></Column> */}
                    <Column
                      field="description"
                      header="Description"
                      body={(rowData) => (
                        <HtmlIntoText html={rowData.description} />)}
                    ></Column>

                    <Column
                      field="meetingDate"
                      header="Meeting Date"
                      body={(rowData) => (
                        <div>
                          {" "}
                          {new Date(rowData.meetingDate).toLocaleDateString("en-GB")}{" "}
                        </div>
                      )}
                    ></Column>
                    <Column field="timeZone" header="Time Zone"></Column>
                    <Column field="startTime" header="Start Time"></Column>
                    <Column field="endTime" header="End Time"></Column>

                    <Column
                      field=""
                      header=""
                      style={{ width: '8rem' }}
                      body={(rowData) => (
                        <div className="d-flex justify-content-center">
                          {console.log(rowData)}
                          <Popover
                            options={["Version History", "Edit", "Delete", "View", "Send Via post",
                              <DownloadPdfAndSendEmail
                                heading={"Meeting"}
                                id={rowData._id}
                                fileName={"Meeting"}
                                files={rowData?.attachment?.map(e => e.fileUrl)}
                              >
                                <ViewPagesPdfTemplate
                                  header={`#TASK ${rowData?.meeting_name}`}
                                  tableData1={[
                                    { "Meeting Name": rowData?.meeting_name || "--" },
                                    { "Video Conferencing Link": rowData?.videoConferencingLink },
                                    { "Meeting Location": rowData?.meetingLocation },
                                    { "Meeting Date": moment(rowData?.meetingDate).format("DD/MM/YYYY") || "--" },
                                    { 'Meeting Guests': rowData?.meeting_guest?.join(",") || "--" }
                                  ]}

                                  tableData2={[
                                    { "Time Zone": rowData?.timeZone },
                                    { "Start Time": rowData?.startTime || "--" },
                                    { 'End Time': rowData?.endTime || "--" },
                                    { 'Meeting Attendees': rowData?.meeting_attendees.map(e => e.first_name).join(",") || "--" },
                                  ]}
                                />
                              </DownloadPdfAndSendEmail>


                            ]}
                            onSelect={(index) => {
                              if (index === 0) {

                                if (!getActionsPermission("Meeting version History")) {
                                  toast.error("You don't have permission of Meeting version history")
                                  return
                                }

                                navigate(`/projectManagement/version-history/Meeting/${rowData?.meeting_name}/${rowData?._id}`);
                              }
                              else if (index === 1) {
                                if (!getActionsPermission("Edit Meeting")) {
                                  toast.error("You don't have permission to edit Meeting")
                                  return
                                }
                                navigate(`edit/${rowData?._id}`);

                              }
                              // else if (index === 2){
                              //   navigate(`/meetings/view/${rowData?._id}`);

                              // }
                              else if (index === 2) {
                                // handleDelete(rowData?._id);
                                if (!getActionsPermission("Delete Meeting")) {
                                  toast.error("You don't have permission to delete Meeting")
                                  return
                                }
                                setMeetingId(rowData?._id);
                                setVisibleDelete(true)
                              }
                              else if (index === 3) {
                                if (!getActionsPermission("View Meeting")) {
                                  toast.error("You don't have permission to view Meeting")
                                  return
                                }
                                navigate(`view/meeting/${rowData?.meeting_name}/${rowData?._id}`);
                              }
                              else if (index === 4) {
                                navigate(`/projectManagement/addressListing/meeting/${rowData?.meeting_name}/${rowData?._id}`)
                              }
                            }}
                          />

                        </div>
                      )}
                    ></Column>
                  </DataTable>
              }

            </div>

            <br />
            <Pagination
              currentPage={tableSortData.page}
              totalCount={totalCount}
              pageSize={tableSortData.perPageData}
              onPageChange={(e) => setTableSortData(pre => ({ ...pre, page: e }))}
            />
          </div>
        </div>
      </div>
      <DeleteDialog
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        onCancel={() => setMeetingId(null)}
        onConfirm={confirmDelete}
        header={"Meeting"}
      />
      {/* 
      <Filter
        visible={visible}
        setVisible={setVisible}
        data={filterDatas}
        applyFilter={filterData}
      /> */}
    </>
  );
};

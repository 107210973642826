import { Skeleton } from '@mui/material'
import React from 'react'
import { BorderedTable } from '../Tables/BorderedTable'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

export const TableSkelton = ({ header, rows }) => {



    return (
        <div>
            <BorderedTable>
                <DataTable
                    value={[...Array(rows || 5)]}
                    tableStyle={{ minWidth: "20rem" }}
                    rows={5}
                    rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]}
                >
                    {header?.map((res, key) => {
                        return (
                            <Column headerClassName="text-nowrap" key={key} headerStyle={{ padding: "15px" }} style={{ padding: "0px 10px" }} field={""} header={res}
                                body={() => <Skeleton style={{ height: "40px" }} />}
                            ></Column>
                        )
                    })}
                </DataTable>
            </BorderedTable>

        </div>
    )
}

import React, { useState, useEffect, useRef } from "react";
import "./inspectionstemplates.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getInspectionDataById } from "../../Api/Admin/AllAPIs";
import { ViewPageTable } from "../../Components/Common/ViewPageComponents/ViewPageTable";
import { ViewPageDocumentName } from "../../Components/Common/ViewPageComponents/ViewPageDocumentName";
import { AiOutlineExport } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { downloadPdf } from "../../Components/Common/ViewPageComponents/downloadPdf";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import { HtmlIntoText } from "../../Components/Common/HtmlIntoText";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import ViewVersion from "../../Components/Common/ViewVersion";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";

//fetching inspection data.
function InspectionsTemplate({ includeVersionHistory, usedAsComponent }) {

  const [data, setData] = useState([]);

  const [inspectionData, setInspectionData] = useState([])

  const { id } = useParams();
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const skeletonData = [
    { qty: 1, height: "2.5rem" },
    { qty: 2, height: "2.5rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },

    { qty: 1, height: "1.4rem" },
    { qty: 1, height: "1.4rem" },
    { qty: 1, height: "1.4rem" },
    { qty: 1, height: "5rem" },


  ]


  async function fetchDataById() {
    try {
      setSkeletonLoader(true);
      const res = await getInspectionDataById(id);
      console.log(res.data.data)
      let data = res?.data?.data[0]
      setData(res?.data?.data?.[0]);

      let inspection_section = data.inspection_section.map(e => {
        let { children, ...rest } = e
        return ({ children: children.map(e => ({ data: e })), key: Math.random(), data: { ...rest } })
      })
      setInspectionData(inspection_section)


    } catch (error) {
      console.log(error, "error loading data");
    }
    finally {
      setSkeletonLoader(false);
    }
  }

  useEffect(() => {
    fetchDataById();
  }, []);
  let navigate = useNavigate();
  let pdfRef = useRef()
  function pdfDownload() {
    downloadPdf(pdfRef.current, [
      {
        heading: "Attached Documents", files: data?.attachFile.map(e => e.url)
      }
    ])
  }

  function formatResponseTableData(data) {

    const filteredData = data.map(section => {
      const filteredChildren = section.children.filter(child => child.data.responses.length > 0);
      return { ...section, children: filteredChildren };
    }).filter(section => section.children.length > 0);


    let final = filteredData.map((res) => {
      let parentInd = data.findIndex(e => e.id == res.id)
      let children = res.children.flatMap((dd, ind2) => {

        let subIndex = data[parentInd].children.findIndex(e => e.data.subsectionHeadingData == dd.data.subsectionHeadingData
        )
        let ok = [];
        dd.data.responses.forEach(responses => {
          let dddd = dd.data.responses.findIndex(e => e)
          ok.push({ data: { inspectionItem: dd.data.subsectionHeadingData, id: `${parentInd * 1 + 1}.${subIndex * 1 + 1}`, ...responses } });
        });
        return ok;
      });
      return {
        data: { ...res.data, id: parentInd * 1 + 1 },
        key: res.key,
        children: children,
      };
    });
    return final;

  }

  return (
    <>
      <div className="">
        <div>
          {
            skeletonLoader ? <FormSkeleton data={skeletonData} mt={"mt-2"} gap={"gap-3"} /> :
              <div className="paint-touch">
                <div className="row">
                  <div className="col-12 py-3 ">
                    <span
                      className="open_Rfi_text"
                      onClick={() => navigate("/projectManagement/inspection")}
                    >
                      {" "}
                      Inspections
                    </span>
                    <span className="px-1 open_Rfi_text"> /</span>
                    <span className="createNew_text"> {data?.name} </span>
                  </div>
                </div>
                <br />

                <div className="row">
                  {!usedAsComponent && <div className="d-flex justify-content-between mb-3">
                    <h1 className="heading">{data?.template_name} </h1>
                    <div className="d-flex align-items-center gap-2" onClick={pdfDownload}>
                      <div
                        className="d-flex gap-2 align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <AiOutlineExport />
                        Export
                        <MdOutlineKeyboardArrowDown />
                      </div>

                      {/* <div className="d-flex" style={{ cursor: "pointer" }}>
                    <BsThreeDotsVertical />
                  </div> */}
                    </div>
                  </div>}

                  <div className="row">
                    <div className="col-6">
                      <ViewPageTable tableData={[
                        { "Name": data?.name || "" },
                        { "Inspection Type": data?.inspection_type || "--" },
                        { "Inspection ID": data?.inspectionId || "--" },
                        { "Date": data?.date || "--" },
                        { "Participant": data?.participant || "--" },
                        { "Conducted By": data?.conductedBy || "--" },
                      ]} />
                    </div>
                    <div className="col-6">
                      <ViewPageTable tableData={[
                        { "Status": data?.status || "--" },
                        { "Inspection Trade": data?.trade || "--" },
                        { "Approver": data?.approver?.[0]?.first_name || "--" },
                        { "Reviewer": data?.reviewer?.[0]?.first_name || "--" },
                        { "Description": <HtmlIntoText html={data?.description || "--"} /> },
                      ]} />
                    </div>
                  </div>

                  <div className="view_page_para_head mt-4 mb-2">Inspection Description:</div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.description
                    }}
                  >
                  </div>
                </div>

                <div className="fs-4 mt-3">Section</div>
                <TreeTable value={inspectionData}
                  style={{ border: "2px solid red !important" }}

                >
                  <Column
                    className="border"
                    header="" expander style={{ width: "70px", border: "2px solid red !important" }}></Column>
                  <Column className="border" body={(e, ind) => {
                    let index = ind?.rowIndex?.toString()?.split("_")
                    let incrementedIndex = index?.length == 1 ? index * 1 + 1 : `${index[0] * 1 + 1}.${index[1] * 1 + 1}`
                    return (
                      <div>{incrementedIndex}</div>
                    )
                  }} header="Id"></Column>
                  <Column className="border" field="sectionHeading" header="Section Name"></Column>
                  <Column className="border" field="subsectionHeadingData" header="Inspection Item" ></Column>
                  <Column className="border" field="itemResult" header="Results" ></Column>
                  <Column className="border" field="feedback" header="Feedback" body={(e) => {
                    return (
                      e?.data?.feedback && <HtmlIntoText html={e?.data?.feedback} />
                    )
                  }} ></Column>
                  {/* <Column className="border" field="type" header="Attachment" ></Column> */}

                </TreeTable>
                <br />

                <div className="fs-4">Responses</div>
                <TreeTable value={formatResponseTableData(inspectionData)}
                  style={{ border: "2px solid red !important" }}
                >
                  <Column
                    className="border"
                    header="" expander style={{ width: "70px", border: "2px solid red !important" }}></Column>
                  <Column className="border" field="id" header="Id"></Column>
                  <Column className="border" field="sectionHeading" header="Section Name"></Column>
                  <Column className="border" field="inspectionItem" header="Section Finding Items"></Column>
                  <Column className="border" field="response"
                    body={(rowData) => {
                      return (
                        <div style={{
                          inlineSize: "150px",
                          overflowWrap: "break-word"
                        }}>{rowData?.data?.response && <HtmlIntoText html={rowData?.data?.response} />}</div>
                      )
                    }}
                    header="Feedback"></Column>
                  <Column className="border" field="attachments"
                    body={(rowData) => {
                      return !rowData.hasOwnProperty("key") && `Attachment:-${rowData?.data?.attachments?.length || 0}`
                    }}
                    header="Attachment"></Column>
                  <Column className="border" field="submitted_by" header="Response Submitted By"></Column>
                </TreeTable>














                {(!usedAsComponent || includeVersionHistory) && (
                  <>
                    <hr />
                    <ViewVersion isView={true} />
                    <br />
                    <br />
                  </>
                )}

              </div>}
        </div>

        <div style={{ height: "0px", overflow: "hidden" }}>
          <div ref={pdfRef}>
            <ViewPagesPdfTemplate
              header={`${data?.name}`}
              description={{
                key: "Incident Descriptions",
                value: <HtmlIntoText html={data?.description} />
              }}
              tableData1={[
                { "Name": data?.name || "" },
                { "Type": data?.inspection_type || "--" },
                { "Inspection ID": data?.inspectionId || "--" },
                { "Date": data?.date || "--" },
                { "Participant": data?.participant || "--" },
                { "Conducted By": data?.conductedBy || "--" },
              ]}
              tableData2={[
                { "Status": data?.status || "--" },
                { "Trade": data?.trade || "--" },
                { "Approver": data?.approver?.[0]?.first_name || "--" },
                { "Reviewer": data?.reviewer?.[0]?.first_name || "--" },
                { "Description": <HtmlIntoText html={data?.description || "--"} /> },

              ]}
            />

          </div>
        </div>
      </div>
      {!usedAsComponent &&
        <div className="d-flex justify-content-end mt-2">
          <button className='btn border' onClick={() => navigate("/projectManagement/inspection")}>Back</button>
        </div>}
      <br />
    </>
  );
}

export default InspectionsTemplate;

import "./inspections.module.css";
import React, { useEffect, useState } from "react";
import TextInput from "../../Components/Inputs/TextInput";
import SingleSelect from "../../Components/Common/SingleSelect";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { useNavigate } from "react-router";
import style from "./inspections.module.css";
import { FaAngleRight } from "react-icons/fa";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import {
  addInspectionData,
  addInspectionType,
  addTrade,
  getCountForInspectionByProjectId,
  getInspectionDataById,
  getInspectionTypeByProjectId,
  getTradeT,
  getTypeT,
  getUserByProjectId,
  updateInspectionData,
  uploadDocs,
} from "../../Api/Admin/AllAPIs";
import { Editor } from "primereact/editor";
import AddSection from "./AddSection";
import { useParams } from "react-router-dom";
import { getActionsPermission, getProjectId, getUserId, getUserName, toast } from "../../utils";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import CustomDragAndDrop from "../../Components/Inputs/CustomDragAndDrop";
import Popover from "../../Components/Common/Popover";
import { AddSectionItem } from "./AddSectionItem";
import { MdAdd } from "react-icons/md";
import AddSets from "../../Components/Common/AddSets";
import { HtmlIntoText } from "../../Components/Common/HtmlIntoText";
import { DeleteConfirmDialog } from "../../Components/Common/DeleteConfirmDialog";
import { AddResponse } from "./AddResponse";
import { TOOL_STATUS } from "../../constants";
import { FormHeader } from "../../Components/Common/FormHeader";
import { useSelector } from "react-redux";

const Newinspectiontemplate = ({ reqFor }) => {
  let [addTypeBtnLoader, setAddtypeBtnLoader] = useState(false);
  let s=useSelector(e=>e.rolesAndPermissionData)
  let [addInspectionTypeDialog, setAddInspectionTypeDialog] = useState(false);
  let [inspectionTypeName, setInspectionTypeName] = useState("");
  let [allInspectionTypes, setAllInspectionTypes] = useState([]);
  let [addTradeBtnLoader, setAddtradeBtnLoader] = useState(false);
  let [addInspectionTradeDialog, setAddInspectionTradeDialog] = useState(false);
  let [inspectionTradeName, setInspectionTradeName] = useState("");
  let [allInspectionTrades, setAllInspectionTrades] = useState([]);
  const userData = localStorage.getItem("userData");
  const projectData = JSON.parse(localStorage.getItem("projectData"));

  const isApproverAccess = getActionsPermission("Inspection Approver");
  const isReviewerAccess = getActionsPermission("Inspection Reviewer");

  const userObj = JSON.parse(userData);
  const userId = userObj._id;
  const userName = userObj.name;
  let [btnLoader, setBtnLoader] = useState(false);

  async function addInspectionTradeHandler() {
    if (inspectionTradeName?.trim() == "") {
      toast.error("Please fill inspection Trade !")
      return
    }
    try {
      setAddtradeBtnLoader(true);
      let body = {
        name: inspectionTradeName,
        project_id: getProjectId(),
      };
      let res = await addTrade(body);
      getAllInspectionTrades();
    } catch (err) {
      console.log(err);
    } finally {
      setAddtradeBtnLoader(false);
      setAddInspectionTradeDialog(false);
      setInspectionTradeName("");
    }
  }
  async function addInspectionTypeHandler() {

    if (inspectionTypeName?.trim() == "") {
      toast.error("Please fill inspection type !")
      return
    }

    try {
      setAddtypeBtnLoader(true);
      let body = {
        name: inspectionTypeName,
        project_id: getProjectId(),
      };
      let res = await addInspectionType(body);
      getAllInspectionTypes();
    } catch (err) {
      console.log(err);
    } finally {
      setAddtypeBtnLoader(false);
      setAddInspectionTypeDialog(false);
      setInspectionTypeName("");
    }
  }

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      inspectionId: "",
      inspection_type: "",
      date: "",
      participant: "",
      status: TOOL_STATUS.draft,
      conductedBy: "",
      approver_id: "",
      reviewer_id: "",
      description: "",
      attachFile: [],
      project_id: "",
      email_data: "",
      creator_email: "",
      inspection_section: [],
      project_id: getProjectId(),
      creator_email: getUserId(),
      trade: "",
      created_by: userName,
      project_name: projectData.project_name,
      project_number: projectData.projectNumber,
    },
  });

  const { fields, append, getValues, remove } = useFieldArray({
    control,
    name: "inspection_section",
  });

  const [allUsersOfProject, setAllUsersOfProject] = useState([]);
  const [skeletonLoader, setSkeletonLoader] = useState(
    reqFor === "edit" ? true : false
  );
  const skeletonData = [
    { qty: 2 },
    { qty: 2 },
    { qty: 1, height: "15rem" },
    { qty: 1, height: "7rem" },
  ];

  useEffect(() => {
    getAllInspectionTypes();
    getAllInspectionTrades();
    if (!id) {
      getIdCount();
    }
  }, []);

  useEffect(() => {
    if (id) {
      getInspectionById(id);
    }
  }, []);

  let status = watch("status");
  async function getInspectionById(id) {
    try {
      let res = await getInspectionDataById(id);
      let {
        name,
        inspectionId,
        inspection_type,
        date,
        trade,
        attachFile,
        participant,
        conductedBy,
        approver_id,
        reviewer_id,
        description,
        project_id,
        creator_email,
        inspection_section,
        status,
        approver_comment,
        reviewer_comment,

      } = res?.data?.data?.[0];

      inspection_section = inspection_section.map((e) => {
        let { children, ...rest } = e;
        return {
          children: children.map((e) => ({ data: e })),
          key: Math.random(),
          data: { ...rest },
        };
      });

      let obj = {
        name,
        inspectionId,
        inspection_type,
        date,
        status,
        participant,
        conductedBy,
        approver_id,
        reviewer_id,
        trade,
        description,
        attachFile: attachFile,
        project_id,
        creator_email,
        approver_comment,
        reviewer_comment,
        created_by: userName,
        project_name: projectData.project_name,
        project_number: projectData.projectNumber,
        inspection_section,
      };
      reset(obj);
    } catch (err) {
      console.log(err);
    } finally {
      setSkeletonLoader(false);
    }
  }

  async function getAllInspectionTrades() {
    try {
      let res = await getTradeT(getProjectId());
      setAllInspectionTrades(res.data.data);
    } catch (err) {
      console.log(err);
    }
  }
  async function getAllInspectionTypes() {
    try {
      let res = await getInspectionTypeByProjectId(getProjectId());
      setAllInspectionTypes(res.data.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function getIdCount() {
    try {
      let res = await getCountForInspectionByProjectId(projectId);
      let id = res.data.data;
      setValue("inspectionId", id);
    } catch (err) {
      console.log(err);
    }
  }
  const projectId = localStorage.getItem("projectId");
  const [deletedFiles, setDeletedFiles] = useState([]);

  const addSection = (sectionName) => {
    append({
      data: { sectionHeading: sectionName, rowType: "section" },
      key: Math.random(),
      children: [],
    });
  };

  const editSection = (sectionName, dd) => {
    const sections = watch("inspection_section");
    sections[dd.ind.rowIndex].data.sectionHeading = sectionName
    setValue("inspection_section", sections);
  }



  const addEditSubsection = (data, sectionIndex, subSectionIndex) => {
    const sections = watch("inspection_section");
    if (subSectionIndex) {
      sections[sectionIndex].children[subSectionIndex] = { data };
    } else {
      sections[sectionIndex].children.push({
        data: { ...data, rowType: "subSection" },
      });
    }
    setValue("inspection_section", sections);
  };

  const addResponse = (data) => {
    let { section, subSection, ...rest } = data;
    const sections = watch("inspection_section");
    sections[section].children?.[subSection].data.responses.push(rest);
    setValue("inspection_section", sections);
  };

  const navigate = useNavigate();
  const { id } = useParams();

  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId);
      let allUsers = res?.data?.data?.[0]?.combined_users || [];
      setAllUsersOfProject(allUsers);
    } catch (err) {
      console.log(err);
    }
  }

  const deleteSubSection = (ind) => {
    let sectionIndex = ind.split("_")[0];
    let subSectionIndex = ind.split("_")[1];
    const sections = watch("inspection_section");
    sections[sectionIndex].children.splice(subSectionIndex, 1);
    setValue("inspection_section", sections);
  };
  const deleteSection = (ind) => {
    const sections = watch("inspection_section");
    sections.splice(ind, 1);
    setValue("inspection_section", sections);
  };

  useEffect(() => {
    getAllUsersOfProject();
  }, []);

  const formSubmitInspection = async (obj) => {
    try {
      setBtnLoader(true);
      let finalObj = {
        ...obj,
        inspection_section: obj.inspection_section.map((res2) => {
          return {
            ...res2.data,
            children: res2.children.map((d) => ({
              ...d.data,
              responses: d.data.responses.map(({ _id, ...d }) => d),
            })),
          };
        }),
      };


      const uploadFile = async (file) => {
        if (file?.file) {
          const formData = new FormData();
          formData.append("upload", file.file);
          let res = await uploadDocs(formData);
          return res.data[0];  // Return the uploaded file info
        }
        return file; // If no file is to be uploaded, return the original file object
      };

      const uploadFilesForKey = async (files) => {
        if (files?.length > 0) {
          const updatedFiles = await Promise.all(files.map(file => uploadFile(file)));
          return updatedFiles;
        }
        return files;
      };

      const uploadSubsectionFiles = async (children) => {
        if (children?.length > 0) {
          for (let j = 0; j < children.length; j++) {
            const child = children[j];

            if (child?.responses?.length > 0) {
              for (let response = 0; response < child.responses.length; response++) {
                const responseObj = child.responses[response];

                if (responseObj?.attachments?.length > 0) {
                  responseObj.attachments = await Promise.all(
                    responseObj.attachments.map(attachment => uploadFile(attachment))
                  );
                }
              }
            }

            if (child?.attachFile?.length > 0) {
              child.attachFile = await uploadFilesForKey(child.attachFile);
            }
          }
        }
      };

      const uploadAllFiles = async (finalObj) => {
        finalObj.attachFile = await uploadFilesForKey(finalObj.attachFile);


        if (finalObj?.inspection_section?.length > 0) {
          for (let i = 0; i < finalObj.inspection_section.length; i++) {
            const section = finalObj.inspection_section[i];
            await uploadSubsectionFiles(section.children);
          }
        }

        return finalObj;
      };
      const body = await uploadAllFiles(finalObj);

      if (id) {
        if (
          body.reviewer_id &&
          body.approver_id &&
          body.status === TOOL_STATUS.draft
        ) {
          body.status = TOOL_STATUS.inreview;
        }
        body.user_name=getUserName()
        let res2 = await updateInspectionData(id, body);
      } else {
        if (body.reviewer_id && body.approver_id) {
          body.status = TOOL_STATUS.inreview;
        }
        let res = await addInspectionData(body);
      }
      navigate("/projectManagement/inspection");
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || "Some Error Occured");
    } finally {
      setBtnLoader(false);
    }
  };

  function formatResponseTableData(data) {
    const filteredData = data
      .map((section) => {
        const filteredChildren = section.children.filter(
          (child) => child.data.responses.length > 0
        );
        return { ...section, children: filteredChildren };
      })
      .filter((section) => section.children.length > 0);

    let final = filteredData.map((res) => {
      let parentInd = data.findIndex((e) => e.id == res.id);
      let children = res.children.flatMap((dd, ind2) => {
        let subIndex = data[parentInd].children.findIndex(
          (e) => e.data.subsectionHeadingData == dd.data.subsectionHeadingData
        );
        let ok = [];
        dd.data.responses.forEach((responses) => {
          let dddd = dd.data.responses.findIndex((e) => e);
          ok.push({
            data: {
              inspectionItem: dd.data.subsectionHeadingData,
              id: `${parentInd * 1 + 1}.${subIndex * 1 + 1}`,
              ...responses,
            },
          });
        });
        return ok;
      });
      return {
        data: { ...res.data, id: parentInd * 1 + 1 },
        key: res.key,
        children: children,
      };
    });
    return final;
  }

  function deleteResponse(rowType, rowData) {
    const sections = watch("inspection_section");

    if (rowType === "removeFullSection") {
      let ind = rowData.data.id - 1;
      // remove all the responses if main section  deleted from response
      sections[ind].children = sections[ind].children.map((e) => ({
        data: { ...e.data, responses: [] },
      }));
    } else {
      let resInd = rowData.data.id.toString().split(".");
      let sectionInd = resInd[0] - 1;
      let subSectionInd = resInd[1] - 1;
      console.log(sectionInd);
      // sections[sectionInd].children[subSectionInd]
      let inddddd = sections[sectionInd].children[
        subSectionInd
      ].data.responses.findIndex((e) => e._id == rowData.data._id);

      sections[sectionInd].children[subSectionInd].data.responses.splice(
        inddddd,
        1
      );

      console.log(sections[sectionInd]);
      console.log(rowData.data);
      console.log(inddddd);
    }

    setValue("inspection_section", sections);

    // console.log(rowType)
  }
  function onFormError() {
    toast.error("Fields with * are mandatory")
  }

  return (
    <>
      <form onSubmit={handleSubmit(formSubmitInspection, onFormError)}>
        <div className={` ${style.createPunch_con}`}>
          <div className="">


            <FormHeader
              to={"/projectManagement/inspection"}
              formHeader={"Inspection"}
              breadcrumItem={["Inspection", `${reqFor === "edit" ? "Edit Inspection Title" : "New Inspection Title"}`]} />



            <>


              {skeletonLoader ? (
                <FormSkeleton data={skeletonData} />
              ) : (
                <>
                  <div className="row m-0 p-0">
                    <div className="col-md-4">
                      <label htmlFor="" className="py-2 labelCss d-flex">
                        Inspection Id <span className="text-danger">*</span>
                      </label>

                      <Controller
                        name="inspectionId"
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <TextInput
                              className={errors?.inspectionId && "Validation"}
                              value={value}
                              onChange={onChange}
                              placeholder={"Inspection ID"}
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-8">
                      <label htmlFor="" className="py-2 labelCss d-flex">
                        Inspection Title<span className="text-danger">*</span>
                      </label>

                      <Controller
                        name="name"
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <TextInput
                              className={
                                errors?.inspection_type && "Validation"
                              }
                              value={value}
                              onChange={onChange}
                              placeholder={"Inspection Title"}
                            />
                          );
                        }}
                      />
                    </div>

                    <div className="col-md-6 mt-3">
                      <label htmlFor="" className="py-2 labelCss d-flex">
                        Inspection Trade:<span className="text-danger">*</span>
                      </label>

                      <div className="d-flex align-items-center">
                        <div className="w-100">
                          <Controller
                            name="trade"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <SingleSelect
                                  className={errors?.trade ? "Validation" : ""}
                                  placeholder="Inspection Trade"
                                  options={allInspectionTrades.map(
                                    (e) => e.name
                                  )}
                                  value={value}
                                  onChange={onChange}
                                  filter={true}
                                  filterInputAutoFocus={true}
                                />
                              );
                            }}
                          />
                        </div>
                        <div>
                          <MdAdd
                            className="fs-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => setAddInspectionTradeDialog(true)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mt-3">
                      <label htmlFor="" className="py-2 labelCss d-flex">
                        Inspection Type:<span className="text-danger">*</span>
                      </label>

                      <div className="d-flex align-items-center ">
                        <div className="w-100">
                          <Controller
                            name="inspection_type"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <SingleSelect
                                  className={
                                    errors?.inspection_type ? "Validation" : ""
                                  }
                                  placeholder="Inspection Type"
                                  options={allInspectionTypes.map(
                                    (e) => e.name
                                  )}
                                  value={value}
                                  onChange={onChange}
                                  filter={true}
                                  filterInputAutoFocus={true}
                                />
                              );
                            }}
                          />
                        </div>
                        <div>
                          <MdAdd
                            className="fs-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => setAddInspectionTypeDialog(true)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 mt-3">
                      <label htmlFor="" className="py-2 labelCss d-flex">
                        Inspection Date:<span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="date"
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <input
                              type="date"
                              style={{ height: "50px" }}
                              className={`${errors?.date && "Validation"
                                } form-control`}
                              value={value}
                              onChange={onChange}
                              placeholder={"Inspection Date"}
                            />
                          );
                        }}
                      />
                    </div>

                    <div className="col-md-6 mt-3">
                      <label htmlFor="" className="py-2 labelCss">
                        Participants:<span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="participant"
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <TextInput
                              className={errors?.participant && "Validation"}
                              value={value}
                              onChange={onChange}
                              placeholder={"Participant"}
                            />
                          );
                        }}
                      />
                    </div>

                    <div className="col-md-6 mt-3">
                      <label htmlFor="" className="py-2 labelCss">
                        Conducted By:<span className="text-danger">*</span>
                      </label>

                      <Controller
                        name="conductedBy"
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <TextInput
                              className={errors?.conductedBy && "Validation"}
                              value={value}
                              onChange={onChange}
                              placeholder={"Conducted By"}
                            />
                          );
                        }}
                      />
                    </div>

                    {isReviewerAccess && <div className="col-md-6 col-12 mt-3">
                      <label htmlFor="" className="py-2 labelCss">
                        Reviewer:
                      </label>

                      <Controller
                        name="reviewer_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <SingleSelect
                              placeholder="Reviewer"
                              className={errors.reviewer_id ? "Validation" : ""}
                              optionLabel="first_name"
                              options={allUsersOfProject}
                              value={
                                allUsersOfProject?.find(
                                  (e) => e._id == value
                                ) || null
                              }
                              onChange={(e) => {
                                onChange(e?.value?._id || null);
                              }}
                              filter={true}
                              filterInputAutoFocus={true}
                            />
                          );
                        }}
                      />
                    </div>

                    }
                    {isApproverAccess &&
                      <div className="col-md-6 col-12 mt-3">
                        <label htmlFor="" className="py-2 labelCss">
                          Approver:
                        </label>
                        <Controller
                          name="approver_id"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <SingleSelect
                                placeholder="Approver"
                                className={errors.approver_id ? "Validation" : ""}
                                optionLabel="first_name"
                                options={allUsersOfProject}
                                value={
                                  allUsersOfProject?.find(
                                    (e) => e._id == value
                                  ) || null
                                }
                                onChange={(e) => {
                                  onChange(e?.value?._id || null);
                                }}
                                filter={true}
                                filterInputAutoFocus={true}
                              />
                            );
                          }}
                        />
                      </div>
                    }
                    <div className="col-md-6 col-12 mt-3"></div>

                    {id && <>
                      <div className="col-md-6 col-12 mt-3">
                        <label htmlFor="" className="py-2 labelCss">
                          Approver Comment:
                        </label>

                        <Controller
                          name="approver_comment"
                          control={control}

                          render={({ field: { onChange, value } }) => {
                            return (
                              <textarea disabled value={value} className="form-control"></textarea>
                            )
                          }}
                        >


                        </Controller>


                      </div>


                      <div className="col-md-6 col-12 mt-3">
                        <label htmlFor="" className="py-2 labelCss">
                          Reviewer Comment:
                        </label>
                        <Controller
                          name="reviewer_comment"
                          control={control}

                          render={({ field: { onChange, value } }) => {
                            return (
                              <textarea disabled value={value} className="form-control"></textarea>
                            )
                          }}
                        />
                      </div>
                    </>}

                    <div className="col-md-12">
                      <label htmlFor="" className="py-2 labelCss">
                        Inspection Instructions:
                      </label>

                      <Controller
                        name="description"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Editor
                              style={{ height: "200px" }}
                              value={value}
                              onTextChange={(e) => onChange(e.htmlValue)}
                            />
                          );
                        }}
                      />
                    </div>

                    <div className="col-md-12 mt-3">
                      <Controller
                        name="attachFile"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <CustomDragAndDrop
                              value={value}
                              setDeleted={setDeletedFiles}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    </div>

                    {/* {id && (
                      <>
                        <div className="col-md-12">
                          <label htmlFor="" className="py-2 labelCss">
                            Approver Comment
                          </label>
                          <Controller
                            name="approver_comment"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <textarea
                                  className="form-control"
                                  value={value}
                                  disabled
                                />
                              );
                            }}
                          />
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="" className="py-2 labelCss">
                            Reviewer Comment
                          </label>
                          <Controller
                            name="reviewer_comment"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <textarea
                                  className="form-control"
                                  value={value}
                                  disabled
                                />
                              );
                            }}
                          />
                        </div>
                      </>
                    )} */}
                  </div>
                </>
              )}
              <div className="row m-0 p-0">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="fw-bold fs-6 mb-1 mt-3">
                    Inspection Sections
                  </div>
                  <AddSection onSubmit={addSection} />
                </div>
                <TreeTable
                  value={fields}
                  style={{ border: "2px solid red !important" }}
                >
                  <Column
                    className="border"
                    header=""
                    expander
                    style={{
                      width: "70px",
                      border: "2px solid red !important",
                    }}
                  ></Column>
                  <Column
                    className="border"
                    body={(e, ind) => {
                      let index = ind?.rowIndex?.toString()?.split("_");
                      let incrementedIndex =
                        index?.length == 1
                          ? index * 1 + 1
                          : `${index[0] * 1 + 1}.${index[1] * 1 + 1}`;
                      return <div>{incrementedIndex}</div>;
                    }}
                    header="Id"
                  ></Column>
                  <Column
                    className="border"
                    field="sectionHeading"
                    header="Section Name"
                  ></Column>
                  <Column
                    className="border"
                    field="subsectionHeadingData"
                    header="Inspection Item"
                  ></Column>
                  <Column
                    className="border"
                    field="itemResult"
                    header="Results"
                  ></Column>
                  <Column
                    className="border"
                    field="feedback"
                    header="Feedback"
                    body={(e) => {
                      return (
                        e?.data?.feedback && (
                          <HtmlIntoText html={e?.data?.feedback} />
                        )
                      );
                    }}
                  ></Column>
                  <Column
                    className="border"
                    field="type"
                    header="Attachment"
                    body={(e) => {
                      console.log(e, "[[][][][]][")
                      return !e.key ? "Attachment :- " + e?.data?.attachFile?.length || 0 : ""
                    }}
                  ></Column>
                  <Column
                    className="border"
                    header="Action"
                    body={(rowData, ind) => {
                      return (
                        <>
                          {rowData.data.rowType === "section" && (
                            <div>
                              <Popover
                                options={[
                                  <AddSectionItem
                                    onSubmit={addEditSubsection}
                                    sectionIndex={ind.rowIndex}
                                    mode={"Add"}
                                  />,
                                  <AddSection
                                    name={{ data: rowData?.data?.sectionHeading, ind: ind }}

                                    mode={"edit"}
                                    onSubmit={editSection}
                                  />,

                                  status !== TOOL_STATUS.approved && (
                                    <DeleteConfirmDialog
                                      onClick={() =>
                                        deleteSection(ind.rowIndex)
                                      }
                                      stopPropagation
                                    >
                                      Delete
                                    </DeleteConfirmDialog>
                                  ),
                                ]}
                                onSelect={(e) => { }}
                              ></Popover>
                            </div>
                          )}
                          {rowData.data.rowType === "subSection" && (
                            <div>
                              <Popover
                                onSelect={(e) => { }}
                                options={[
                                  <AddSectionItem
                                    subSectionIndex={ind.rowIndex}
                                    data={rowData?.data}
                                    onSubmit={addEditSubsection}
                                    sectionIndex={ind.rowIndex}
                                    mode={"Edit"}
                                  />,
                                  status !== TOOL_STATUS.approved && (
                                    <DeleteConfirmDialog
                                      onClick={() =>
                                        deleteSubSection(ind.rowIndex)
                                      }
                                      stopPropagation
                                    >
                                      Delete
                                    </DeleteConfirmDialog>
                                  ),
                                ]}
                              />
                            </div>
                          )}
                        </>
                      );
                    }}
                  ></Column>
                </TreeTable>
              </div>

              {fields?.length > 0 && id && (
                <div className="d-flex mt-3 justify-content-between align-items-center">
                  <div className="fw-bold fs-6 mb-1 mt-3">
                    Inspection Response
                  </div>
                  <AddResponse inspectionData={fields} onSubmit={addResponse} />
                </div>
              )}

              {formatResponseTableData(fields)?.length > 0 && id && (
                <TreeTable
                  value={formatResponseTableData(fields)}
                  style={{ border: "2px solid red !important" }}
                >
                  <Column
                    className="border"
                    header=""
                    expander
                    style={{
                      width: "70px",
                      border: "2px solid red !important",
                    }}
                  ></Column>
                  <Column className="border" field="id" header="Id"></Column>
                  <Column
                    className="border"
                    field="sectionHeading"
                    header="Section Name"
                  ></Column>
                  <Column
                    className="border"
                    field="inspectionItem"
                    header="Section Finding Items"
                  ></Column>
                  <Column
                    className="border"
                    field="response"
                    body={(rowData) => {
                      return (
                        <div
                          style={{
                            inlineSize: "150px",
                            overflowWrap: "break-word",
                          }}
                        >
                          {rowData?.data?.response && (
                            <HtmlIntoText html={rowData?.data?.response} />
                          )}
                        </div>
                      );
                    }}
                    header="Feedback"
                  ></Column>
                  <Column
                    className="border"
                    field="attachments"
                    body={(rowData) => {
                      return (
                        !rowData.hasOwnProperty("key") &&
                        `Attachment:-${rowData?.data?.attachments?.length || 0}`
                      );
                    }}
                    header="Attachment"
                  ></Column>

                  <Column
                    className="border"
                    field="submitted_by"
                    header="Response Submitted By"
                  ></Column>
                  <Column
                    className="border"
                    body={(rowData) => {
                      return (
                        <Popover
                          options={[
                            <DeleteConfirmDialog
                              onClick={() =>
                                deleteResponse(
                                  rowData?.key
                                    ? "removeFullSection"
                                    : "removeSingleResponse",
                                  rowData
                                )
                              }
                              stopPropagation
                            >
                              Delete
                            </DeleteConfirmDialog>,
                          ]}
                          onSelect={() => {
                            // console.log(rowData, "p[p[p[")
                          }}
                        />
                      );
                    }}
                    header="Action"
                  ></Column>

                  {/* <Column className="border" header="Action"></Column> */}
                </TreeTable>
              )}

              <div className="row m-0 p-0">
                <div className="d-flex justify-content-end mt-4">
                  <button
                    type="button"
                    className="btn border me-3"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>
                  <PrimaryButton
                    loader={btnLoader}
                    type="submit"
                    text={reqFor !== "edit" ? "Create" : "Update"}
                  />
                </div>
              </div>
            </>
            <br />
          </div>
        </div>
      </form >
      <AddSets
        loading={addTypeBtnLoader}
        visible={addInspectionTypeDialog}
        setVisible={setAddInspectionTypeDialog}
        CategoryName={"Inspection Type"}
        label={"Inspection Type"}
        reqFor={"create"}
        value={inspectionTypeName}
        setValue={setInspectionTypeName}
        onSubmit={addInspectionTypeHandler}
      />
      <AddSets
        loading={addTradeBtnLoader}
        visible={addInspectionTradeDialog}
        setVisible={setAddInspectionTradeDialog}
        CategoryName={"Inspection Trade"}
        label={"Inspection Trade"}
        reqFor={"create"}
        value={inspectionTradeName}
        setValue={setInspectionTradeName}
        onSubmit={addInspectionTradeHandler}
      />
    </>
  );
};

export default Newinspectiontemplate;

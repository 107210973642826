import { useNavigate } from "react-router-dom"
import "./common.css"

export const FormHeader = ({ to, formHeader, breadcrumItem }) => {
    let navigate = useNavigate()
    return (
        <>
            <div>
                <span
                    className="form_header_breatcrum_item pointer text-capitalize"
                    onClick={() => navigate(to)}
                >
                    {breadcrumItem?.[0] || ""}
                </span>
                <span className="px-1 open_Rfi_text">/</span>
                <span className="text-capitalize form_header_breatcrum_item">{breadcrumItem?.[1] || ""}</span>
            </div>
            <div className="text-capitalize heading my-3">{formHeader}</div></>
    )
}
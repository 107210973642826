import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./Routes";
import 'bootstrap/dist/css/bootstrap.min.css';
import { PrimeReactProvider } from "primereact/api";
import "./App.css"
import "primereact/resources/themes/lara-light-cyan/theme.css";

import { useSelector } from "react-redux";
import GlobalCustomSnakbar from "./Components/Common/GlobalCustomSnakbar";
import { CustomLoader } from "./Components/Common/CustomLoader";

function App({ pageProps }) {

  const snackbarData = useSelector((e) => e.snackbar);
  return (
    <>

      <PrimeReactProvider>
        <BrowserRouter>
          <GlobalCustomSnakbar snackBarData={snackbarData} />
          <CustomLoader />
          <Router {...pageProps} />
        </BrowserRouter>
      </PrimeReactProvider>
    </>
  );

}
export default App


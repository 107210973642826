import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./openrfis.css";
import { BorderedTable } from "../Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Statuscomp } from "../Common/StatusComp";
import ResponseDialog from "./ResponseDialog";
import { AiOutlineExport } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { getRfiById, sendEmailWithAttachment } from "../../Api/Admin/AllAPIs";
import axios from "axios";
import fileDownload from "js-file-download";
import { ViewPageTable } from "../Common/ViewPageComponents/ViewPageTable";
import moment from "moment";
import { ViewPageDocumentName } from "../Common/ViewPageComponents/ViewPageDocumentName";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { toPng } from "html-to-image";
import { ViewPagesPdfTemplate } from "../Common/ViewPageComponents/ViewPagesPdfTemplate";
import FormSkeleton from "../Common/FormSkeleton";
import { TableSkelton } from "../Common/TableSkelton";
import EmailTemplateWithPdf from "../Common/EmailTemplateWithPdf";
import ViewVersion from "../Common/ViewVersion";
import { downloadPdf } from "../Common/ViewPageComponents/downloadPdf";
import { ViewPdf } from "../Common/ViewPdf";

export default function RfiView({ includeVersionHistory, usedAsComponent }) {


  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [rfi, setRfi] = useState({});
  const [contractorData, setContractorData] = useState([]);

  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const skeletonData = [
    { qty: 1, height: "2.5rem" },
    { qty: 2, height: "2.5rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 1, height: "2.4rem" },

    { qty: 1, height: "5rem" },
  ];
  let [attachedData, setAttachedData] = useState({
    drawing: [],
    files: [],
  });
  let pdfRef = useRef();
  const { id } = useParams();

  const handleGetRfi = async () => {
    try {
      setSkeletonLoader(true);
      const response = await getRfiById(id);
      const rfiData = response?.data?.data;
      console.log(rfiData);
      let data = rfiData?.responsibleContractor?.map((res) => {
        let pp = rfiData?.membersResponsible?.filter(
          (item) => item?.company == res?._id
        );
        return {
          company: res?.company_name,
          persons:
            pp.length == 0
              ? "No Person Added"
              : pp?.map((ress) => ress?.first_name)?.join(", "),
        };
      });
      setContractorData(data);
      setRfi(rfiData);
      setAttachedData({
        drawing: [],
        files: [
          ...response?.data?.data?.attachFile.map((e) => e.fileUrl),
          ...response?.data?.data?.attachFile.map((e) => e.fileUrl).flat(Infinity),
        ],
        // files: response.data.data.attachFile.map((e) => e.fileUrl),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setSkeletonLoader(false);
    }
  };

  useEffect(() => {
    handleGetRfi();
  }, []);

  const handleDownload = async (fileName, fileUrl) => {
    console.log("Downloading file:", fileName, "from URL:", fileUrl);
    try {
      const response = await axios.get(fileUrl, { responseType: "blob" });
      console.log("Response:", response);
      fileDownload(response.data, fileName);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  function downloadPdf2() {
    const content = pdfRef.current;

    let urlLinks = [...rfi?.attachFile?.map(e => e.fileUrl), ...rfi?.response?.map(es => es?.attachFile.map(sa => sa?.fileUrl)).flat(Infinity)]


    downloadPdf(pdfRef.current, [
      {
        heading: "Attached Documents", files: urlLinks
      }
    ], "rfi")
    return


    const array1 = attachedData.files;
    const array2 = [];
    toPng(content, { quality: 1.0 })
      .then((dataUrl) => {
        const pdf = new jsPDF("p", "mm", "a4");
        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
        let startY = pdfHeight + 10; // Starting position for the first heading and link
        // Function to add links with a heading
        const addLinksWithHeading = (heading, links) => {
          pdf.setFontSize(14);
          // pdf.setFont("helvetica", "bold");
          pdf.setTextColor(71, 83, 103); // Set text color to red
          pdf.text(heading, 10, startY);
          startY += 10; // Space between heading and first link

          pdf.setFontSize(12);
          pdf.setTextColor(0, 0, 255); // Set text color to blue
          pdf.setFont("helvetica", "normal");

          links.forEach((link, index) => {
            const text = link;
            const textX = 10; // Align text to the left
            const textWidth = pdf.getTextWidth(text);

            // Check if text exceeds the width of the page
            if (textWidth > pdfWidth - 20) {
              const splittedText = pdf.splitTextToSize(text, pdfWidth - 20);
              let textY = startY;
              splittedText.forEach((line) => {
                pdf.text(line, textX, textY);
                textY += 7; // Line height
              });
              startY = textY;
            } else {
              pdf.text(text, textX, startY);
              startY += 10; // Move to the next line for the next link
            }

            const linkHeight = 10; // Height of link area
            pdf.link(textX, startY - linkHeight, textWidth, linkHeight, {
              url: link,
            }); // Adjust y position to align with text

            startY += 5; // Add some space between links
          });

          startY += 5; // Add some space after all links
        };

        if (array1.length !== 0) {
          addLinksWithHeading("Attached Documents", array1);
        }
        if (array2.length !== 0) {
          addLinksWithHeading("Linked Drawings", array2);
        }

        pdf.save("download.pdf");
      })
      .catch((err) => {
        console.error("Error generating PDF", err);
      });
  }

  async function okok(dd) {
    const doc = new jsPDF();

    try {
      // doc.autoTable({
      //   html: tableRef.current,
      //   didParseCell: function (data) {
      //     data.cell.styles.fontSize = 2;
      //   },
      // });

      let res = await downloadPdf(
        pdfRef.current,
        [
          {
            heading: "Attached Documents",
            files: rfi?.attachFile?.map((e) => e.fileUrl),
          },
        ],
        "Rfi",
        true
      );

      const reader = new FileReader();
      reader.readAsDataURL(res);
      reader.onloadend = async function () {
        const base64data = reader.result.split(",")[1];
        const formattedAttachment = {
          content: base64data,
          filename: "rfi.pdf",
          type: "application/pdf",
          disposition: "attachment",
        };

        const body = {
          subject: "Email header",
          to: dd.to,
          text: "This is text of email",
          cc: dd.cc,
          bcc: dd.bcc,
          attachments: formattedAttachment,
          htmlData: dd.text,
        };

        await sendEmailWithAttachment(body);
      };
      // doc.save("rfi.pdf");
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div className={`rfiView card`}>
        <div className="rfiparent">
          {skeletonLoader ? (
            <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} />
          ) : (
            <>
              {!usedAsComponent && (
                <div className="w-100 breadcrum mb-5 d-flex justify-content-between align-items-center">
                  <div className="w-100">
                    <span
                      className="open_Rfi_text"
                      onClick={() => navigate("/projectManagement/openrfis")}
                    >
                      RFIs
                    </span>
                    <span className="px-1 open_Rfi_text ">/</span>
                    <span className="createNew_text">{rfi?.subject}</span>
                  </div>
                  {/* <div style={{ width: "120px" }}>
                    <EmailTemplateWithPdf sendEmail={okok} isDialog={true} />
                  </div> */}
                </div>
              )}

              <div className="rfibody d-flex flex-column gap-4">
                {!usedAsComponent && (
                  <div className="d-flex justify-content-between">
                    <h1 className="headdingRFI"> {rfi?.subject}</h1>
                    <div className="d-flex align-items-center gap-2">
                      <div
                        className="d-flex gap-2 align-items-center"
                        style={{ cursor: "pointer" }}
                        onClick={downloadPdf2}
                      >
                        <AiOutlineExport />
                        Export
                        <MdOutlineKeyboardArrowDown />
                      </div>
                    </div>
                  </div>
                )}

                <div className="d-flex flex-column gap-3">
                  <div className="row">
                    <div className="col-6">
                      <ViewPageTable
                        tableData={[
                          {
                            "Question Received From":
                              rfi?.questionReceived?.[0]?.first_name || "--",
                          },
                          { "RFI Number": rfi?.rfi || "--" },
                          { "Workflow Status": rfi?.workflow_status || "--" },
                          {
                            "RFI Manager":
                              rfi?.rfi_manager
                                ?.map((e) => e?.first_name)
                                ?.join(", ") || "--",
                          },
                          {
                            "Responsible Person":
                              rfi?.assignees
                                ?.map((e) => e?.first_name)
                                ?.join(", ") || "--",
                          },
                          { Reference: rfi?.reference || "--" },
                          { "Scheduled Impact": rfi?.scheduledImpact || "--" },
                          { "Drawing Number": rfi?.drawingNumber || "--" },
                          { Location: rfi?.location || "--" },
                        ]}
                      />
                      <div className="gray-lt text-nowrap pb-2">
                        Responsible Contractor Company / Name:
                      </div>
                      <div className="d-flex flex-column">
                        {contractorData?.map((res, index) => (
                          <div className="gray-d pb-2" key={index}>
                            {res?.company} - {res?.persons}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-6">
                      <ViewPageTable
                        tableData={[
                          {
                            "Approval Status":
                              rfi?.status == "pendingapproval"
                                ? "Pending Approval"
                                : rfi?.status || "--",
                          },
                          {
                            "Due Data":
                              moment(
                                rfi?.dueDate,
                                "DD/MM/YYYY h:mm:ss a"
                              ).format("MM/DD/YYYY") || "--",
                          },
                          { Location: rfi?.location || "--" },
                          { Category: rfi?.rfiCategosry[0]?.name || "--" },
                          { "Cost Code": rfi?.costCode || "--" },
                          { "Cost Impact": rfi?.costImpact || "--" },
                          {
                            "Hours Spent": rfi?.hourSpent
                              ? `${rfi?.hourSpent?.split("-")[0]} hour ${rfi?.hourSpent?.split("-")[1]
                              } Min`
                              : "--",
                          },
                          {
                            Reviewer:
                              rfi?.reviewer?.length !== 0 &&
                                rfi?.reviewer !== undefined
                                ? `${rfi?.reviewer[0]?.first_name} ${rfi?.reviewer[0]?.last_name}`
                                : "--",
                          },
                          {
                            Approver:
                              rfi?.approver?.length !== 0 &&
                                rfi?.approver !== undefined
                                ? `${rfi?.approver[0]?.first_name} ${rfi?.approver[0]?.last_name}`
                                : "--",
                          },
                          { "Reviewer Comment": rfi?.reviewer_comment || "--" },
                          { "Approver Comment": rfi?.approver_comment || "--" },
                          // { Reference: rfi?.reference || "--" },
                          {
                            "Created Date":
                              moment(rfi?.created_at).format(
                                "DD/MM/YYYY h:mm:ss A"
                              ) || "--",
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>



              <div className="view_page_para_head mt-4 mb-2">Question</div>
              <div className="view_page_bold_text">
                <div dangerouslySetInnerHTML={{ __html: rfi?.question }} />
              </div>
              <div className="view_page_para_head mt-4 mb-2">
                Attached Document:
              </div>
              {rfi?.attachFile?.map((res, key) => {
                return (
                  <ViewPageDocumentName
                    key={key}
                    docUrl={res?.fileUrl}
                    name={res?.fileName}
                  />
                );
              })}
            </>
          )}
          <hr />

          <div className="d-flex flex-column mt-4 gap-3">
            <div className="d-flex justify-content-between">
              <h2>{`Responses (${rfi?.response?.length})`}</h2>{" "}
              <div
                className="clear_btn gray-lt"
                onClick={() => setVisible(true)}
              >
                Add Response
              </div>
            </div>

            {rfi?.response?.length === 0 ? (
              <div className="noData gray-ult w-100 justify-content-center text-center">
                There are no responses to this RFI right now. Once added, all
                the responses to this RFI will be visible here.
              </div>
            ) : skeletonLoader ? (
              <TableSkelton
                header={[
                  "",
                  "Response By",
                  "Response Date",
                  "Response",
                  "Attachments",
                ]}
              />
            ) : (
              <div>
                <BorderedTable>
                  <DataTable
                    value={rfi?.response}
                  //   footer={customFooter}
                  // paginator
                  // rows={5}
                  // rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]}
                  >
                    <Column
                      body={(rfi, rowIndex) => (
                        <div className="text-center">
                          {rowIndex.rowIndex + 1}
                        </div>
                      )}
                      style={{ minWidth: "4rem" }}
                    />
                    {/* <Column
                      field="response_by"
                      header="Response By"
                      style={{ width: "15%" }}
                        body={(rfi, rowIndex) => (
                          <div>{rowIndex.rowIndex + 1}</div>
                        )}
                    /> */}
                    <Column
                      field="response_date"
                      header="Response Date"
                      style={{ width: "15%" }}
                    //   body={(rfi, rowIndex) => (
                    //     <div>{rowIndex.rowIndex + 1}</div>
                    //   )}
                    />
                    <Column
                      field="response_descripttion"
                      header="Response"
                      style={{ width: "54.6%" }}
                      body={(rfi, rowIndex) => (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: rfi?.response_descripttion,
                          }}
                        />
                      )}
                    />
                    <Column
                      field=""
                      header="Attachments"
                      style={{ width: "15%" }}
                      body={(rowData, rowIndex) => (
                        <div>
                          {/* {rowData?.attachFile?.map((e) => e?.fileName)} */}
                          {rowData?.attachFile?.map((file, index) => (
                            <ViewPageDocumentName
                              docUrl={file?.fileUrl}
                              key={index}
                              name={file?.fileName}
                            />
                          ))}
                        </div>
                      )}
                    />
                  </DataTable>
                </BorderedTable>
              </div>
            )}
          </div>

          {(!usedAsComponent || includeVersionHistory) && (
            <>
              <hr />
              <ViewVersion isView={true} />
              <br />
              <br />
            </>
          )}
          <div>
            {/* <hr /> */}

            {/* <div className="g-Info d-flex flex-column gap-3">
            <h2>General Information </h2>
            <div className="">
              <div className="genralInfoRfi w-100 table-class-rfi d-flex flex-wrap">
                <div className="w-50 box">
                  <div className="d-flex flex-column">
                    <div className="d-flex w-100  ">
                      <div className="w-50 table-data-class gray-lt">
                        Responsible Contractor
                      </div>
                      <div className="w-50 table-data-class gray-d">
                        {rfi?.responsibleContractor}
                      </div>
                    </div>
                    <div className="d-flex w-100  ">
                      <div className="w-50 table-data-class gray-lt">
                        Member(s) Responsible
                      </div>
                      <div className="w-50 table-data-class gray-d">
                        {rfi?.membersResponsible}
                      </div>
                    </div>
                    <div className="d-flex w-100  ">
                      <div className="w-50 table-data-class gray-lt">
                        Assignees
                      </div>
                      <div className="w-50 table-data-class gray-d">
                        {rfi?.assignees?.map((item => item))}
                      </div>
                    </div>
                    <div className="d-flex w-100  ">
                      <div className="w-50 table-data-class gray-lt">
                        RFI Manager
                      </div>
                      <div className="w-50 table-data-class gray-d">
                        {rfi?.rfi_manager}
                      </div>
                    </div>
                    <div className="d-flex w-100  ">
                      <div className="w-50 table-data-class gray-lt">
                        Category
                      </div>
                      <div className="w-50 table-data-class gray-d">
                        {rfi?.category}
                      </div>
                    </div>
                    <div className="d-flex w-100  ">
                      <div className="w-50 table-data-class gray-lt">
                        Location
                      </div>
                      <div className="w-50 table-data-class gray-d">
                        {" "}
                        {rfi?.location}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-50 box">
                  <div className="d-flex flex-column">
                    <div className="d-flex w-100  ">
                      <div className="w-50 table-data-class gray-lt">
                        Status
                      </div>
                      <div className="w-50 table-data-class gray-d d-flex gap-2 ">
                        {rfi?.status?.toLowerCase() === "open" ? (
                          <div
                            style={{ width: "20px", height: "20px" }}
                            className="open_status"
                          ></div>
                        ) : rfi?.status?.toLowerCase() === "close" ? (
                          <div
                            style={{ width: "20px", height: "20px" }}
                            className="close_status"
                          ></div>
                        ) : rfi?.status?.toLowerCase() === "reopen" ? (
                          <div
                            style={{ width: "20px", height: "20px" }}
                            className="reopen_status"
                          ></div>
                        ) : (
                          <></>
                        )}

                        <div className="">{rfi?.status}</div>
                      </div>
                    </div>
                    <div className="d-flex w-100  ">
                      <div className="w-50 table-data-class gray-lt">
                        Approval
                      </div>
                      <div className="w-50 table-data-class gray-d">
                        {'null'}
                      </div>
                    </div>
                    <div className="d-flex w-100  ">
                      <div className="w-50 table-data-class gray-lt">
                        Drawing Number
                      </div>
                      <div className="w-50 table-data-class gray-ult">{rfi?.drawingNumber}</div>
                    </div>
                    <div className="d-flex w-100  ">
                      <div className="w-50 table-data-class gray-lt">
                        Cost Code
                      </div>
                      <div className="w-50 table-data-class gray-ult">{rfi?.costCode}</div>
                    </div>
                    <div className="d-flex w-100  ">
                      <div className="w-50 table-data-class gray-lt">
                        Schedule Impact
                      </div>
                      <div className="w-50 table-data-class gray-ult">{rfi?.scheduledImpact}</div>
                    </div>
                    <div className="d-flex w-100  ">
                      <div className="w-50 table-data-class gray-lt">
                        Cost Impact
                      </div>
                      <div className="w-50 table-data-class gray-ult">{rfi?.costImpact}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h2>Attached Files</h2>
            <div className="">
              <div className="d-flex gap-4 flex-wrap">
                {rfi?.attachFile?.map((file, index) => (
                  <div key={index} className=" box ">
                    <div
                      className="fw-bold border border-2 p-2 rounded-3 d-flex"
                      onClick={() => handleDownload(file.fileName, file.fileUrl)}
                      style={{ cursor: 'pointer' }}
                    >
                      {file.fileName}
                    </div>
                  </div>
                ))}
              </div>

            </div>
          </div> */}
          </div>
        </div>
        <ResponseDialog
          visible={visible}
          setVisible={setVisible}
          id={id}
          reloade={handleGetRfi}
        />
      </div>

      <div style={{ height: "0px", overflow: "hidden" }}>
        <div ref={pdfRef}>
          <div className="card p-5">

            <h1 className="headdingRFI"> {rfi?.subject}</h1>
            <div className="d-flex flex-column gap-3 mt-3">
              <div className="row">
                <div className="col-6">
                  <ViewPageTable
                    tableData={[
                      {
                        "Question Received From":
                          rfi?.questionReceived?.[0]?.first_name || "--",
                      },
                      { "RFI Number": rfi?.rfi || "--" },
                      { "Workflow Status": rfi?.workflow_status || "--" },
                      {
                        "RFI Manager":
                          rfi?.rfi_manager
                            ?.map((e) => e?.first_name)
                            ?.join(", ") || "--",
                      },
                      {
                        "Responsible Person":
                          rfi?.assignees
                            ?.map((e) => e?.first_name)
                            ?.join(", ") || "--",
                      },
                      { Reference: rfi?.reference || "--" },
                      { "Scheduled Impact": rfi?.scheduledImpact || "--" },
                      { "Drawing Number": rfi?.drawingNumber || "--" },
                      { Location: rfi?.location || "--" },
                    ]}
                  />
                  <div className="gray-lt text-nowrap pb-2">
                    Responsible Contractor Company / Name:
                  </div>
                  <div className="d-flex flex-column">
                    {contractorData?.map((res, index) => (
                      <div className="gray-d pb-2" key={index}>
                        {res?.company} - {res?.persons}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-6">
                  <ViewPageTable
                    tableData={[
                      {
                        "Approval Status":
                          rfi?.status == "pendingapproval"
                            ? "Pending Approval"
                            : rfi?.status || "--",
                      },
                      {
                        "Due Data":
                          moment(rfi?.dueDate, "DD/MM/YYYY h:mm:ss a").format(
                            "MM/DD/YYYY"
                          ) || "--",
                      },
                      { Location: rfi?.location || "--" },
                      { Category: rfi?.rfiCategosry?.name || "--" },
                      { "Cost Code": rfi?.costCode || "--" },
                      { "Cost Impact": rfi?.costImpact || "--" },
                      {
                        "Hours Spent": rfi?.hourSpent
                          ? `${rfi?.hourSpent?.split("-")[0]} hour ${rfi?.hourSpent?.split("-")[1]
                          } Min`
                          : "--",
                      },
                      {
                        Reviewer:
                          rfi?.reviewer?.length !== 0 &&
                            rfi?.reviewer !== undefined
                            ? `${rfi?.reviewer[0]?.first_name} ${rfi?.reviewer[0]?.last_name}`
                            : "--",
                      },
                      {
                        Approver:
                          rfi?.approver?.length !== 0 &&
                            rfi?.approver !== undefined
                            ? `${rfi?.approver[0]?.first_name} ${rfi?.approver[0]?.last_name}`
                            : "--",
                      },
                      { "Reviewer Comment": rfi?.reviewer_comment || "--" },
                      { "Approver Comment": rfi?.approver_comment || "--" },
                      // { Reference: rfi?.reference || "--" },
                      {
                        "Created Date":
                          moment(rfi?.created_at).format(
                            "DD/MM/YYYY h:mm:ss A"
                          ) || "--",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="view_page_para_head mt-4 mb-2">Question</div>
            <div className="view_page_bold_text">
              <div dangerouslySetInnerHTML={{ __html: rfi?.question }} />
            </div>

            <div>
              {/* <BorderedTable> */}
              <DataTable
                value={rfi?.response}
              //   footer={customFooter}
              // paginator
              // rows={5}
              // rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]}
              >
                <Column
                  body={(rfi, rowIndex) => (
                    <div>
                      {rowIndex.rowIndex + 1}
                    </div>
                  )}
                // style={{ minWidth: "4rem" }}
                />
                {/* <Column
                      field="response_by"
                      header="Response By"
                      // style={{ width: "15%" }}
                      //   body={(rfi, rowIndex) => (
                      //     <div>{rowIndex.rowIndex + 1}</div>
                      //   )}
                    /> */}
                <Column
                  field="response_date"
                  header="Response Date"
                  style={{ width: "15%" }}
                //   body={(rfi, rowIndex) => (
                //     <div>{rowIndex.rowIndex + 1}</div>
                //   )}
                />
                <Column
                  field="response_descripttion"
                  header="Response"
                  // style={{ width: "54.6%" }}
                  body={(rfi, rowIndex) => (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: rfi?.response_descripttion,
                      }}
                    />
                  )}
                />
                {/* <Column
                      field=""
                      header="Attachments"
                      
                      body={(rowData, rowIndex) => (
                        <div>
                        {rowData?.attachFile?.map((file, index) => (
                      <ViewPageDocumentName
                        docUrl={file?.fileUrl}
                        key={index}
                        name={file?.fileName}
                      />
                    ))}
                        </div>
                      )}
                    /> */}
              </DataTable>
              {/* </BorderedTable> */}
            </div>
            {(!usedAsComponent || includeVersionHistory) && (
              <>
                <hr />
                <ViewVersion isView={true} />
                <br />
                <br />
              </>
            )}
          </div>
        </div>
      </div>

      {!usedAsComponent &&
        <div className="d-flex justify-content-end mt-2">
          <button
            className="btn border"
            onClick={() => navigate("/projectManagement/openrfis")}
          >
            Back
          </button>
        </div>
      }

      <br />
    </>
  );
}

import React, { useEffect, useState } from "react";
import Loader from "../../Components/Common/Loader";
import TextInput from "../../Components/Inputs/TextInput";
import { Controller, useForm } from "react-hook-form";
import SingleSelect from "../../Components/Common/SingleSelect";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  convertHourlySpentStringIntoObject,
  getActionsPermission,
  getProjectCode,
  getProjectId,
  getProjectName,
  getUserId,
  getUserName,
  getUserRoleId,
  toast,
} from "../../utils";
import { MultiSelect } from "primereact/multiselect";
import { Editor } from "primereact/editor";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import CustomDragAndDrop from "../../Components/Inputs/CustomDragAndDrop";
import TimeInput from "../../Components/Inputs/TimeSelector";
import {
  addCategory,
  addSubmittalCategory,
  addtask,
  getCompanyByProjectId,
  getCountForTaskByProjectId,
  getManyUsersByCompanyIds,
  getSubmittalCategory,
  gettaskbyid,
  getUserByProjectId,
  updatetasktable,
  uploadMultipleDocs,
} from "../../Api/Admin/AllAPIs";
import { MdAdd } from "react-icons/md";
import AddSets from "../../Components/Common/AddSets";
import { TOOL_STATUS, WORKFLOW_STATUS } from "../../constants";
import { FormHeader } from "../../Components/Common/FormHeader";
import { useSelector } from "react-redux";

export const CreateUpdateTask = () => {
  let d = useSelector(e => e.rolesAndPermissionData)
  let navigate = useNavigate();
  let project_id = getProjectId();
  let [allUsers, setAllUsers] = useState([]);
  let [allCompanies, setAllCompanies] = useState([]);
  let [allResponsibleMembers, setAllResponsibleMembers] = useState([]);
  let [currentApprover, setCurrentApprover] = useState({})
  let [currentReviewer, setCurrentReviewer] = useState({})
  let [allCategories, setAllCategories] = useState([]);
  let [addCategoryDialog, setAddCategoryDialog] = useState(false);
  let [categoryName, setCategoryName] = useState("");
  let [taskData, setTaskData] = useState({});
  let [addCategoryLoader, setAddCategoryLoader] = useState(false);
  const projectData = JSON.parse(localStorage.getItem("projectData"));
  const [btnLoader, setBtnLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [files, setFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  let { id } = useParams();
  const domain = window.location.origin;
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      task_name: "",
      taskId: "",
      status: TOOL_STATUS.draft,
      taskManager: [], // multiple users id mongo id
      responsiblePerson: [], // multiple persion mongo id
      category: "",
      responsibleContractor: [], // all componanies mongo id
      responsibleMembers: [], // users of company mongo id
      reviewer_id: null,
      approver_id: null,
      description: "",
      project_id: getProjectId(),
      dueDate: "",
      attachments: [],
      email_data: [],
      hourSpent: "",
      workFlowStatus: "",
      creator_email: getUserId(),
      created_by: projectData.createdAt,
      project_name: projectData.project_name,
      project_number: projectData.project_number,
    },
  });

  let responsibleContractor = watch("responsibleContractor");

  useEffect(() => {
    getAllUsers();
    getAllCompany();
    getAllCategories();
    if (!id) {
      getTaskId();
    } else {
      getTaskById();
    }
  }, []);

  async function getTaskById() {
    try {
      let res = await gettaskbyid(id);
      console.log(res.data.data[0], "project task data tadk data");
      let {
        task_name,
        status,
        taskId,
        taskManager,
        responsiblePerson,
        category,
        responsibleContractor,
        responsibleMembers,
        reviewer_id,
        approver_id,
        description,
        dueDate,
        hourSpent,
        workFlowStatus,
        project_id,
        attachments,
        project_number,
        approver_comment,
        reviewer_comment,
      } = res.data.data[0];
      console.log(res.data.data[0])
      setTaskData(res?.data?.data?.[0] || {});

      if (approver_id == getUserId()) {
        setCurrentApprover(res.data.data[0]?.approver)
      }
      if (reviewer_id == getUserId()) {
        setCurrentReviewer(res.data.data[0]?.reviewer)
      }


      setFiles(
        attachments.map((e) => {
          delete e._id;
          e.file = null;
          return e;
        })
      );

      reset({
        task_name,
        taskId,
        status,
        taskManager: taskManager.map((e) => e._id),
        responsiblePerson: responsiblePerson.map((e) => e._id),
        category,
        responsibleContractor: responsibleContractor.map((e) => e._id),
        responsibleMembers: responsibleMembers.map((e) => e._id),
        reviewer_id,
        approver_id,
        description,
        project_id,
        dueDate,
        hourSpent,
        workFlowStatus,
        approver_comment: approver_comment || "",
        reviewer_comment: reviewer_comment || "",
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function getTaskId() {
    try {
      let res = await getCountForTaskByProjectId(project_id);
      console.log(res.data, "task task task task");
      setValue("taskId", res.data.data);
    } catch (error) {
      console.log(error);
    }
  }
  async function getAllCategories() {
    try {
      let res = await getSubmittalCategory(project_id);
      // console.log(res.data.data, "all categorid")
      setAllCategories(res.data.data);
    } catch (err) {
      console.log(err);
    }
  }

  const getAllUsers = async () => {
    try {
      let res = await getUserByProjectId(project_id);
      // console.log(res.data.data, "alluser user all users")
      setAllUsers(res?.data?.data?.[0].combined_users || []);
    } catch (err) {
      console.log(err);
    }
  };
  const getAllCompany = async () => {
    try {
      let res = await getCompanyByProjectId(project_id);
      // console.log(res.data.data, "all company copnay")
      setAllCompanies(res?.data?.data?.[0]?.companynames || []);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (responsibleContractor.length > 0) {
      getUsersOfCompany(responsibleContractor);
    }
  }, [responsibleContractor]);

  async function getUsersOfCompany(companiesIds) {
    try {
      let body = {
        company_id: companiesIds,
      };

      let res = await getManyUsersByCompanyIds(project_id, body);
      // console.log(res.data.data, "many uer user use r")

      setAllResponsibleMembers(res?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  }

  async function addCategoryHandler() {
    try {
      setAddCategoryLoader(true);
      if (categoryName.trim() !== "") {
        let body = {
          name: categoryName,
          project_id: project_id,
        };
        let res = await addSubmittalCategory(body);
        setCategoryName("");
        setAddCategoryDialog(false);
        getAllCategories();
      } else {
        toast.error("please fill category name");
      }
    } catch (error) {
      console.log(error);
      toast.error("Some Error Occured");
    } finally {
      setAddCategoryLoader(false);
    }
  }

  async function formSubmit(data) {
    try {
      setBtnLoader(true);
      let allFiles = [...(taskData?.attachments || [])];
      console.log(files);

      let newFiles = files.filter((res) => res.file).map((e) => e.file);
      console.log(newFiles);

      if (newFiles.length > 0) {
        let re = await uploadMultipleDocs(newFiles);
        console.log(re.data.data);
        allFiles = [...allFiles, ...(re?.data?.data || [])];
      }

      let body = {
        ...data,
        attachments: [...allFiles],
        email_data: [],
      };

      if (id) {
        if (deletedFiles.length > 0) {
          body.deleted_file = deletedFiles;
        }
        body.user_id = getUserId();
        body.user_name = getUserName();

        if (taskData.status === TOOL_STATUS.draft) {
          body.status = TOOL_STATUS.inreview;
        }

        // send email to reviewer and users of approver changed

        if (taskData.approver_id !== body.approver_id) {
          let reviewerData = allUsers.find((e) => e._id == body.reviewer_id);
          let approverData = allUsers.find((e) => e._id == body.approver_id);
          // send email to reviewer
          body.email_data.push({
            name: reviewerData?.first_name,
            approver_name: approverData.first_name,
            to: reviewerData?.email_address,
            tool_number: body?.taskId,
            tool: "Task",
            tool_name: body.task_name,
            project_name: getProjectName(),
            subject: `FYI: Approver appointed to Task #${body?.taskId} ${body.task_name}`,
            project_id: getProjectCode(),
            template_type: "addedApproverEmailToAll",
            url: `${domain}/coretools/tasktable`,
            updated_by: getUserName(),
          });
          let filteredMembers = new Set(
            [
              body.responsiblePerson,
              body.taskManager,
              body.responsibleContractor,
            ].flat(Infinity)
          );
          // email data of users
          filteredMembers?.forEach((userId) => {
            let currentUser = allUsers.find((e) => e._id == userId);
            if (currentUser) {
              body.email_data.push({
                name: currentUser?.first_name,
                approver_name: approverData.first_name,
                to: currentUser?.email_address,
                tool_number: body?.taskId,
                subject: `FYI: Approver appointed to Task #${body?.taskId} ${body.task_name}`,
                tool: "Task",
                tool_name: body.task_name,
                project_name: getProjectName(),
                project_id: getProjectCode(),
                template_type: "addedApproverEmailToAll",
                url: `${domain}/coretools/tasktable`,
                updated_by: getUserName(),
              });
            }
          });
        }
        // send email to apprver and all users on reviewer change

        if (taskData.reviewer_id !== body.reviewer_id) {
          let approverData = allUsers.find((e) => e._id == body.approver_id);
          let reviewerData = allUsers.find((e) => e._id == body.reviewer_id);
          // add data of email to apprver
          body.email_data.push({
            name: approverData?.first_name,
            to: approverData?.email_address,
            reviewer_name: reviewerData.first_name,
            tool_number: body?.taskId,
            tool: "Task",
            tool_name: body.task_name,
            project_name: getProjectName(),
            subject: `FYI: Approver appointed to Task #${body?.taskId} ${body.task_name}`,
            project_id: getProjectCode(),
            template_type: "addedReviewerEmailToAll",
            url: `${domain}/coretools/tasktable`,
            updated_by: getUserName(),
          });
          let filteredMembers = new Set(
            [
              body.responsiblePerson,
              body.taskManager,
              body.responsibleContractor,
            ].flat(Infinity)
          );

          filteredMembers?.forEach((userId) => {
            let currentUser = allUsers.find((e) => e._id == userId);
            if (currentUser) {
              body.email_data.push({
                name: currentUser?.first_name,
                to: currentUser?.email_address,
                reviewer_name: reviewerData.first_name,
                tool_number: body?.taskId,
                subject: `FYI: Approver appointed to Task #${body?.taskId} ${body.task_name}`,
                tool: "Task",
                tool_name: body.task_name,
                project_name: getProjectName(),
                project_id: getProjectCode(),
                template_type: "addedReviewerEmailToAll",
                url: `${domain}/coretools/tasktable`,
                updated_by: getUserName(),
              });
            }
          });
        }
        // send email to all user on edit task
        let filteredMembers = new Set(
          [
            body.responsiblePerson,
            body.taskManager,
            body.approver_id,
            body.reviewer_id,
          ].flat(Infinity)
        );

        filteredMembers?.forEach((userId) => {
          let currentUser = allUsers.find((e) => e._id == userId);
          if (currentUser) {
            body.email_data.push({
              name: currentUser?.first_name,
              to: currentUser?.email_address,
              tool_number: body?.taskId,
              subject: `FYI: Task #${body?.taskId} ${body.task_name} Edited in ${getProjectName()}`,
              tool: "Task",
              tool_name: body.task_name,
              project_name: getProjectName(),
              project_id: getProjectCode(),
              template_type: "emailToAllUserWhileUpdatingToolData",
              url: `${domain}/coretools/tasktable`,
              updated_by: getUserName(),
            });
          }
        });

        // send email to all users on workflow status
        if (taskData.workFlowStatus !== body.workFlowStatus) {
          let filteredMembers = new Set(
            [
              body.responsiblePerson,
              body.taskManager,
              body.approver_id,
              body.reviewer_id,
            ].flat(Infinity)
          );

          filteredMembers?.forEach((userId) => {
            let currentUser = allUsers.find((e) => e._id == userId);
            if (currentUser) {
              body.email_data.push({
                name: currentUser?.first_name,
                to: currentUser?.email_address,
                tool_number: body?.taskId,
                subject: `FYI: Task #${body?.taskId} ${body.task_name
                  } Status Changed in ${getProjectName()}`,
                tool: "Task",
                tool_name: body.task_name,
                project_name: getProjectName(),
                project_id: getProjectCode(),
                template_type: "emailToAllUserWhileUpdatingToolWorkflowStatus",
                url: `${domain}/coretools/tasktable`,
                workflow_status: `${taskData.workFlowStatus} To ${body.workFlowStatus}`,
                updated_by: getUserName(),
              });
            }
          });
        }
        body.user_name = getUserName()
        let res = await updatetasktable(id, body);
        toast.success("Task Updated successfully");
      } else {
        // change status of task accordingly of reviewer and approval assign
        if (body.reviewer_id && body.approver_id) {
          body.status = TOOL_STATUS.inreview;
        }

        // send email to reviewer if selected
        if (body.reviewer_id) {
          let reviewerData = allUsers.find((e) => e._id == body.reviewer_id);
          body.email_data.push({
            name: reviewerData?.first_name,
            to: reviewerData?.email_address,
            tool_number: body?.taskId,
            tool: "Task",
            tool_name: body.task_name,
            subject: `Action Required: Appointed as the Reviewer to  Task #${body?.taskId} ${body.task_name}`,
            project_name: getProjectName(),
            project_id: getProjectCode(),
            template_type: "createdToolEmailToReviewer",
            url: `${domain}/coretools/tasktable`,
            created_by: getUserName(),
          });
        }
        // send email to approver if selected
        if (body.approver_id) {
          let approverData = allUsers.find((e) => e._id == body.approver_id);
          body.email_data.push({
            name: approverData?.first_name,
            to: approverData?.email_address,
            tool_number: body?.taskId,
            tool: "Task",
            tool_name: body.task_name,
            project_name: getProjectName(),
            subject: `FYI: Appointed as the Approver to  Task #${body?.taskId} ${body.task_name}`,
            project_id: getProjectCode(),
            template_type: "createdToolEmailToApprover",
            url: `${domain}/coretools/tasktable`,
            created_by: getUserName(),
          });
        }

        // make array of objects with all users (responsible member, person, task manager) email data

        let filteredMembers = new Set(
          [
            body.responsiblePerson,
            body.taskManager,
            body.responsibleContractor,
          ].flat(Infinity)
        );

        let email_data_obj = setEmailDataOfAllUsers(
          body,
          filteredMembers,
          "createdToolEmailToAssociatedUsers"
        );

        body.email_data = [...body.email_data, ...email_data_obj];

        let res = await addtask(body);
        console.log(res);
        toast.success("Task Created Successfully ");
      }
      navigate("/coretools/tasktable");
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Some Error Occured");
    } finally {
      setBtnLoader(false);
    }
  }

  function setEmailDataOfAllUsers(body, filteredMembers, templateType) {
    let arr = [];

    // add email data of responsibl members selected
    filteredMembers?.forEach((userId) => {
      let currentUser = allUsers.find((e) => e._id == userId);
      if (currentUser) {
        arr.push({
          name: currentUser?.first_name,
          to: currentUser?.email_address,
          tool_number: body?.taskId,
          subject: `FYI:New Task created in ${getProjectName()}`,
          tool: "Task",
          tool_name: body.task_name,
          project_name: getProjectName(),
          project_id: getProjectCode(),
          template_type: templateType,
          url: `${domain}/coretools/tasktable`,
          created_by: getUserName(),
        });
      }
    });

    return arr;
  }
  function onError() {
    toast.info("Please Fill all the Fields Marked with * are Mandatory");
  }
  return (
    <div className=" incident">
      <div className="show-data ">

        <FormHeader
          to={"/coretools/tasktable"}
          formHeader={"Task"}
          breadcrumItem={["Task", `${id ? "Edit" : "Create"} Task`]} />

        {loader ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit(formSubmit, onError)}>
            <div className="row m-0 p-0">
              {/*  New section End */}
              <div className="row m-0 p-0">
                {/* task id  */}
                <div className="col-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Task ID : <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="taskId"
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <TextInput
                          placeholder="Task ID"
                          value={value}
                          className={errors?.taskId ? "Validation" : ""}
                          onChange={onChange}
                        />
                      );
                    }}
                  />
                </div>
                {/* Task Name  */}
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Task Name : <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="task_name"
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <TextInput
                          placeholder="Task Name"
                          value={value}
                          className={errors?.task_name ? "Validation" : ""}
                          onChange={onChange}
                        />
                      );
                    }}
                  />
                </div>
                {/* workflow status  */}
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Workflow Status : <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="workFlowStatus"
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <SingleSelect
                          placeholder="Workflow Status"
                          value={value}
                          className={errors.workFlowStatus ? "Validation" : ""}
                          options={WORKFLOW_STATUS}
                          onChange={(e) => {
                            console.log(e);
                            onChange(e?.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
                {/* responsible person  */}
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Responsible Person (s) :{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="responsiblePerson"
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <MultiSelect
                          filter={true}
                          value={allUsers.filter((e) => value.includes(e._id))}
                          onChange={(e) => {
                            onChange(e.value.map((e) => e._id));
                          }}
                          placeholder="Responsible Person"
                          optionLabel="first_name"
                          options={allUsers}
                          className={`${errors.responsiblePerson ? "Validation" : ""
                            } w-100`}
                        />
                      );
                    }}
                  />
                </div>

                {/* Responsible Contractor  */}

                <di v className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Responsible Contractor (s) :
                  </label>
                  <Controller
                    name="responsibleContractor"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <MultiSelect
                          filter={true}
                          value={allCompanies.filter((e) =>
                            value.includes(e._id)
                          )}
                          placeholder="Responsible Contractor"
                          optionLabel="company_name"
                          onChange={(e) => {
                            onChange(e.value.map((e) => e._id));
                          }}
                          options={allCompanies}
                          className={`${errors.responsibleContractor ? "Validation" : ""
                            } w-100`}
                        />
                      );
                    }}
                  />
                </di>
                {/* Responsible Member  */}

                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Responsible Member(s) :
                  </label>
                  <Controller
                    name="responsibleMembers"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <MultiSelect
                          filter={true}
                          placeholder="Responsible Member"
                          value={allResponsibleMembers.filter((e) =>
                            value.includes(e._id)
                          )}
                          onChange={(e) => {
                            onChange(e.value.map((e) => e._id));
                          }}
                          optionLabel="name"
                          options={allResponsibleMembers}
                          className={`${errors.responsibleMembers ? "Validation" : ""
                            } w-100`}
                        />
                      );
                    }}
                  />
                </div>

                {/* Reviewer  */}
                {getActionsPermission("Task Reviewer") && <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Reviewer:
                  </label>
                  <Controller
                    name="reviewer_id"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <SingleSelect
                          // disabled={taskData?.reviewer_id == getUserId()}
                          placeholder="Reviewer"
                          className={errors.reviewer_id ? "Validation" : ""}
                          optionLabel="first_name"
                          options={allUsers}
                          // options={[...allUsers.filter(
                          //   (e) => e._id !== getUserId()
                          // ), currentReviewer]}
                          value={allUsers?.find((e) => e._id == value) || null}
                          onChange={(e) => {
                            onChange(e?.value?._id || null);
                          }}
                          filter={true}
                          filterInputAutoFocus={true}
                        />
                      );
                    }}
                  />
                </div>}

                {/* Approver  */}
                {getActionsPermission("Task Approver") && <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Approver:
                  </label>
                  <Controller
                    name="approver_id"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <SingleSelect
                          placeholder="Approver"
                          // disabled={taskData?.approver_id == getUserId()}
                          className={errors.approver_id ? "Validation" : ""}
                          optionLabel="first_name"
                          options={allUsers}
                          // options={[...allUsers.filter(
                          //   (e) => e._id !== getUserId()
                          // ), currentApprover]}
                          value={allUsers?.find((e) => e._id == value) || null}
                          onChange={(e) => {
                            onChange(e?.value?._id || null);
                          }}
                          filter={true}
                          filterInputAutoFocus={true}
                        />
                      );
                    }}
                  />
                </div>
                }
                {/* task manager  */}
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Task Manager (s) :
                  </label>
                  <Controller
                    name="taskManager"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <MultiSelect
                          filter={true}
                          value={allUsers.filter((e) => value.includes(e._id))}
                          placeholder="Task Manager"
                          onChange={(e) => {
                            onChange(e.value.map((e) => e._id));
                          }}
                          optionLabel="first_name"
                          options={allUsers}
                          className={`${errors.taskManager ? "Validation" : ""
                            } w-100`}
                        />
                      );
                    }}
                  />
                </div>

                {/* category  */}
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Category: <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex align-items-center">
                    <div className="w-100">
                      <Controller
                        name="category"
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <SingleSelect
                              placeholder="Category"
                              filter={true}
                              filterInputAutoFocus={true}
                              value={
                                allCategories?.find((e) => value == e?._id) ||
                                null
                              }
                              className={errors.category ? "Validation" : ""}
                              optionLabel="name"
                              options={allCategories}
                              onChange={(e) => {
                                console.log(e);
                                onChange(e?.value?._id || null);
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                    <div>
                      <MdAdd
                        className="h2"
                        style={{ cursor: "pointer" }}
                        onClick={() => setAddCategoryDialog(true)}
                      />
                    </div>
                  </div>
                </div>
                {/* due date  */}
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Due Date: <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="dueDate"
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <input
                          type="date"
                          style={{ height: "50px" }}
                          placeholder="Due Date"
                          value={value}
                          className={`${errors.dueDate ? "Validation" : "form-control"
                            } form-control`}
                          onChange={onChange}
                        />
                      );
                    }}
                  />
                </div>

                {/* Hours spent  */}
                <div className="col-6">
                  <label className="py-2 labelCss">
                    Hours Spent <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="hourSpent"
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <TimeInput
                          className={errors?.hourSpent && "Validation"}
                          value={convertHourlySpentStringIntoObject(value)}
                          onChange={(e) => {
                            onChange(`${e.hours}-${e.minutes}`);
                          }}
                        />
                      );
                    }}
                  />
                </div>

                {/* Description  */}
                <div className="col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Description:
                  </label>

                  <Controller
                    name="description"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Editor
                          style={{ minHeight: "200px" }}
                          value={value}
                          onTextChange={(e) => onChange(e.htmlValue)}
                        />
                      );
                    }}
                  />
                </div>

                {id && (
                  <>
                    <div className="col-md-6 col-12">
                      <label htmlFor="" className="py-2 labelCss">
                        Approver Comment :
                      </label>
                      <Controller
                        name="approver_comment"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <textarea
                              disabled
                              className="form-control"
                              value={value}
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-6 col-12">
                      <label htmlFor="" className="py-2 labelCss">
                        Reviewer Comment :
                      </label>
                      <Controller
                        name="reviewer_comment"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <textarea
                              disabled
                              className="form-control"
                              value={value}
                            />
                          );
                        }}
                      />
                    </div>
                  </>
                )}

                {/* documents  */}
                <div className="col-md-12 mt-3">
                  <CustomDragAndDrop
                    value={files}
                    setDeleted={setDeletedFiles}
                    onChange={(e) => {
                      console.log(e);
                      setFiles(e);
                    }}
                  // label={"Documents"}
                  />
                </div>
              </div>

              <div className="btn_main mt-4 d-flex align-items-center justify-content-end gap-3">
                <Link to={"/coretools/tasktable"}>
                  <button className="btn-Secondary  px-2 py-2">Cancel</button>
                </Link>
                <PrimaryButton
                  loader={btnLoader}
                  className={"py-2"}
                  type="submit"
                  text={id ? "Update" : "Create"}
                />
              </div>
            </div>
          </form>
        )}
      </div>
      <br />
      <AddSets
        loading={addCategoryLoader}
        visible={addCategoryDialog}
        setVisible={setAddCategoryDialog}
        CategoryName={"Category"}
        label={"Category"}
        reqFor={"create"}
        value={categoryName}
        setValue={setCategoryName}
        onSubmit={addCategoryHandler}
      />
    </div>
  );
};

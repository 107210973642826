import React from 'react'
import { getProjectName } from '../../../utils'

export const PdfHeaderAndFooter = ({ children, projectName }) => {
    return (
        <div className='d-flex flex-column justify-content-between'>
            <div className='d-flex justify-content-between'>
                <div>
                    <img src='/images/cdclogo.svg' className="pdf_logo" />

                </div>
                <div className='text-nowrap pdf-header-text'>
                    <div className='text-end pdf-header-project-name'>{projectName || getProjectName()}</div>
                    <div className='text-end'>595a Cranbrook Road, Ilford, Essex IG2 6JZ</div>
                    <div className='text-end'>TEL: 0208 004 3327  </div>
                    <div className='text-end'>Email: info@cdc.construction  </div>
                    <div className='text-end'>www.cdc.construction  </div>
                </div>
            </div>
            {children}
            <div className='d-flex justify-content-around'>
                <div className='pdf_footer_item'>Company Reg. No. 1147633</div>
                <div className='pdf_footer_item'>VAT REG. No. 396671738</div>
                <div className='pdf_footer_item'>Registered in England Whales</div>
            </div>
        </div>
    )
}

import React, { useRef, useState } from "react";
import {
  deletePunch,
  filterPunch,
  getPunchByProjectId,
  updatePunch,
} from "../../Api/Admin/AllAPIs";
import { useEffect } from "react";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { Statuscomp } from "../../Components/Common/StatusComp";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
import Filter from "./Filter";
import {
  getActionsPermission,
  getProjectCode,
  getProjectName,
  getUserData,
  getUserId,
  isApproverOfProject,
  isReviewerOfProject,
  searchDataWithKeys,
  toast,
} from "../../utils";
import { CSVLink } from "react-csv";
import Popover from "../../Components/Common/Popover";
import DeleteDialog from "../../Components/Common/DeleteDialog";
import { FaCheck, FaRegClock } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as htmlToImage from "html-to-image";
import { ExportPdfButton } from "../../Components/Buttons/ExportPdfButton";
import ChangeStatusModal from "../../Components/ChangeStatusModal/ChangeStatusModal";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import { DownloadPdfAndSendEmail } from "../../Components/Common/ViewPageComponents/DownloadPdfAndSendEmail";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import {
  HtmlIntoString,
  HtmlIntoText,
} from "../../Components/Common/HtmlIntoText";
import moment from "moment";
import Pagination from "../../Components/Common/Pagination/Pagination";
import { ChangeApprovalStatus } from "../../Components/Tools/ChangeApprovalStatus";
import { CDC_LOGO_URL } from "../../constants";
import { useSelector } from "react-redux";

export const PunchList = () => {
  const [visible, setVisible] = useState(false);
  const [clearBtn, setClearBtn] = useState(true);
  const [search, setSearch] = useState("");
  const [resSearch, setResSearch] = useState([]);
  const [loader, setLoader] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [selectedPunchId, setSelectedPunchId] = useState(null);
  const { id } = useParams();
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [original, setOriginal] = useState([]);

  let navigate = useNavigate();
  const [test, setTest] = useState([]);
  let d=useSelector(e=>e.rolesAndPermissionData)

  const [open, setOpen] = useState(null);
  const [dropdownvalue, setDropDownValue] = useState(null);
  const [comment, setComment] = useState("");
  const [type, setType] = useState("");
  const [itemId, setItemId] = useState("");
  const [statusRequired, setStatusRequired] = useState(false);
  const [currentStatusRowData, setCurrentStatusRowData] = useState({});
  const projectId = localStorage.getItem('projectId')
  const userData = localStorage.getItem("userData");
  const userObj = JSON.parse(userData);
  const userId = userObj._id;
  const userName = userObj.name;
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const perPageData = 10;
  const [tempFilterData, setTempFilterData] = useState({});
  const [allData, setAllData] = useState([]);
  const [puchData, setPunchData] = useState([]);
  const [filterData, setFilterData] = useState({
    MembersResponsible: [],
    punchItemManager: [],
  });
  const [tableSortData, setTableSortData] = useState({
    page: 1,
    search: ""
  })

  const capitalizeFirstLetter = (string) => {
    if (!string) return "--"; // Handle undefined or null cases
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  async function changeStatus(e) {
    e.preventDefault();

    if (dropdownvalue == null) {
      setStatusRequired(true);
      return;
    }

    setOpen(false);
    setLoader(true);

    try {
      if (type == "reviewer") {
        if (dropdownvalue.code == "approved") {
          let payload = {
            punch_review_status: "approved",
            reviewer_comment: comment,
            email_data: [
              {
                email: currentStatusRowData?.approver?.email_address,
                type: "Approver",
                rfi_name: currentStatusRowData?.subject,
                project: getProjectName(),
                toolName: "PUNCH LIST",
                name: currentStatusRowData?.approver?.first_name,
              },
            ],
            punch_approval_status: "under_review",
            status: "close",
            user_name: getUserData()?.first_name || "",
          };

          await updatePunch(itemId, payload);
        } else {
          let payload = {
            punch_review_status: "unapproved",
            reviewer_comment: comment,
            status: "reopen",
            punch_approval_status: "pending",
            user_name: getUserData()?.first_name || "",
          };
          await updatePunch(itemId, payload);
        }
      } else if (type == "approver") {
        if (dropdownvalue.code == "approved") {
          let payload = {
            punch_approval_status: "approved",
            approver_comment: comment,
            punch_review_status: "approved",
            user_name: getUserData()?.first_name || "",
          };
          await updatePunch(itemId, payload);
        } else {
          let payload = {
            punch_review_status: "under_review",
            punch_approval_status: "unapproved",
            approver_comment: comment,
            user_name: getUserData()?.first_name || "",
          };
          await updatePunch(itemId, payload);
        }
      } else {
        console.log("nonooo");
      }
    } catch (err) {
      console.log(err);
    } finally {
      await fetchPunch();
      setItemId("");
      setType("");
      setLoader(false);
      setDropDownValue(null);
    }
  }

  useEffect(() => {
    fetchPunch();
  }, [tableSortData.page, tableSortData.search, tempFilterData]);

  // useEffect(() => {
  //   const delay = 2000;
  //   const timeoutId = setTimeout(() => {
  //     fetchPunch();
  //   }, delay);
  //   return () => clearTimeout(timeoutId);
  // }, [search])

  const fetchPunch = async () => {
    try {
      setLoader(true);
      setSkeletonLoader(true);

      let queryParams = {
        page: tableSortData.page,
        perPageData,
        search: tableSortData.search
      }
      if (search !== '') {
        queryParams.search = search
      }
      let body = { ...tempFilterData };
      // setSearch("");
      const res = await getPunchByProjectId(projectId, queryParams, body);
      setPunchData(res?.data?.data || []);
      setAllData(res?.data?.data || []);
      setOriginal(res?.data?.data);
      setTotalCount(res?.data?.totalCount);
      console.log(res?.data?.data, "listtttttttttttt")
    } catch (err) {
      console.error(err);
    } finally {
      setSkeletonLoader(false);
    }
  };

  const deletePunchs = async (id) => {
    setVisibleDelete(true);
    setSelectedPunchId(id);
  };
  const confirmDelete = async () => {
    setLoader(true);
    let payload = {
      created_by: userName,
    }
    try {
      await deletePunch(selectedPunchId, payload);
      toast.success("Punch Deleted Successfully!");
      fetchPunch();
      setLoader(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [filterActive, setFilterActive] = useState(false);

  const csvData = original.map((item) => ({
    "Punch List Name": item?.PunchItemName,
    "Date": moment(item.date).format('DD-MM-YYYY'),
    "Members Responsible": item.MembersResponsible?.map(e => e?.first_name).join(", "),
    "Punch List Manager": item.punchItemManager?.map(e => e?.first_name).join(", "),
    "Location": item.Location,
    "Category": capitalizeFirstLetter(item.category?.name),
    "Trade": capitalizeFirstLetter(item.Trade?.name),
    "Description": HtmlIntoString({ html: item.discription }),
    "Link Drawing": item.linkDrawing,
    "Is Deleted": item.is_deleted,
    "Created At": moment(item.created_at).format('DD-MM-YYYY  hh:mm A'),
    // "Status Key": item.status_key, // Uncomment if needed
  }));


  const csvHeaders = [
    { label: "Punch List Name", key: "Punch List Name" },
    { label: "Date", key: "Date" },
    { label: "Members Responsible", key: "Members Responsible" },
    { label: "Punch List Manager", key: "Punch List Manager" },
    { label: "Location", key: "Location" },
    { label: "Category", key: "Category" },
    { label: "Trade", key: "Trade" },
    { label: "Description", key: "Description" },
    { label: "Link Drawing", key: "Link Drawing" },
    { label: "Is Deleted", key: "Is Deleted" },
    { label: "Created At", key: "Created At" },
    // { label: "Status Key", key: "Status Key" }, // Uncomment if needed
  ];

  const formatHourSpent = (hourSpent) => {
    if (!hourSpent) return "--";
    const { hours, minutes } = hourSpent;
    return `${hours || "0"} hr ${minutes || "0"} min`;
  };
  const tableRef = useRef();

  const exportToPDF = () => {

    const doc = new jsPDF();
    const imgData = CDC_LOGO_URL
    const imgW = 20;
    const imgH = 10;
    const imageX = 5;
    const imageY = 5;
    doc.addImage(imgData, 'JPEG', imageX, imageY, imgW, imgH);
    const projectName = `${getProjectName()} (Project Id: ${getProjectCode() || "-"})`;
    const textX = imageX + imgW + 6;
    const textY = imageY + 7.5;
    // Add project name text to the PDF
    doc.setFontSize(16);
    doc.text(projectName, textX, textY);
    doc.autoTable({
      html: tableRef.current,
      startY: 30,
      didParseCell: function (data) {
        data.cell.styles.fontSize = 2;
      },
      didDrawPage: function (data) {

      },
    });

    // Save the PDF
    doc.save('Punch.pdf');
  };


  function changeToCap(str) {
    return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;
  }

  function onFilter() {
    setTempFilterData(filterData);
    setPage(1);
    setFilterActive(true);
  }

  function isAbletoAccess(rowId) {
    return getUserId() == rowId;
  }
  function clearFilter() {
    setFilterActive(false);
    setFilterData({
      MembersResponsible: null,
      punchItemManager: null,

    });
    setTempFilterData({
      punchItemManager: null,
      membersResponsible: null,
      // initiatedDate: null,
      // dueDate: null,
    });
  }

  function changeStatusUi2(status) {
    switch (status) {
      case 'Not Started':
        return <div className="statusOuterns">
          <div className="statusDotns" />
          <div className="Statusns">Not Started</div>
        </div>
        break;
      case 'In Progress':
        return <div className="statusOuterip">
          <div className="statusDotip" />
          <div className="Statusip">In Progress</div>
        </div>
        break;
      case 'Completed':
        return <div className="statusOuterc">
          <div className="statusDotc" />
          <div className="Statusc">Completed</div>
        </div>
        break;
      case 'Ready for Review':
        return <div className="statusOuterr">
          <div className="statusDotr" />
          <div className="Statusr">Ready for Review</div>
        </div>
        break;
      case 'Incomplete':
        return <div className="statusOuterin">
          <div className="statusDotin" />
          <div className="Statusin">Incomplete</div>
        </div>
        break;

      default:
        break;
    }
  }

  async function statusChange(data, id, toolId, comment) {
    try {

      let result = await updatePunch(id, { ...data, ...comment, punchID: toolId });
      fetchPunch()


    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <ChangeStatusModal
        open={open}
        setOpen={setOpen}
        value={dropdownvalue}
        setValue={setDropDownValue}
        onSubmit={changeStatus}
        setComment={setComment}
        statusRequired={statusRequired}
        setStatusRequired={setStatusRequired}
      />
      <div className="between">
        <h1 className="page_heading">Punch List</h1>
        <div className="d-flex align_center gap-2">
          <div className="right d-flex gap-2 align-items-center">
            <ExportPdfButton
              exportToPDF={exportToPDF}
              csvBtn={
                <CSVLink
                  className="pe-1"
                  data={csvData}
                  headers={csvHeaders}
                  filename={"Punch.csv"}
                >
                  CSV
                </CSVLink>
              }
            />
          </div>


         {getActionsPermission("Create Punch") &&<PrimaryButton
            onClick={() => navigate("create")}
            addIcon
            text={"Create"}
          />}
        </div>
      </div>

      <div style={{ height: "0", overflow: "hidden" }}>
        <div>Punch List</div>
        <table ref={tableRef}>
          <thead>
            <tr>
              <th>Punch List ID</th>
              <th>Punch List Name</th>
              <th>Date</th>
              <th>Responsible Contractor</th>
              <th>Responsible Members</th>
              <th>Responsible Person</th>
              <th>Workflow Status</th>
              <th>Punch List Manager</th>
              <th>Location</th>
              <th>Category</th>
              <th>Trade</th>
              <th>Description</th>
              <th>Link Drawing</th>
              <th>Is Deleted</th>
              <th>Created At</th>

            </tr>
          </thead>
          <tbody>
            {original?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item?.punchID}</td>
                  <td>{item?.PunchItemName}</td>
                  <td>{moment(item?.date).format("DD-MM-YYYY")}</td>
                  <td>{item?.responsibleContractor?.map(e => e?.company_name)?.join(", ")}</td>
                  <td>{item?.MembersResponsible?.map((res) => res?.first_name)?.join(", ")}</td>
                  <td>{item?.person?.map((res) => res?.first_name)?.join(", ")}</td>
                  <td>{item.workflow_status}</td>
                  <td>{item.punchItemManager?.map(e => e.first_name)?.join(", ")}</td>
                  <td>{item.Location}</td>
                  <td>{capitalizeFirstLetter(item.category?.name)}</td>
                  <td>{capitalizeFirstLetter(item.Trade?.name)}</td>
                  <td>
                    <HtmlIntoText html={item.discription} />
                  </td>
                  <td>{item.linkDrawing}</td>
                  <td>{item.is_deleted ? "Yes" : "No"}</td>
                  <td>{moment(item.created_at).format('DD-MM-YYYY  hh:mm A')}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <br />

      <div className="row">
        <div className="col-md-3 searchInput">
          <SearchInput
            value={tableSortData.search}
            onChange={(e) => {
              const searchTerm = e.target.value;
              setTableSortData(prev => ({
                ...prev,
                page: 1,
                search: searchTerm
              }));

              setPunchData(
                searchDataWithKeys(["PunchItemName"], puchData, e.target.value)
              );
            }}
            onCancel={() => {
              setSearch("");
              setTableSortData(pre => ({ ...pre, page: 1, search: '' }))
              setPunchData(puchData);
            }

            }

          />
        </div>
        <div className="col-md-9 d-flex justify-content-end">

          <Filter
            onFilter={onFilter}
            filterData={filterData}
            setFilterData={setFilterData}
          />
          <button
            disabled={!filterActive}
            className="filter_btn"
            onClick={clearFilter}
          >
            Clear Filter
          </button>
        </div>
      </div>












      <br />
      <div>
        <div>
          <div className="tools_table_scroll">
            {skeletonLoader ? (
              <TableSkelton
                header={[
                  "Punch List ID",
                  "Punch List Name",
                  "Member Responsible",
                  "Punch Item Manager",
                  "Due Date",
                  "Approval Status",
                  "",
                ]}
              />
            ) : (

              <div style={{ width: '100%', overflow: 'auto' }}>
                <div style={{ width: '2400px' }}>
                  <DataTable value={puchData}>
                    <Column
                      headerClassName="text-nowrap"
                      sortable
                      field="id"
                      header="Punch List ID"
                      body={(rowData, rowIndex) => (
                        <div>{rowData.punchID} </div>
                      )}

                    ></Column>
                    <Column
                      headerClassName="text-nowrap"
                      header="Workflow Status"
                      body={(rowData) => changeStatusUi2(rowData?.workflow_status)}
                    ></Column>
                    <Column
                      // field="PunchItemName"
                      headerClassName="text-nowrap"
                      header="Punch List Name"
                      body={(rowData) => (
                        <div
                          className=""
                          // onClick={() =>
                          //   navigate(`view/${rowData?._id}`)
                          // }
                          style={{ cursor: "pointer" }}
                        >
                          {rowData?.PunchItemName}
                        </div>
                      )}
                    ></Column>

                    <Column
                      headerClassName="text-nowrap"
                      header="Responsible Contractor Company / Name"
                      body={(rowData) => {
                        if (!rowData || !rowData.responsibleContractor) {
                          return <div>No data available</div>;
                        }

                        // Safeguard against undefined rowData.responsibleContractor
                        let data = rowData.responsibleContractor.map(res => {
                          let pp = rowData.MembersResponsible
                            ? rowData.MembersResponsible.filter(item => item?.company === res?._id)
                            : [];

                          return {
                            company: res?.company_name || "Unknown Company",
                            persons: pp.length === 0
                              ? "No person added"
                              : pp.map(e => e?.first_name).join(', ')
                          };
                        });

                        return (
                          <div className="d-flex flex-column">
                            {data.map((res, index) => (
                              <div key={index}>{res.company}---{res?.persons}</div>
                            ))}
                          </div>
                        );
                      }}
                    />


                    <Column
                      sortable
                      headerClassName="text-nowrap"
                      // field="punchItemManager"
                      header="Punch List Manager (s)"
                      body={(rowData) => {
                        // console.log(rowData)
                        let data = rowData?.punchItemManager?.map(
                          (res) => res?.first_name
                        )?.join(", ");
                        return (
                          <div title={data}>
                            {data?.slice(0, 15)}
                            {data?.length > 15 ? "..." : ""}
                          </div>
                        );
                      }}
                    ></Column>
                    <Column
                      headerClassName="text-nowrap"
                      header="Responsible Persons(s)"
                      body={(rowData) => {
                        // console.log(rowData)
                        let data = rowData?.person?.map(
                          (res) => res?.first_name
                        )?.join(", ");
                        return (
                          <div title={data}>
                            {data?.slice(0, 15)}
                            {data?.length > 15 ? "..." : ""}
                          </div>
                        );
                      }}
                    ></Column>
                    <Column
                      headerClassName="text-nowrap"
                      sortable
                      field="Date"
                      body={(rowData) =>
                        moment(rowData?.date).format("DD-MM-YYYY")
                      }
                      header="Date"
                    ></Column>
                    <Column
                      headerClassName="text-nowrap"
                      body={(rowData) => {

                        return (<ChangeApprovalStatus
                          status={rowData.status}
                          onSubmit={statusChange}
                          toolData={{
                            tool_id: rowData?._id,
                            tool_number: rowData?.punchID,
                            tool_name: rowData?.PunchItemName,
                            tool: "Punch List",
                            _id: rowData?._id,
                            approver_id: rowData?.approver_id,
                            reviewer_id: rowData?.reviewer_id,
                            reviewer_name: `${rowData?.reviewer?.first_name} ${rowData?.reviewer?.last_name}`,
                            approver_name: `${rowData?.approver?.first_name} ${rowData?.approver?.last_name}`,
                            reviewer_email: `${rowData?.reviewer?.email_address}`,
                            approver_email: `${rowData?.approver?.email_address}`,
                            creator_name: rowData?.creator_email?.[0]?.first_name || rowData?.creator_email?.[0]?.name,
                            creator_email: rowData?.creator_email?.[0]?.email_address || rowData?.creator_email?.[0]?.email
                          }}
                          rowData={rowData}

                        />
                        )

                      }}
                      header="Approval Status"></Column>

                    <Column
                      header={"Action"}
                      body={(rowData) => (
                        <div className="d-flex justify-content-center">
                          <Popover
                            options={["Version History", "Edit", "Delete", "View",

                              <DownloadPdfAndSendEmail

                                fileName={"PUNCH"}
                                heading={"punch"}
                                id={rowData._id}
                                files={rowData?.attachFile?.map((e) => e.url)}
                              >



                                <ViewPagesPdfTemplate

                                  header={rowData?.PunchItemName}
                                  // tableData1={[
                                  //   {"responsibleContractor" :  rowData?.responsibleContractor?.map(e => e?.company_name)?.join(", ")},

                                  //   { "Members Responsible": rowData.MembersResponsible?.map(e => e?.first_name).join(", ")},
                                  //   {"Punch List Manager": rowData.punchItemManager?.map(e => e?.first_name).join(", ")},
                                  //   { "Location": rowData?.Location || "--" },
                                  //   { "Category": capitalizeFirstLetter(rowData?.category?.name) || "--" },
                                  //   { "Trade": capitalizeFirstLetter(rowData?.Trade?.name) || "--" },
                                  // ]}
                                  tableData1={[
                                    { "Punch List ID": rowData?.punchID },
                                    {
                                      "Responsible Contractor Company":
                                        rowData?.responsibleContractor
                                          ?.map((e) => e?.company_name)
                                          ?.join(", ") || "--",
                                    },
                                    {
                                      " Responsible Member":
                                        rowData?.MembersResponsible
                                          ?.map((e) => e?.first_name)
                                          ?.join(", ") || "--",
                                    },
                                    {
                                      "Responsible Person ":
                                        rowData?.person?.map((e) => e?.first_name)?.join(", ") ||
                                        "--",
                                    },
                                    {
                                      "Punch List Manager":
                                        rowData?.punchItemManager?.map((e) => e?.first_name) || "--",
                                    },
                                    { "Trade": capitalizeFirstLetter(rowData?.Trade?.name) || "--" },
                                    { "Due Date": moment(rowData?.date).format("MM/DD/YYYY") || "--", },
                                    { " Workflow Status": rowData?.workflow_status || "--" },
                                    { "Location": rowData?.Location || "--" },
                                    { "Category": capitalizeFirstLetter(rowData?.category?.name) || "--" },
                                    { "Hour Spent": formatHourSpent(rowData?.hourSpent) },
                                    { "Reviewer's Comments": rowData?.reviewer_comment || "--" },
                                    { "Approver's Comments": rowData?.approver_comment || "--" },
                                  ]}

                                  description={{
                                    key: "Punch List Description",
                                    value: <HtmlIntoText html={rowData?.discription} />,
                                  }}

                                // description={{
                                //   key: "Specification Description",
                                //   value: (
                                //     <HtmlIntoText html={rowData?.discription} />
                                //   ),
                                // }}
                                // tableData2={[
                                //   { Status: rowData?.status || "" },
                                //   // { "Member Responsible": rowData?.MembersResponsible?.[0]?.first_name || "" },
                                //   {
                                //     "Due Date":
                                //       moment(rowData?.Date).format(
                                //         "MM/DD/YYYY"
                                //       ) || "--",
                                //   },
                                // ]}
                                />

                                <div>


                                </div>
                              </DownloadPdfAndSendEmail>,
                              "Send Via Post",
                            ]}
                            onSelect={(index) => {
                              if (index === 0) {
                                if (!getActionsPermission("Punch version History")) {
                                  toast.error("You don't have permission of Punch list version history")
                                  return
    
                                }
                                navigate(
                                  `/projectManagement/version-history/PUNCH/${rowData?.PunchItemName}/${rowData?._id}`
                                );
                              } else if (index === 1) {
                                if (!getActionsPermission("Edit Punch")) {
                                  toast.error("You don't have permission to edit Punch List")
                                  return
                                }
                                navigate(`edit/${rowData?._id}`);
                              }
                              // else if (index === 2)
                              //   navigate(`/view/${rowData?._id}`)
                              else if (index === 2) {
                                if (!getActionsPermission("Delete Punch")) {
                                  toast.error("You don't have permission to Delete Punch List")
                                  return
                                }

                                deletePunchs(rowData?._id);
                              }
                              else if (index === 3){
                                if (!getActionsPermission("View Punch")) {
                                  toast.error("You don't have permission to view Punch List")
                                  return
                                }
                                navigate(
                                  `view/punch/${rowData?.PunchItemName}/${rowData?._id}`
                                );
                              }
                              else if (index === 5) {
                                navigate(
                                  `/projectManagement/addressListing/punch/${rowData?.PunchItemName}/${rowData?._id}`
                                );
                              }
                              // navigate(`view/${rowData?._id}`);
                            }}
                          />
                        </div>
                      )}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            )}
          </div>

          <Pagination
            currentPage={tableSortData.page}
            totalCount={totalCount}
            pageSize={perPageData}
            onPageChange={(e) => setTableSortData(pre => ({ ...pre, page: e }))}
          />
        </div>
      </div>

      <DeleteDialog
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        onCancel={() => setSelectedPunchId(null)}
        onConfirm={confirmDelete}
        header={"Punch Lists"}
      />
    </>
  );
};

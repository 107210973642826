import React, { useEffect, useRef, useState } from "react";
import "./openrfis.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";

import {
  deleteRfi,
  getRfiByProjectId,
  sendEmailWithAttachment,
  updateRfi,
} from "../../Api/Admin/AllAPIs";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { SearchInput } from "../Inputs/SearchInput";
import { BorderedTable } from "../Tables/BorderedTable";
import { CSVLink } from "react-csv";
import Popover from "../Common/Popover";
import { DeleteDialog } from "../Common/DeleteDialog";
import { ExportPdfButton } from "../Buttons/ExportPdfButton";
import ChangeStatusModal from "../ChangeStatusModal/ChangeStatusModal";
import { TableSkelton } from "../Common/TableSkelton";
import {
  filterArrayWithMultipleKey,
  getActionsPermission,
  getProjectCode,
  getProjectName,
  getUserData,
  getUserId,
  showDateAndTime,
  toast,
} from "../../utils";
import { HtmlIntoString, HtmlIntoText } from "../Common/HtmlIntoText";
import Pagination from "../Common/Pagination/Pagination";
import Filter from "./Filter";
import moment from "moment/moment";
import SendEmail from "./SendEmail";
import { downloadPdf } from "../Common/ViewPageComponents/downloadPdf";
import { ViewPagesPdfTemplate } from "../Common/ViewPageComponents/ViewPagesPdfTemplate";
import { DownloadPdfAndSendEmail } from "../Common/ViewPageComponents/DownloadPdfAndSendEmail";
import { ChangeApprovalStatus } from "../Tools/ChangeApprovalStatus";
import { ViewPageDocumentName } from "../Common/ViewPageComponents/ViewPageDocumentName";
import { ViewPageTable } from "../Common/ViewPageComponents/ViewPageTable";
import { useSelector } from "react-redux";
export const Openrfis = () => {
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  let e = useSelector(e => e.rolesAndPermissionData)
  const [search, setSearch] = useState("");
  const [originalRfi, setOriginalRfi] = useState([]);
  const [rfiId, setRfiId] = useState(null);

  const navigate = useNavigate();
  let pdfRef = useRef();
  const [rfi, setRfi] = useState({});
  const [loader, setLoader] = useState(false);
  const emailRef = useRef();
  const [open, setOpen] = useState(null);
  const [dropdownvalue, setDropDownValue] = useState(null);
  const [comment, setComment] = useState("");
  const [type, setType] = useState("");
  const [itemId, setItemId] = useState("");
  const [currentStatusRowData, setCurrentStatusRowData] = useState({});
  const [statusRequired, setStatusRequired] = useState(false);
  const [openMailBox, setOpenMailBox] = useState(false);
  const [rfiData, setRfiData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const perPageData = 10;
  const [tempFilterData, setTempFilterDate] = useState({
    status: null,
    location: null,
    responsibleContractor: null,
    membersResponsible: null,
    initiatedDate: null,
    dueDate: null,
  });
  const projectId = localStorage.getItem('projectId')
  const userData = localStorage.getItem("userData");
  const userObj = JSON.parse(userData);
  const userId = userObj._id;
  const userName = userObj.name;
  const [filterRfiData, setFilterRfiData] = useState({
    status: null,
    location: null,
    responsibleContractor: null,
    membersResponsible: null,
    initiatedDate: null,
    dueDate: null,
  });
  const [filterActive, setFilterActive] = useState(false);
  const [contractorData, setContractorData] = useState([]);

  async function changeStatus(e) {
    e.preventDefault();

    if (dropdownvalue == null) {
      setStatusRequired(true);
      return;
    }
    setOpen(false);

    try {
      if (type == "reviewer") {
        let emailData = [];
        let payload = {
          status: dropdownvalue?.code,
          reviewer_comment: comment,
          project_id: projectId,
          email_data: [],
        };

        // creator_email
        if (dropdownvalue?.code == "pendingapproval") {
          emailData = [
            {
              email: currentStatusRowData?.approver?.email_address,
              type: "Approver",
              tool_name: currentStatusRowData?.subject,
              project: getProjectName(),
              toolType: "RFI",
              name: currentStatusRowData?.approver?.first_name,
              project_id: "8767",
              tool_id: currentStatusRowData?.rfi,
              createdAt: moment().format("DD MMM YYYY  hh:mm A"),
              createdBy: userData?.name,
            },
          ];
        }

        emailData.push({
          email: currentStatusRowData?.creator_email,
          type: "Project Creator",
          tool_name: currentStatusRowData?.subject,
          project: getProjectName(),
          toolType: "RFI",
          name: currentStatusRowData?.approver?.first_name,
          project_id: "8767",
          tool_id: currentStatusRowData?.rfi,
          createdAt: moment().format("DD MMM YYYY  hh:mm A"),
          createdBy: userData?.name,
        });

        payload.email_data = emailData;

        await updateRfi(itemId, payload);
      } else {
        let payload = {
          status: dropdownvalue?.code,
          approver_comment: comment,
          project_id: projectId,
        };

        await updateRfi(itemId, payload);
      }
    } catch (err) {
      console.log(err);
    } finally {
      fetchRfi();
      setItemId("");
      setType("");
      setLoader(false);
      setDropDownValue(null);
    }
  }

  const fetchRfi = async () => {

    try {
      setSkeletonLoader(true);
      let queryParams = {
        page,
        perPageData,
        search,
      };
      if (search !== "") {
        queryParams.search = search;
      }

      let body = {
        ...tempFilterData,
        dueDate: tempFilterData?.dueDate
          ? moment(tempFilterData?.dueDate).format("yyyy-MM-DD")
          : null,
      };
      const res = await getRfiByProjectId(projectId, queryParams, body);
      console.log(res);
      console.log(res.data.data);
      let response = res?.data?.data;
      console.log(response);
      setRfiData(res?.data?.data || []);
      setOriginalRfi(res?.data?.data);
      setAllData(res?.data?.data || []);
      setTotalRecords(res?.data?.totalCount || 0);
      console.log(response?.responsibleContractor);
    } catch (err) {
      console.error(err);
    } finally {
      setSkeletonLoader(false);
    }
  };

  function companyAndpersonData(id) {
    let response = rfiData?.find((ee) => ee._id === id);
    let datass = response?.responsibleContractor?.map((res) => {
      let pp = response?.membersResponsible?.filter(
        (item) => item?.company == res?._id
      );
      return {
        company: res?.company_name,
        persons:
          pp?.length == 0
            ? "No Person Added"
            : pp?.map((ress) => ress?.first_name)?.join(", "),
      };
    });
    console.log(datass);
    setContractorData(datass);
  }

  const handleDelete = async (id) => {
    setVisibleDelete(true);
    setRfiId(id);
  };

  const confirmDelete = async () => {
    setLoader(true);
    let payload = {
      created_by: userName,
    }
    try {
      await deleteRfi(rfiId, payload);
      fetchRfi();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchRfi();
    console.log(userData);
  }, [page, tempFilterData]);

  useEffect(() => {
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      fetchRfi();
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [search]);

  const csvData = originalRfi?.map((item) => ({
    Subject: item.subject,
    ["RFI Number"]: item.rfi,
    "Responsible Contractor": item?.responsibleContractor
      ?.map((res) => res?.company_name)
      ?.join(", "),
    "Members Responsible": item?.membersResponsible
      ?.map((res) => res?.first_name)
      ?.join(", "),
    "Initiated Date": item.initiatedDate,
    DueDate: item.dueDate,
    Status: item.status,
    Location: item.location,
    "Question Received": item.questionReceived,
    // Reference: item.reference,
    "Drawing Number": item.drawingNumber,
    Category: item.category,
    "Scheduled Impact": item.scheduledImpact,
    "Cost Impact": item.costImpact,
    Question: HtmlIntoString({ html: item.question }),
    Deleted: item.is_deleted,
    "Created at": item.created_at,
  }));

  const csvHeaders = [
    { label: "Subject", key: "Subject" },
    { label: "RFI Number", key: "RFI Number" },
    { label: "Responsible Contractor", key: "Responsible Contractor" },
    { label: "Members Responsible", key: "Members Responsible" },
    { label: "Initiated Date", key: "Initiated Date" },
    { label: "DueDate", key: "DueDate" },
    { label: "Status", key: "Status" },
    { label: "Location", key: "Location" },
    { label: "Question Received", key: "Question Received" },
    { label: "Reference", key: "Reference" },
    { label: "Drawing Number", key: "Drawing Number" },
    { label: "Category", key: "Category" },
    { label: "Scheduled Impact", key: "Scheduled Impact" },
    { label: "Cost Impact", key: "Cost Impact" },
    { label: "Question", key: "Question" },
    { label: "Deleted", key: "Deleted" },
    { label: "Created at", key: "Created at" },
  ];

  const tableRef = useRef();

  const exportToPDF = async () => {
    const doc = new jsPDF();

    try {
      doc.autoTable({
        html: tableRef.current,
        didParseCell: function (data) {
          data.cell.styles.fontSize = 2;
        },
      });
      const pdfBlob = doc.output("blob");
      const reader = new FileReader();
      reader.readAsDataURL(pdfBlob);
      reader.onloadend = async function () {
        const base64data = reader.result.split(",")[1]; // Remove the data URL prefix
        const formattedAttachment = {
          content: base64data,
          filename: "rfi.pdf",
          type: "application/pdf",
          disposition: "attachment",
        };

        const body = {
          subject: "Email header header",
          to: "virajgulia1998@gmail.com",
          text: "This is text of email",
          cc: [],
          bcc: [],
          attachments: formattedAttachment,
          htmlData: emailRef?.current?.innerHTML || "",
        };
        let ee = await sendEmailWithAttachment(body);
      };
      doc.save("rfi.pdf");
    } catch (err) {
      console.log(err);
    }
  };

  function filterData() {
    setTempFilterDate(filterRfiData);
    setPage(1);
    setFilterActive(true);
  }

  function clearFilter() {
    setFilterActive(false);
    setFilterRfiData({
      status: null,
      location: null,
      responsibleContractor: null,
      membersResponsible: null,
      initiatedDate: null,
      dueDate: null,
    });
    setTempFilterDate({
      status: null,
      location: null,
      responsibleContractor: null,
      membersResponsible: null,
      initiatedDate: null,
      dueDate: null,
    });
  }

  function searchData(e) {
    let value = e.target.value;
    setSearch(value);
    setRfiData(
      filterArrayWithMultipleKey(rfiData, ["status", "subject"], value)
    );
  }

  function changeStatusUi(status) {
    switch (status) {
      case "draft":
        return (
          <div className="statusOuterD">
            <div className="statusDotD" />
            <div className="StatusD">Draft</div>
          </div>
        );
        break;
      case "inreview":
        return (
          <div className="statusOuterI">
            <div className="statusDotI" />
            <div className="StatusI">In Review</div>
          </div>
        );
        break;
      case "pendingapproval":
        return (
          <div className="statusOuterP">
            <div className="statusDotP" />
            <div className="StatusP">Pending Approver</div>
          </div>
        );
        break;
      case "approved":
        return (
          <div className="statusOuterA">
            <div className="statusDotA" />
            <div className="StatusA">Approved</div>
          </div>
        );
        break;

      default:
        break;
    }
  }
  function changeStatusUi2(status) {
    switch (status) {
      case "Not Started":
        return (
          <div className="statusOuterns">
            <div className="statusDotns" />
            <div className="Statusns">Not Started</div>
          </div>
        );
        break;
      case "In Progress":
        return (
          <div className="statusOuterip">
            <div className="statusDotip" />
            <div className="Statusip">In Progress</div>
          </div>
        );
        break;
      case "Completed":
        return (
          <div className="statusOuterc">
            <div className="statusDotc" />
            <div className="Statusc">Completed</div>
          </div>
        );
        break;
      case "Ready for Review":
        return (
          <div className="statusOuterr">
            <div className="statusDotr" />
            <div className="Statusr">Ready for Review</div>
          </div>
        );
        break;
      case "Incomplete":
        return (
          <div className="statusOuterin">
            <div className="statusDotin" />
            <div className="Statusin">Incomplete</div>
          </div>
        );
        break;

      default:
        break;
    }
  }

  async function sendMailFunction(dd) {
    try {
      const body = {
        subject: "Email header",
        to: dd.to,
        text: "This is text of email",
        cc: dd.cc,
        bcc: dd.bcc,
        //   attachments: formattedAttachment,
        htmlData: dd.text,
      };

      await sendEmailWithAttachment(body);
    } catch (err) {
      console.log(err);
    }
  }
  
  async function statusChange(data, id, toolId,comment) {
    try {
      console.log(data, id);

      let res = await updateRfi(id, { ...data,...comment, rfi: toolId });
      fetchRfi();
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <ChangeStatusModal
        open={open}
        setOpen={setOpen}
        value={dropdownvalue}
        setValue={setDropDownValue}
        onSubmit={changeStatus}
        setComment={setComment}
        type={type}
        statusRequired={statusRequired}
        setStatusRequired={setStatusRequired}
      />
      <SendEmail
        sendEmail={sendMailFunction}
        open={openMailBox}
        setOpen={setOpenMailBox}
      />

      <div className="d-flex flex-sm-row flex-column justify-content-between">
        <div className="page_heading">RFI</div>

        <div className="d-flex align_center justify-content-end gap-2 mt-sm-0 mt-3">
          <div className="right d-flex gap-2 align-items-center">
            <ExportPdfButton
              exportToPDF={exportToPDF}
              csvBtn={
                <CSVLink
                  className="pe-1"
                  data={csvData}
                  headers={csvHeaders}
                  filename={"RFI.csv"}
                >
                  CSV
                </CSVLink>
              }
            />
          </div>

          {getActionsPermission("Create Rfi") && <PrimaryButton
            onClick={() => navigate("createRfi")}
            addIcon
            text={"Create RFI"}
          />}
        </div>
      </div>

      <div style={{ height: "0", overflow: "hidden" }}>
        <table ref={tableRef}>
          <thead>
            <tr>
              <th>#</th>
              <th>Subject</th>
              <th>RFI Number</th>
              <th>Responsible Contractor</th>
              <th>Members Responsible</th>
              <th>Initiated Date</th>
              <th>Due Date</th>
              <th>Status</th>
              <th>Location</th>
              <th>Question Received</th>
              <th>Reference</th>
              <th>Drawing Number</th>
              <th>Category</th>
              <th>Scheduled Impact</th>
              <th>Cost Impact</th>
              <th>Question</th>
              <th>Deleted</th>
              <th>Created at</th>
            </tr>
          </thead>
          <tbody>
            {originalRfi?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.subject}</td>
                  <td>{item.rfi}</td>
                  <td>
                    {item?.responsibleContractor
                      ?.map((res) => res?.company_name)
                      ?.join(", ")}
                  </td>
                  <td>
                    {item?.membersResponsible
                      ?.map((res) => res?.first_name)
                      ?.join(", ")}
                  </td>
                  <td>{item.initiatedDate}</td>
                  <td>{item.dueDate}</td>
                  <td>{item.status}</td>
                  <td>{item.location}</td>
                  <td>{item.questionReceived}</td>
                  <td>{item.reference}</td>
                  <td>{item.drawingNumber}</td>
                  <td>{item.category}</td>
                  <td>{item.scheduledImpact}</td>
                  <td>{item.costImpact}</td>
                  <td>
                    <HtmlIntoText html={item.question} />
                  </td>
                  <td>{item.is_deleted ? "Yes" : "No"}</td>
                  <td>
                    {moment(item?.created_at)?.format("DD-MM-YYYY h:m A")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center">
        <div className="searchInput">
          <SearchInput
            onCancel={() => {
              setSearch("");
              searchData({ target: { value: "" } });
            }}
            value={search}
            onChange={searchData}
          />
        </div>
        <div className="d-flex text-nowrap gap-2 mt-sm-0 mt-2">
          <Filter
            className={"w-100"}
            filterData={filterRfiData}
            setFilterData={setFilterRfiData}
            onFilter={filterData}
          />
          <button
            disabled={!filterActive}
            className="filter_btn w-100"
            onClick={clearFilter}
          >
            Clear Filter
          </button>
        </div>
      </div>
      <br />
      <div>

        <div className="tools_table_scroll">
          {skeletonLoader ? (
            <TableSkelton
              header={[
                "RFI Number",
                "workflow Status",
                "Subject",
                "Responsible Contractor Company / Name",
                "RFI Manager",
                "Responsible Member",
                "Due Date",
                "Approval Status",
                "",
              ]}
            />
          ) : (
            <BorderedTable>
              <DataTable value={rfiData}>
                <Column
                  field=""
                  header="RFI Number"
                  body={(rowData, rowIndex) => (
                    // <div>{rowIndex.rowIndex + 1}</div>
                    <div>{rowData?.rfi}</div>
                  )}
                ></Column>
                <Column
                  header="Workflow Status"
                  body={(rowData) => changeStatusUi2(rowData?.workflow_status)}
                ></Column>

                <Column
                  field="subject"
                  header="Subject"
                  body={(rowData) => <div>{rowData?.subject}</div>}
                ></Column>

                <Column
                  header="Responsible Contractor Company / Name"
                  body={(rowData) => {
                    let data = rowData?.responsibleContractor?.map((res) => {
                      let pp = rowData?.membersResponsible?.filter(
                        (item) => item?.company == res?._id
                      );
                      return {
                        company: res?.company_name,
                        persons:
                          pp.length == 0
                            ? "No Person Added"
                            : pp?.map((ress) => ress?.first_name)?.join(", "),
                      };
                    });
                    // let data = rowData?.responsibleContractor?.map(res => res?.company_name)?.join(', ')
                    return (
                      <div className="d-flex flex-column">
                        {data?.map((res, index) => (
                          <div key={index}>
                            {res?.company} - {res?.persons}
                          </div>
                        ))}
                      </div>
                    );
                  }}
                />

                <Column
                  header="RFI Manager"
                  body={(rowData) => {
                    let data = rowData?.rfi_manager
                      ?.map((res) => res?.first_name)
                      ?.join(", ");
                    return (
                      <div title={data}>
                        {data?.slice(0, 15)}
                        {data?.length > 15 ? "..." : ""}
                      </div>
                    );
                  }}
                />

                <Column
                  header="Responsible Person"
                  body={(rowData) => {
                    let data = rowData?.assignees?.join(", ");
                    return (
                      <div title={data}>
                        {data?.slice(0, 15)}
                        {data?.length > 15 ? "..." : ""}
                      </div>
                    );
                  }}
                ></Column>
                <Column
                  header="Approver"
                  field="approver.first_name"
                ></Column>
                <Column
                  header="Reviewer"
                  body={(rowData) => {
                    let data = rowData?.assignees?.join(", ");
                    return (
                      <div title={data}>
                        {data?.slice(0, 15)}
                        {data?.length > 15 ? "..." : ""}
                      </div>
                    );
                  }}
                ></Column>




                {/* <Column
                                        header="Date created "
                                        body={(rowData) => moment(rowData.initiatedDate).format("MM-DD-YYYY")}
                                    ></Column> */}
                <Column
                  body={(rowData) => (
                    <div style={{ whiteSpace: "nowrap" }}>
                      {moment(rowData.dueDate, "DD/MM/YYYY h:mm:ss a").format(
                        "DD-MM-YYYY"
                      )}
                    </div>
                  )}
                  header="Due Date"
                ></Column>


                <Column
                  headerClassName="text-nowrap"
                  body={(rowData) => {
                    return (
                      <ChangeApprovalStatus
                        status={rowData.status}
                        onSubmit={statusChange}
                        toolData={{
                          tool_id: rowData?._id,
                          tool_number: rowData?.rfi,
                          tool_name: rowData?.subject,
                          tool: "Rfi",
                          _id: rowData?._id,
                          approver_id: rowData?.approver_id,
                          reviewer_id: rowData?.reviewer_id,
                          reviewer_name: `${rowData?.reviewer?.first_name} ${rowData?.reviewer?.last_name}`,
                          approver_name: `${rowData?.approver?.first_name} ${rowData?.approver?.last_name}`,
                          reviewer_email: `${rowData?.reviewer?.email_address}`,
                          approver_email: `${rowData?.approver?.email_address}`,
                          creator_name:
                            rowData?.creator_email?.[0]?.first_name ||
                            rowData?.creator_email?.[0]?.name,
                          creator_email:
                            rowData?.creator_email?.[0]?.email_address ||
                            rowData?.creator_email?.[0]?.email,
                        }}
                        rowData={rowData}
                      />
                    );
                  }}
                  header="Approval Status"
                ></Column>

                <Column
                  field=""
                  header="Action"
                  body={(rowData, meta) => (
                    <div className="flex justify-content-center">
                      <Popover
                        options={[
                          "Version History",
                          "Edit",
                          "Delete",
                          "View",
                          <div>
                            <DownloadPdfAndSendEmail
                              heading={"RFI"}
                              fileName={"RFI"}
                              id={rowData._id}
                              files={[
                                ...rowData?.attachFile?.map((e) => e.fileUrl),
                                ...rowData?.response
                                  ?.map((es) =>
                                    es?.attachFile.map((sa) => sa?.fileUrl)
                                  )
                                  .flat(Infinity),
                              ]}
                            >
                              <div className="p-5">
                                <div className="d-flex flex-column gap-3">
                                  <div className="row">
                                    <div className="col-6">
                                      <ViewPageTable
                                        tableData={[
                                          {
                                            "Question Received From":
                                              rowData?.questionReceived || "--",
                                          },
                                          {
                                            "RFI Number": rowData?.rfi || "--",
                                          },
                                          {
                                            "Workflow Status":
                                              rowData?.workflow_status || "--",
                                          },
                                          {
                                            "RFI Manager":
                                              rowData?.rfi_manager
                                                ?.map((e) => e?.first_name)
                                                ?.join(", ") || "--",
                                          },
                                          {
                                            "Responsible Person":
                                              rowData?.assignees
                                                ?.map((e) => e)
                                                ?.join(", ") || "--",
                                          },
                                          {
                                            Reference:
                                              rowData?.reference || "--",
                                          },
                                          {
                                            "Scheduled Impact":
                                              rowData?.scheduledImpact || "--",
                                          },
                                          {
                                            "Drawing Number":
                                              rowData?.drawingNumber || "--",
                                          },
                                          {
                                            Location: rowData?.location || "--",
                                          },

                                        ]}
                                      />
                                      <div className="gray-lt text-nowrap pb-2">
                                        Responsible Contractor Company / Name:
                                      </div>
                                      <div className="d-flex flex-column">
                                        {rowData?.responsibleContractor?.map(
                                          (res, index) => (
                                            <div
                                              className="gray-d pb-2"
                                              key={index}
                                            >
                                              {res?.company_name} -{" "}
                                              {
                                                rowData?.membersResponsible?.find(
                                                  (e) =>
                                                    e?.company ==
                                                    res?._id
                                                )?.first_name
                                              }
                                            </div>
                                          )
                                        )}
                                      </div>

                                    </div>
                                    <div className="col-6">
                                      <ViewPageTable
                                        tableData={[
                                          {
                                            "Approval Status":
                                              rowData?.status ==
                                                "pendingapproval"
                                                ? "Pending Approval"
                                                : rowData?.status || "--",
                                          },
                                          {
                                            "Due Data":
                                              moment(
                                                rowData?.dueDate,
                                                "DD/MM/YYYY h:mm:ss a"
                                              ).format("MM/DD/YYYY") || "--",
                                          },
                                          {
                                            Location: rowData?.location || "--",
                                          },
                                          {
                                            Category: rowData?.category || "--",
                                          },
                                          {
                                            "Cost Code":
                                              rowData?.costCode || "--",
                                          },
                                          {
                                            "Cost Impact":
                                              rowData?.costImpact || "--",
                                          },
                                          {
                                            "Hours Spent": rowData?.hourSpent
                                              ? `${rowData?.hourSpent?.split(
                                                "-"
                                              )[0]
                                              } hour ${rowData?.hourSpent?.split(
                                                "-"
                                              )[1]
                                              } Min`
                                              : "--",
                                          },
                                          {
                                            Reviewer:
                                              rowData?.reviewer?.length !== 0 &&
                                                rowData?.reviewer !== undefined
                                                ? `${rowData?.reviewer?.first_name} ${rowData?.reviewer?.last_name}`
                                                : "--",
                                          },
                                          {
                                            Approver:
                                              rowData?.approver?.length !== 0 &&
                                                rowData?.approver !== undefined
                                                ? `${rowData?.approver?.first_name} ${rowData?.approver?.last_name}`
                                                : "--",
                                          },
                                          {
                                            "Reviewer Comment":
                                              rowData?.reviewer_comment || "--",
                                          },
                                          {
                                            "Approver Comment":
                                              rowData?.approver_comment || "--",
                                          },
                                          //   { Reference: rowData?.reference || "--" },
                                          {
                                            "Created Date":
                                              moment(
                                                rowData?.created_at
                                              ).format(
                                                "DD/MM/YYYY h:mm:ss A"
                                              ) || "--",
                                          },
                                        ]}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="view_page_para_head mt-4 mb-2 ">
                                  Question
                                </div>
                                <div className="view_page_bold_text ">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: rowData?.question,
                                    }}
                                  />
                                </div>
                                <div>
                                  {/* <BorderedTable> */}
                                  <DataTable
                                    value={rowData?.response}
                                  //   footer={customFooter}
                                  // paginator
                                  // rows={5}
                                  // rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]}
                                  >
                                    <Column
                                      body={(rfi, rowIndex) => (
                                        <div>{rowIndex.rowIndex + 1}</div>
                                      )}
                                    // style={{ minWidth: "4rem" }}
                                    />
                                    {/* <Column
                      field="response_by"
                      header="Response By"
                      // style={{ width: "15%" }}
                      //   body={(rfi, rowIndex) => (
                      //     <div>{rowIndex.rowIndex + 1}</div>
                      //   )}
                    /> */}
                                    <Column
                                      field="response_date"
                                      header="Response Date"
                                      style={{ width: "15%" }}
                                    //   body={(rfi, rowIndex) => (
                                    //     <div>{rowIndex.rowIndex + 1}</div>
                                    //   )}
                                    />
                                    <Column
                                      field="response_descripttion"
                                      header="Response"
                                      // style={{ width: "54.6%" }}
                                      body={(rfi, rowIndex) => (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: rfi?.response_descripttion,
                                          }}
                                        />
                                      )}
                                    />
                                    {/* <Column
                      field=""
                      header="Attachments"
                      
                      body={(rowData, rowIndex) => (
                        <div>
                        {rowData?.attachFile?.map((file, index) => (
                      <ViewPageDocumentName
                        docUrl={file?.fileUrl}
                        key={index}
                        name={file?.fileName}
                      />
                    ))}
                        </div>
                      )}
                    /> */}
                                  </DataTable>
                                  {/* </BorderedTable> */}
                                </div>
                              </div>
                            </DownloadPdfAndSendEmail>
                          </div>,
                          "Send Via Post",
                        ]}
                        onSelect={(index, event) => {
                          if (index === 0) {
                            if (!getActionsPermission("Rfi version history")) {
                              toast.error("You don't have permission of Rfi version history")
                              return

                            }
                            navigate(
                              `/projectManagement/version-history/RFI/${rowData?.subject}/${rowData?._id}`
                            );
                          }
                          else if (index === 1) {
                            if (!getActionsPermission("Edit Rfi")) {
                              toast.error("You don't have permission to edit Rfi")
                              return
                            }
                            navigate(`editRfi/${rowData?._id}`);
                          }
                          else if (index === 2) {
                            if (!getActionsPermission("Delete Rfi")) {
                              toast.error("You don't have permission to delete Rfi")
                              return
                            }
                            handleDelete(rowData?._id);
                          }
                          else if (index === 3) {
                            if (!getActionsPermission("View Rfi")) {
                              toast.error("You don't have permission to view Rfi")
                              return
                            }
                            navigate(
                              `view/RFI/${rowData?.subject}/${rowData?._id}`
                            );
                          }
                          // else if (index === 4) {
                          //     setRfi(rowData)
                          //     console.log(rfi)
                          //     // navigate(`view/RFI/${rowData?.subject}/${rowData?._id}`);
                          // }
                          else if (index === 5) {
                            navigate(
                              `/projectManagement/addressListing/RFI/${rowData?.subject}/${rowData?._id}`
                            );
                          }
                          // else if (index === 5) {
                          //     setOpenMailBox(true)

                          // }
                        }}
                      />
                    </div>
                  )}
                  style={{ width: "4rem", color: "#121212" }}
                ></Column>
              </DataTable>
            </BorderedTable>
          )}
        </div>
        <br />
        <Pagination
          currentPage={page}
          totalCount={totalRecords}
          pageSize={perPageData}
          onPageChange={(page) => {
            console.log(page);
            setPage(page);
          }}
        />
      </div>
      <DeleteDialog
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        onCancel={() => setRfiId(null)}
        onConfirm={confirmDelete}
        header={"Rfi"}
      />
    </>
  );
};
export default Openrfis;

import React, { useState, useRef, useEffect } from "react";
import { Steps } from "primereact/steps";
import { Toast } from "primereact/toast";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { BsInfoLg } from "react-icons/bs";
import { RiToolsLine } from "react-icons/ri";
import { ProjectStagesIcon } from "../../Icons/CreateProjectIcons";
import { PiFolderSimpleUserThin } from "react-icons/pi";
import { PiDotsThreeOutlineThin } from "react-icons/pi";
import { SiPluscodes } from "react-icons/si";
import { HiOutlinePaintBrush } from "react-icons/hi2";
import { SlCalender } from "react-icons/sl";
import { GiSettingsKnobs } from "react-icons/gi";
import { MdOutlineCamera } from "react-icons/md";
import { getProjectId } from "../../../utils";



const FormLogic = ({ mode }) => {
  let { pathname } = useLocation();
  const [idStore, setIdStore] = useState();
  const [activeIndex, setActiveIndex] = useState(() => {
    const storedIndex = sessionStorage.getItem("activeStepIndex");
    return storedIndex ? parseInt(storedIndex, 10) : 0;
  })
  let location = useLocation();
  const toast = useRef(null);
  const navigate = useNavigate();



  const items = [
    {
      icon: <BsInfoLg />,
      template: (item) => itemRenderer(item, 0),
      label: "Project Information"
    },
    {
      icon: <PiFolderSimpleUserThin />,
      template: (item) => itemRenderer(item, 1),
      label: "Project Directory"

    },

    {
      icon: <SiPluscodes />,
      template: (item) => itemRenderer(item, 2),
      label: "Cost Codes"
    },

    {
      icon: <MdOutlineCamera />,
      template: (item) => itemRenderer(item, 3),
      label: "Project Tools"
    },

    {


      icon: <PiDotsThreeOutlineThin />,
      template: (item) => itemRenderer(item, 4),
      label: "Project Stages"
    },
    {
      icon: <HiOutlinePaintBrush />,
      template: (item) => itemRenderer(item, 5),
      label: "Project Drawings"
    },
    {
      icon: <GiSettingsKnobs />,
      template: (item) => itemRenderer(item, 6),
      label: "Specifications"
    },
    {
      icon: <GiSettingsKnobs />,
      template: (item) => itemRenderer(item, 7),
      label: "Project Schedule"
    },

    // { label: "Project Information", icon: 'pi pi-user', },
    // { label: "Project Stages" },
    // { label: "Project Tools" },
    // { label: "Cost Codes" },
    // { label: "Project Directory" },
    // { label: "Project Drawings" },
    // { label: "Specifications" },
    // { label: "Project Schedule" },
  ];
  const itemRenderer = (item, itemIndex) => {
    const isActiveItem = activeIndex === itemIndex;
    return (
      <div className={`d-flex flex-column ${isActiveItem ? "" : "gap-3"} align-items-center`}>
        <div
          className={`stepper_tab_icon stepper_tab_icon_translated ${isActiveItem && "stepper_tab_icon_active"}`}
          onClick={() => {
            setActiveIndex(itemIndex)
            goToPage(itemIndex + 1)
          }}
        >
          {item.icon}
        </div>
        <div className={`stepper_tab_label ${isActiveItem && "stepper_tab_label_active"}`}>{item.label}</div>
      </div>
    );
  };

  useEffect(() => {
    if (getProjectId()) {
      sessionStorage.setItem("activeStepIndex", activeIndex);
    }
  }, [activeIndex]);

  useEffect(() => {
    if (location.pathname == "/coretools/admin/projectform") {
      navigate("step-1")
    }
  }, [location.pathname])

  useEffect(() => {
    if (pathname) {
      const index = pathname.split("-");
      setActiveIndex(parseInt(index[1]) - 1);
      console.log(index[1]);
    }
  }, [pathname]);

  const goToPage = (page) => {
    if (!getProjectId()) {
      setActiveIndex(activeIndex);
      return
    }
    navigate(`step-${page}`);
    setActiveIndex(page - 1);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = 'Are you sure you want to leave? Your changes may not be saved.';
      event.returnValue = message; // For most browsers
      return message; // For some older browsers
    };

    const handlePopState = (event) => {
      const message = 'Are you sure you want to leave? Your changes may not be saved.';
      const shouldLeave = window.confirm(message);
      if (!shouldLeave) {
        // Prevent navigation
        window.history.pushState(null, null, window.location.href);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    // Push initial state to handle popstate properly
    window.history.pushState(null, null, window.location.href);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <div className="">
      <div className=" formLogic_container">
        <div className="d-flex align-items-center gap-3 mb-4">
          <div className="stepper_tab_icon">
            {items?.[activeIndex]?.icon}
          </div>
          <div className="heading_500_33"> {items?.[activeIndex]?.label}</div>
        </div>

        <Toast ref={toast} />

        {/* <Steps
          model={items}
          activeIndex={activeIndex}
          onSelect={(e) => goToPage(e.index + 1)}
          readOnly={false}
          className="my-4"
        /> */}
        <Steps
          model={items} activeIndex={activeIndex} readOnly={false} />
      </div>

      <div className="steps-content">
        <Outlet
          context={{
            navigation: {
              next: () => goToPage(activeIndex + 2),
              previous: () => goToPage(activeIndex),
              id: idStore,
            },
          }}
        />
      </div>
      <br />
    </div>
  );
};

export default FormLogic;

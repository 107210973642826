import React, { useEffect, useRef, useState } from "react";
import "primeicons/primeicons.css";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate, useParams } from "react-router-dom";
import Filter from "./Filter";
import {
  deleteInspectionData,
  getInspectionDataByProjectId,
  updateInspectionData,
} from "../../Api/Admin/AllAPIs";
import { changeStatusUi2, formatDateInIST, getActionsPermission, getProjectId, searchDataWithKeys, toast } from "../../utils";
import DeleteDialog from "../../Components/Common/DeleteDialog";
import Popover from "../../Components/Common/Popover";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import { DownloadPdfAndSendEmail } from "../../Components/Common/ViewPageComponents/DownloadPdfAndSendEmail";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import { HtmlIntoText } from "../../Components/Common/HtmlIntoText";
import Pagination from "../../Components/Common/Pagination/Pagination";
import jsPDF from "jspdf";
import { ExportPdfButton } from "../../Components/Buttons/ExportPdfButton";
import { CSVLink } from "react-csv";
import { ChangeApprovalStatus } from "../../Components/Tools/ChangeApprovalStatus";
import ThreeDotsMenu from "../../Components/Common/ThreeDotMenu";
import { useSelector } from "react-redux";

export default function Inspections() {
  let s = useSelector(e => e.rolesAndPermissionData)
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [loader, setLoader] = useState(true);
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [inspectionData, setInspectionData] = useState([]);
  const [inspectionsId, setInspectionsId] = useState(null);
  const [search, setSearch] = useState("");
  const projectId = localStorage.getItem("projectId");

  let navigate = useNavigate();
  const [pdfData, setPdfData] = useState([]);
  const perPageData = 10;
  const [totalCount, setTotalCount] = useState(0);
  const [allData, setAllData] = useState([]);

  const userData = localStorage.getItem("userData");
  const userObj = JSON.parse(userData);
  const userName = userObj.name;

  const [filteredData, setFilteredData] = useState({
    inspection_type: "",
    trade: "",
    reviewer_id: "",
    approver_id: "",
  })

  const [tableSearchData, setTableSearchData] = useState({
    inspection_type: "",
    trade: "",
    reviewer_id: "",
    approver_id: "",
    page: 1,
    perPageData
  })


  const hasResponses = (inspection_section) => {
    return inspection_section.some((section) =>
      section.children.some(
        (child) => child.responses && child.responses.length > 0
      )
    );
  };

  /// handle fetchdata
  async function fetchdata() {
    setLoader(true);
    try {

      setSkeletonLoader(true);
      const res = await getInspectionDataByProjectId(getProjectId(), { ...tableSearchData, search });
      console.log(res.data, 'inspection data');
      setTotalCount(res.data.totalCount)
      setPdfData(res.data.data)
      setAllData(res?.data?.data)
      setInspectionData(res?.data?.data || []);

    }
    catch (error) {
      console.log(error)
    }
    finally {
      setSkeletonLoader(false);
    }
    setLoader(false);
  }

  useEffect(() => {
    if (projectId) {
      fetchdata();
    }
  }, [tableSearchData, search]);

  // handle delete
  const handleDelete = async (id) => {
    setVisibleDelete(true);
    setInspectionsId(id);
  };

  async function confirmDelete() {
    let payload = {
      created_by: userName,
    }
    try {
      const res = await deleteInspectionData(inspectionsId, payload);
      console.log(res, "inspection delete response");
      await fetchdata();
      toast.success("Inspection Deleted Successfully!");
    } catch (error) {
      console.log(error, "error deleting data");
      toast.error("Something Wents Wrong, Please Try Again!");
    }
  }

  const csvData = pdfData.map((item) => ({
    "Inspection Id": item.inspectionId,
    "Inspections Title": item.name,
    "Inspection Type": item.inspection_type,
    "Inspection Trade": item.trade,
    "Participant": item.participant,
    "Conducted By": item.conductedBy,
    "Date": formatDateInIST(item.date),
    "Response Received": hasResponses(item.inspection_section) ? "Y" : "N",
    "Reviewer": item.reviewer?.first_name,
    "Approver": item.approver?.first_name,
    "Approval Status": item.status
  }));

  const csvHeaders = [
    { label: "Inspection Id", key: "Inspection Id" },
    { label: "Inspections Title", key: "Inspections Title" },
    { label: "Inspection Type", key: "Inspection Type" },
    { label: "Inspection Trade", key: "Inspection Trade" },
    { label: "Participant", key: "Participant" },
    { label: "Conducted By", key: "Conducted By" },
    { label: "Date", key: "Date" },
    { label: "Response Received", key: "Response Received" },
    { label: "Reviewer", key: "Reviewer" },
    { label: "Approver", key: "Approver" },
    { label: "Approval Status", key: "Approval Status" }
  ];
  const tableRef = useRef();

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      html: tableRef.current,
      didParseCell: function (data) {
        data.cell.styles.fontSize = 2;
      },
    });
    doc.save("inspection.pdf");
  };

  async function statusChange(data, _id, toolId, comment, toolData) {
    try {
      const res = await updateInspectionData(_id, { ...data, ...comment });
      fetchdata();
    } catch (error) { }
  }

  function filterData(data) {
    setTableSearchData(pre => ({ ...pre, ...data, page: 1, perPageData }))
  }
  function clearFilter() {

    setTableSearchData(pre => ({
      inspection_type: "",
      trade: "",
      reviewer_id: "",
      approver_id: "",
      page: 1,
      perPageData,

    }))
    setFilteredData({
      inspection_type: "",
      trade: "",
      reviewer_id: "",
      approver_id: "",
    })



  }
  function checkFilterDisables() {
    let { page, perPageData, ...rest } = tableSearchData

    return Object.values(rest).some(e => e)
  }

  return (
    <>
      <div style={{ height: "0", overflow: "hidden" }}>
        <table ref={tableRef}>
          <thead>
            <tr>
              <th>Inspection Id</th>
              <th>Inspections Title</th>
              <th>Inspection Type</th>
              <th>Inspection Trade</th>
              <th>Participant</th>
              <th>Conducted By</th>
              <th>Date</th>
              <th>Reponse Received</th>
              <th>Reviewer</th>
              <th>Approver</th>
              <th>Approval Status</th>

            </tr>
          </thead>
          <tbody>
            {pdfData?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.inspectionId}</td>
                  <td>{item.name}</td>
                  <td>{item.inspection_type}</td>
                  <td>{item.trade}</td>
                  <td>{item.participant}</td>
                  <td>{item.conductedBy}</td>
                  <td>{formatDateInIST(item.date)}</td>
                  <td>{hasResponses(item.inspection_section) ? "Y" : "N"}</td>
                  <td>{item.reviewer.first_name}</td>
                  <td>{item.approver.first_name}</td>
                  <td>{item.status}</td>

                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div>
        <div>
          <div className="d-flex flex-sm-row flex-column justify-content-between">
            <h1 className="page_heading">Inspection</h1>
            <div className="right d-flex align-items-center justify-content-end mt-sm-0 mt-3">
              <div className="d-flex ">
                <ExportPdfButton exportToPDF={exportToPDF}
                  csvBtn={<CSVLink
                    className="pe-1"
                    data={csvData}
                    headers={csvHeaders}
                    filename={"Inspection.csv"}
                  >
                    CSV
                  </CSVLink>
                  }
                />
                <div className="ms-3">
                  {getActionsPermission("Create Inspection") && <PrimaryButton
                    onClick={() => navigate("newinspectiontemplate")}
                    addIcon
                    text="Create"
                  />}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center">
            <div className="searchInput">
              <SearchInput
                // placeholder={}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                  setInspectionData(searchDataWithKeys(["name"], allData, e.target.value))
                }}
                onCancel={() => {
                  setSearch("");
                  setInspectionData(allData);
                }}
              />
            </div>
            <div>
              <div className="d-flex text-nowrap gap-2 mt-sm-0 mt-2">
                <Filter
                  className="w-100"
                  filterData={filteredData}
                  setFilterData={setFilteredData}
                  onFilter={filterData}
                />
                <button
                  disabled={!checkFilterDisables()}
                  className="filter_btn w-100"
                  onClick={clearFilter}
                >
                  Clear Filter
                </button>
              </div>
            </div>

          </div>

          <div className="mt-2">
            <div className="tools_table_scroll">
              {skeletonLoader ? (
                <TableSkelton
                  header={[
                    "Inspection Id",
                    "Inspections Title",
                    "Inspection Type",
                    "Inspection Trade",
                    "Participant",
                    "Conducted By",
                    "Date",
                    "Response Received",
                    "Reviewer",
                    "Approver",
                    "Approval Status",
                    "Actions",
                  ]}
                />
              ) : (
                <DataTable value={inspectionData}>
                  <Column
                    header="Inspection Id"
                    sortable
                    field="inspectionId"
                  ></Column>
                  <Column
                    field="name"
                    sortable
                    header="Inspections Title"
                  ></Column>

                  <Column
                    sortable
                    field="inspection_type"
                    header="Inspection Type"
                  ></Column>
                  <Column
                    sortable
                    field="trade"
                    header="Inspection Trade"
                  ></Column>
                  <Column
                    sortable
                    field="participant"
                    header="Participant"
                  ></Column>
                  <Column
                    sortable
                    header="Conducted By"
                    field="conductedBy"
                  ></Column>

                  <Column
                    header="Date"
                    field="date"
                    sortable
                    body={(rowData) => formatDateInIST(rowData.date)}
                  ></Column>
                  <Column
                    header="Response Received"
                    body={(rowData) =>
                      hasResponses(rowData.inspection_section) ? "Y" : "N"
                    }
                  ></Column>
                  <Column
                    sortable
                    header="Reviewer"
                    field="reviewer.first_name"
                  ></Column>
                  <Column
                    sortable
                    header="Approver"
                    field="approver.first_name"
                  ></Column>
                  <Column
                    sortable
                    headerClassName="text-nowrap"
                    field="status"
                    body={(rowData) => {
                      return (
                        <ChangeApprovalStatus
                          status={rowData.status}
                          onSubmit={statusChange}
                          toolData={{
                            tool_id: rowData?._id,
                            tool_number: rowData?.inspectionId,
                            tool_name: rowData?.name,
                            tool: "Inspection",
                            _id: rowData?._id,
                            approver_id: rowData?.approver_id,
                            reviewer_id: rowData?.reviewer_id,
                            reviewer_name: `${rowData?.reviewer?.first_name} ${rowData?.reviewer?.last_name}`,
                            approver_name: `${rowData?.approver?.first_name} ${rowData?.approver?.last_name}`,
                            reviewer_email: `${rowData?.reviewer?.email_address}`,
                            approver_email: `${rowData?.approver?.email_address}`,
                            creator_name:
                              rowData?.creator_email?.[0]?.first_name ||
                              rowData?.creator_email?.[0]?.name,
                            creator_email:
                              rowData?.creator_email?.[0]?.email_address ||
                              rowData?.creator_email?.[0]?.email,
                          }}
                          rowData={rowData}
                        />
                      );
                    }}
                    header="Approval Status"
                  ></Column>

                  <Column
                    header="Actions"
                    style={{ minWidth: "7rem" }}
                    body={(rowData) => {
                      return (
                        <div className="d-flex gap-2 align-items-center">
                          <Popover
                            options={[
                              "Version History",
                              "Edit",
                              "Delete",
                              "View",

                              <DownloadPdfAndSendEmail
                                heading={"INSPECTION"}
                                id={rowData._id}
                                fileName={"Inspection"}
                                files={rowData?.attachFile?.map((e) => e.url)}
                              >
                                <ViewPagesPdfTemplate
                                  header={`${rowData?.name}`}
                                  description={{
                                    key: "Incident Descriptions",
                                    value: (
                                      <HtmlIntoText
                                        html={rowData?.inspection_description}
                                      />
                                    ),
                                  }}
                                  tableData1={[
                                    { "Name": rowData?.name || "" },
                                    { "Inspection Type": rowData?.inspection_type || "--" },
                                    { "Inspection ID": rowData?.inspectionId || "--" },
                                    { "Date": rowData?.date || "--" },
                                    { "Participant": rowData?.participant || "--" },
                                    { "Conducted By": rowData?.conductedBy || "--" },
                                  ]}
                                  tableData2={[
                                    { "Status": rowData?.status || "--" },
                                    { "Inspection Trade": rowData?.trade || "--" },
                                    { "Approver": rowData?.approver?.first_name || "--" },
                                    { "Reviewer": rowData?.reviewer?.first_name || "--" },
                                    { "Description": <HtmlIntoText html={rowData?.description || "--"} /> },
                                  ]}
                                />
                              </DownloadPdfAndSendEmail>,
                              "Send Via Post",
                            ]}
                            onSelect={(index) => {
                              if (index === 0) {
                                if (!getActionsPermission("Inspection version history")) {
                                  toast.error("You don't have permission of Inspection version history")
                                  return
                                }
                                navigate(
                                  `/projectManagement/version-history/INSPECTION/${rowData?.name}/${rowData?._id}`
                                );
                              } else if (index === 1) {
                                if (!getActionsPermission("Edit Inspection")) {
                                  toast.error("You don't have permission to Edit Inspection")
                                  return
                                }
                                navigate(`${rowData?._id}`);

                                // navigate(`inspectionsTemplate/${rowData?._id}`)
                              } else if (index === 2) {
                                if (!getActionsPermission("Delete Inspection")) {
                                  toast.error("You don't have permission to Delete Inspection")
                                  return
                                }
                                handleDelete(rowData?._id);
                              } else if (index === 3) {
                                if (!getActionsPermission("View Inspection")) {
                                  toast.error("You don't have permission to View Inspection")
                                  return
                                }
                                navigate(
                                  `inspectionsTemplate/INSPECTION/${rowData?.name}/${rowData?._id}`
                                );
                              } else if (index === 5) {
                                navigate(
                                  `/projectManagement/addressListing/INSPECTION/${rowData?.name}/${rowData?._id}`
                                );
                              }
                            }}
                          />

                        </div>
                      );
                    }}
                  ></Column>
                </DataTable>
              )}
            </div>

            <br />
            <Pagination
              currentPage={tableSearchData.page}
              totalCount={totalCount}
              pageSize={perPageData}
              onPageChange={(page) => setTableSearchData(pre => ({ ...pre, page }))}
            />
          </div>
        </div>
      </div>
      <DeleteDialog
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        onCancel={() => setInspectionsId(null)}
        onConfirm={confirmDelete}
        header={"Inspection"}
      />
      {/* <Filter visible={visible} setVisible={setVisible} setIsFiltered={setIsFiltered} filteredData={filteredData} setFilteredData={setFilteredData} data={inspectionData} /> */}
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import "./CoordinationIssues.css";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { FaCheck, FaRegClock } from "react-icons/fa";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import {
  deleteIssue,
  filterIssue,
  getIssueByProjectId,
  updateIssue,
} from "../../Api/Admin/AllAPIs";
import Loader from "../../Components/Common/Loader";
import Filter from "./Filter";
import View from "./View";
import { Statuscomp } from "../../Components/Common/StatusComp";
import { getActionsPermission, getProjectCode, getProjectName, getUserId, isApproverOfProject, isReviewerOfProject, searchDataWithKeys, toast } from "../../utils";
import { CSVLink } from "react-csv";
import Popover from "../../Components/Common/Popover";
import DeleteDialog from "../../Components/Common/DeleteDialog";
import { ImCross } from "react-icons/im";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportPdfButton } from "../../Components/Buttons/ExportPdfButton";
import ChangeStatusModal from "../../Components/ChangeStatusModal/ChangeStatusModal";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import { ViewPageTable } from "../../Components/Common/ViewPageComponents/ViewPageTable";
import { DownloadPdfAndSendEmail } from "../../Components/Common/ViewPageComponents/DownloadPdfAndSendEmail";
import Pagination from "../../Components/Common/Pagination/Pagination";
import { HtmlIntoString, HtmlIntoText } from "../../Components/Common/HtmlIntoText";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import { ChangeApprovalStatus } from "../../Components/Tools/ChangeApprovalStatus";
import moment from "moment";
import { CDC_LOGO_URL } from "../../constants";
import { useSelector } from "react-redux";

export const CoordinationIssues = () => {
  let d = useSelector(e => e.rolesAndPermissionData)
  const [currentStatusRowData, setCurrentStatusRowData] = useState({})
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(true);
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [search, setSearch] = useState();
  const [viewRes, setViewRes] = useState();

  // for table
  const [originalIssue, setOriginalIssue] = useState([]);
  const [selectedIssueId, setSelectedIssueId] = useState(null);
  const projectId = localStorage.getItem('projectId')
  const userData = localStorage.getItem("userData");
  const userObj = JSON.parse(userData);
  const userId = userObj._id;
  const userName = userObj.name;


  const [page, setPage] = useState(1)
  const perPageData = 10;
  const [totalCount, setTotalCount] = useState(0);
  const [allData, setAllData] = useState([])
  const [issueData, setIssueData] = useState([])
  const [filteredData, setFilteredData] = useState({
    issue_type: null,
    issue_trade: null,
    issue_priority: null,
    responsiblePerson: null,
  })
  const [tempFilterData, setTempFilterDate] = useState({
    issue_type: null,
    issue_trade: null,
    issue_priority: null,
    responsiblePerson: null,

  })

  const [open, setOpen] = useState(null);
  const [dropdownvalue, setDropDownValue] = useState(null);
  const [comment, setComment] = useState("");
  const [type, setType] = useState("");
  const [itemId, setItemId] = useState("");
  const [statusRequired, setStatusRequired] = useState(false);
  function isAbletoAccess(rowId) {
    return getUserId() == rowId
  }
  async function changeStatus(e) {
    e.preventDefault();

    if (dropdownvalue == null) {
      setStatusRequired(true);
      return;
    }

    setOpen(false);
    setLoader(true);

    try {
      if (type == "reviewer") {
        if (dropdownvalue.code == "approved") {
          let payload = {
            issue_review_status: "approved",
            reviewer_comment: comment,
            issue_approval_status: "under_review",
            issue_status: 'close'
          };

          await updateIssue(itemId, payload);
        } else {
          let payload = {
            issue_review_status: "unapproved",
            reviewer_comment: comment,
            issue_status: "reopen",
            issue_approval_status: "pending",
          };
          await updateIssue(itemId, payload);
        }
      } else if (type == "approver") {
        if (dropdownvalue.code == "approved") {
          let payload = {
            issue_approval_status: "approved",
            approver_comment: comment,
            issue_review_status: "approved",
          };
          await updateIssue(itemId, payload);
        } else {
          let payload = {
            issue_review_status: "under_review",
            issue_approval_status: "unapproved",
            approver_comment: comment,
          };
          await updateIssue(itemId, payload);
        }
      } else {
        console.log("nonooo");
      }
    } catch (err) {
      console.log(err);
    } finally {
      await handleGetIssue();
      setItemId("");
      setType("");
      setLoader(false);
      setDropDownValue(null);
    }
  }



  function changeToCap(str) {
    return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`
  }




  const handleGetIssue = async () => {
    try {
      let params = {
        page,
        perPageData
      }

      setSearch("")
      setSkeletonLoader(true);
      let body = { ...tempFilterData }
      const res = await getIssueByProjectId(projectId, params, body);
      console.log(res);
      const sortedData = (res?.data?.data || []).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setIssueData(sortedData)
      setTotalCount(res?.data?.totalCount || 0)
      setAllData(sortedData)
      setOriginalIssue(sortedData)


      setLoader(false);
    } catch (err) {
      console.error(err);
    }
    finally {
      setSkeletonLoader(false);
    }
  };

  const handleDelete = async (id) => {
    setVisibleDelete(true);
    setSelectedIssueId(id);
  };

  const confirmDelete = async () => {
    setLoader(true);
    let payload = {
      created_by: userName,
    }
    try {
      await deleteIssue(selectedIssueId, payload);
      handleGetIssue();
      setLoader(false);
      toast.success("Issue Deleted Successfully!");
    } catch (error) {
      console.log(error);
      toast.error("Something Wents Wrong, Please Try Again!");
    }
  };
  const [filterActive, setFilterActive] = useState(false)

  useEffect(() => {
    if (projectId) {
      handleGetIssue();
    }
  }, [page, tempFilterData]);


  const csvData = originalIssue.map((item) => ({
    Title: item.issue_title,
    Description: HtmlIntoString({ html: item.issue_description }),
    Location: item.issue_location,
    "Responsible Member": item?.responsibleMember?.map(res => res?.first_name)?.join(', '),
    "Responsible Contractor Company": item?.responsibleContractor?.map(res => res?.company_name)?.join(', '),
    "Responsible Person": item?.responsiblePerson?.map(res => res?.first_name)?.join(', '),
    "Issue Identified By": item?.Issue_IdentifiedBy[0]?.first_name,
    "Due Date": moment(item?.issue_dueDate).format('DD-MM-YYYY'),
    Priority: item.issue_priority?.name,
    Trade: item.issue_trade?.name,
    Type: item.issue_type?.name,
    // "Priority Type": item.issue_priorityType,
    // "Assignee Company": item.issue_assigneeCompany,
    // Status: item.issue_status,
    // Deleted: item.is_deleted,
    // "Created At": item.created_at,
  }));

  const csvHeaders = [
    { label: "Title", key: "Title" },
    { label: "Description", key: "Description" },
    { label: "Location", key: "Location" },
    { label: "Responsible Member", key: "Responsible Member" },
    { label: "Responsible Contractor Company", key: "Responsible Contractor Company" },
    { label: "Responsible Person", key: "Responsible Person" },
    { label: "Issue Identified By", key: "Issue Identified By" },
    { label: "Due Date", key: "Due Date" },
    { label: "Priority", key: "Priority" },
    { label: "Trade", key: "Trade" },
    { label: "Type", key: "Type" },

    // { label: "Status", key: "Status" },

  ];

  function changeStatusUi(status) {
    switch (status) {
      case 'draft':
        return <div className="statusOuterD">
          <div className="statusDotD" />
          <div className="StatusD">Draft</div>
        </div>
        break;
      case 'inreview':
        return <div className="statusOuterI">
          <div className="statusDotI" />
          <div className="StatusI">In Review</div>
        </div>
        break;
      case 'pendingapproval':
        return <div className="statusOuterP">
          <div className="statusDotP" />
          <div className="StatusP">Pending Approver</div>
        </div>
        break;
      case 'approved':
        return <div className="statusOuterA">
          <div className="statusDotA" />
          <div className="StatusA">Approved</div>
        </div>
        break;

      default:
        break;
    }
  }

  const getId = (id) => {
    console.log(id);

    navigate(`edit/${id}`);
  };
  const tableRef = useRef();

  const exportToPDF = () => {

    const doc = new jsPDF();



    const imgData = CDC_LOGO_URL
    const imgW = 20;
    const imgH = 10;
    const imageX = 5;
    const imageY = 5;
    doc.addImage(imgData, 'JPEG', imageX, imageY, imgW, imgH);
    const projectName = `${getProjectName()} (Project Id: ${getProjectCode() || "-"})`;
    const textX = imageX + imgW + 6;
    const textY = imageY + 7.5;
    // Add project name text to the PDF
    doc.setFontSize(16);
    doc.text(projectName, textX, textY);



    doc.autoTable({
      html: tableRef.current,
      startY: 30,
      didParseCell: function (data) {
        data.cell.styles.fontSize = 2;
      },
      didDrawPage: function (data) {

      },
    });

    // Save the PDF
    doc.save('Issue.pdf');
  };

  let navigate = useNavigate();

  function filterData() {
    console.log(filteredData)

    setTempFilterDate(filteredData)
    setPage(1)
    setFilterActive(true)
  }
  function clearFilter() {
    setFilterActive(false)
    setFilteredData({
      issue_type: null,
      issue_trade: null,
      issue_priority: null,
      responsiblePerson: null,
    })
    setTempFilterDate({
      issue_type: null,
      issue_trade: null,
      issue_priority: null,
      responsiblePerson: null,
    })
  }

  async function statusChange(data, id, toolId, comment) {
    try {
      let res = await updateIssue(id, { ...data, ...comment, issue: toolId });
      handleGetIssue()

    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <ChangeStatusModal
        open={open}
        setOpen={setOpen}
        value={dropdownvalue}
        setValue={setDropDownValue}
        onSubmit={changeStatus}
        setComment={setComment}
        statusRequired={statusRequired}
        setStatusRequired={setStatusRequired}
      />

      <div className="coordination">
        <div>
          {/* top-bar  */}
          <div className="between">
            <h1 className="page_heading">ISSUES</h1>

            <div className="flex align_center gap-2">
              <div className="right d-flex gap-2 align-items-center">
                <ExportPdfButton
                  exportToPDF={exportToPDF}
                  csvBtn={
                    <CSVLink
                      className="pe-1"
                      data={csvData}
                      headers={csvHeaders}
                      filename={"issue.csv"}
                    >
                      CSV
                    </CSVLink>
                  }
                />
              </div>
              {getActionsPermission("Create Issue") && <PrimaryButton
                addIcon
                text={"Create"}
                onClick={() => navigate("create")}
              />}
            </div>
          </div>

          <div style={{ height: "0", overflow: "hidden" }}>
            <table ref={tableRef}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Location</th>
                  <th>Responsible Member</th>
                  <th>Responsible Contractor</th>
                  <th>Responsible Person</th>
                  <th>Issue IdentifiedBy</th>
                  <th>Due Date</th>
                  <th>Issue Type</th>
                  <th>Issue Priority</th>
                  <th>Issue Trade</th>
                  {/* <th>Deleted</th>
                  <th>Created At</th> */}
                </tr>
              </thead>
              <tbody>
                {originalIssue?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.issue_title}</td>
                      <td><HtmlIntoText html={item.issue_description} /></td>
                      <td>{item.issue_location}</td>
                      <td>{item?.responsibleMember?.map(res => res?.first_name)?.join(', ')}</td>
                      <td>{item?.responsibleContractor?.map(res => res?.company_name)?.join(', ')}</td>
                      <td>{item?.responsiblePerson?.map(res => res?.first_name)?.join(', ')}</td>
                      <td>{item?.Issue_IdentifiedBy[0]?.first_name}</td>
                      <td>{moment(item?.issue_dueDate).format('DD-MM-YYYY')}</td>
                      <td>{item.issue_type?.name}</td>
                      <td>{item.issue_priority?.name}</td>
                      <td>{item.issue_trade?.name}</td>
                      {/* <td>{item.issue_status}</td> */}
                      {/* <td>{item.is_deleted}</td>
                      <td>{item.created_at}</td> */}
                    </tr>
                  );
                })}

              </tbody>
            </table>
          </div>


          <div className="tabView">


            <div className="flex between ms-5"></div>
            <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center">
              <div className="searchInput">
                <SearchInput
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                    // setSpecificationData(searchDataWithKeys(["numberToIgnore", "defaultRevision"], allData, e.target.value))
                    setIssueData(searchDataWithKeys(["issue_title"], allData, e?.target?.value))
                  }}
                  onCancel={() => {
                    setSearch("")
                    setIssueData(allData)
                    handleGetIssue()
                  }}
                />
              </div>

              <div className="d-flex text-nowrap gap-2 mt-sm-0 mt-2">
                <Filter
                  className="w-100"
                  filterData={filteredData}
                  setFilterData={setFilteredData}
                  onFilter={filterData}
                  disabled={!filterActive}
                />
                <button
                  disabled={!filterActive}
                  className="filter_btn w-100" onClick={clearFilter}>Clear Filter</button>
              </div>
            </div>
            <br />

            <div style={{ width: '100%', overflow: 'auto' }}>
              <div style={{ width: '2400px' }}>
                {

                  skeletonLoader ?
                    <TableSkelton
                      header={[
                        " Issue ID",
                        " Issue Title ",
                        " Issue Type",
                        "Priority",
                        " Trade/Location",
                        " Resposible Person",
                        " Responsible contractor company/Responsible contractor Name",
                        " Issue Identified by",
                        "Due Date",
                        "Approval Status",
                        "Action",
                      ]}
                    />
                    :
                    <BorderedTable>
                      <DataTable
                        value={issueData}
                        sortMode="multiple"
                      // rowGroupMode="subheader" groupRowsBy="representative.name"
                      // expandableRowGroups expandedRows={expandedRows} onRowToggle={(e) => {
                      //     setExpandedRows(e.data)
                      //     console.log(e.data)
                      // }}



                      >
                        <Column sortable field="issue_ID" header=" Issue ID"
                          style={{ width: "10rem" }}
                          body={(rowData) => (
                            <div
                              className=""

                            >
                              {rowData?.issue_ID}
                              {/* {console.log(rowData?.issue_ID)} */}
                            </div>
                          )}
                        ></Column>
                        <Column
                          style={{ width: "10rem" }}
                          sortable
                          field="issue_title"
                          header="Issue Title "
                          body={(rowData) => (
                            <div
                              className=""
                            // onClick={
                            //   () => navigate(`view/${rowData._id}`)
                            //   // handleView(rowData)
                            // }
                            >
                              {rowData?.issue_title}
                              {console.log(rowData?.issue_title)}
                            </div>
                          )}
                        ></Column>

                        <Column
                          sortable
                          field="issue_type"
                          header=" Issue Type"
                          // style={{ width: "10rem" }}

                          body={(rowData) => {
                            const issueType = rowData?.issue_type;
                            if (issueType && typeof issueType === 'object') {
                              return <div>{issueType.name || 'N/A'}</div>;
                            }
                            return <div>{issueType || 'N/A'}</div>;
                          }}></Column>

                        <Column sortable field="issue_priority" header="Priority"
                          // style={{ width: "10rem" }}
                          body={(rowData) => {
                            return (
                              <div >
                                {rowData?.issue_priority?.name}
                              </div>
                            )
                          }}></Column>
                        {/* <Column
                          field="issue_assigneeCompany"
                          header="Assignee Company"
                        ></Column> */}
                        <Column sortable field="issue_trade" header=" Trade"
                          // style={{ width: "12rem" }}
                          body={(rowData) => {
                            return (
                              <div >
                                {rowData?.issue_trade?.name}
                              </div>
                            )
                          }}>

                        </Column>
                        <Column
                          field="responsiblePerson"
                          sortable
                          style={{ width: "13rem" }}
                          header="Responsible Person"
                          body={(rowData) => {
                            let data = rowData?.
                              responsiblePerson
                              ?.map(res => res?.first_name)?.join(', ')
                            return (
                              <div title={data}>
                                {data || "--"}
                              </div>
                            )
                          }}
                        ></Column>
                        <Column

                          headerClassName="text-nowrap"
                          body={(rowData) => {
                            let data = rowData?.responsibleContractor?.map((res) => {
                              let pp = rowData?.responsibleMember?.filter(
                                (item) => item?.company == res?._id
                              );
                              console.log(pp)
                              return {
                                company: res?.company_name,
                                persons:
                                  pp?.length == 0
                                    ? "No Person Added"
                                    : pp?.map((ress) => ress?.first_name)?.join(", "),
                              };
                            });
                            // let data = rowData?.responsibleContractor?.map(res => res?.company_name)?.join(', ')
                            return (
                              <div className="d-flex flex-column">
                                {data?.map((res, index) => (
                                  <div key={index}>
                                    {res?.company} - {res?.persons}
                                  </div>
                                ))}
                              </div>
                            );
                          }}

                          header="Responsible Contractor Company / Name"></Column>
                        {/* <Column
                    headerClassName="text-nowrap"
                    header="Responsible Contractor Company / Name"
                    body={(rowData) => {
                      if (!rowData || !rowData.responsibleContractor) {
                        return <div>No data available</div>;
                      }

                      // Safeguard against undefined rowData.responsibleContractor
                      let data = rowData.responsibleContractor.map(res => {
                        let pp = rowData.MembersResponsible
                          ? rowData.MembersResponsible.filter(item => item?.
                            company === res?._id)
                          : [];

                        return {
                          company: res?.company_name || "Unknown Company",
                          persons: pp.length === 0
                            ? "No person added"
                            : pp.map(e => e?.first_name).join(', ')
                        };
                      });

                      return (
                        <div className="d-flex flex-column">
                          {data.map((res, index) => (
                            <div key={index}>{res.company}---{res?.persons}</div>
                          ))}
                        </div>
                      );
                    }}
                  />              */}
                        <Column
                          // style={{ width: "15rem" }}
                          sortable
                          field="Issue_IdentifiedBy"
                          header="Issue Identified by"
                          body={(rowData) => {
                            let data = rowData?.Issue_IdentifiedBy?.map(res => res?.first_name)?.join(', ')
                            return (
                              <div title={data || "--"}>
                                {data?.slice(0, 15)}{data?.length > 15 ? '...' : ''}
                              </div>
                            )
                          }}
                        ></Column>

                        <Column sortable field="issue_dueDate" header="Due Date"

                          // style={{ width: "10rem" }}
                          body={(rowData) => moment(rowData?.issue_dueDate).format('DD-MM-YYYY')}
                        ></Column>
                        <Column
                          sortable
                          field="status"
                          headerClassName="text-nowrap"
                          body={(rowData) => {
                            return (<ChangeApprovalStatus
                              status={rowData.status}
                              onSubmit={statusChange}
                              toolData={{
                                tool_id: rowData?._id,
                                tool_number: rowData?.issue_ID,
                                tool_name: rowData?.issue_title,
                                tool: "Issues",
                                _id: rowData?._id,
                                approver_id: rowData?.approver_id,
                                reviewer_id: rowData?.reviewer_id,
                                reviewer_name: `${rowData?.reviewer?.first_name} ${rowData?.reviewer?.last_name}`,
                                approver_name: `${rowData?.approver?.first_name} ${rowData?.approver?.last_name}`,
                                reviewer_email: `${rowData?.reviewer?.email_address}`,
                                approver_email: `${rowData?.approver?.email_address}`,
                                creator_name: rowData?.creator_email?.[0]?.first_name || rowData?.creator_email?.[0]?.name,
                                creator_email: rowData?.creator_email?.[0]?.email_address || rowData?.creator_email?.[0]?.email,
                              }}
                              rowData={rowData}
                            />
                            )
                          }}
                          header="Approval Status"></Column>
                        <Column
                          header="Action"
                          body={(rowData) => (
                            <div className="d-flex justify-content-center">
                              <Popover
                                options={["Version History", "Edit", "Delete", "View",
                                  <DownloadPdfAndSendEmail
                                    fileName={"Issues"}
                                    heading={"Issues"}
                                    id={rowData._id}
                                    files={rowData?.forms_file?.map(e => e.url)}
                                  >
                                    <ViewPagesPdfTemplate
                                      header={`#Issue ${rowData?.issue_title}`}
                                      tableData1={[
                                        { "Issue Title": rowData?.issue_title || "" },
                                        { "Issue ID": rowData?.issue_ID || "" },

                                        { "Responsible Person": rowData?.responsiblePerson?.map(e => e?.first_name).join(", ") || "--" },
                                        { Location: rowData?.issue_location || "" },

                                        {
                                          "Responsible Contractor Company": rowData?.responsibleContractor?.map(e => e?.
                                            company_name).join(", ") || "--"
                                        },
                                        { "Reviewer": rowData?.reviewer?.first_name || "--" },
                                        { "Approver Comment": rowData?.approver_comment || "--" },
                                      ]}
                                      tableData2={[
                                        { "Due Date": moment(rowData?.issue_dueDate).format("DD/MM/YYYY") },
                                        { "Issue Type": rowData?.issue_type?.name || "--" },
                                        { "Responsible Member": rowData?.responsibleMember?.map(e => e?.first_name).join(", ") || "--" },
                                        {
                                          "Issue Identified by": rowData?.
                                            Issue_IdentifiedBy?.map(e => e?.first_name).join(", ") || "--"
                                        },
                                        ,
                                        { Trade: rowData?.issue_trade?.name || "--" },
                                        { Priority: rowData?.issue_priority?.name || "--" },
                                        { "Approver": rowData?.approver?.first_name || "--" },
                                        { "Reviewer Comment": rowData?.approver_comment || "--" }
                                      ]}
                                    />
                                  </DownloadPdfAndSendEmail>,
                                  "Send Via Post"]}
                                onSelect={(index) => {
                                  if (index === 0) {

                                    if (!getActionsPermission("Issue version history")) {
                                      toast.error("You don't have permission of Issue version history")
                                      return
                                    }
                                    navigate(
                                      `/projectManagement/version-history/ISSUE/${rowData?.issue_title}/${rowData?._id}`
                                    );
                                    // navigate(`/versionHistory/Issues/${rowData.issue_title}/${rowData._id}`)
                                  } else if (index === 1) {
                                    if (!getActionsPermission("Edit Issue")) {
                                      toast.error("You don't have permission to edit Issue")
                                      return
                                    }
                                    getId(rowData?._id);
                                  } else if (index === 2) {
                                    if (!getActionsPermission("Delete Issue")) {
                                      toast.error("You don't have permission to delete Issue")
                                      return
                                    }
                                    handleDelete(rowData?._id);
                                  }
                                  else if (index === 3) {
                                    if (!getActionsPermission("View Issue")) {
                                      toast.error("You don't have permission to view Issue")
                                      return
                                    }
                                    navigate(`view/ISSUE/${rowData?.issue_title}/${rowData?._id}`)
                                  }
                                  else if (index === 5) {
                                    navigate(`/projectManagement/addressListing/ISSUE/${rowData?.issue_title}/${rowData?._id}`)
                                  }
                                }}
                              />

                            </div>
                          )}
                        ></Column>
                      </DataTable>
                    </BorderedTable>}
              </div>
              <br />
              <Pagination
                currentPage={page}
                totalCount={totalCount}
                pageSize={perPageData}
                onPageChange={setPage}
              />



            </div>


          </div>

          {/* graph view  */}
        </div>
        <DeleteDialog
          visible={visibleDelete}
          setVisible={setVisibleDelete}
          onCancel={() => setSelectedIssueId(null)}
          onConfirm={confirmDelete}
          header={"Issue"}
        />
        {/* <Filter
          filterVisible={filterVisible}
          setFilterVisible={setFilterVisible}
          res={customers}
          applyFilter={filterData}
        /> */}
        <View visible={visible} setVisible={setVisible} res={viewRes} />
      </div>

    </>
  );
};
